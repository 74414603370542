import { Input } from '@angular/core';
import { Data } from '@angular/router';
import { Type } from 'class-transformer';
import { LojaEstado } from './lojaEstado';

export class Loja {
  @Type(() => Date)
  dataAbertura: Date;
  @Type(() => Date)
  dataHoraAlteracao: Date;
  @Type(() => Date)
  dataHoraInclusao: Date;

  public abreviacao: string;
  public acessoSemLogin: string;
  public ativo: string = 'S';
  public bairro: string;
  public cidade: string;
  public cidadeIbge: string;
  public cnae: string;
  public cnpj: string;
  public contador: string;
  public contadorCidade: string;
  public contadorCpf: string;
  public contadorCrc: string;
  public contadorEmail: string;
  public contadorIdCidade: number;
  public contadorPessoaJuridica: string = 'N';
  public dataInicioSistema: Date;
  public ddd: number;
  public carenciaDias: number = 0;
  public ehValidoCnpjCpf: boolean = true;
  public email: string;
  public endereco: string;
  public enderecoCep: string;
  public enderecoComplemento: string;
  public enderecoNumero: number;
  public enderecoTipo: string = '';
  public enquadramento: number;
  public estado: string;
  public estadoIbge: number;
  public foto: string;
  public fotoLoja: string;
  public historicoFoto: string = 'N';
  public id: number;
  public idBairro: number = 0;
  public idCidade: number = 0;
  public idConfFinanceiroContabil: number;
  public idConfTransferenciaContabil: number;
  public idEmpresa: number;
  public idEstado: number;
  public idFinanceiroContabilDespesa: number;
  public idLojaMatriz: number;
  public idLojaValor: number;
  public idPais: number;
  public idParceiroTransferencia: number;
  public idPraca: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public inscricaoEstadual: string;
  public inscricaoMunicipal: string;
  public juroPercentual: number = 0;
  public latitude: string;
  public lojaEstados: LojaEstado[] = [];
  public longitude: string;
  public margemLucroDiferenciada: string = 'N';
  public mdfeSerie: number;
  public mdfeAmbiente: number;
  public mdfeEnvio: string;
  public nfeEmail: string = 'N';
  public nfeMensagem: string;
  public nomeFantasia: string;
  public pais: string;
  public parceiro: string;
  public praca: string;
  public razaoSocial: string;
  public receituarioEntregaEmbalagem: string;
  public receituarioIgnorarCulturaPro: string = 'N';
  public receituarioQuantidadeProduto: number;
  public regraPrecoVenda: number = 0;
  public regraVariacaoCusto: number;
  public responsavel: string;
  public serie: string;
  public site: string;
  public spedContCritEscrituracao: number;
  public spedContInciTributaria: number;
  public spedContMetoApropriacao: number;
  public spedContTipoAtividade: number;
  public spedContTipoContribuacao: number;
  public spedFiscalC197Ajuste: string;
  public spedFiscalE116Receita: string;
  public spedFiscalE200Filho: string;
  public spedFiscalE250Receita: string;
  public spedFiscalPerfil: string;
  public telefone: string;
  public uf: string;
  public url: string;
  public nfeEmailCopia: string;
  public codigoReceitaE116Fiscal: string;
  public enviarIbptNfe: string = 'S';
  public tipoContingencia: string;
  public dataHoraContingencia: Data;
  public hashIntegracao: string;
  public spedFiscalEnviarHMensal: string = 'N';
  public spedFiscalEnviarKMensal: string = 'N';
  public spedValorMercadoriaLiquido: string = 'S';
  public receituarioEletronicoAmbiente: number = 0;
  public status: string = 'H';
  public cteSerie: number;
  public cteAmbiente: number;
  public cteEnvio: string;
  public sat: string;
  public idConsumidorFinal: number;
  public consumidor: string;


  @Input()
  set cep(cep: string) {
    this.enderecoCep = cep;
  }
  get cep(): string {
    return this.enderecoCep;
  }
  @Input()
  set complemento(complemento: string) {
    this.enderecoComplemento = complemento;
  }
  get complemento(): string {
    return this.enderecoComplemento;
  }

  @Input()
  set numero(numero: number) {
    this.enderecoNumero = numero;
  }
  get numero(): number {
    return this.enderecoNumero;
  }
}
