import { Component } from '@angular/core';
import { RelatorioGrupo } from 'src/app/interface/relatorioGrupo';
import { Criterio } from 'src/app/modelo/criterio';
import { RelatorioAdicional } from 'src/app/modelo/relatorioAdicional';
import { RelatorioComponent } from '../relatorio.component';
import filtros from './acessorio/filtro';
import grupos from './acessorio/grupo';
import totais from './acessorio/total';

@Component({
  templateUrl: './r1110.component.html',
})
export class R1110Component extends RelatorioComponent {
  public financeiros: any[] = [];
  public filtros = filtros;
  public grupos = grupos;
  public relatorioGrupo: RelatorioGrupo = { totais: totais, linhas: [] };
  public usaDataVencimento: boolean = false;
  public maisInformacoes: boolean = false;
  public rotuloContaBancaria: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 29).apelido;
  public rotuloTipoTitulo: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 30).apelido;
  public rotuloContaCentroResultado: string = this.utilSessao.getMenuEmpresa().find((menuEmpresa) => menuEmpresa.idMenu == 32).apelido;

  listarRelatorio(criterios: Criterio[]): void {
    super.listar(criterios).subscribe(() => {
      this.financeiros = this.relatorioResultados.find((relatorioResultado) => relatorioResultado.dado == 'Financeiro').lista;
      this.ordenarAgrupar(this.financeiros);
      this.ehImprimirRelatorio();
    });
  }

  definirRegraRelatorio(relatorioAdicional: RelatorioAdicional): void {
    this.usaDataVencimento = this.definirRegra(relatorioAdicional, 190, this.usaDataVencimento);
    this.maisInformacoes = this.definirRegra(relatorioAdicional, 224, this.maisInformacoes);
    this.atualizarTotalizador();
    this.ehModoPaisagem();
  }

  atualizarTotalizador(): void {
    let valor: number = 0;
    valor += this.usaDataVencimento ? 1 : 0;
    valor -= this.maisInformacoes ? 1 : 0;
    this.posicionarTotalizador('diaAtraso', this.verificarMaiorAtraso(), this.usaDataVencimento ? 1 : 0);
    this.posicionarTotalizador('valorOriginal', true, valor);
    this.posicionarTotalizador('desconto', true, valor);
    this.posicionarTotalizador('juro', true, valor);
    this.posicionarTotalizador('valorRecebimento', true, valor);
  }

  ehModoPaisagem(): void {
    if (this.maisInformacoes) {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', 'N', ''));
    } else {
      this.utilNotificacao.setRelatorioAdicional(new RelatorioAdicional(1, 'RETRATO', '', 'S'));
    }
  }

  verificarMaiorAtraso(): boolean {
    let somaDias: number = 0;
    this.financeiros.forEach((financeiro) => (somaDias += financeiro.diaAtraso));
    if (somaDias > 0) {
      return true;
    } else {
      return false;
    }
  }
}
