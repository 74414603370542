<link *ngIf="cssUrl" rel="stylesheet" [href]="sanitizer.bypassSecurityTrustResourceUrl(cssUrl)" />
<div [ngClass]="{ 'folha-retrato': retrato, 'folha-paisagem': !retrato, 'relatorio-modo-escuro': !usaModoClaro }" class="centraliza-folha">
  <layout [idRelatorioInterno]="_idRelatorioInterno" [titulo]="_relatorio && _relatorio.layoutEspecifico == 'N' && _relatorio.dashboard == 'N' ? _relatorio.id + ' - ' + _relatorio.nome : null" [subTitulo]="_subTitulo" [tag]="_relatorio ? _relatorio.id : null" [filtros]="_filtros" [relatorio]="_relatorio" [componente]="componente" (relatorioTituloAdicional)="relatorioTituloAdicional = $event" (filtroEmt)="setCriterios($event)" [utilitarios]="utilitarios" (utilitarioEmt)="verificarUtilitario($event)">    
    <div class="folha" [ngClass]="{ 'folha-retrato': retrato, 'folha-paisagem': !retrato, matricial: matricial, 'relatorio-modo-escuro': !usaModoClaro, 'remove-padding-top': _relatorio.dashboard == 'S' }" (click)="ehFecharBotoesAdicionais()">     
      <div class="row">
        <div class="col-sm-1" *ngIf="_relatorio && (_relatorio.layoutEspecifico == 'N' || apresentaEmpresa) && _empresa.foto == 'S' && dadoEmpresa"><img [src]="_empresa.fotoEmpresa" [alt]="_empresa.nome" height="50" width="50"/></div>
        <div [ngClass]="_relatorio && (_relatorio.layoutEspecifico == 'N' || apresentaEmpresa) && _empresa.foto == 'S' && dadoEmpresa ? 'col-sm-9' : 'col-sm-10'">
          <h1 *ngIf="_relatorio.dashboard == 'N'" [ngClass]="matricial ? 'matricial' : 'relatorio-titulo'">{{ _relatorio && _relatorio.layoutEspecifico == 'N' ? _relatorio.id + ' - ' + _relatorio.nome + nomeAdicional + (relatorioTituloAdicional ? ' - ' + relatorioTituloAdicional : '') : '' }}</h1>
          <div *ngIf="dadoEmpresa && _relatorio && (_relatorio.layoutEspecifico == 'N' || apresentaEmpresa)">
            <h2 [ngClass]="matricial ? 'matricial' : 'relatorio-empresa'">{{ _empresa.nome }}</h2>
          </div>
        </div>
        <div *ngIf="_relatorio.layoutEspecifico == 'N'" class="col-sm-2 text-right"><span [ngClass]="matricial ? 'matricial' : 'relatorio-rotulo'">{{ bibDialogo.versao.toUpperCase() + ': ' }}</span> {{ _relatorio ? _relatorio.versao : '' }}</div>
      </div>
      <div *ngIf="_relatorio && _relatorio.layoutEspecifico != 'S'" [ngClass]="matricial ? 'matricial' : 'criterios row'">
        {{ bibDialogo.criterios.toUpperCase() + ' =  ' + (criterioNomes.length == criterios.length ? 'NENHUM' : '') }}
        <ng-container *ngIf="criterioNomes.length != criterios.length">
          <ng-container *ngFor="let criterio of criterios; let i = index">
            <ng-container *ngIf="criterio.rotulo != null && criterio.visualizacaoInicial">
              {{ criterio.valor != null && criterio.nome != 'ID_RELATORIO' ? criterio.rotulo + ': ' + (criterio.tipo == 1 ? (criterio.valor | data) : criterio.tipo == 3 ? criterio.valor : criterio.apresentacao) + (i + 1 == criterios.length || (criterios[i + 1] && !criterios[i + 1].rotulo) ? '' : ' | ') : null }}
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div *ngIf="_relatorio.dashboard == 'N' && _relatorio.layoutEspecifico != 'S'" class="divSeta naoImprimir">
        <img class="imagemSeta" [src]="'img/seta.png'" height="40px" width="54px" />
        <div class="atencaoOrdenar">
          <atencao [atencao]="bibDialogo.cliqueParaOrdenar" [tipo]="bibPropriedade.atencao.informativo"></atencao>
        </div>
      </div>  

      <div class="mb-5" [ngClass]="[modal == true ? '' : 'relatorio-tabela', matricial && linhaDivisoria ? 'linha-divisoria-matricial' : '', linhaDivisoria && !matricial ? 'linha-divisoria' : '']">      
        <ng-content></ng-content>
      </div>

      <div *ngIf="_relatorio.layoutEspecifico != 'S'">
        <footer class="rodape-esquerdo">
          <p class="inferior somente-imprimir">{{ bibDialogo.usuario + ': ' + nomeUsuario + ' | ' + bibDialogo.dataHoraImpressao + ' ' + (dataAtual | data: 'dd/MM/yy HH:mm') }}</p>
        </footer>
      </div>
    </div>
  </layout>
</div>
<div class="naoImprimir opcao" [ngClass]="{ 'page-sidebar has-open': mostraAdicional }">
  <div *ngIf="mostraAdicional" class="list-group list-group-flush">
    <div class="justify-content-end mt-2 mb-2 mr-4">
      <button class="btn btn-outline-primary naoImprimir d-flex flex-row align-items-center" (click)="mostraAdicional = false" tabindex="-1">{{ this.bibDialogo.fechar }}</button>
    </div>
    <ng-container *ngFor="let relatorioAdicional of _relatorioAdicionais; let i = index">
      <ng-container *ngIf="relatorioAdicional.oculto == 'N' && relatorioAdicional.id != 4">
        <div *ngIf="_relatorio.layoutEspecifico == 'S' ? (relatorioAdicional.id > 100 || (relatorioAdicional.id == 3 && apresentaEmpresa)) : (_relatorio || relatorioAdicional.id > 100) && relatorioAdicional.mostraAdicional != 'N'">
          <div [ngClass]="i == 4 ? 'border-top border-dark' : ''" class="list-group-item d-flex justify-content-between align-items-center">
            <span>{{ relatorioAdicional.nome }} </span>
            <interruptor [tabindex]="-1" [campo]="relatorioAdicional.marcado" (alteracao)="setRelatorioAdicional(relatorioAdicional)"></interruptor>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="relatorio-footer naoImprimir" *ngIf="_grupos.length > 0 || _ordens.length > 0">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-4 pt-3 margem-agrupar-ordenar"><lista *ngIf="_ordens.length > 0" [rotulo]="bibDialogo.ordenar" [campo]="_ordens.length > 1 ? _ordens[0].id : campoId" [lista]="_ordens" (alteracao)="setCampoOrdem($event.campo)" [compacto]="true" [obrigatorio]="_ordens.length > 1"></lista></div>
      <div class="col-sm-4 pt-3 margem-agrupar-ordenar"><lista *ngIf="_grupos.length > 0" [rotulo]="bibDialogo.agrupar" [campo]="campoId" [lista]="_grupos" (alteracao)="setCampoGrupo($event)" [compacto]="true" [icone]="campoGrupo != null ? iconeAgrupaTotalizador : null" [ajuda]="campoGrupo != null ? mensagemIconeAgrupaTotalizador : null" (btnAdicional)="agruparTotalizadores()" [iconeDesabilitado]="false"></lista></div>
      <div role="button" class="p-2 rodape negrito naoImprimir">{{ bibDialogo.versaoSistema }}</div>      
    </div>
  </div>
</div>

