import { Lote } from '../agrow/modelo/lote';
import { ProdutoFormuladoDose } from '../agrow/modelo/produtoFormuladoDose';
import { ProdutoUnidade } from '../agrow/modelo/produtoUnidade';
import { Receituario } from '../agrow/modelo/receituario';
import { TabelaPrecoConfiguracao } from '../agrow/modelo/tabelaPrecoConfiguracao';
import { Util } from '../utilitario/util';
import { UtilSessao } from '../utilitario/util.sessao';
import { UtilProduto } from '../utilitario/utilProduto';
import { CfopEmpresa } from './cfopEmpresa';
import { Empresa } from './empresa';
import { Estoque } from './estoque';
import { Local } from './local';
import { Loja } from './loja';
import { MovimentacaoParcela } from './movimentacaoParcela';
import { MovimentacaoQuantidade } from './movimentacaoQuantidade';
import { Negociacao } from './negociacao';

export class MovimentacaoProduto {
  private cofinsAliquota: number = 0;
  private cofinsBase: number = 0;
  private cofinsValor: number = 0;
  public icmsAliquota: number = 0;
  private icmsBase: number = 0;
  public icmsInterestadual: number;
  public icmsDesonerado: number = 0;
  public icmsDesoneradoInicial: number = 0;
  private icmsStBase: number = 0;
  private icmsStValor: number = 0;
  private icmsValor: number = 0;
  private ipiAliquota: number = 0;
  private ipiBase: number = 0;
  private ipiValor: number = 0;
  private pisAliquota: number = 0;
  private pisBase: number = 0;
  private pisValor: number = 0;
  public quantidade: number = 1;
  public quantidadeConvertida: number = 1;
  private valorUnitario: number;
  public abreviacao: string;
  public acrescimoValor: number = 0;
  public apresentaTabelaPreco: boolean = false;
  public bloqueadoEstoque: string = 'N';
  public bloqueadoEstoqueNfe: string = 'N';
  public bloqueadoFinanceiro: string = 'N';
  public categoria: string;
  public cfopBloqueadoEstoque: string;
  public cfopBloqueadoFinanceiro: string;
  public cfopBloqueiaQuantidadeFaturada: string;
  public cfopEmpresa: CfopEmpresa;
  public cfopEntrada: boolean;
  public cfopSaida: boolean;
  public cfopSaidaNfe: string;
  public classeToxicologica: string;
  public codigoBeneficioFiscal: string;
  public comissaoPercentual: number = 0;
  public comissaoValor: number = 0;
  public comissaoOrigem: number = 0;
  public cultura: string;
  public custoGerencial: number = 0;
  public custoNfe: number = 0;
  public custoReposicao: number = 0;
  public descontoPercentual: number;
  public descontoRateado: number = 0;
  public descontoRateadoUnitario: number = 0;
  public descontoValor: number;
  public descontoPercentualUnitario: number = 0;
  public descontoValorUnitario: number = 0;
  public descricao: string;
  public despesaRateado: number = 0;
  public devolucao: string;
  public devolucaoSaida: boolean = false;
  public devolucaoVinculada: string;
  public estoqueComprado: number = 0;
  public estoqueDisponivel: number = 0;
  public estoqueFisico: number = 0;
  public estoqueNfeRegra: number = 0;
  public estoqueReservado: number = 0;
  public estoqueTransferido: number = 0;
  public estoqueCarteira: number = 0;
  public estoques: Estoque[];
  public expandido: boolean = false;
  public expandidoCultura: boolean = false;
  public fator: number;
  public financeiroGerado: string;
  public financeiroGeradoVinculado: string;
  public foto: string;
  public freteRateado: number = 0;
  public freteRateadoNaoSoma: number = 0;
  public grupo: string;
  public ibptEstadualAliquota: number = 0;
  public ibptEstadualValor: number = 0;
  public ibptNacionalAliquota: number = 0;
  public ibptNacionalValor: number = 0;
  public icmsDispensado: number = 0;
  public icmsStAliquota: number = 0;
  public icmsStMva: number = 0;
  public id: number;
  public idCfopEmpresa: number;
  public idCstCofins: number;
  public idCstIcms: number;
  public idCstIpi: string;
  public idCstPis: number;
  public idEmpresa: number;
  public idMovimentacao: number;
  public idMovimentacaoOrigem: number;
  public idMovimentacaoProOrigem: number;
  public idProduto: number;
  public idProdutoParceiro: number;
  public idTabelaPrecoConfiguracao: number;
  public idTemporario: number = 0;
  public idUnidade: number;
  public idUnidadePadrao: number;
  public idUnidadeAlternativa: number;
  public importacaoXml: string = 'N';
  public impostosPreenchidos: boolean;
  public indice: number;
  public isentoValor: number = 0;
  public lucroPercentual: number = 0;
  public lucroValor: number = 0;
  public margemLucro: number;
  public movimentacaoQuantidades: MovimentacaoQuantidade[] = [];
  public numeroAplicacao: number = 1;
  public numeroCfopEmpresa: number;
  public numeroProduto: number;
  public numeroProdutoNfe: string;
  public ncmProdutoXml: string;
  public observacao: string;
  public operacao: number;
  public ordem: number = 0;
  public outroRateado: number = 0;
  public outroValor: number = 0;
  public penalidadeValor: number = 0;
  public praga: string;
  public produto: string;
  public produtoMostrarDescricao: string;
  public produtoNfe: string;
  public produtoNome: string;
  public produtoNumero: number;
  public produtoPesoBruto: number;
  public produtoPesoLiquido: number;
  public produtoTipo: string = 'P';
  public quantidadeBalanco: number;
  public quantidadeCancelada: number = 0;
  public quantidadeDesfeita: number = 0;
  public quantidadeDevolvida: number = 0;
  public quantidadeDevolvidaEst: number = 0;
  public quantidadeDevolvidaSub: number = 0;
  public quantidadeDisponvel: number = 0;
  public quantidadeEntregue: number = 0;
  public quantidadeFaturada: number = 0;
  public quantidadePendente: number = 0;
  public quantidadePendenteFaturamento: number = 0;
  public receituarioEmitido: string = 'N';
  public seguroRateado: number = 0;
  public selecionado: string = 'N';
  public status: string = 'N';
  public tabelaPrecoConfiguracao: TabelaPrecoConfiguracao;
  public usaComissao: string = 'N';
  public usaEstoque: string = 'N';
  public usaFinanceiro: string = 'N';
  public usaIcmsDesonerado: string = 'N';
  public usaLucro: string = 'N';
  public usaMovimentacaoQuantidade: boolean = false;
  public valorBaixa: number = 0;
  public valorFinal: number;
  public valorRestante: number = 0;
  public valorTotal: number;
  public valorUnitarioTabela: number;
  public lotes: Lote[] = [];
  public locais: Local[] = [];
  public produtoUnidades: ProdutoUnidade[] = [];
  public unidadeXml: string;
  public icmsBasePercentual: number = 0;
  public inssRetidoValor: number = 0;
  public irrfRetidoValor: number = 0;
  public csllRetidoValor: number = 0;
  public pisRetidoValor: number = 0;
  public cofinsRetidoValor: number = 0;
  public deducoesValor: number = 0;
  public outrasRetencoesValor: number = 0;
  public issBase: number = 0;
  public issAliquota: number = 0;
  public issValor: number = 0;

  public valorUnitarioXml: number = 0;
  public quantidadeXml: number = 0;
  public identificacao: number;
  public identificacaoOrigem: number;
  public grauToxicologica: string;
  public idProdutoFormulado: number;
  public fichaEmergencia: string = 'N';
  public idCultura: number;
  public idPraga: number;
  public idOperacao: number;
  public idGrupo: number;
  public descontoPorValor: boolean = false;
  public culturas: any[] = [];
  public pragas: any[] = [];
  public produtoFormuladoDoses: ProdutoFormuladoDose[] = [];
  public listandoImpostos: string = 'N';
  public subtrairDesoneradoBase: boolean = false;
  public receituario: Receituario;
  public loteXml: Lote;
  public ignoraSepararEntregar: string = 'N';
  public destacarSemLojaEstado: boolean = false;
  public idAplicacao: number;
  public aplicacao: string;
  public idEquipamento: number;
  public estagioCultura: string;
  public exigeAliquotaPis: string;
  public exigeAliquotaCofins: string;

  constructor() {
    this.idTemporario = Math.random();
  }

  setDescontoPercentual(descontoPercentual: number, negociacao: Negociacao, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): void {
    this.descontoPercentual = new Util().arredondar(descontoPercentual);
    this.descontoValor = new Util().arredondar(this.quantidade * this.valorUnitario * (this.descontoPercentual * 0.01), 2);
    this.calcularTotal(negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  setDescontoPercentualUnitario(descontoPercentual: number, negociacao: Negociacao, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): void {
    this.descontoPercentualUnitario = new Util().arredondar(descontoPercentual, 3);
    this.descontoValorUnitario = new Util().arredondar(this.valorUnitarioTabela * (this.descontoPercentualUnitario * 0.01), 3);
    if (this.valorUnitarioTabela > 0) {
      this.valorUnitario = this.valorUnitarioTabela - this.descontoValorUnitario;
    }
    this.calcularTotal(negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  setDescontoValor(descontoValor: number, negociacao: Negociacao, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): void {
    descontoValor = descontoValor > this.quantidade * this.valorUnitario ? this.quantidade * this.valorUnitario : descontoValor;
    this.descontoValor = new Util().arredondar(descontoValor);
    this.descontoPercentual = new Util().arredondar(this.quantidade > 0 ? (descontoValor / (this.quantidade * this.valorUnitario)) * 100 : 0, 2);
    this.calcularTotal(negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  setDescontoUnitarioRateadoValor(descontoValor: number, negociacao: Negociacao, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): void {
    descontoValor = descontoValor > this.quantidade * this.valorUnitario ? this.quantidade * this.valorUnitario : descontoValor;
    this.descontoRateadoUnitario = new Util().arredondar(descontoValor);
    this.descontoPercentual = new Util().arredondar(this.quantidade > 0 ? (descontoValor / (this.quantidade * this.valorUnitario)) * 100 : 0, 2);
    this.calcularTotal(negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  setDescontoValorUnitario(descontoValor: number, negociacao: Negociacao, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number, acumulaDesconto: boolean = false): void {
    descontoValor = descontoValor > this.valorUnitarioTabela ? this.valorUnitarioTabela : descontoValor;
    this.descontoValorUnitario = new Util().arredondar(descontoValor, 4);
    this.descontoPercentualUnitario = new Util().arredondar(this.valorUnitarioTabela > 0 ? (descontoValor / this.valorUnitarioTabela) * 100 : 0, 3);
    if (this.valorUnitarioTabela > 0) {
      if (acumulaDesconto) {
        this.valorUnitario = new Util().arredondar(this.valorUnitarioTabela, 3) < new Util().arredondar(this.valorUnitario, 3) ? this.valorUnitario - this.descontoValorUnitario : this.valorUnitarioTabela - this.descontoValorUnitario;
      } else {
        this.valorUnitario = new Util().arredondar(this.valorUnitarioTabela, 3) < new Util().arredondar(this.valorUnitario, 3) ? this.valorUnitario : this.valorUnitarioTabela - this.descontoValorUnitario;
      }
    }
    this.calcularTotal(negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  getDescontoPercentual(): number {
    return this.descontoPercentual;
  }

  setQuantidade(quantidade: number, negociacao: Negociacao, mediaDiaPrazo: number, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number, saida: boolean): void {
    const descontorValorUnitarioAnterior: number = this.descontoValor / this.quantidade;
    this.setIcmsDesonerado(this.icmsDesonerado);
    if (this.quantidade != quantidade && this.icmsDesoneradoInicial != 0 && this.quantidade != 0 && this.quantidade != 1) {
      const proporcao = quantidade / this.quantidade;
      const icmsDesoneradoProporcional = this.icmsDesoneradoInicial * proporcao;
      this.setIcmsDesonerado(icmsDesoneradoProporcional);
    }

    this.quantidade = new Util().arredondar(quantidade, saida ? new UtilSessao().getEmpresa().regraCasasDecimais : new UtilSessao().getEmpresa().regraCasasDecimaisEntrada);
    this.converterUnidadeAlternativa();
    if (this.movimentacaoQuantidades.length == 1) {
      this.movimentacaoQuantidades[0].quantidade = quantidade;
    }
    const descontorValorNovo: number = descontorValorUnitarioAnterior * quantidade;
    this.setDescontoValor(descontorValorNovo, negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  getQuantidade(): number {
    return this.quantidade;
  }

  converterUnidadeAlternativa(): void {
    if (this.idUnidadePadrao && this.idUnidadePadrao != this.idUnidade && this.produtoUnidades && this.produtoUnidades.length > 0) {
      const produtoUnidade: ProdutoUnidade = this.produtoUnidades.find((produtoUnidade) => (produtoUnidade.idUnidade == this.idUnidade));
      if (produtoUnidade != null && produtoUnidade.operacao && produtoUnidade.fator) {
        const MUTIPLICA: boolean = produtoUnidade.operacao == "M";
        this.quantidadeConvertida = MUTIPLICA ? this.quantidade * produtoUnidade.fator : this.quantidade / produtoUnidade.fator;
      } else {
        this.quantidadeConvertida = this.quantidade;
      }
    } else {
      this.quantidadeConvertida = this.quantidade;
    }
  }

  setValorUnitario(valorUnitario: number, negociacao: Negociacao, mediaDiaPrazo: number, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number, saida: boolean, naturezaOperacaoFinalidade: number = 0, casasDecimais: number): void {
    const DEVOLUCAO: number = 3;
    if (this.usaIcmsDesonerado == 'S' && this.importacaoXml != 'S' && (naturezaOperacaoFinalidade == 0 || naturezaOperacaoFinalidade != DEVOLUCAO)) {
      if (this.listandoImpostos == 'N') {
        const reajuste: number = 100 * 0.01 - (100 - this.icmsBasePercentual) * 0.01 * (this.getIcmsAliquota() * 0.01);
        valorUnitario = valorUnitario / reajuste;
      }
      if (this.listandoImpostos == 'S') {
        this.listandoImpostos = 'N';
      }
    }
    const descontorValorUnitarioAnterior: number = this.descontoValor / this.valorUnitario;
    this.valorUnitario = new Util().arredondar(valorUnitario, casasDecimais);
    const descontorValorNovo: number = descontorValorUnitarioAnterior * this.valorUnitario;
    this.setDescontoValor(descontorValorNovo, negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  setValorUnitarioSemAlteraDescontoValor(valorUnitario: number, negociacao: Negociacao, mediaDiaPrazo: number, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number): void {
    this.valorUnitario = new Util().arredondar(valorUnitario, new UtilSessao().getEmpresa().regraCasasDecimais);
    const usaDescontoUnitario = new UtilSessao().sistema == 'Sigescom-w';
    if (usaDescontoUnitario && this.valorUnitarioTabela > 0) {
      const descontoValor: number = this.valorUnitarioTabela - this.valorUnitario;
      if (descontoValor > 0) {
        this.setDescontoValorUnitario(descontoValor, negociacao, movimentacaoParcelas, valorMovimentacao);
      }
    }
    this.setDescontoValor(this.descontoValor, negociacao, movimentacaoParcelas, valorMovimentacao);
  }

  setCofinsAliquota(aliquotaCofins: number): void {
    this.cofinsAliquota = aliquotaCofins;
    this.setCofinsValor();
  }

  getCofinsAliquota(): number {
    return this.cofinsAliquota;
  }

  setCofinsBase(baseCofins: number) {
    this.cofinsBase = baseCofins;
    this.setCofinsValor();
  }

  getCofinsBase(): number {
    return this.cofinsBase;
  }

  setCofinsValor(valorCofins: number = null) {
    let cofinsCalculado = (this.cofinsBase / 100) * this.cofinsAliquota;
    if (cofinsCalculado > 0 && cofinsCalculado < 0.01) {
      this.cofinsValor = 0.01;
    } else {
      this.cofinsValor = valorCofins ? valorCofins : new Util().arredondar((this.cofinsBase / 100) * this.cofinsAliquota);
    }
  }

  getCofinsValor(): number {
    return this.cofinsValor;
  }

  setIcmsAliquota(aliquotaIcms: number) {
    this.icmsAliquota = aliquotaIcms;
    this.setIcmsValor();
  }

  getIcmsAliquota(): number {
    return this.icmsAliquota;
  }

  setIcmsBase(baseIcms: number) {
    this.icmsBase = baseIcms;
    this.icmsBasePercentual = new Util().arredondar(this.valorTotal == 0 ? this.icmsBasePercentual : (this.icmsBase / this.valorTotal) * 100);
    this.setIcmsValor();
  }

  getIcmsBase(): number {
    return this.icmsBase;
  }

  setIcmsValor(valorIcms: number = null) {
    this.icmsValor = new Util().arredondar(valorIcms ? valorIcms : (this.icmsBase / 100) * this.icmsAliquota);
    this.subtrairIcmsDaBasePisCofins(this.icmsValor);
  }

  subtrairIcmsDaBasePisCofins(valorIcms: number = null): void {
    let utilSessao: UtilSessao = new UtilSessao();
    const empresa: Empresa = utilSessao.getEmpresa();
    if (empresa.subtrairIcmsPisCofins == 'S') {
      let loja: Loja = utilSessao.getLojas().find((loja) => loja.id == utilSessao.getIdLojaMovimentacao());
      const LUCRO_PRESUMIDO = 2;
      const LUCRO_REAL = 3;
      if (valorIcms != null && valorIcms != 0 && loja && (loja.enquadramento == LUCRO_REAL || (loja.enquadramento == LUCRO_PRESUMIDO && this.cfopEmpresa && this.cfopEmpresa.cfopEntradaSaida == 'S'))) {
        if (this.pisAliquota != 0) {
          this.setPisBase(this.valorFinal - valorIcms);
        }
        if (this.cofinsAliquota != 0) {
          this.setCofinsBase(this.valorFinal - valorIcms);
        }
      }
    }
    this.subtrairIcmsStIpiDaBasePisCofins(this.icmsStValor, this.ipiValor);
  }

  subtrairIcmsStIpiDaBasePisCofins(valorIcmsSt: number = null, valorIpi: number = null): void {
    let utilSessao: UtilSessao = new UtilSessao();
    const empresa: Empresa = utilSessao.getEmpresa();
    if (empresa.somarIcmsStIpiPisCofins == 'S') {
      if (valorIcmsSt != null && valorIcmsSt != 0) {
        this.setPisBase(this.pisBase != 0 ? this.pisBase - valorIcmsSt : this.pisBase);
        this.setCofinsBase(this.cofinsBase != 0 ? this.cofinsBase - valorIcmsSt : this.cofinsBase);
      }
      if (this.cofinsAliquota != 0 && valorIpi != null && valorIpi != 0) {
        this.setPisBase(this.pisBase != 0 ? this.pisBase - valorIpi : this.pisBase);
        this.setCofinsBase(this.cofinsBase != 0 ? this.cofinsBase - valorIpi : this.cofinsBase);
      }
    }
  }

  getIcmsValor(): number {
    return this.icmsValor;
  }

  setIcmsDesonerado(icmsDesonerado: number) {
    if (icmsDesonerado < 0) {
      icmsDesonerado = 0;
    } else {
      this.icmsDesonerado = new Util().arredondar(icmsDesonerado);
    }
    if (icmsDesonerado == this.icmsDesonerado && this.icmsDesonerado != this.icmsDesoneradoInicial) {
      this.icmsDesoneradoInicial = this.icmsDesonerado;
    }
  }

  getIcmsDesonerado(): number {
    return this.icmsDesonerado;
  }

  setCodigoBeneficioFiscal(codigoBeneficioFiscal: string) {
    this.codigoBeneficioFiscal = codigoBeneficioFiscal;
  }

  getCodigoBeneficioFiscal(): string {
    return this.codigoBeneficioFiscal;
  }

  setIcmsStBase(baseIcmsSt: number) {
    this.icmsStBase = baseIcmsSt;
  }

  getIcmsStBase(): number {
    return this.icmsStBase;
  }

  setIcmsStValor(valorIcmsSt: number) {
    this.icmsStValor = valorIcmsSt;
  }

  getIcmsStValor(): number {
    return this.icmsStValor;
  }

  setIpiAliquota(aliquotaIpi: number) {
    this.ipiAliquota = aliquotaIpi;
    this.setIpiValor();
  }

  getIpiAliquota(): number {
    return this.ipiAliquota;
  }

  setIpiBase(baseIpi: number) {
    this.ipiBase = baseIpi;
    this.setIpiValor();
  }

  getIpiBase(): number {
    return this.ipiBase;
  }

  setIpiValor(valorIpi: number = null) {
    this.ipiValor = new Util().arredondar(Number(valorIpi ? valorIpi : new Util().arredondar((this.ipiBase / 100) * this.ipiAliquota)));
  }

  getIpiValor(): number {
    return this.ipiValor;
  }

  setPisAliquota(aliquotaPis: number) {
    this.pisAliquota = aliquotaPis;
    this.setPisValor();
  }

  getPisAliquota(): number {
    return this.pisAliquota;
  }

  setPisBase(basePis: number) {
    this.pisBase = basePis;
    this.setPisValor();
  }

  getPisBase(): number {
    return this.pisBase;
  }

  setPisValor(valorPis: number = null) {
    let pisCalculado = (this.pisBase / 100) * this.pisAliquota;
    if (pisCalculado > 0 && pisCalculado < 0.01) {
      this.pisValor = 0.01;
    } else {
      this.pisValor = new Util().arredondar(valorPis ? valorPis : new Util().arredondar((this.pisBase / 100) * this.pisAliquota));
    }
  }

  getPisValor(): number {
    return this.pisValor;
  }

  getValorUnitario(): number {
    return this.valorUnitario;
  }

  calcularTotal(negociacao: Negociacao = null, movimentacaoParcelas: MovimentacaoParcela[] = null, valorMovimentacao: number): void {
    this.valorTotal = new Util().arredondar(this.quantidade * this.valorUnitario, new UtilSessao().getEmpresa().regraCasasDecimais) - this.descontoValor;
    this.valorFinal = this.valorTotal;
  }

  definirRegraEstoque(estoqueComprado: number, estoqueFisico: number, estoqueReservado: number, estoqueTransferido: number, estoqueNFe: number, estoqueCarteira: number): void {
    this.estoqueComprado = estoqueComprado;
    this.estoqueFisico = estoqueFisico;
    this.estoqueReservado = estoqueReservado;
    this.estoqueTransferido = estoqueTransferido;
    this.estoqueNfeRegra = estoqueNFe;
    this.estoqueCarteira = estoqueCarteira;
  }

  setBloquearFinanceiroEstoque(classificacaoBloqueadoEstoque: string, classificacaoBloqueadoEstoqueNfe: string, classificacaoBloqueadoFinanceiro: string, financeiro: string, idOperacao: number): void {
    this.bloqueadoEstoque = 'N';
    this.bloqueadoEstoqueNfe = 'N';
    if (classificacaoBloqueadoEstoque == 'S' || this.cfopBloqueadoEstoque == 'S') {
      this.bloqueadoEstoque = 'S';
    }
    this.bloqueadoFinanceiro = 'N';
    if (classificacaoBloqueadoFinanceiro == 'S' || this.cfopBloqueadoFinanceiro == 'S' || (this.financeiroGeradoVinculado == financeiro && financeiro != 'N')) {
      this.bloqueadoFinanceiro = 'S';
    }
    if (new UtilSessao().getEmpresaOperacaoBloqueia().find((empresaOperacaoBloqueia) => empresaOperacaoBloqueia.idOperacao == idOperacao) != undefined) {
      this.bloqueadoFinanceiro = 'S';
    }
    if (classificacaoBloqueadoEstoqueNfe == 'S') {
      this.bloqueadoEstoqueNfe = 'S';
    }
  }

  calcularLucro(negociacao: Negociacao, movimentacaoParcelas: MovimentacaoParcela[], valorMovimentacao: number, bloqueiaFinanceiro: boolean = false, icmsDesoneradoValor: number): void {
    const utilProduto: UtilProduto = new UtilProduto();
    this.penalidadeValor = utilProduto.calcularPenalidadeValor(negociacao, this.valorTotal, movimentacaoParcelas, valorMovimentacao);
    const custoGerencialTotal: number = this.custoGerencial * this.quantidadeConvertida + this.penalidadeValor;
    this.lucroValor = utilProduto.calcularLucroValor(this.valorTotal, custoGerencialTotal, icmsDesoneradoValor);
    this.lucroPercentual = utilProduto.calcularLucroPercentual(this.valorTotal, custoGerencialTotal, icmsDesoneradoValor);
  }

  setCfopEmpresa(cfopEmpresa: CfopEmpresa, bloqueiaAlteracao: boolean, classificacaoBloqueadoEstoque: string, classificacaoBloqueadoEstoqueNfe: string, classificacaoBloqueadoFinanceiro: string, financeiro: string, idOperacao: number): void {
    if (cfopEmpresa != undefined) {
      this.idCfopEmpresa = cfopEmpresa.id;
      this.cfopEmpresa = cfopEmpresa;
      if (bloqueiaAlteracao) {
        this.cfopBloqueadoEstoque = cfopEmpresa.bloqueadoEstoque;
        this.cfopBloqueadoFinanceiro = cfopEmpresa.bloqueadoFinanceiro;
        this.cfopBloqueiaQuantidadeFaturada = cfopEmpresa.cfopBloqueiaQuantidadeFaturada;
        this.definirRegraEstoque(this.estoqueComprado, this.estoqueFisico, this.estoqueReservado, this.estoqueTransferido, this.estoqueNfeRegra, this.estoqueCarteira);
        this.setBloquearFinanceiroEstoque(classificacaoBloqueadoEstoque, classificacaoBloqueadoEstoqueNfe, classificacaoBloqueadoFinanceiro, financeiro, idOperacao);
        this.atualizarCfopEmpresa();
      }
      this.ehValidarPisCofinsPorCfop(cfopEmpresa);
    }
  }

  atualizarCfopEmpresa(): void {
    if (this.cfopSaida && !this.cfopEntrada && !this.produtoNfe) {
      let cfopEmpresa: CfopEmpresa;
      cfopEmpresa.idCfop = this.numeroCfopEmpresa;
      cfopEmpresa.idCfopContrapartida = cfopEmpresa.id;
      cfopEmpresa.id = this.idCfopEmpresa;
      this.cfopEmpresa = cfopEmpresa;
    }
  }

  ehValidarPisCofinsPorCfop(cfopEmpresa: CfopEmpresa) {
    if (cfopEmpresa.cfopTransferencia == 'S') {
      if (cfopEmpresa.idCstCofins != undefined) {
        this.idCstCofins = cfopEmpresa.idCstCofins;
      }
      if (cfopEmpresa.idCstPis != undefined) {
        this.idCstPis = cfopEmpresa.idCstPis;
      }
      if (cfopEmpresa.cofinsAliquota != undefined) {
        this.setCofinsBase(0);
        this.setCofinsAliquota(cfopEmpresa.cofinsAliquota);
        if (cfopEmpresa.cofinsAliquota != 0) {
          this.setCofinsBase(new Util().arredondar(this.valorFinal));
        }
      }
      if (cfopEmpresa.pisAliquota != undefined) {
        this.setPisBase(0);
        this.setPisAliquota(cfopEmpresa.pisAliquota);
        if (cfopEmpresa.pisAliquota != 0) {
          this.setPisAliquota(new Util().arredondar(this.valorFinal));
        }
      }
    }
  }

  setInssRetidoValor(valorInssRetido: number) {
    this.inssRetidoValor = valorInssRetido;
  }

  getInssRetidoValor(): number {
    return this.inssRetidoValor;
  }

  setIrrfRetidoValor(valorIrrfRetido: number) {
    this.irrfRetidoValor = valorIrrfRetido;
  }

  getIrrfRetidoValor(): number {
    return this.irrfRetidoValor;
  }

  setCsllRetidoValor(valorCsllRetido: number) {
    this.csllRetidoValor = valorCsllRetido;
  }

  getCsllRetidoValor(): number {
    return this.csllRetidoValor;
  }

  setPisRetidoValor(valorPisRetido: number) {
    this.pisRetidoValor = valorPisRetido;
  }

  getPisRetidoValor(): number {
    return this.pisRetidoValor;
  }

  setCofinsRetidoValor(valorCofinsRetido: number) {
    this.cofinsRetidoValor = valorCofinsRetido;
  }

  getDeducoesValor(): number {
    return this.deducoesValor;
  }

  setDeducoesValor(valorDeducoes: number) {
    this.deducoesValor = valorDeducoes;
  }

  getCofinsRetidoValor(): number {
    return this.cofinsRetidoValor;
  }

  setOutrasRetencoesValor(valorOutrasRetencoes: number) {
    this.outrasRetencoesValor = valorOutrasRetencoes;
  }

  getOutrasRetencoesValor(): number {
    return this.outrasRetencoesValor;
  }

  setIssAliquota(aliquotaIss: number) {
    this.issAliquota = aliquotaIss;
    if (this.getIssBase() == 0 && this.issAliquota > 0) {
      this.setIssBase(new Util().arredondar(this.valorTotal));
    } else {
      this.setIssValor();
    }
  }

  getIssAliquota(): number {
    return this.issAliquota;
  }

  setIssBase(baseIss: number) {
    this.issBase = baseIss;
    this.setIssValor();
  }

  getIssBase(): number {
    return this.issBase;
  }

  setIssValor(valorIss: number = null) {
    let issCalculado = (this.issBase / 100) * this.issAliquota;
    if (issCalculado > 0 && issCalculado < 0.01) {
      this.issValor = 0.01;
    } else {
      this.issValor = new Util().arredondar(valorIss ? valorIss : new Util().arredondar((this.issBase / 100) * this.issAliquota));
    }
  }

  getIssValor(): number {
    return this.issValor;
  }
}
