import { Component, Input } from '@angular/core';
import { RelatorioComponent } from '../../relatorio.component';

@Component({
  selector: 'r1128resumido',
  templateUrl: './r1128resumido.component.html',
  styleUrls: ['./r1128resumido.component.css'],
})
export class R1128ResumidoComponent extends RelatorioComponent {
  @Input() movimentacao: any;
  @Input() movimentacaoProdutos: any[] = [];
  @Input() movimentacaoParcelas: any[] = [];
  @Input() movimentacaoProdutoTotais: any[] = [];
  @Input() i: number = 0;
  @Input() usaDescontoValorFinal: boolean = false;
  @Input() usaObservacao: boolean = false;
  @Input() usaFormaPagamento: boolean = false;
  @Input() usaLote: boolean = false;
  @Input() usaLogomarca: boolean = false;
  @Input() contagem: number = 0;
  @Input() ehMatricial: boolean = false;
  @Input() usaValorUnitarioComDesconto: boolean = false;
  @Input() usaDesconto: boolean = false;
  @Input() mostraVia: boolean = true;
  @Input() foto: string;

  @Input() mostraTotal = true;
  public usaValorUnitario: boolean = true;
  public quantidadProduto: number = 14;
  public imagemEmpresa: string = 'img/empresa-semfoto.jpg';

  montarFormaPagamento(idMovimentacao: number): string {
    const movimentacaoParcelas: any[] = this.movimentacaoParcelas.filter((movimentacaoParcela) => (movimentacaoParcela.idMovimentacao = idMovimentacao));
    if (movimentacaoParcelas.length > 0) {
      if (movimentacaoParcelas.length > 1) {
        if (movimentacaoParcelas[0].tipoTitulo != movimentacaoParcelas[1].tipoTitulo) {
          return 'Entrada ' + this.tratarTipoTitulo(movimentacaoParcelas[0].tipoTitulo) + ', outra(s) ' + this.tratarTipoTitulo(movimentacaoParcelas[1].tipoTitulo);
        }
      }
      return this.tratarTipoTitulo(movimentacaoParcelas[0].tipoTitulo);
    }
    return '';
  }

  tratarTipoTitulo(tipoTitulo: string): string {
    return tipoTitulo && tipoTitulo.length > 0 ? tipoTitulo : 'não informada';
  }
}
