import { ReceituarioEletronico } from "./receituarioEletronico";

export class ReceituarioEletronicoGrade {
  public id: number;
  public idMovimentacao: number;
  public data: Date;
  public numero: number;
  public receita: string;
  public idEmpresa: number;
  public idLoja: number;
  public loja: string;
  public nomeParceiro: string;
  public status: string;
  public statusDescricao: string;
  public enviando: string;
  public transmitido: string;
  public cancelado: string;
  public dataTransmissao: string;
  public protocoloTransmissao: string;
  public dataCancelamento: string;
  public protocoloCancelamento: string;
  public tipoAssinatura: string;
  public agronomo: string;
  public expandido: boolean = false;
  public selecionado: string;
  public receituarioEletronico: ReceituarioEletronico;
  public nfeVinculada: string;
}
