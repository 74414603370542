import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';

const criterioTipoLancamento: Criterio[] = [new Criterio('ATIVO', 'S')];

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataDe, nome: 'DT_DE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.dataAte, nome: 'DT_ATE', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.data, valor: new Date() },
  { rotulo: bibDialogo.contaContabil, nome: 'IDS_CONTA_CONTABIL', obrigatorio: 'S', coluna: 12, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.tipoLancamento, nome: 'ID_TIPO_LANCAMENTO', obrigatorio: 'S', coluna: 6, servicoWeb: bibServico.tipoLancamentoContabil, tipo: bibPropriedade.filtro.lista, criterios: criterioTipoLancamento },
]);
export default filtros;