export class Conta {
  public abreviacao: string;
  public aceite: string;
  public agenciaBanco: string;
  public ativo: string = 'S';
  public carteira: string;
  public cedente: string;
  public codigoTransmissao: string;
  public conciliacaoAutomatica: string = 'N';
  public contaContabil: string;
  public convenio: string;
  public dataHoraAlteracao: Date;
  public dataHoraInclusao: Date;
  public diasDesconto: number;
  public diasJuros: number;
  public diasMulta: number;
  public diasProtesto: number;
  public digito: string;
  public digitoBanco: string;
  public especie: string;
  public homologacao: string = 'S';
  public id: number;
  public idBanco: string;
  public idContaContabil: number;
  public idContaTecnospeed: number;
  public idEmpresa: number;
  public idLoja: number;
  public idUsuarioAlteracao: number;
  public idUsuarioInclusao: number;
  public impressaoAtualizada: string = 'S';
  public impressaoAtualizadaRemessa: string = 'S';
  public instrucoes: string;
  public layout: string;
  public limite: number = 0;
  public localPagamento: string;
  public modalidade: string;
  public nome: string;
  public numero: string;
  public numeroBanco: string;
  public numeroBoleto: number;
  public percentualMulta: number = 0;
  public registroInstantaneo: string = 'S';
  public segredoBanco: string;
  public senhaBanco: string;
  public sequenciaRemessa: string;
  public taxaJuros: number = 0;
  public tipo: string = 'B';
  public tipoContaBancaria: string;
  public tipoDesconto: number;
  public tipoImpressao: string;
  public tipoJuros: string;
  public tipoMulta: number;
  public tipoProtesto: number;
  public ultimoBoleto: number;
  public validacaoAtiva: string = 'S';
  public valorDesconto: number = 0;
  public exigeAberturaCaixa: string = 'N';
  public idCaixaVinculado: number;
  public idLojaBoleto: number;
  public lojaBoleto: string;
  public enviarSped: string = 'N';
  public quantidadeBoletoRecebido: number = 0;
  public diasCompensacao: number = 0;
  public idLojaVinculada: number;
  public lojaVinculada: string;
  public idParceiroSpedFiscal: number;
  public parceiroSpedFiscal: string;
}
