import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.somenteReceitaMaiorDespesa, id: '-1' },
  { nome: bibDialogo.somenteClientesDebito, id: '0' },
  { nome: bibDialogo.todos, id: '-' },
]);

const filtroComboAntecipacao: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.sim, id: 'S' },
  { nome: bibDialogo.nao, id: 'N' },
]);

const filtroComboSituacaoNFe: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.nfeTransmitida, id: 'fin.numero_fiscal is not null' },
  { nome: bibDialogo.aguardandoNfe, id: 'fin.numero_fiscal is null' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataVencimentoDe, nome: 'VENCIMENTO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataVencimentoAte, nome: 'VENCIMENTO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 6, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 6, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.calcularAntecipacao, nome: 'CALCULAR_ANTECIPACAO', obrigatorio: 'S', coluna: 6, tipo: bibPropriedade.filtro.combo, valor: 'N', lista: filtroComboAntecipacao },
  { rotulo: '% ' + bibDialogo.antecipacao, nome: 'ANTECIPACAO', obrigatorio: 'S', coluna: 3, tipo: bibPropriedade.filtro.decimal, valor: 0, sufixo: '%', vinculoVisualizacao: 'CALCULAR_ANTECIPACAO', visualizacaoInicial: false, visualizacao: ['S'] },
  { rotulo: bibDialogo.dataAntecipacao, nome: 'DATA_ANTECIPACAO', obrigatorio: 'S', coluna: 3, tipo: bibPropriedade.filtro.data, valor: new Date(), vinculoVisualizacao: 'CALCULAR_ANTECIPACAO', visualizacaoInicial: false, visualizacao: ['S'] },
  { rotulo: bibDialogo.situacao, nome: 'SITUACAO_FINANCEIRO', coluna: 12, tipo: bibPropriedade.filtro.combo, valor: '-1', lista: filtroComboSituacao, obrigatorio: 'S' },
  { rotulo: bibDialogo.familia, nome: 'ID_FAMILIA', coluna: 6, tipo: bibPropriedade.filtro.busca, servicoWeb: bibServico.familia },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.nfe, nome: 'NFE', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboSituacaoNFe, obrigatorio: true },
]);

export default filtros;
