<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [ordens]="ordens" [grupos]="grupos" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (campoOrdemEmt)="ordenar(movimentacaoProdutos, $event)" (campoGrupoEmt)="agrupar(movimentacaoProdutos, $event)" (filtroEmt)="listarRelatorio($event)" (agrupaTotalizadorEmt)="agruparTotalizador()" (adicionalEmt)="definirRegraRelatorio($event)">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="r-1 text-right">{{ bibDialogo.numero }}</th>
      <th class="r-1">{{ bibDialogo.data }}</th>
      <th *ngIf="usaParceiro" class="r-3">{{ bibDialogo.clienteFornecedor }}</th>
      <th class="r-1">{{ bibDialogo.responsavel }}</th>
      <th class="r">{{ bibDialogo.produto }}</th>
      <th class="r-0-5">{{ bibDialogo.unidadeAbreviacao }}</th>
      <th class="r-1 text-right">{{ bibDialogo.qtd }}</th>
      <th class="r-1 text-right quebra-linha">{{ bibDialogo.valor }}</th>
      <th *ngIf="usaDesconto" class="r-1 text-right">{{ bibDialogo.desconto }}</th>
      <th class="r-1 text-right">{{ bibDialogo.valorTotal }}</th>
      <th *ngIf="usaComissao" class="r-1 text-right">{{ bibDialogo.comissao }}</th>
      <th class="r-2">{{ bibDialogo.operacao }}</th>
    </thead>
    <tbody>
      <ng-container *ngFor="let movimentacaoProduto of movimentacaoProdutos; let i = index">
        <cabecalhoGrupo [ehAgrupadoOcultado]="ehAgrupadoOcultado" [relatorioGrupo]="relatorioGrupo" [i]="i"></cabecalhoGrupo>
        <tr class="conteudo">
          <td>{{ movimentacaoProduto.abreviacao }}</td>
          <td class="text-right">{{ movimentacaoProduto.movimentacaoNumero }}</td>
          <td>{{ movimentacaoProduto.movimentacaoData | data }}</td>
          <td class="limitar" *ngIf="usaParceiro">{{ movimentacaoProduto.parceiroNumero + ' - ' + movimentacaoProduto.parceiro }}</td>
          <td class="limitar">{{ movimentacaoProduto.colaboradorNumero + ' - ' + movimentacaoProduto.colaboradorAbreviacao }}</td>
          <td class="limitar">{{ movimentacaoProduto.numero + ' - ' + movimentacaoProduto.produto }}</td>
          <td>{{ movimentacaoProduto.unidadeAbreviacao }}</td>
          <td class="text-right">{{ movimentacaoProduto.quantidade | monetario: this.empresaRegraCasasDecimais }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorUnitario | monetario: this.empresaRegraCasasDecimais }}</td>
          <td *ngIf="usaDesconto" class="text-right">{{ movimentacaoProduto.desconto | monetario }}</td>
          <td class="text-right">{{ movimentacaoProduto.valorFinal | monetario }}</td>
          <td *ngIf="usaComissao" class="text-right">{{ movimentacaoProduto.comissao | monetario }}</td>
          <td>{{ movimentacaoProduto.operacao }}</td>
        </tr>
        <tr class="sem-borda" rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i"></tr>
      </ng-container>
    </tbody>
    <tfoot>
      <tr *ngIf="movimentacaoProdutos?.length > 0" rodapeTotal [relatorioGrupo]="relatorioGrupo"></tr>
    </tfoot>
  </table>
</relatorioLayout>
