import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { Lote } from 'src/app/agrow/modelo/lote';
import { ProdutoFormulado } from 'src/app/agrow/modelo/produtoFormulado';
import { Receituario } from 'src/app/agrow/modelo/receituario';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibServico from 'src/app/biblioteca/bibServico';
import { Adicoes } from 'src/app/integracao/tecnospeed/nfe/adicoes';
import { Desembaraco } from 'src/app/integracao/tecnospeed/nfe/desembaraco';
import { Importacao } from 'src/app/integracao/tecnospeed/nfe/importacao';
import { Medicamento } from 'src/app/integracao/tecnospeed/nfe/medicamento';
import { Nfe } from 'src/app/integracao/tecnospeed/nfe/nfe';
import { Rastreavel } from 'src/app/integracao/tecnospeed/nfe/rastreavel';
import { UfDestino } from 'src/app/integracao/tecnospeed/nfe/ufDestino';
import { CfopEmpresa } from 'src/app/modelo/cfopEmpresa';
import { ClassificacaoFiscal } from 'src/app/modelo/classificacaoFiscal';
import { Criterio } from 'src/app/modelo/criterio';
import { Cst } from 'src/app/modelo/cst';
import { Empresa } from 'src/app/modelo/empresa';
import { Estado } from 'src/app/modelo/estados';
import { GrupoIcms } from 'src/app/modelo/grupoIcms';
import { Loja } from 'src/app/modelo/loja';
import { LojaEstado } from 'src/app/modelo/lojaEstado';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { MovimentacaoProduto } from 'src/app/modelo/movimentacaoProduto';
import { MovimentacaoQuantidade } from 'src/app/modelo/movimentacaoQuantidade';
import { NcmEmpresa } from 'src/app/modelo/ncmEmpresa';
import { Parceiro } from 'src/app/modelo/parceiro';
import { ParceiroDestino } from 'src/app/modelo/parceiroDestino';
import { ParceiroEndereco } from 'src/app/modelo/parceiroEndereco';
import { ParceiroTelefone } from 'src/app/modelo/parceiroTelefone';
import { Produto } from 'src/app/modelo/produto';
import { ProdutoIcms } from 'src/app/modelo/produtoIcms';
import { ProdutoIcmsEspecifico } from 'src/app/modelo/produtoIcmsEspecifico';
import { Transporte } from 'src/app/modelo/transporte';
import { ComunicacaoService } from 'src/app/servico/comunicacao.service';
import { MonetarioPipe } from 'src/app/utilitario/monetario.pipe';
import { Util } from 'src/app/utilitario/util';
import { UtilSessao } from 'src/app/utilitario/util.sessao';

export class UtilDocumentoEletronico {
  private receituarios: Receituario[] = [];
  private bibServico = bibServico;
  private empresa: Empresa;
  private processamentoConcluido: boolean = false;
  public classificacaoFiscais: ClassificacaoFiscal[] = [];
  public movimentacaoQuantidades: MovimentacaoQuantidade[];
  public grupoIcmss: GrupoIcms[];
  public produtosFormulado: ProdutoFormulado[];
  public informacaoComplementarProduto: string = '';
  public informacoesComplementares: string;
  public lotes: Lote[];
  public ncmEmpresas: NcmEmpresa[] = [];
  public plainToClass: Function = plainToClass;
  public produtoIcmss: ProdutoIcms[];
  public cstIcmss: Cst[];
  public cstsIpis: Cst[];
  public cstPisCofinss: Cst[];
  public cfopEmpresas: CfopEmpresa[] = [];
  public movimentacaoParcelas: MovimentacaoParcela[] = [];
  public movimentacaoProdutos: MovimentacaoProduto[] = [];
  public produtos: Produto[];
  public monetarioPipe: MonetarioPipe = new MonetarioPipe();
  public lojaEstadoDestinatario: LojaEstado = new LojaEstado();
  public lojaEstadoEmitente: LojaEstado = new LojaEstado();
  public lojaEstados: LojaEstado[];
  public parceiroDestinos: ParceiroDestino[];
  public parceiroEnderecos: ParceiroEndereco[];
  public parceiroTelefones: ParceiroTelefone[];
  public parceiros: Parceiro[];
  public estados: Estado[];
  public enviarIbpt: boolean = true;
  public produtosIcmsEspecificos: ProdutoIcmsEspecifico[];

  constructor(public comunicacaoService: ComunicacaoService, public utilSessao: UtilSessao) { }

  public iniciar(movimentacoes: any, previsualizarDanfe: boolean = false): Observable<any> {
    const idsMovimentacoes: number[] = movimentacoes.filter((movimentacao) => movimentacao.selecionado == "S" || movimentacao.consultandoNfeNaoTransmitida == 'S').map((movimentacao) => movimentacao.id);
    if (idsMovimentacoes.length > 0) {
      const idsLoja: number[] = movimentacoes.filter((movimentacao) => movimentacao.selecionado == "S").map((movimentacao) => movimentacao.idLoja);
      const idsParceiros: number[] = movimentacoes.filter((movimentacao) => movimentacao.selecionado == "S").map((movimentacao) => movimentacao.idParceiro);
      movimentacoes.forEach((movimentacao) => (movimentacao.idTransportadora ? idsParceiros.push(movimentacao.idTransportadora) : ''));
      return new Observable<any>((observable) => {
        this.listarMovimentacaoProdutos(idsMovimentacoes, previsualizarDanfe).subscribe(() => {
          const idsProdutos: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
          this.listarEmpresa(movimentacoes).subscribe(() => {
            this.listarLojaEstado(idsLoja).subscribe(() => {
              this.listarReceituarios(idsMovimentacoes).subscribe(() => {
                this.listarProdutoIcms(this.movimentacaoProdutos).subscribe(() => {
                  this.listarGrupoIcms(idsProdutos).subscribe(() => {
                    const idsProdutosFormulados: number[] = this.movimentacaoProdutos.filter((movimentacaoProduto) => movimentacaoProduto.idProdutoFormulado != null).map((movimentacaoProduto) => movimentacaoProduto.idProduto);
                    this.listarProdutosFormulados(idsProdutosFormulados).subscribe(() => {
                      this.listarCfopEmpresa(this.movimentacaoProdutos).subscribe(() => {
                        this.listarClassificacaoFiscal().subscribe(() => {
                          this.listarMovimentacaoQuantidade(this.movimentacaoProdutos).subscribe(() => {
                            this.listarLote().subscribe(() => {
                              this.listarProduto(this.movimentacaoProdutos).subscribe(() => {
                                const idsNcms: number[] = this.retornarIdsNcms();
                                this.listarNcmEmpresa(idsNcms).subscribe(() => {
                                  this.listarEstado().subscribe(() => {
                                    this.listarParceiro(idsParceiros).subscribe(() => {
                                      this.listarParceiroEndereco(idsParceiros).subscribe(() => {
                                        this.listarParceiroDestinos(idsParceiros).subscribe(() => {
                                          this.listarParceiroTelefone(idsParceiros).subscribe(() => {
                                            this.listarCstIcms(this.movimentacaoProdutos).subscribe(() => {
                                              this.listarCstIpi(this.movimentacaoProdutos).subscribe(() => {
                                                this.listarCstPisCofins(this.movimentacaoProdutos).subscribe(() => {
                                                  this.listarMovimentacaoParcela(idsMovimentacoes).subscribe(() => {
                                                    this.listarProdutosAliquotasIcmsEspecificas(this.movimentacaoProdutos, movimentacoes).subscribe(() => {
                                                      observable.next();
                                                      observable.complete();
                                                    })
                                                  });
                                                });
                                              });
                                            });
                                          });
                                        });
                                      });
                                    });
                                  });
                                });
                              });
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }
  }

  private retornarIdsNcms(): number[] {
    let idsNcms: number[] = [];
    this.produtos.forEach((produto) => (produto.idNcm ? idsNcms.push(produto.idNcm) : ''));
    return idsNcms;
  }

  private listarMovimentacaoProdutos(idsMovimentacoes: any, previsualizarDanfe: boolean = false): Observable<any> {
    return new Observable<any>((observable) => {
      let criterios: Criterio[] = [];
      criterios.push(new Criterio('IDS_MOVIMENTACOES', idsMovimentacoes.toString()));
      if (previsualizarDanfe == false) {
        criterios.push(new Criterio('MOVIMENTACAO_FINALIZADA', 'S'));;
      }
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoProduto).subscribe((res) => {
        this.movimentacaoProdutos = this.plainToClass(MovimentacaoProduto, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarEmpresa(movimentacoes: any): Observable<any> {
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', movimentacoes[0].idEmpresa)), this.bibServico.empresa).subscribe((res) => {
        this.empresa = this.plainToClass(Empresa, res[0]) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarReceituarios(idMovimentacoes: number[]): Observable<any> {
    let criterio: Criterio = new Criterio('IDS_MOVIMENTACAO', idMovimentacoes.toString());
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterio), this.bibServico.receituario).subscribe((res) => {
        this.receituarios = this.plainToClass(Receituario, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarProdutoIcms(movimentacaoProdutos: any): Observable<any> {
    let idsProdutos: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PRODUTOS', idsProdutos.toString())), this.bibServico.produtoIcms).subscribe((res) => {
        this.produtoIcmss = this.plainToClass(ProdutoIcms, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarGrupoIcms(idsProdutos: number[]): Observable<any> {
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_PRODUTOS', idsProdutos.toString())), this.bibServico.grupoIcms).subscribe((res) => {
        this.grupoIcmss = this.plainToClass(GrupoIcms, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarProdutosFormulados(idsProdutos: number[]): Observable<any> {
    return new Observable<any>((observable) => {
      if (idsProdutos.length > 0) {
        this.comunicacaoService.listar(new Transporte(new Criterio('IDS_PRODUTOS', idsProdutos.toString())), this.bibServico.produtoFormulado).subscribe((res) => {
          this.produtosFormulado = this.plainToClass(ProdutoFormulado, res) as any;
          observable.next();
          observable.complete();
        });
      } else {
        observable.next();
        observable.complete();
      }
    });
  }

  private listarCfopEmpresa(movimentacaoProdutos: any): Observable<any> {
    let idCfopEmpresas: number[] = [];
    this.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (movimentacaoProduto.idCfopEmpresa) {
        idCfopEmpresas.push(movimentacaoProduto.idCfopEmpresa);
      }
    });
    let criterio: Criterio = new Criterio('IDS', idCfopEmpresas.length > 0 ? this.separarValoresDistinct(idCfopEmpresas).toString() : 0);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterio), this.bibServico.cfopEmpresa).subscribe((res) => {
        this.cfopEmpresas = this.plainToClass(CfopEmpresa, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarClassificacaoFiscal(): Observable<any> {
    let produtosIcms: ProdutoIcms[] = this.produtoIcmss.filter((produtoIcms) => produtoIcms.idClassificacaoFiscal != null);
    let idClassificacaoFiscais: number[] = [];
    if (produtosIcms.length > 0) {
      idClassificacaoFiscais = produtosIcms.map((produtoIcms) => produtoIcms.idClassificacaoFiscal);
    }
    let gruposIcms: GrupoIcms[] = this.grupoIcmss.filter((grupoIcms) => grupoIcms.idClassificacaoFiscal != null);
    if (gruposIcms.length > 0) {
      gruposIcms.forEach((grupoIcms) => {
        idClassificacaoFiscais.push(grupoIcms.idClassificacaoFiscal);
      });
    }
    let cfopEmpresas: CfopEmpresa[] = this.cfopEmpresas.filter((cfopEmpresa) => cfopEmpresa.idClassificacaoFiscal != null);
    if (cfopEmpresas.length > 0) {
      cfopEmpresas.forEach((cfopEmpresa) => {
        idClassificacaoFiscais.push(cfopEmpresa.idClassificacaoFiscal);
      });
    }
    let criterio: Criterio = new Criterio('IDS', idClassificacaoFiscais ? this.separarValoresDistinct(idClassificacaoFiscais).toString() : 0);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterio), this.bibServico.classificacaoFiscal).subscribe((res) => {
        this.classificacaoFiscais = this.plainToClass(ClassificacaoFiscal, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarMovimentacaoQuantidade(movimentacaoProdutos: any): Observable<any> {
    let idsMovimentacaoProduto: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.id);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_MOVIMENTACAO_PRO', idsMovimentacaoProduto.toString())), this.bibServico.movimentacaoQuantidade).subscribe((res) => {
        this.movimentacaoQuantidades = this.plainToClass(MovimentacaoQuantidade, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarLote(): Observable<any> {
    let movimentacaoQuantidades: MovimentacaoQuantidade[] = this.movimentacaoQuantidades.filter((movimentacaoQuantidade) => movimentacaoQuantidade.idLote != null);
    let idsLote: number[];
    if (movimentacaoQuantidades.length > 0) {
      idsLote = movimentacaoQuantidades.map((movimentacaoQuantidade) => movimentacaoQuantidade.idLote);
    }
    let criterio: Criterio = new Criterio('IDS', idsLote ? this.separarValoresDistinct(idsLote).toString() : 0);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterio), this.bibServico.lote).subscribe((res) => {
        this.lotes = this.plainToClass(Lote, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarProduto(movimentacaoProdutos: any): Observable<any> {
    let idProdutos: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS', this.separarValoresDistinct(idProdutos).toString())), this.bibServico.produto).subscribe((res) => {
        this.produtos = this.plainToClass(Produto, res) as any;
        let idsNcms: Number[] = [];
        this.produtos.forEach((produto) => {
          if (produto.idNcm) {
            idsNcms.push(produto.idNcm);
          }
        });
        observable.next();
        observable.complete();
      });
    });
  }

  listarNcmEmpresa(idsNcms: Number[]): Observable<any> {
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('IDS_NCM', idsNcms.length > 0 ? idsNcms.toString() : 0)), this.bibServico.ncmEmpresa).subscribe((res) => {
        this.ncmEmpresas = this.plainToClass(NcmEmpresa, res) as any;
        this.processamentoConcluido = true;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarEstado(): Observable<any> {
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(), this.bibServico.estado).subscribe((res) => {
        this.estados = this.plainToClass(Estado, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarParceiro(idsParceiros: number[]): Observable<any> {
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_PARCEIROS', idsParceiros.toString())), this.bibServico.parceiro).subscribe((res) => {
        this.parceiros = this.plainToClass(Parceiro, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarParceiroEndereco(idParceiros: number[]): Observable<any> {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIROS', idParceiros.toString()), new Criterio('ID_VINCULO', 3)];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroEndereco).subscribe((res) => {
        this.parceiroEnderecos = this.plainToClass(ParceiroEndereco, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarParceiroDestinos(idParceiros: number[]): Observable<any> {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIROS', idParceiros.toString())];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroDestino).subscribe((res) => {
        this.parceiroDestinos = this.plainToClass(ParceiroDestino, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarParceiroTelefone(idParceiros: number[]): Observable<any> {
    let criterios: Criterio[] = [new Criterio('ID_PARCEIROS', idParceiros.toString()), new Criterio('ID_VINCULO', 3)];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.parceiroTelefone).subscribe((res) => {
        this.parceiroTelefones = this.plainToClass(ParceiroTelefone, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarCstIcms(movimentacaoProdutos: any[]): Observable<any> {
    let idsCstIcms: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => Number(movimentacaoProduto.idCstIcms));
    let criterios: Criterio[] = [new Criterio('IDS', this.separarValoresDistinct(idsCstIcms).toString())];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cstIcms).subscribe((res) => {
        this.cstIcmss = this.plainToClass(Cst, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarCstIpi(movimentacaoProdutos: any[]): Observable<any> {
    let idsCstIpis: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => Number(movimentacaoProduto.idCstIpi));
    let criterios: Criterio[] = [new Criterio('IDS', this.separarValoresDistinct(idsCstIpis).toString())];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cstIpi).subscribe((res) => {
        this.cstsIpis = this.plainToClass(Cst, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  separarValoresDistinct(array: number[]): number[] {
    let contador: Map<number, number> = new Map();

    array.forEach((valor) => {
      contador.set(valor, (contador.get(valor) || 0) + 1);
    });

    let valores: number[] = [];
    contador.forEach((ocorrencias, valor) => {
      if (ocorrencias === 1) {
        valores.push(valor);
      }
    });

    array.forEach((valor, indice) => {
      if (array.indexOf(valor, indice + 1) !== -1 && valores.indexOf(valor) === -1) {
        valores.push(valor);
      }
    });
    return valores;
  }

  private listarCstPisCofins(movimentacaoProdutos: any[]): Observable<any> {
    let idsCstPisCofins: number[] = [];
    this.movimentacaoProdutos.forEach((movimentacaoProduto) => {
      if (movimentacaoProduto.idCstCofins) {
        idsCstPisCofins.push(movimentacaoProduto.idCstCofins);
      }
      if (movimentacaoProduto.idCstPis) {
        idsCstPisCofins.push(movimentacaoProduto.idCstPis);
      }
    });

    let criterios: Criterio[] = [new Criterio('IDS', this.separarValoresDistinct(idsCstPisCofins).toString())];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.cstPisCofins).subscribe((res) => {
        this.cstPisCofinss = this.plainToClass(Cst, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarMovimentacaoParcela(idsMovimentacoes: number[]): Observable<any> {
    let criterios: Criterio[] = [new Criterio('IDS_MOVIMENTACOES', idsMovimentacoes.toString())];
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoParcela).subscribe((res) => {
        this.movimentacaoParcelas = this.plainToClass(MovimentacaoParcela, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  public gerarInformacaoComplementar(movimentacao: Movimentacao, estado: string): string {
    if (this.processamentoConcluido) {
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
      this.enviarIbpt = loja.enviarIbptNfe == 'S' ? true : loja.enviarIbptNfe == 'C' && this.parceiros.find((parceiro) => parceiro.id == movimentacao.idParceiro).contribuinte != 1 ? true : false;
      let informacoesComplementares: string = '';
      if (movimentacao.idParceiroDestino != null) {
        informacoesComplementares += this.parceiroDestinos.filter((parceiroDestino) => parceiroDestino.id == movimentacao.idParceiroDestino).map((parceiroDestino) => parceiroDestino.nome != null ? parceiroDestino.nome + ' ' : '' + parceiroDestino.complemento != null ? ' - ' + parceiroDestino.complemento : '') + '\n';
      }
      informacoesComplementares += movimentacao.observacao ? movimentacao.observacao + '. ' : '';
      informacoesComplementares += movimentacao.informacaoComplementar ? movimentacao.informacaoComplementar + '. ' : '';

      if (this.empresa.receituario == 'S') {
        let movimentacaoReceituarios: Receituario[] = this.receituarios.filter((receituario) => receituario.idMovimentacao == movimentacao.id);
        if (loja.receituarioEntregaEmbalagem != null && movimentacaoReceituarios.length > 0) {
          const numerosReceituarios = movimentacaoReceituarios.filter((movimentacaoReceituario, i, arr) => arr.findIndex((receituario) => receituario.numero === movimentacaoReceituario.numero) === i);
          informacoesComplementares += 'REC. EMITIDAS: ';
          numerosReceituarios.forEach((numeroReceituario) => (informacoesComplementares += (numeroReceituario.mascaraInicial != undefined ? numeroReceituario.mascaraInicial : '') + numeroReceituario.numero + (numeroReceituario.mascaraFinal != undefined ? numeroReceituario.mascaraFinal : '') + ' '));
          informacoesComplementares += bibDialogo.informacaoEntregaEmbalagem + ' ' + loja.receituarioEntregaEmbalagem + '. ';
        }
      }
      informacoesComplementares += this.gerarInformacaoTributosIbpt(movimentacao).trim()
      informacoesComplementares = informacoesComplementares.replace('\n', '|')
      informacoesComplementares = informacoesComplementares.replace('..', '.');
      informacoesComplementares = informacoesComplementares.replace('  ', ' ');
      return informacoesComplementares;
    }
  }

  public gerarInformacaoComplementarContribuinte(movimentacao: Movimentacao, estado: string): string {
    if (this.processamentoConcluido) {
      let informacoesComplementares: string = '';
      const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
      movimentacao.movimentacaoProdutos.forEach((movimentacaoProduto) => {
        const produto: Produto = this.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
        const produtoIcms: ProdutoIcms = this.produtoIcmss.find((produtoIcms) => produtoIcms.idProduto == movimentacaoProduto.id && produtoIcms.estado == estado && produtoIcms.enquadramento == loja.enquadramento && (produtoIcms.tipo == movimentacao.entradaSaidaInterna || (movimentacao.identificacao == 6 ? -1 : movimentacao.identificacao == 7 ? 1 : 0)));
        const grupoIcms: GrupoIcms = this.grupoIcmss.find((grupoIcms) => produto.idGrupo == grupoIcms.idGrupo && grupoIcms.estado == estado && grupoIcms.enquadramento == loja.enquadramento && (grupoIcms.tipo == movimentacao.entradaSaidaInterna || (movimentacao.identificacao == 6 ? -1 : movimentacao.identificacao == 7 ? 1 : 0)));
        const cfopEmpresa: CfopEmpresa = this.cfopEmpresas.find((cfopEmpresaBusca) => cfopEmpresaBusca.id == movimentacaoProduto.idCfopEmpresa);
        if ((produtoIcms && produtoIcms.idClassificacaoFiscal) || (grupoIcms && grupoIcms.idClassificacaoFiscal) || (cfopEmpresa && cfopEmpresa.idClassificacaoFiscal)) {
          const classificacaoFiscal: ClassificacaoFiscal = this.classificacaoFiscais.find((classificacaoFiscalBusca) => classificacaoFiscalBusca.id == (produtoIcms && produtoIcms.idClassificacaoFiscal != undefined ? produtoIcms.idClassificacaoFiscal : grupoIcms && grupoIcms.idClassificacaoFiscal ? grupoIcms.idClassificacaoFiscal : cfopEmpresa && cfopEmpresa.idClassificacaoFiscal ? cfopEmpresa.idClassificacaoFiscal : ''));
          informacoesComplementares += classificacaoFiscal && classificacaoFiscal.descricaoNfe && classificacaoFiscal.descricaoNfe != undefined ? (informacoesComplementares.indexOf(classificacaoFiscal.descricaoNfe + '.') == -1 ? classificacaoFiscal.descricaoNfe + '. ' : '') : '';
        }
        informacoesComplementares += cfopEmpresa && cfopEmpresa.descricaoNfe && cfopEmpresa.descricaoNfe != undefined ? (informacoesComplementares.indexOf(cfopEmpresa.descricaoNfe + '.') == -1 ? cfopEmpresa.descricaoNfe + '. ' : '') : '';
      });

      if (movimentacao.icmsDesoneradoValor > 0) {
        informacoesComplementares += bibDialogo.icmsDesoneradoNfe + ' ' + this.monetarioPipe.transform(movimentacao.icmsDesoneradoValor, 2) + '. ';
      }

      if (movimentacao.icmsDispensadoValor > 0) {
        informacoesComplementares += bibDialogo.icmsDispensadoNfe + ' ' + this.monetarioPipe.transform(movimentacao.icmsDispensadoValor, 2) + '. ';
      }

      let idsClassificacoes: number[] = [];
      this.produtoIcmss.forEach((produtoIcms) => (produtoIcms.estado == estado ? idsClassificacoes.push(produtoIcms.idClassificacaoFiscal) : ''));
      this.grupoIcmss.filter((grupoIcms) => (grupoIcms.estado == estado ? idsClassificacoes.push(grupoIcms.idClassificacaoFiscal) : ''));
      this.cfopEmpresas.forEach((cfopEmpresa) => idsClassificacoes.push(cfopEmpresa.idClassificacaoFiscal));
      this.classificacaoFiscais = this.classificacaoFiscais.filter((classificacaoFiscal) => idsClassificacoes.includes(classificacaoFiscal.id));
      this.classificacaoFiscais.forEach((classificacaoFiscal) => (informacoesComplementares += classificacaoFiscal.descricaoNfe != null && classificacaoFiscal.descricaoNfe != undefined ? (informacoesComplementares.indexOf(classificacaoFiscal.descricaoNfe + '.') == -1 ? classificacaoFiscal.descricaoNfe + '. ' : '') : ''));
      console.error(informacoesComplementares);
      informacoesComplementares = informacoesComplementares.replace('\n', '|')
      informacoesComplementares = informacoesComplementares.replace('..', '.');
      informacoesComplementares = informacoesComplementares.replace('  ', ' ');
      console.error(informacoesComplementares);
      return informacoesComplementares;
    }
  }

  private tratarMensagemNfeLoja(loja: Loja, movimentacao: Movimentacao): string {
    let mensagemNfeLoja: string = loja && loja.nfeMensagem ? loja.nfeMensagem.trim() : '';
    if (loja.nfeMensagem != '' && mensagemNfeLoja.includes('<<RESPONSAVEL>>')) {
      if (movimentacao.colaborador != null) {
        mensagemNfeLoja = ' RESPONSÁVEL: ' + mensagemNfeLoja.replace('<<RESPONSAVEL>>', movimentacao.colaborador)
      } else {
        mensagemNfeLoja = mensagemNfeLoja.replace('<<RESPONSAVEL>>', '')
      }
    }
    return mensagemNfeLoja;
  }

  private gerarInformacaoTributosIbpt(movimentacao: Movimentacao): string {
    let informacoesComplementares: string = '';
    const loja: Loja = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja);
    if (this.enviarIbpt) {
      informacoesComplementares = bibDialogo.valorAproximadoDosTributos + ': ';
      informacoesComplementares += bibDialogo.federal + ' ' + this.monetarioPipe.transform(movimentacao.ibptNacionalValor ? movimentacao.ibptNacionalValor : 0, 2) + ' (' + this.monetarioPipe.transform(movimentacao.ibptNacionalPercentual ? movimentacao.ibptNacionalPercentual : 0, 2) + '%) ';
      informacoesComplementares += bibDialogo.estadual + ' ' + this.monetarioPipe.transform(movimentacao.ibptEstadualValor ? movimentacao.ibptEstadualValor : 0, 2) + ' (' + this.monetarioPipe.transform(movimentacao.ibptEstadualPercentual ? movimentacao.ibptEstadualPercentual : 0, 2) + '%) ';
      informacoesComplementares += '- ' + bibDialogo.fonteIbpt + '.';
    }
    return informacoesComplementares + this.tratarMensagemNfeLoja(loja, movimentacao);
  }

  public listarLojaEstado(idsLoja: number[]): Observable<any> {
    const criterio: Criterio[] = [];
    criterio.push(new Criterio('IDS_LOJA', idsLoja.toString()));
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterio), this.bibServico.lojaEstado).subscribe((res) => {
        this.lojaEstados = this.plainToClass(LojaEstado, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  private listarProdutosAliquotasIcmsEspecificas(movimentacaoProdutos: any, movimentacoes: any): Observable<any> {
    const movimentacao = movimentacoes.find((movimentacao) => movimentacao.id == movimentacaoProdutos[0].idMovimentacao);
    let idsProdutos: number[] = this.movimentacaoProdutos.map((movimentacaoProduto) => movimentacaoProduto.idProduto);
    const criterios: Criterio[] = [new Criterio('IDS_PRODUTOS', idsProdutos.toString()), new Criterio('ATIVO', 'S')];
    const idCidadeLoja: number = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja).idCidade;
    const idConsumidorFinal: number = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja).idConsumidorFinal ? this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja).idConsumidorFinal : this.utilSessao.getEmpresa().idConsumidorFinal;
    if (movimentacao.idParceiroDestino != null && movimentacao.idParceiroDestino != idConsumidorFinal) {
      criterios.push(new Criterio('ID_PARCEIRO_DESTINO', movimentacao.idParceiroDestino));
    } else if (movimentacao.idParceiro != null && movimentacao.idParceiro != idConsumidorFinal) {
      criterios.push(new Criterio('ID_PARCEIRO', movimentacao.idParceiro));
    } else if (movimentacao.idParceiro != null && movimentacao.idParceiro == idConsumidorFinal) {
      criterios.push(new Criterio('ID_CONSUMIDOR_FINAL', idCidadeLoja));
    }
    const enquadramento: number = this.utilSessao.getLojas().find((loja) => loja.id == movimentacao.idLoja).enquadramento;
    criterios.push(new Criterio('ENQUADRAMENTO', enquadramento));
    criterios.push(new Criterio('ESTADO_LOJA', movimentacao.idLoja));
    return new Observable<any>((observable) => {
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoIcms).subscribe((res) => {
        this.produtosIcmsEspecificos = this.plainToClass(ProdutoIcmsEspecifico, res) as any;
        observable.next();
        observable.complete();
      });
    });
  }

  public gerarPartilha(movimentacao: Movimentacao, movimentacaoProduto: MovimentacaoProduto, nfe: Nfe, estados: Estado[]): UfDestino {
    if (this.processamentoConcluido) {
      let ufDestino: UfDestino = new UfDestino();
      if (movimentacaoProduto.icmsInterestadual >= 0) {
        let estado: Estado = estados.find((estado) => estado.abreviacao == nfe.emitente.endereco.estado);
        this.lojaEstadoEmitente = this.lojaEstados.find((lojaEstado) => lojaEstado.estado == estado.nome);
        estado = estados.find((estado) => estado.abreviacao == nfe.destinatario.endereco.estado);
        this.lojaEstadoDestinatario = this.lojaEstados.find((lojaEstado) => lojaEstado.estado == estado.nome);
        const produtoAliquotaIcmsEspecifica: ProdutoIcmsEspecifico = this.produtosIcmsEspecificos.find((produtoIcmsEspecifico) => produtoIcmsEspecifico.idProduto == movimentacaoProduto.idProduto);
        if (produtoAliquotaIcmsEspecifica != null && produtoAliquotaIcmsEspecifica.aliquotaInterna) {
          ufDestino.aliquotaInterestadual = produtoAliquotaIcmsEspecifica.aliquotaInterna;
        } else {
          ufDestino.aliquotaInterestadual = this.lojaEstadoDestinatario.aliquotaInterna;
        }
        ufDestino.aliquotaInterna = movimentacao.parceiroTipoPessoa == 'F' ? this.lojaEstadoEmitente.aliquotaFisica : this.lojaEstadoEmitente.aliquotaJuridica;
        ufDestino.baseCalculoIcms = movimentacaoProduto.getIcmsBase();
        ufDestino.icmsInterestadual = movimentacaoProduto.icmsInterestadual;
        ufDestino.percentualIcmsFcp = estado.pobrezaAliquota;
      }
      return ufDestino;
    }
  }

  public getParceiro(idParceiro: number, idParceiroDestino: number): Parceiro {
    let parceiro: Parceiro;
    parceiro = this.parceiros.find((parceiro) => parceiro.id == idParceiro);
    if (idParceiroDestino) {
      parceiro.parceiroDestinos = this.parceiroDestinos.filter((parceiroDestino) => parceiroDestino.idParceiro == parceiro.id && parceiroDestino.id == idParceiroDestino);
    } else {
      parceiro.parceiroEnderecos = this.parceiroEnderecos.filter((parceiroEndereco) => parceiroEndereco.idParceiro == parceiro.id);
    }
    parceiro.parceiroTelefones = this.parceiroTelefones.filter((parceiroTelefone) => parceiroTelefone.idParceiro == parceiro.id);
    return parceiro;
  }

  ehMedicamento(ncmNumero: string): boolean {
    if (ncmNumero.substring(0, 4).trim() == '3001' || ncmNumero.substring(0, 4).trim() == '3002' || ncmNumero.substring(0, 4).trim() == '3003' || ncmNumero.substring(0, 4).trim() == '3004' || ncmNumero.substring(0, 4).trim() == '3005' || ncmNumero.substring(0, 4).trim() == '3006') {
      return true;
    } else {
      return false;
    }
  }

  public gerarMedicamentos(produto: Produto): Medicamento[] {
    if (this.processamentoConcluido) {
      let medicamentos: Medicamento[] = [];
      if (this.ehMedicamento(produto.ncm.substring(produto.ncm.indexOf('-'), 0).trim())) {
        if (produto.anvisaCodigo) {
          this.utilSessao.mensagemProcessandoNFe('Anvisa');
          let medicamento: Medicamento = new Medicamento();
          medicamento.codigoAnvisa = produto.anvisaCodigo;
          medicamento.motivoInsencaoAnvisa = produto.anvisaMotivoIsencao != '' ? produto.anvisaMotivoIsencao : '';
          medicamento.valorMaximo = produto.anvisaPrecoMaximo;
          medicamentos.push(medicamento);
        }
      }
      return medicamentos.length > 0 ? medicamentos : null;
    }
  }

  public gerarImportacao(numeroParceiro: string, ordem: string): Importacao[] {
    if (this.processamentoConcluido) {
      let importacoes: Importacao[] = [];
      let importacao = new Importacao();
      importacao.numero = "2413487300";
      importacao.dataEmissao = "2024-06-26";
      importacao.viaTransporte = 1;
      importacao.valorAfrmm = 0.00;
      importacao.formaImportacao = 1;
      importacao.codigoExportador = numeroParceiro;

      importacao.desembaraco = new Desembaraco();
      importacao.desembaraco.local = "GUARULHOS";
      importacao.desembaraco.estado = "SP";
      importacao.desembaraco.data = "2024-06-26";

      importacao.adicoes = [];
      let adicao = new Adicoes();
      adicao.numero = "1";
      adicao.numeroSequencia = ordem;
      adicao.codigoFabricante = "1";
      importacao.adicoes.push(adicao);
      importacoes.push(importacao);
      return importacoes;
    }
  }

  public gerarRastreabilidade(produto: Produto, movimentacaoProduto: MovimentacaoProduto): Rastreavel[] {
    this.utilSessao.mensagemProcessandoNFe('rastreabilidade');
    if (this.processamentoConcluido) {
      this.informacaoComplementarProduto = '';
      let rastreaveis: Rastreavel[] = [];
      const movimentacaoQuantidades: MovimentacaoQuantidade[] = this.movimentacaoQuantidades.filter((movimentacaoQuantidade) => movimentacaoQuantidade.idMovimentacaoProduto == movimentacaoProduto.id);
      movimentacaoQuantidades.forEach((movimentacaoQuantidade) => {
        if (movimentacaoQuantidade.idLote) {
          const lote: Lote = this.lotes.find((lote) => lote.id == movimentacaoQuantidade.idLote);
          if (lote) {
            this.informacaoComplementarProduto += ' LOTE: ' + lote.nome;
            this.informacaoComplementarProduto += ' QTD.: ' + movimentacaoQuantidade.quantidade;
            this.informacaoComplementarProduto += lote.dataFabricacao ? ' FAB.: ' + this.formatarDataPadrao(lote.dataFabricacao) : '';
            this.informacaoComplementarProduto += lote.dataValidade ? ' VAL.: ' + this.formatarDataPadrao(lote.dataValidade) : '';
            if (lote.dataFabricacao && lote.dataValidade) {
              let rastreavel: Rastreavel = new Rastreavel();
              rastreavel.lote = lote.nome;
              rastreavel.quantidade = movimentacaoQuantidade.quantidade;
              rastreavel.dataFabricacao = this.formatarData(lote.dataFabricacao);
              rastreavel.dataValidade = this.formatarData(lote.dataValidade);
              rastreavel.codigoAgragacao = produto.codigoBarra;
              rastreaveis.push(rastreavel);
            }
          }
        }
      });
      return rastreaveis.length > 0 ? rastreaveis : null;
    }
  }

  public gerarProdutoFormulado(produto: Produto) {
    if (produto.idProdutoFormulado) {
      this.utilSessao.mensagemProcessandoNFe('Produto Formulado');
      const produtoFormulado: ProdutoFormulado = this.produtosFormulado.find((produtoFormulado) => produtoFormulado.id == produto.idProdutoFormulado);
      if (produtoFormulado) {
        this.informacaoComplementarProduto += produtoFormulado.nomeEmbarque ? ' NOME EMBARQUE: ' + produtoFormulado.nome : '';
        this.informacaoComplementarProduto += produtoFormulado.descricaoClasse ? ' CLASSE: ' + produtoFormulado.descricaoClasse : '';
        this.informacaoComplementarProduto += produtoFormulado.ingredienteAtivo ? ' ING ATI: ' + produtoFormulado.ingredienteAtivo : '';
        this.informacaoComplementarProduto += produtoFormulado.numeroRisco ? ' RISCO: ' + produtoFormulado.numeroRisco : '';
        this.informacaoComplementarProduto += produtoFormulado.numeroOnu ? ' ONU: ' + produtoFormulado.numeroOnu : '';
        this.informacaoComplementarProduto += produtoFormulado.grupoEmbalagem ? ' GRUPO EMB: ' + produtoFormulado.grupoEmbalagem : '';
        this.informacaoComplementarProduto += produtoFormulado.registro ? ' REGISTRO: ' + produtoFormulado.registro : '';
        this.informacaoComplementarProduto += produtoFormulado.classeToxicologica ? ' CLA TOX: ' + produtoFormulado.classeToxicologica : '';
        this.informacaoComplementarProduto += '.';
      }
    }
  }

  public gerarInformacaoComplementarProduto(movimentacaoProduto: MovimentacaoProduto): String {
    if (this.processamentoConcluido) {
      const produto: Produto = this.produtos.find((produto) => produto.id == movimentacaoProduto.idProduto);
      this.gerarRastreabilidade(produto, movimentacaoProduto);
      this.gerarCalculoDesonerado(produto, movimentacaoProduto);
      this.gerarProdutoFormulado(produto);
      return this.informacaoComplementarProduto.trim().replace('=', '');;
    }
  }

  public gerarCalculoDesonerado(produto: Produto, movimentacaoProduto: MovimentacaoProduto) {
    if (produto.motivoDesonerado && produto.motivoDesonerado.length > 0) {
      this.utilSessao.mensagemProcessandoNFe('ICMS desonerado item');
      const util: Util = new Util();
      let movimentacaoProdutoValorUnitarioFinal: number = util.arredondar(movimentacaoProduto.valorTotal / movimentacaoProduto.quantidade);
      if (movimentacaoProduto.icmsBasePercentual > 0) {
        this.informacaoComplementarProduto += 'ICMS DESONERADO: ' + this.monetarioPipe.transform(movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.quantidade, 2) + ' x ' + this.monetarioPipe.transform(100 - movimentacaoProduto.icmsBasePercentual, 2) + '% x ' + this.monetarioPipe.transform(movimentacaoProduto.icmsAliquota, 2) + '% = ' + this.monetarioPipe.transform(movimentacaoProduto.icmsDesonerado, 2) + ' MOTIVO: ' + produto.motivoDesonerado + ';';
      } else {
        this.informacaoComplementarProduto += 'ICMS DESONERADO: ' + this.monetarioPipe.transform(movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.quantidade, 2) + ' x ' + this.monetarioPipe.transform(movimentacaoProduto.icmsAliquota, 2) + '% = ' + this.monetarioPipe.transform(movimentacaoProduto.icmsDesonerado, 2) + ' MOTIVO: ' + produto.motivoDesonerado + ';';
      }
    }
  }

  formatarDataPadrao(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [dia, mes, ano].join('/');
  }

  formatarData(data: Date): string {
    let d = new Date(data),
      mes = '' + (d.getMonth() + 1),
      dia = '' + d.getDate(),
      ano = d.getFullYear();

    if (mes.length < 2) mes = '0' + mes;
    if (dia.length < 2) dia = '0' + dia;
    return [ano, mes, dia].join('-');
  }
}
