<containerFormulario [vertical]="vertical" [rotulo]="_rotulo" [idMenu]="idMenu" [campoAtual]="campoAtual" [preenchido]="preenchido" [compacto]="compacto" [ajuda]="ajuda" [ajudaDireita]="ajudaDireita" [obrigatorio]="obrigatorio" [prefixo]="prefixo" [sufixo]="sufixo" [desabilitado]="desabilitado" [campoFor]="_id">
  <label *ngIf="descricaoAcima" [for]="id">{{ descricaoAcima + ': ' }}</label>
  <input #input class="form-control" [ngClass]="[fonteMenor ? 'fonte-menor' : '', compacto ? 'compacto' : '', !campoAtual || listaExterna ? '' : preenchido ? '' : 'borda-atencao', usaModoClaro || desabilitado ? 'fonte-preta' : '']" [id]="_id" [name]="_rotulo" [maxlength]="exigidoCaracteres || maximoCaracteres" [ngModel]="campoAtual != null && campoAtual != '' && campoAtual.length > 0 ? campoAtual.trim() : campoAtual" (ngModelChange)="alterarCampo($event)" (focus)="navegar(true)" (blur)="navegar(false)" [disabled]="desabilitado" type="text" autocomplete="off" />
  <atencao *ngIf="_minimoCaracterBusca > 0 && focado" [atencao]="mensagemAtencao" [tipo]="bibPropriedade.atencao.alerta" class="align-self-center mr-md-1"></atencao>
  <ajuda class="align-self-center mr-md-1" *ngIf="!_rotulo && ajuda" [ajuda]="ajuda" [ajudaDireita]="ajudaDireita"></ajuda>
  <label *ngIf="carregando" class="input-group-prepend" for="pi2">
    <span class="input-group-text">
      <div class="loader loader-sm"></div>
    </span>
  </label>
  <icone *ngIf="campoAtual || desabilitado" [icone]="'fas fa-times'" [clique]="true" [desabilitado]="desabilitado" (iconeEmt)="limpar()" [centralizaVertical]="true"></icone>
  <btnAdicional [compacto]="compacto" [icone]="icone" [ajuda]="bibDialogo.buscar" [id]="'busca' + _id.charAt(0).toUpperCase() + _id.slice(1)" [desabilitado]="desabilitado && iconeDesabilitado" [focoIndice]="-1" (btnAdicional)="clicarBtnAdicional()" (mouseover)="focoBtnAdicional = true" (mouseout)="sairFocoBotao()"></btnAdicional>
  <div #listaBusca *ngIf="lista.length > 0" class="listaBusca">
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let registro of lista; let i = index" [id]="i" [ngClass]="posicao == i ? 'selecionado' : ''" (mouseenter)="ehEscolhendo(true, i)" (mouseleave)="ehEscolhendo(false, i)" (click)="selecionar()">
        <div class="posicao-lista" [ngClass]="registro && registro.pai == 'S' ? 'negrito' : ''">
          <div class="row">{{ (_servico == bibServico.contaContabil ? registro.numeracao + ' ' : '') + (registro ? (_servico == bibServico.cidade ? registro.cidadeEstado : registro.nome ? registro.nome.split('  ').join('&nbsp;') : '' || (registro.tipoPessoa && registro.tipoPessoa == 'J') ? registro.nomeFantasia : registro.razaoSocial) : '') }}<atencao *ngIf="registro.idEmpresa && registro.idEmpresa != 0 && registro.idEmpresa != this.utilSessao.getEmpresa().id && registro.empresaAbreviacao" [atencao]="registro.empresaAbreviacao"></atencao></div>
          <div class="row">
            <div *ngIf="registro.enderecoCompleto" class="fonte-pequena">{{ registro.enderecoCompleto }}</div>
          </div>
        </div>
        <atencao *ngIf="apresentarAbreviacaoEmpresa" [atencao]="registro.empresaAbreviacao" class="col-sm d-flex align-items-center justify-content-end" [tipo]="empresaAbreviacaoSessao != registro.empresaAbreviacao ? bibPropriedade.atencao.discreto : null"></atencao>
      </li>
    </ul>
  </div>
</containerFormulario>
