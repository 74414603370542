const bibDialogo = {
  a: 'À',
  aConciliar: 'a conciliar',
  aPagar: 'Selecionados (a pagar)',
  aPrazo: 'A prazo',
  aReceber: 'Selecionados (a receber)',
  aVista: 'À vista',
  aVistaOuAPrazo: 'Á vista ou A prazo',
  abatimento: 'Abatimento',
  aberta: 'Aberta',
  abertas: 'Abertas',
  aberto: 'Aberto',
  abraAMovimentacao: 'Abra a movimentação',
  abreviacao: 'Abreviação',
  abreviacaoAbr: 'Abr',
  abreviacaoMaxMin: 'Abreviação mínimo 3 caracteres',
  abreviacaoPago: 'PG',
  abreviacaoTotal: 'Tot.',
  abril: 'Abril',
  abrilAbreviacao: 'Abr.',
  abrir: 'Abrir',
  abrirCte: 'Abrir CT-e',
  abrirMdfe: 'Abrir MDF-e',
  abrirMovimentacao: 'Abrir movimentação',
  abrirMovimentacaoData: 'Não é permitido abrir movimentação com data diferente da atual.',
  abrirMovimentacaoResponsavel: 'Não é permitido abrir movimentação de outro responsável',
  abrirNovaAba: 'Abrir nova aba',
  abrirReceituario: 'Abrir receituário',
  abrirRomaneioEntrega: 'Abrir romaneio entrega',
  aceitar: 'Aceitar',
  aceite: 'Aceite',
  acessar: 'Acessar',
  acesseSiteCidade: 'Acessar o site cidades (gov)',
  acesseSiteConfaz: 'Clique para acessar o site do Conselho Nacional de Política Fazendária - CONFAZ, para mais informações',
  acesso: 'Acesso',
  acessoHorarioLocal: 'Horário e local',
  acessoRapido: 'Acesso rápido',
  acimaLimiteMaximo: 'Acima do limite máximo',
  acrescentar: 'Acrescentar',
  acrescimo: 'Acréscimo',
  acrescimoNaoPermiteValorMenorQueAtual: 'Acréscimo não permiti valor menor que o atual',
  adiantamento: 'Adiantamento',
  adiantamentoEstorno: 'Adiantamento (estorno)',
  adicionais: 'Adicionais',
  adicional: 'Adicional',
  adicionar: 'Adicionar',
  adicionarChave: 'Adicionar chave',
  adicionarComVinculo: 'Adicionar com vínculo',
  adicionarOperacao: 'Adicionar a partir de',
  adicionarOperacoes: 'Adicionar operações',
  adicionarRotuloColaborador: 'Rótulo <<RESPONSAVEL>>',
  adicionarRotuloNumeroDocumentoAbreviacao: 'Rótulo <<Nº DOCUMENTO>>',
  adicionarRotuloOperacao: 'Rótulo <<OPERAÇÃO>>',
  adicionarRotuloParceiro: 'Rótulo <<PARCEIRO>>',
  adicionarRotuloParcela: 'Rótulo <<PARCELA>>',
  adicionarRotuloTipoDeTitulo: 'Rótulo <<TIPO DE TÍTULO>>',
  adicioneContaContabil: 'Adicione uma conta contábil',
  administrador: 'ADMINISTRADOR',
  aerea: 'Aéreo',
  soloSulcoPlantio: 'Via solo - sulco plantio',
  agencia: 'Agencia',
  agenciaBanco: 'Agência Banco',
  agosto: 'Agosto',
  agostoAbreviacao: 'Ago.',
  agricola: 'Agrícola',
  agronomo: 'Agrônomo',
  agrupar: 'Agrupar',
  aguardando: 'Aguardando',
  aguardandoAprovacao: 'Aguardando aprovação',
  aguardandoCancelamentoNaReceita: 'Aguardando cancelamento na receita',
  aguardandoCancelamentoNoSistema: 'Aguardando cancelamento no sistema',
  aguardandoChegar: 'Aguardando chegar (entrada)',
  aguardandoEntrega: 'Aguardando entrega',
  aguardandoEnvio: 'Aguardando envio',
  aguardandoFinanceiro: 'Aguardando financeiro',
  aguardandoNfe: 'Aguardando NF-e',
  aguardandoPagamento: 'Aguardando pagamento',
  aguardandoPreenchimentoReceituario: 'Aguardando preenchimento do receituário',
  aguardandoSalvar: 'Aguardando salvar [CTRL + B]',
  aguardeUmMomento: 'Por favor, aguarde mais um momento.',
  importante: 'Importante',
  ajudaValidarXml: 'Ao clicar aqui a xml da nota será copiada, basta colar no navegador',
  ajudaAliquotaInterna: '4% para importados, 7% para os estados de origem do Sul e Sudeste (exceto ES), destinado para os estados do Norte, Nordeste, CentroOeste e Espírito Santo e 12% para os demais casos',
  ajudaAreaReceituario: 'Não foi possível sugerir a área automaticamente, verifique as informações: dose, número de aplicação e fator da unidade da dose',
  ajudaAutorizacaoCartao: 'N° de autorização do comprovante de pagamento em cartão',
  ajudaBloquearNegociacao: 'Ao selecionar forma de pagamento diferente de livre, não é permitido que o vendedor altere a negociação no momento da venda',
  ajudaCartaoParcelamento: 'Pagamento em cartão, informe apenas a(s) data(s) que o cliente irá efetuar o pagamento',
  ajudaCarteira: 'Preencher com o número 19 ou 27',
  ajudaCodigoBeneficioFiscal: 'Utilizado na NF-e, é obrigatório em alguns casos em que existe incetivo fiscal por parte da sua UF',
  ajudaComissaoAguardando: 'Valor proporcional ao que o cliente já pagou, mas ainda NÃO repassado ao VENDEDOR',
  ajudaComissaoLiberada: 'Valor proporcional ao que o CLIENTE já pagou',
  ajudaComissaoLiberadaConciliacao: 'Data que o financeiro foi conciliado',
  ajudaComissaoNaoLiberada: 'Valor proporcional ao que o CLIENTE ainda FALTA pagar',
  ajudaComissaoPaga: 'Valor já pago ao VENDEDOR',
  ajudaComissaoValorTotalAPagar: 'Valor total aberto a pagar ao VENDEDOR no final',
  ajudaCredito: 'Despesa com o cliente, exemplo: Devolução',
  ajudaCultura: 'São apresentadas as culturas cadastradas nas indicações',
  ajudaDataPagamentoComissao: 'Data pagamento da comissão ao vendedor',
  ajudaData: 'Tecle [-] para data do início do mês, [espaço] para data atual ou [+] para data do final do mês',
  ajudaDiaFixo: 'O dia da conciliação das parcelas será sempre no dia informado aqui (máximo dia 31)',
  ajudaDoseFator: 'Fator para conversão da unidade da dose para KG ou LT. Exemplo para a unidade ml p.c/ha o fator é 0,001',
  ajudaExceto: 'O nome que for preenchido neste campo ele irá "ignorar" no momento da busca',
  ajudaEstadoOrigem: 'Estado(s) da(s) sua(s) loja(s)',
  ajudaEmailCopiaNfe: 'Ao enviar o e-mail com o XML e DANFE da NF-e para um cliente o sistema também copia o e-mail para os destinatários aqui informados. Em caso de mais de um e-mail, separar com ; (ponto e vírgula)',
  ajudaEmailTamanho: 'E-mail deve conter no máximo 50 caractéres',
  ajudaEnviarTransporteNfe: 'Enviar placa da transportadora para documento fiscal quando for intermunicipal.',
  ajudaEnvioInstantaneo: 'Comunicação direta com as APIs dos bancos, tendo assim uma resposta instantânea sobre a situação de seu boleto, permitindo assim que os clientes façam o pagamento logo após a emissão dos títulos!',
  ajudaFiltroBloqueadoContaBancaria: 'Este filtro será habilitado apenas ao selecionar uma conta contábil que utiliza correntista conta bancária',
  ajudaFiltroBloqueadoParceiro: 'Este filtro será habilitado apenas ao selecionar uma conta contábil que utiliza correntista cliente/fornecedor',
  ajudaGeneroProdutoSped: 'Este campo será enviado para o registro 0200 no Sped fiscal e Sped contribuições',
  ajudaHorarioLocal: 'Horário e local que este usuário pode acessar o sistema',
  ajudaImpressao: 'Define se a impressão será atualizada com os dados de remessas de alterações gerado, mesmo que as alterações ainda não tenham sido confirmadas pelo banco.',
  ajudaImpressaoAtualizada: 'Define se a impressão será atualizada após o vencimento',
  ajudaIntegracao: 'Código de Integração',
  ajudaIntegracaoEidaf: 'Integração com Eidaf',
  ajudaIntegracaoAgroDefesa: 'Integração com AgroDefesa',
  ajudaIp: 'Digite o Ip da rede, com os dígitos separados por ponto',
  ajudaJuros: 'Juro automático + Juro + Acréscimo',
  ajudaLimitarEspecie: 'Se marcar sim, o sistema vai ocultar tipos de títulos com espécies de nível inferior no momento da baixa. Exemplo: Não será possível baixar no cartão de crédito uma venda negociada em dinheiro',
  ajudaLojaClienteTransferencia: 'Vincula a loja a um cliente para ser utilizado em emissão de nota fiscal quando é realizado uma transferência entre lojas',
  ajudaLojaJuroDiario: 'Percentual de juro aplicado diariamente em receitas atrasadas',
  ajudaLojaPrecoMinimo: 'Ao consultar um produto para venda, é aplicado este percentual sobre o preço do produto a fim de sugerir ao vendedor qual seria o preço mínimo a ser vendido',
  ajudaLojaVariacaoMaxima: 'Percentual de variação máxima aceita entre o valor de compra e o custo de reposição cadastrado',
  ajudaMascaraContaContabil: '00.00.00.00',
  ajudaNegociacaoPadrao: 'Informe qual negociação deve ser sugerida na venda',
  ajudaNegociacaoRestrita: 'Adicione quais negociações restritas este cliente pode utilizar',
  ajudaNumeroCasasDecimaisEntrada: 'Número de casas decimais para efetuar operações de entrada',
  ajudaNumeroCasasDecimaisSaida: 'Número de casas decimais para efetuar operações de saída / venda',
  ajudaPerda: 'Total de desconto concedido no momento do recebimento (baixa)',
  ajudaPermiteEstoquFisicoENFfeNegativo: 'Produtos podem ter o estoque físico e nf-e negativo?',
  ajudaPermiteEstoqueNegativo: 'Este produto pode ter o estoque disponível negativo?',
  ajudaPermiteEstoqueFisicoNegativo: 'Este produto pode ter o estoque físico negativo?',
  ajudaPermitirAlterarDiasEntre: 'Ao liberar o usuário terá a opção de mudar o dia do venvimento',
  ajudaPrazoMaximo: 'Ao definir a quantidade de dias, só poderá informar vencimentos dentro deste prazo',
  ajudaQuantidadeTotal: 'Quantidade disponível em todas as lojas',
  ajudaEnviarSped: 'Enviar sped contribuições (Regime de caixa)',
  ajudaValorDescontoAnulado: 'Desconto + Valor Anulado',
  ajudaValorOriginal: 'Valor original da receita',
  ajudaValorPago: 'Valor que o cliente já pagou da receita',
  ajudaValorBaixa: 'Valor pago pelo CLIENTE',
  ajudaValorRestante: 'Valor que resta para o cliente pagar desta receita',
  ajudaValorRestanteTotal: 'Valor restante - Desconto de antecipação',
  ajudaVendaDireta: 'Utilizada somente na operação venda direta',
  ajudaChaveReferenciada: 'Informe aqui a chave da NF-e que estamos devolvendo',
  ajudaContadorEmailCopiaNfe: 'Ao marcar sim, todas as notas fiscais emitidas serão enviadas via e-mail para o contador',
  ajudaSugestaoCompra: 'Estoque mínimo total - Quantidade total',
  ajuste: 'Ajuste',
  ajusteDeEstoque: 'Ajuste de estoque',
  ajusteLimite: ' Ajuste limite',
  alertaArredondamento: 'Por ser agrupado e totalizado por CFOP, este relatório pode ter divergências mínimas de centavos entre as movimentações por questões de arredondamento',
  alertaNumeroManual: 'Tem certeza que deseja informar o número de forma manual?',
  alertaPesoAcimaDe: 'O peso total dos produtos (_ KG) está acima do máximo definido na empresa (_ KG)',
  alertaQuantidadeAcimaDe: 'Produto(s) com quantidade acima do máximo definido na empresa.\n_',
  alertaValorAcimaDe: 'O valor total da movimentação (R$_) está acima do máximo definido na empresa (R$_)',
  alertarPeso: 'Peso acima de',
  alertarQuantidade: 'Quantidade acima de',
  alertarValor: 'Valor acima de',
  alertas: 'Alertas',
  alertaRelatorio1053: 'Relação das movimentações com lucro zerado, destacadas em vermelho no relatório',
  alimentaFinanceiro: 'Alimenta financeiro',
  aliquota: 'Alíquota',
  aliquotaPisEntrada: 'PIS Entrada',
  aliquotaPisSaida: 'PIS Saída',
  aliquotaCofinsEntrada: 'COFINS Entrada',
  aliquotaCofinsSaida: 'COFINS Saída',
  aliquotaEntrada: 'Alíquota entrada',
  aliquotaFisica: 'Alíquota física',
  aliquotaIcms: 'Alíquota ICMS',
  aliquotaJuridica: 'Alíquota jurídica',
  aliquotaSaida: 'Aliquota saída',
  aliquotasPermitidas: 'alíquotas permitidas',
  aliquotaPisCofinsIncorreta: 'Alíquota de _ incorreta',
  altamente: 'I - Altamente tóxico',
  alterado: 'Alterado',
  alterar: 'Alterar',
  alterarBoleto: 'Alterar boleto',
  alterarFoto: 'Alterar foto',
  alterarLogomarca: 'Alterar logomarca',
  alterarSenha: 'Alterar senha',
  alterePeloMenusUmValorParaEfetuarAlteracao: 'Altere pelo menos um registro para salvar',
  alternativa: 'Alternativa',
  ambiente: 'Ambiente',
  analiticaConta: 'Analítica (Conta)',
  ano: 'Ano',
  anp: 'Anp',
  anteciparPara: 'Antecipar para',
  antecipacao: 'Antecipação',
  anterior: 'Anterior',
  antes: 'Antes',
  anual: 'Anual',
  anvisa: 'Anvisa',
  anvisaCodigo: 'Código ANVISA',
  anvisaCodigoExplicacao: 'Número do registro na ANVISA ou preencher com o literal “ISENTO”, no caso de medicamento isento de registro',
  anvisaMotivoIsencao: 'Informar o número da decisão que o isenta na ANVISA, como por exemplo o número da Resolução da Diretoria Colegiada da ANVISA (RDC)',
  anvisaNcm: 'Para medicamentos que o NCM começam com 3001, 3002, 3003, 3004, 3005 e 3006',
  anvisaPrecoMaximo: 'Preço máximo consumidor',
  aoAlterarLojaValoresSeraoRecalculados: 'Ao alterar a loja, todos os valores dos itens serão recalculados. Deseja continuar?',
  aoEntrarComEstoqueSeraAlimentadoCom: 'Ao entrar com _ _, o estoque será alimentado com _ _',
  aoFinalizarEntrega: 'Finalizar entrega',
  aoFinalizarMovimentacao: 'Fin. movimentação',
  apelido: 'Apelido',
  apiId: 'Api Id',
  apiKey: 'Api Key',
  apiSecret: 'Api Secret',
  aplicacao: 'Aplicação',
  aplicar: 'Aplicar',
  aprovacoesAprovadas: 'Aprovações aprovadas',
  aprovacoesReprovadas: 'Aprovações reprovadas',
  aprovado: 'Aprovado',
  aprovador: 'Aprovador',
  aprovar: 'Aprovar',
  apresentaSeguinteSituacao: ' apresenta a seguinte situação: ',
  apuracaoContribuicaoEspecifica: 'Apuração da contribuição a alíquotas específicas (Diferenciadas e/ou por unidade de medida de produto)',
  apuracaoContribuicaoExclusiva: 'Apuração da contribuição exclusivamente a alíquota básica',
  apuracaoLucro: 'Apuração de lucro',
  apuracaoLucroClienteEspecial: 'Apuração de lucro cliente especial',
  apuradoMes: 'Apurado mês',
  aqui: 'Aqui',
  area: 'Área',
  areaHa: 'Área Ha',
  arquivo: 'Arquivo',
  art: 'ART',
  artAtual: 'atual',
  artMesmoEstado: 'Já foi cadastrada uma ART para este mesmo estado',
  assesoriaJuridica: 'Assessoria jurídica',
  assinatura: 'Assinatura',
  assinaturaConfirmacao: 'Usado como assinatura de confirmação - máximo 6 caracteres',
  assinaturaEntregador: 'Ass. entregador',
  assinaturaRecebedor: 'Ass. recebedor',
  atalho: 'Atalho',
  atePeriodoInformado: 'Até o período informado',
  atencao: 'Atenção!',
  atencaoAgrotoxicoVenono: 'Atenção: agrotóxico é veneno',
  atencaoAnexo: 'Leia atentamente as orientações no Anexo I',
  atividadeDeComercio: 'Atividade de comércio',
  atividadeFinanceira: 'Atividade financeira',
  atividadeImobiliaria: 'Atividade imobiliária',
  ativo: 'Ativo',
  atraso: 'Atraso',
  atrasoMaximo: 'Atraso máximo',
  atual: 'atual',
  atualizacaoCadastro: 'Atualização de Cadastro',
  atualizacaoJuroAutomatico: 'A atualização do cálculo do juro automático, será realizado no próximo dia',
  atualizacaoLimiteCredito: 'Atualização de limite de máximo',
  atualizadoEm: 'Atualizado em',
  atualizarMargemLote: 'Atualizar margem em lote',
  atualizarRoteiroContabil: 'Atualizar lista categorias de roteiro contábil',
  automatico: 'Automático',
  avalista: 'Avalista',
  avancar: 'Avançar',
  avancarPagina: 'Avançar pagina',
  avancarPara: 'Avançar para',
  avancarRegistro: 'Avançar registro',
  bancosHomologados: 'Bancos homologados: ',
  backendW: 'N',
  bairro: 'Bairro',
  baixa: 'Baixa',
  baixaConferencia: 'Baixa (conferência)',
  baixaDespesa: 'Baixa (despesa)',
  baixaEmAndamento: 'Baixa em andamento, filtre o financeiro novamente para continuar',
  baixaEstorno: 'Baixa (estorno)',
  baixaNaoEncontrada: 'Não foi encontrada nenhuma _ com os critérios informados',
  baixaReceita: 'Baixa (receita)',
  baixada: 'Baixada',
  baixarRemessa: 'Baixar remessa',
  baixarValorMenorTituloSelecionado: 'O valor informado para baixa (_) é menor que o valor total selecionado (_). Tem certeza que deseja continuar faltando (_)?',
  baixas: 'Baixas',
  bancaria: 'Bancária',
  banco: 'Banco',
  base: 'Base',
  baseCalculo: 'Base cálculo',
  baseIcms: 'Base ICMS',
  baseObrigatorioProduto: 'Base de ICMS é obrigatório para o CST _ usado no item _',
  basePisCofins: 'Base Pis/Cofins',
  bimestral: 'Bimestral',
  bloqueado: 'Bloqueado',
  bloqueadoEstoque: 'Bloquear estoque',
  bloqueadoEstoqueDisponivel: 'Bloquear estoque Disponível',
  bloqueadoFinanceiro: 'Bloquear financeiro',
  bloquear: 'Bloquear',
  bloquearContabilidade: 'Bloquear contabilidade',
  bloquearEstoque: 'Bloquear estoque',
  bloquearFinanceiro: 'Bloquear financeiro',
  bloquearNfe: 'Bloquear NFe',
  bloquearDocumentoEletronico: 'Bloquear doc. eletrônico',
  bloquearNegociacao: 'Bloq. negociação',
  bloqueio: 'Bloqueio',
  bloqueioTela: 'Bloquear Tela',
  boleto: 'Boleto',
  boletoGerado: 'Boleto gerado',
  boletoHomologacao: 'Boleto em homologação',
  boletoAAlterar: 'Boleto a alterar',
  boletoMensagem: 'Segue o link para acesso ao boleto ${linkBoleto}',
  boletoNaoProcessado: 'Boleto ainda não foi processado',
  boletoTutorial: 'Tutorial para configuração do boleto: ',
  buscaDa: 'Busca da',
  buscaDo: 'Busca do',
  buscaParcial: '% ou + para busca parcial',
  buscar: 'Buscar',
  buscarConta: 'Buscar conta',
  bula: 'Bula',
  cadastrarCliente: 'Cadastrar cliente',
  caixa: 'Caixa',
  carenciaDias: 'Carência (Dias)',
  calculoEstoqueMaximo: 'Estoque máximo - (estoque comprado + estoque físico)',
  calculoHistorico: 'Maior média = _ * (_ dias / 30)',
  calculoValorAcrescimo: 'juro + juro automático + acréscimo',
  calculoValorAnterior: 'Valor anterior = juro + juro automático + acréscimo - desconto - valor anulado',
  calculoValorFinal: 'Valor final = valor - desconto + juro + juro automático + acréscimo',
  calculoValorRestante: 'Valor restante = valor final - valor pago - valor anulado',
  calculosAutomaticos: 'Cálculos Automáticos',
  caldaAereoAte: 'Calda aéreo (Até)',
  caldaAereoDe: 'Calda aéreo (De)',
  caldaTerrestreAte: 'Calda terrestre (Até)',
  caldaTerrestreDe: 'Calda terrestre (De)',
  caldaUnidade: 'Calda unidade',
  campo: 'Campo',
  calcularAntecipacao: 'Calcular antecipação?',
  campoAbreviacaoTresCaracteres: 'Campo abreviação pode conter somente três caracteres',
  campoAteMaiorCampoDe: '_ com valor maior que _',
  camposSemDados: 'Campos sem dados',
  cancelada: 'Cancelada',
  cancelamento: 'Cancelamento',
  cancelamentoDesconto: 'Cancelamento de desconto',
  cancelamentoEmAndamento: 'Cancelamento em andamento',
  cancelamentoEmProcessamento: 'Cancelamento em processamento',
  cancelamentoForaPrazo: 'Nota emitida com data anterior a atual, talvez será necessário solicitar junto a seu contador o cancelamento extemporâneo',
  desejaCancelarForaPrazo: 'Nota emitida com data anterior a atual, talvez será necessário efetuar o cancelamento extemporâneo. Deseja continuar?',
  cancelar: 'Cancelar',
  caracteresDaInformacaoComplementar: 'Remova os seguintes caracteres da informação complementar: ',
  caracteresInvalidosParaNomeFiscal: 'Nome fiscal contém caracteres que não podem ser usados &, +, #, !, *, $, ..., (aspas simples) e (aspas duplas).',
  caracteresInvalidosParaSenha: 'Senha de acesso contém caracteres que não podem ser usados %, &, -, +, #, !, *, $, (virgula), (aspas simples) e (aspas duplas).',
  cartaCorrecao: 'Carta de correção',
  cartaCorrecaoNfe: 'Carta de correção - NF-e',
  cartaCorrecaoVinculada: 'carta(s) de correção vinculada(s)',
  cartao: 'Cartão',
  cartaoExigeTaxa: 'Cartão exige uma taxa negociada com a instituição financeira, caso não queira controlar o valor líquido, informe zero na taxa',
  cartaoParcelamentoMaximo: 'Para a negociação informada nesta movimentação, o número máximo de parcelas são _',
  carteira: 'Carteira',
  casado: 'Casado',
  categoria: 'Categoria',
  categoriaPai: 'Categoria Pai',
  categoriaRoteiroContabil: 'Categoria roteiro contábil',
  categoriaRoteiroContabilAbreviado: 'Categoria roteiro cont.',
  centroResultado: 'Centro de resultado',
  centroResultadoPai: 'Centro resultado pai',
  cep: 'CEP',
  certezaArquivoExcel: 'CUIDADO, ao gerar o arquivo, ele ficará salvo neste computador, mesmo que você feche o sistema. Tem certeza que deseja continuar?',
  certezaArquivoExcelPermissao: 'CUIDADO, ao liberar esta permissão, o usuário irá salvar o arquivo no computador onde ele estiver usando e o mesmo será mantido mesmo que ele feche o sistema.',
  certezaEstornarCancelamento: 'Tem certeza que deseja estornar estes cancelamentos?',
  certezaEstornarEntrega: 'Tem certeza que deseja estornar estas entregas?',
  certezaEstornarProduto: 'Tem certeza que deseja estornar estes produtos?',
  certezaExcluirChave: 'Tem certeza que deseja excluir a chave _ ?',
  certezaExcluirConfiguracao: 'Tem certeza que deseja excluir esta configuração?',
  certezaExcluirLancamento: 'Tem certeza que deseja excluir este lançamento?',
  certezaExcluirRoteiro: 'Tem certeza que deseja excluir este roteiro?',
  certezaOrdenar: 'Tem certeza que deseja ordenar por _?',
  certezaEnviarVinculoReceituarioNfe: 'Tem certeza que deseja enviar a NF-e para vincular ao receituário?',
  certezaGerarNovosLancamentos: 'Ao gerar novos lançamentos, os lançamentos anteriores serão excluídos, deseja continuar?',
  cest: 'CEST',
  cestObrigatorioEmProduto: 'CEST é obrigatório para o CST _ quando utilizado com o NCM _ usado no item _',
  cestErrado: 'Verifique o CEST usado no item _, pois está fora do padrão (sete dígitos)',
  cestErradoEspecifico: 'Verifique o CEST, pois está fora do padrão (sete dígitos)',
  cfop: 'CFOP',
  cfopEntrada: 'CFOP entrada',
  cfopSaida: 'CFOP saída',
  cfta: 'CFTA',
  chave: 'Chave',
  chaveIncluida: 'Chave já incluída',
  chaveMinimoCaracteres: 'Chave deve conter 44 caracteres',
  chaveNaoPodeConterEspacoMinimoCaracteres: 'Chave não pode ter espaços em branco e deve conter 44 caracteres',
  chaveNaoVinculadaAoResponsavel: 'Chave não vinculada ao responsável',
  chaveNaoVinculadaAoUsuario: 'Chave não vinculada ao usuário',
  chaveNfe: 'Chave NF-e',
  chaveReferenciada: 'Chave referenciada',
  cheque: 'Cheque',
  cheques: 'Cheques',
  chequesDisponiveis: 'Cheques disponíveis',
  chequesSelecionados: 'Cheques selecionados',
  cidade: 'Cidade',
  cidadeUF: 'Cidade / UF',
  cienteInformacaoReceita: 'Estou ciente das informações contidas nesta receita',
  cincoDiasAtraso: 'Após 5 dias de atraso',
  classe: 'Classe',
  classeAmbiental: 'Classe ambiental',
  classeToxicologica: 'Classe toxicológica',
  classificacao: 'Classificação',
  classificacaoFiscal: 'Classificação fiscal',
  clausulas: 'Cláusulas',
  cli: 'Cli',
  cliente: 'Cliente',
  clienteColaborador: 'CLIENTE/COLABORADOR',
  clienteEspecial: 'Cliente especial',
  clienteFornecedor: 'Cliente/Fornecedor',
  clienteFornecedorFinal: 'Cliente/Fornecedor final',
  clienteIraBuscarNa: 'Cliente irá buscar na',
  clientePagouNaoRepassadoAoVendedor: 'Cliente já pagou, mas ainda NÃO repassado ao VENDEDOR',
  clienteSemEnderecoPrincipal: 'sem endereço principal',
  cliqueAqui: 'Clique aqui',
  cliqueBolaDetalhes: 'Clique nas bolinhas para mais informações',
  cliqueParaMostrarDetalhes: 'Clique para mostrar detalhes',
  cliqueParaOcultarDetalhes: 'Clique para ocultar detalhes',
  cliqueParaOrdenar: 'Para ordenar clique na coluna desejada',
  cliqueAquiParaCopiarId: 'Clique aqui para copiar o ID',
  cmv: 'CMV',
  cnae: 'CNAE',
  cnpj: 'CNPJ',
  cnpjContador: 'CNPJ contador',
  cnpjCpf: 'CNPJ/CPF',
  cnpjIncorreto: 'CNPJ incorreto',
  cnpjNeogrid: 'CNPJ Neogrid',
  cnpjNeogridFornecedor: 'CNPJ do fornecedor que será enviado no arquivo',
  cobranca: 'Cobrança',
  cod: 'COD',
  codApropriacaoCreditos: 'Cod. apropriação créditos',
  codContibuicaoApurada: 'Cod. atribuição apurada',
  codCriterioEscrituracao: 'Cod. critério escrituração',
  codIncidenciaTributaria: 'Cod. incidência tributária',
  codigo: 'Código',
  codigoFilial: 'Código filial',
  codigoAdicionalIdentificador: 'Qualquer informação adicional para identificar o produto. Ex: Código na indústria',
  codigoAgencia: 'Código agência',
  codigoBeneficioFiscal: 'Código do benefício fiscal',
  codigoBeneficioFiscalOitoCaracteres: 'O código do benefício fiscal deve conter 8 carácteres',
  codigoCedente: 'Código cedente',
  codigoDeBarra: 'Código de barras',
  codigoDeBarraIntegracao: 'Código de barras integração',
  codigoDeBarraIntegracaoMensagem: 'Utilizado somente nas integrações com os fornecedores não será enviado para nenhum documento fiscal',
  codigoReceitaE116: 'Código Receita E116',
  codigoTransmissao: 'Código transmissão',
  cofins: 'COFINS',
  cofinsBase: 'Base cofins',
  cofinsRetido: 'COFINS Retido',
  cofinsValor: 'Valor cofins',
  colaborador: 'Colaborador',
  comAcesso: 'Com acesso',
  comInstabilidade: 'Com instabilidade',
  comMovimentacao: 'Com movimentação',
  comMovimento: 'Com movimento',
  comissao: 'Comissão',
  comissaoAbertaRestante: 'Aberta (Com valor liberado)',
  comissaoGerada: 'Comissão gerada',
  comissaoLiberada: 'Comissão liberada',
  comissaoNaoLiberada: 'Comissão não liberada',
  comissaoOrigemColaborador: 'Origem comissão: Colaborador',
  comissaoOrigemGrupo: 'Origem comissão: Grupo',
  comissaoOrigemGrupoColaborador: 'Origem comissão: Grupo e colaborador',
  comissaoOrigemProduto: 'Origem comissão: Produto',
  comissaoOrigemProdutoColaborador: 'Origem comissão: Produto e colaborador',
  comissaoPercentual: '% Comissão',
  comissionado: 'Comissionado',
  comp: 'Comp',
  compacto: 'Compacto',
  compatibilidade: 'Compatibilidade',
  compendioEletronicoDeveConterAoMenoUmaIndicacao: 'Compêndio eletrônico deve conter pelo menos uma indicação',
  complementar: 'Complementar',
  complemento: 'Complemento',
  compostaCrescente: 'Composta crescente',
  compostaDecrescente: 'Composta decrescente',
  composto: 'Composto',
  compra: 'Compra',
  compraTotal: 'Compra total',
  compraTotalMes: 'Compra total mês',
  compraVenda: 'Compra/Venda',
  comprado: 'Comprado',
  comprador: 'Comprador',
  comprovanteDeDevolucao: 'Comprovante de devolução',
  comprovanteEntregaProdutos: 'Comprovante de entrega de produtos',
  concederPermissaoCompleta: ' Conceder permissão completa a',
  concedido: 'concedido',
  conciliado: 'Conciliado',
  conciliacaoAutomatica: 'Conciliação Automática',
  conciliacaoAutomaticaAbreviada: 'Conc. Automática',
  conciliacaoBaixa: 'Conciliações x Baixa',
  conciliacaoLancamentoContabil: 'Conciliações x Lançamentos contábeis',
  conciliacaoSemData: 'Conciliação sem data definida',
  conciliacao: 'Conciliação',
  conciliadas: 'Conciliadas',
  conciliar: 'Conciliar',
  concluida: 'Concluída',
  concluido: 'Concluído',
  configuracao: 'Configuração',
  configuracaoContabil: 'Configuração contábil',
  configuracaoContabilFinanceiro: 'Configuração contábil financeiro',
  configuracaoDefinidaGrupo: 'Configuração definida no grupo',
  configuracaoFinanceiro: 'Configuração financeiro',
  configuracaoFinanceiroContabil: 'Configuração financeiro contábil',
  configuracaoMovimentacaoAtalho: 'Conf. movimentação',
  configuracaoMovimentacaoContabil: 'Configuração movimentação contábil',
  configuracaoTabelaPreco: 'Configuração tabela de preço',
  configuracaoTransferenciaContabil: 'Configuração transferência contábil',
  configuradoContingencia: 'Loja está configurada para emitir em contingência.',
  configuradoHomologacao: 'Loja está configurada para emitir em homologação.',
  configurarInformacoesFiscais: 'Configurar informações fiscais',
  configurarValor: 'Configurar valor',
  confirmacaoInformacoes: 'Confirmo e atesto as informações contidas neste receituário',
  confirmar: 'Confirmar',
  confirmarLimparParcelas: 'Será necessário realizar o parcelamento novamente. Deseja continuar?',
  considerandoDataAtual: 'Considerando data atual',
  considerarValorUnitarioComAdicionaisAbaixo: 'Considerar valor unitário e adicionais abaixo: ',
  consultarBoleto: 'Consultar boleto',
  consultarCancelamento: 'Consultar cancelamento',
  consultarReceituarioEletronico: 'Consultar receituário',
  consultarMDFe: 'Consultar MDF-e',
  consultarNfe: 'Consultar NF-e',
  consultarSat: 'Consultar SAT',
  consultarCte: 'Consultar CT-e',
  consumidorFinalCpfPadrao: 'Consumidor final CPF padrão 111.111.111-11',
  consumidorFinalNaoPermitido: 'Consumidor final não permitido para operação que emite nota',
  consumidorFinalSomenteNFCe: 'A emissão é permitida apenas para o consumidor final.',
  conta: 'Conta',
  contaAmbienteHomologacao: 'Conta em ambiente de homologação',
  contaAtual: 'Conta atual',
  contaBancaria: 'Conta bancária',
  contaBancariaLoja: 'A conta _ não está vinculada com a _',
  contaContabil: 'Conta contábil',
  contaContabilContrapartida: 'Conta contábil contrapartida',
  contrapartida: 'Contrapartida',
  contaContabilPaiNaoEncontrada: 'Conta contábil pai de numeração _ não encontrada',
  contaContabilVincularCaixa: 'Conta contábil utilizada para lancaçamentos contábeis das contas tipo caixa',
  contaContabilVincularCliente: 'Conta contábil utilizada para lancaçamentos contábeis dos clientes',
  contaContabilVincularDepreciacao: 'Conta contábil utilizada para lancaçamentos contábeis da depreciação',
  contaContabilVincularDesconto: 'Conta contábil utilizada para lancaçamentos contábeis dos descontos de',
  contaContabilVincularDespesa: 'Conta contábil utilizada para lancaçamentos contábeis das despesas lançadas direto no financeiro',
  contaContabilVincularEspecifica: 'Conta contábil utilizada para lancaçamentos contábeis das contas específicas definidas no cadastro do parceiro (Ex.: Despesas)',
  contaContabilVincularFornecedor: 'Conta contábil utilizada para lancaçamentos contábeis dos fornecedores',
  contaContabilVincularJuro: 'Conta contábil utilizada para lancaçamentos contábeis de juros de',
  contaContabilVincularMulta: 'Conta contábil utilizada para lancaçamentos contábeis de multas de',
  contaContabilVincularReceita: 'Conta contábil utilizada para lancaçamentos contábeis das receitas lançadas direto no financeiro',
  contaContabilVincularTaxasCartao: 'Conta contábil utilizada para lancaçamentos contábeis de taxas de cartão',
  contaCorrente: 'Conta corrente',
  contaCredito: 'Conta crédito' /*Conta saída/crédito*/,
  contaDebito: 'Conta débito' /*Conta entrada/débito*/,
  contaDestino: 'Conta destino',
  contaEspecifica: 'Conta específica',
  contaInicial: 'Conta inicial',
  contaOrigem: 'Conta origem',
  contaPrevista: 'Conta prevista',
  contaReferencial: 'Conta referencial',
  contabil: 'Contábil',
  contabilidade: 'Contabilidade',
  contador: 'Contador',
  contadorMaiscula: 'CONTADOR',
  contasComAcesso: 'Contas com acesso',
  contasContabeis: 'Contas contábeis',
  contasContabeisDevemSerDiferentes: 'Conta débito e conta crédito devem ser diferentes',
  contasDeAtivo: 'Contas de ativo',
  contasDeCompensacao: 'Contas de compensação',
  contasDePassivo: 'Contas de passivo',
  contasDeResultado: 'Contas de resultado',
  contasIguais: 'Conta de origem e destino iguais',
  contatarCliente: 'Contatar Cliente',
  contato: 'Contato',
  contemReceituarioNaoPreenchido: 'Contém receituário não preenchido',
  contingencia: 'Contingência',
  contingenciaDisponivel: 'Contingência disponível',
  continuar: 'Continuar',
  contrato: 'Contrato',
  contribuinte: 'Contribuinte',
  controla: 'Controla',
  controlarEntrega: 'Controlar entrega',
  entregaOpcional: 'Entrega opcional',
  controlarEstoque: 'Controlar estoque',
  controlarLote: 'Controlar lote',
  controlarSepararEntregarMercadoria: 'Controlar separação e entrega de mercadoria',
  ajudaEntregaOpcional: 'Vendedor poderá escolher se deseja ou não usar a tela de separar e entregar',
  controleEstoque: 'Controle de estoque',
  convenio: 'Convênio',
  conversao: 'Conversão',
  copia: 'Cópia',
  copiar: 'Copiar',
  copiarEnderecoPrincipal: 'Copiar endereço principal',
  cor: 'Cor',
  corUtilizadaNaVenda: 'Cor utilizada na venda para sinalizar este tipo de cliente',
  correntista: 'Correntista',
  correspondencia: 'Correspondência',
  cpf: 'CPF',
  cpfCnpj: 'CNPJ/CPF',
  cpfIncorreto: 'CPF incorreto',
  crc: 'CRC',
  crea: 'CREA',
  credito: 'Crédito',
  creditoHistorico: 'Crédito (histórico)',
  creditoTotal: 'Crédito total',
  crescente: 'Crescente',
  criar: 'Criar',
  criarAgora: 'Criar agora',
  criterios: 'Critérios',
  csllRetido: 'CSLL Retido',
  cst: 'CST',
  cstPisEntrada: 'CST PIS ENTRADA',
  cstPisSaida: 'CST PIS SAÍDA',
  cstCofinsEntrada: 'CST COFINS ENTRADA',
  cstCofinsSaida: 'CST COFINS SAÍDA',
  cstContribuindoIsento: 'CST para operação com contribuinte isento de inscrição',
  cstEntrada: 'CST entrada',
  cstPisCofinsDiferentes: 'O produto _ está com CST de PIS e Cofins diferentes',
  cstSaida: 'CST saída',
  cstReducaoComBaseIntegral: 'Para o CST _ a base de cálculo não pode ser integral',
  cstReducaoComBaseZerada: 'Para o CST _ a base de cálculo não pode ser zerada',
  cte: 'CT-e',
  cteMensagemRetornoErro: 'Informações do CT-e \n Situação: _',
  cteMensagemRetornoSucesso: 'Informações do CT-e \n Situação: _ \n Número _ \n Chave: _',
  cteDocumentoFiscalVazio: 'Nenhum documento fiscal informado',
  cteInformacoesCargaVazio: 'Nenhuma informação da carga foi informada',
  cteUnidadeMedidaMetroCubico: '00 - Metro cúbico',
  cteUnidadeMedidaKilo: '01 - Kilo',
  cteUnidadeMedidaTonelada: '02 - Tonelada',
  cteUnidadeMedidaUnidade: '03 - Unidade',
  cteUnidadeMedidaLitro: '04 - Litro',
  cteUnidadeMedidaMilhaoBTU: '05 - Milhão BTU',
  cteFinalizado: 'Não é permitido excluir CT-e já finalizado',
  cteCancelado: 'Não é permitido excluir CT-e já cancelado',
  ctrlAtras: '[ctrl ⟵] voltar ',
  ctrlAvancar: '[ctrl ⟶] avançar',
  ctrlShiftAtras: '[ctrl shift ⟵] voltar ',
  ctrlShiftAvancar: '[ctrl shift ⟶] avançar',
  cultura: 'Cultura',
  curvaABC: 'Curva ABC',
  cus: 'Cus',
  custo: 'Custo',
  custoBruto: 'Custo bruto',
  custoLiquido: 'Custo líquido',
  custoMedio: 'Custo médio',
  custoMedioNfe: 'Custo médio NF-e',
  custoNfe: 'Custo NF-e',
  custoReposicao: 'Custo reposição',
  custoReposicaoMaiorCustoGerencial: 'O custo de reposição do produto está maior que o gerencial, deseja continuar?',
  custoReposicaoMaiorVenda: 'maior que preço de venda',
  custoTotal: 'Custo total',
  daMovimentacaoN: 'da movimentação:',
  dados: 'Dados',
  dadosAdicionais: 'Dados adicionais',
  dadosComplementares: 'Dados complementares',
  dadosContador: 'Dados contador',
  dadosPagamento: 'Dados pagamento',
  dadosProfissionais: 'Dados profissionais',
  dadosRecebimento: 'Dados recebimento',
  dasMovimentacoesN: 'das movimentações:',
  dashboard: 'Dashboard',
  data: 'Data',
  dataAbertura: 'Data abertura',
  dataAberturaIncorreta: 'Data abertura incorreta',
  dataAlteracao: 'Data alteração',
  dataAniversarioAte: 'Data aniversário (Até)',
  dataAniversarioDe: 'Data aniversário (De)',
  dataAquisicao: 'Data Aquisição',
  dataAte: 'Data (Até)',
  dataAtualizacao: 'Data atualização',
  dataAutorizacao: 'Data autorização',
  dataBaixa: 'Data baixa',
  dataBaixaAte: 'Data baixa (Até)',
  dataBaixaComissao: 'Data baixa comissão',
  dataBaixaDe: 'Data baixa (De)',
  dataCobrancaAte: 'Data cobrança (Até)',
  dataCobrancaDe: 'Data cobrança (De)',
  dataComissaoAte: 'Data comissão (Até)',
  dataComissaoDe: 'Data comissão (De)',
  dataConciliacao: 'Data conciliação',
  dataConciliacaoAte: 'Data conciliação (Até)',
  dataConciliacaoDe: 'Data conciliação (De)',
  dataConciliacaoMenorQueBaixa: 'Data de conciliação menor que a data da baixa',
  dataConferencia: 'Data conferência',
  dataConferenciaIgualDataAtual: 'Data de conferência deve ser igual a data atual',
  dataDe: 'Data (De)',
  dataDesfazimento: 'Data desfazimento',
  dataDesfazimentoAte: 'Data desfaz. (Até)',
  dataDesfazimentoDe: 'Data desfaz. (De)',
  dataAntecipacao: 'Data de antecipação',
  dataDoAceite: 'Data do aceite',
  dataDocumentoAbreviado: 'Data DOC',
  dataEHora: 'Data e hora',
  dataEmissaoAte: 'Data emissão (Até)',
  dataEmissaoDe: 'Data emissão (De)',
  dataEntrada: 'Data entrada',
  dataEntradaAte: 'Data entrada (Até)',
  dataEntradaDe: 'Data entrada (De)',
  dataEntradaSaida: 'Data entrada/saída',
  dataEntrega: 'Data entrega',
  dataEntregaAte: 'Data Entrega (Até)',
  dataEntregaDe: 'Data Entrega (De)',
  dataFabricacao: 'Data fabricação',
  dataFinalizado: 'Data finalizado',
  dataFinalizadoAte: 'Data finalizado (Até)',
  dataFinalizadoDe: 'Data finalizado (De)',
  dataFutura: 'Não é permitido data maior que a data atual',
  dataGeracaoAte: 'Data geração (Até)',
  dataGeracaoDe: 'Data geração (De)',
  dataHora: 'Data hora',
  dataHoraAbertura: 'Data hora abertura',
  dataHoraFechamento: 'Data hora fechamento',
  dataHoraImpressao: 'Data e hora impressão',
  dataHotaAlteracao: 'Data hora alteração',
  dataIntervaloUmAno: 'O intervalo entre as datas deve ser no máximo 1 ano',
  dataIntervaloUmMes: 'O intervalo entre a data da visita e a data da próxima agendada deve ser no máximo 1 mês',
  dataLancamento: 'Data lançamento',
  dataLancamentoAte: 'Data lançamento (Até)',
  dataLancamentoDe: 'Data lançamento (De)',
  dataLiberacaoAte: 'Data liberação (até)',
  dataLiberacaoDe: 'Data liberação (De)',
  dataMaiorDataAte: 'Data (até) menor que data (de)',
  dataMoviAte: 'Data mov. (Até)',
  dataMoviDe: 'Data mov. (De)',
  dataMovimentacao: 'Data movimentação',
  dataNascimento: 'Data de nascimento',
  dataNascimentoIncorreta: 'Data de nascimento incorreta',
  dataNegociacao: 'Data negociação',
  dataNegociacaoAte: 'Data negociação (Até)',
  dataNegociacaoDe: 'Data negociação (De)',
  dataPagamento: 'Data pagamento',
  dataPagamentoAte: 'Data pagamento (Até)',
  dataPagamentoDe: 'Data pagamento (De)',
  dataPreDatada: 'Data pré-datada',
  dataPreDatadaAte: 'Data pré-datada (Até)',
  dataPreDatadaDe: 'Data pré-datada (De)',
  dataPrevista: 'Data Prevista',
  dataPrevistaAte: 'Data prevista (Até)',
  dataPrevistaDe: 'Data prevista (De)',
  dataRecebimento: 'Data recebimento',
  dataRecebimentoAte: 'Data recebimento(Até)',
  dataRecebimentoDe: 'Data recebimento(De)',
  dataRemessa: 'Data remessa',
  dataRemessaAte: 'Data remessa (Até)',
  dataRemessaDe: 'Data remessa (De)',
  dataRepasse: 'Data repasse',
  dataSaida: 'Data saída',
  dataSaidaAte: 'Data saída (Até)',
  dataSaidaDe: 'Data saída (De)',
  dataSaidaMenorDataNegociacao: 'Data de saída menor que data de negociação',
  dataSaldo: 'Data saldo',
  dataSolicitacaoPagamentoAte: 'Data solicita pag. (Até)',
  dataSolicitacaoPagamentoDe: 'Data solicita pag. (De)',
  dataUltCompra: 'Data Últ. Compra',
  dataUltCompraAte: 'Data últ. compra (Até)',
  dataUltCompraDe: 'Data últ. compra (De)',
  dataUltimaCompraAbreviado: 'Dt. últ. compra',
  dataValidade: 'Data validade',
  dataValidadeMenorFabricacao: 'Data de validade menor que a data de fabricação',
  dataVencimento: 'Data vencimento',
  dataVencimentoAte: 'Data vencimento (Até)',
  dataVencimentoDe: 'Data vencimento (De)',
  dataVencimentoDeAteDiferem: 'Data de vencimento (De) e (Até) deve ser igual a data atual',
  dataVencimentoMovimentacao: 'Data vencimento movimentação',
  dataVencimentoMenorDataAtual: 'Data vencimento é menor que a data atual',
  dc: 'D/C',
  debito: 'Débito',
  debitoCredito: 'Débito e crédito',
  debitoCreditoAbreviacao: 'D/C',
  debitoECredito: 'Débito/crédito',
  debitosAtrasado: 'Débitos atrasados',
  debitosVencer: 'Débitos a vencer',
  declaraDevolucaoPagamento: 'Previsão recebimento de devolução no valor de',
  declaraPrevisaoPagamento: 'Previsão de pagamento no valor de',
  declaracaoRecebimentoMercadoria: 'Declaro que recebi a mercadoria da entrega e não apresentou defeito',
  declaracaoRecibo: '_ _, inscrita no _ sob o nº _ a importância de _ (_)',
  decrescente: 'Decrescente',
  deducoes: 'Deduções',
  definaTipoTituloPadrao: 'Defina um título padrão no menu de tipo de título',
  definir: 'Definir',
  definirMargemLucro: 'A margem de lucro deste produto não foi definida, desta forma ao finalizar não será gerado o preço de venda e terá que ser cadastrado manualmente.',
  deletarFinanceiroBoleto: 'Tem certeza que deseja descartar o boleto do cliente _ no valor de _ com vencimento em _?',
  dentro: 'Dentro',
  dep: 'dep',
  depois: 'Depois',
  deposito: 'Depósito',
  depreciacao: 'Depreciação',
  depreciacaoInformarDataAquisicao: 'Para primeira depreciação o campo Data Aquisição é obrigatório',
  depreciacaoValor: 'Para depreciação o campo Valor deve ser maior que zero',
  depreciacaoValorAtual: 'Para depreciação o campo Valor Atual deve ser maior que zero',
  desativarFinanceiroBaixado: 'Não é permitido desativar financeiro já baixado',
  descartarBoleto: 'Descartar boleto',
  desconsiderarCultura: 'Desconsiderar cultura',
  desconsiderarDevolucaoDeCompra: 'Desconsiderar devolução de compra',
  desconsiderarSequenciaParcela: 'Descons. sequência',
  desconto: 'Desconto',
  descontoAbreviacao: 'Desc',
  descontoAntecipacao: 'Desconto antecipação',
  descontoAcimaDe: 'Desconto acima de',
  descontoMaximo: 'Desconto máximo',
  descontoNegociacao: 'Desconto negociação',
  descontoRateado: 'Desconto rateado',
  descontoTotal: 'Desconto total',
  descontos: 'Descontos',
  descricao: 'Descrição',
  descricaoFator: 'Transformação da unidade do produto para kg ou litro',
  descricaoFiscal: 'Descrição fiscal',
  descricaoNcm: 'Descrição NCM',
  descricaoNfe: 'Descrição NFE',
  descricaoPodutos: 'Descrição dos produtos',
  desejaGerarBoletoDesatualizado: 'O boleto gerado está desatualizado em relação ao financeiro. Deseja continuar a visualizar o PDF do boleto?',
  desejaAbrirCteNumero: 'Deseja abrir CT-e número',
  desejaAbrirMdfeNumero: 'Deseja abrir MDF-e número',
  desejaAbrirMovimentacaoNumero: 'Deseja abrir movimentação número',
  desejaAbrirRomaneioEntregaNumero: 'Deseja abrir romaneio de entrega número',
  desejaBuscarPrecoLoja: 'Deseja buscar o preço de qual loja?',
  desejaCancelarNfe: 'Tem certeza que deseja cancelar NF-e _ ?',
  alterarNegociacao: 'Toda a negociação já realizada será excluida.',
  desejaContinuar: 'Tem certeza que deseja continuar?',
  desejaExcluirCartaCorrecao: 'Tem certeza que deseja excluir esta carta de correção?',
  desejaExcluirFoto: 'Tem certeza que deseja excluir esta foto?',
  desejaExcluirParcela: 'Tem certeza que deseja excluir todas as parcelas e gerar novamente?',
  desejaExcluitBoleto: 'Deseja excluir boleto',
  desejaLimparFiltro: 'Deseja limpar os demais filtros?',
  desejaSair: 'Tem certeza que deseja sair sem salvar as alterações?',
  desejaSubstituirTela: 'Tem certeza que deseja ir para uma nova tela sem salvar as alterações?',
  desejaReplicarTituloEConta: 'Tem certeza que deseja replicar o tipo de título (_) e a conta (_) para todos os títulos das baixas que estão com estes campos vazios?',
  desejaDesistirCancelamento: 'Tem certeza que deseja desistir do cancelamento? Ao desistir o sistema irá estornar o cancelamento e finalilzar a movimentação novamente',
  desfazer: 'Desfazer',
  desmarcarTodos: 'Desmarcar todos',
  desonerado: 'Desonerado',
  despesa: 'Despesa',
  despesaAConciliar: 'Despesa a conciliar',
  despesaAmanha: 'Despesa amanhã',
  despesaGeral: 'Despesa geral',
  despesaMaiorMesAnterior: 'Despesa maior que mês anterior',
  despesaMenorMesAnterior: 'Despesa menor que mês anterior',
  despesasAbertas: 'Despesas abertas no financeiro',
  despesasAcessorias: 'Despesas acessórias',
  despesasMesContaContabil: 'Despesas do mês por conta contábil',
  destacaObservacao: 'Destaca esta observação em relatórios',
  destinatario: 'Destinatário',
  destinatarioNaoPossuiEmailCadastrado: 'Destinatário não possui e-mail cadastrado',
  destino: 'Destino',
  desistirCancelamento: 'Desistir cancelamento',
  detalhamentoDeArea: 'Detalhamento de área',
  deveConterPeloMenosUmLancamento: 'Lançamento contábil deve conter pelo menos um lançamento',
  deveConterPeloMenosUmRoteiro: 'Roteiro contábil deve conter pelo menos um roteiro',
  deveConterPeloMenosUmaConfiguracao: '_ deve conter pelo menos uma configuração',
  devolucao: 'Devolução',
  devolucaoAberta: 'Devolução (aberta)',
  devolucoes: 'Devoluções',
  devolucoesEspeciais: 'Devoluções especiais',
  dezembro: 'Dezembro',
  dezembroAbreviacao: 'Dez.',
  dia: 'Dia',
  diaConciliacaoPadrao: 'Dia conciliação',
  diaDeFechamento: 'Dia de fechamento',
  diaDeGeracao: 'Dia de geração',
  diaFechamento: 'Dia fechamento',
  diaFixo: 'Dia fixo',
  diaMais: 'Dias após a venda',
  diaMes: 'Dia/Mês',
  diaSemana: 'Dia da semana',
  diagnostico: 'Diagnóstico',
  dialogoBoletoSituacaoErroProcessamento: 'Erro no processamento de boleto.',
  dialogoCustoNfe: 'Média ponderada de todas as entradas fiscais efetuadas no sistema',
  dias: 'Dias',
  diasCompensacao: 'Dias para compensação',
  diasAntecipacao: 'Antecipação de valor',
  diasAtrasados: 'Dia atraso',
  diasAtraso: 'Dias atraso',
  diasDesconto: 'Dias para desconto',
  diasEntre: 'Dias entre',
  diasFixo: 'Dias fixo',
  diasJurosMora: 'Dias de Juros/Mora',
  diasMulta: 'Dias para multa',
  diasProtesto: 'Dias para protesto',
  diasVencido: 'Dias vencido',
  dicaContaEspecifica: 'Ao usar este tipo de título no momento da baixa o sistema vai sempre utilizar a conta bancária informada aqui',
  diferenacaMaiorZero: 'Diferença entre débito e crédito deve ser 0',
  diferenca: 'Diferença',
  diferencaEntreDebitoCreditoDesejaContinuar: 'Diferença entre débito (_) e crédito (_).Tem certeza que deseja continuar?',
  diferimentoAliquota: '% Diferimento',
  digiteNome: 'Digite o nome do(a)',
  digiteNomeIntegracao: 'Digite o nome da integração desejada',
  digiteNomeTela: 'Digite o nome do menu desejado',
  digiteNumeroNomeDashboard: 'Digite o número ou nome do dashboard desejado',
  digiteNumeroNomeProduto: 'Digite número ou nome do produto',
  digiteNumeroNomeRelatorio: 'Digite o número ou nome do relatório desejado',
  digital: 'Digital',
  digito: 'Dígito',
  digitoAgencia: 'Dígito agência',
  diminuir: 'Diminuir',
  dinamicaDevolucao: 'Dinâmica da devolução',
  dinheiro: 'Dinheiro',
  disponiveis: 'Disponíveis',
  disponivel: 'Disponível',
  disponivelAjuda: 'Conciliadas + Limite',
  disponivelLoja: 'Disponível loja',
  disponivelTotal: 'Disponível total',
  disposicaoResiduosEmbalagens: 'Disposição final de resíduos e embalagens',
  divorciado: 'Divorciado',
  do: 'Do',
  doFinanceiroN: 'Referente a quitação do financeiro Nº',
  documento: 'Documento',
  documentoFiscal: 'Documento fiscal',
  documentoFiscalObrigatorio: 'Documento fiscal é de preenchimento obrgatório',
  documentoFiscalVazio: 'Nenhum documento fiscal informado',
  documentos: 'Documentos',
  doisNumeros: 'Ao menos dois números;',
  domingo: 'Domingo',
  dosFinanceiroN: 'Referente a quitação dos financeiros Nº',
  dose: 'Dose',
  doseAte: 'Dose (Até)',
  doseDe: 'Dose (De)',
  doseFatorVazio: 'Tem certeza que deseja salvar sem a unidade da dose? Sendo assim não será possível a sugestão automática da área no receituário',
  doseForaFaixa: 'Dose está fora da faixa. Tem certeza que deseja continuar?',
  doseUnd: 'Dose (Und)',
  downloadValidacao: 'Não é permitido fazer download de _ que não foi transmitido.',
  duasLetrasMinusculas: 'Ao menos duas letras minúsculas;',
  duplicaCnpjCpf: 'Duplicar CNPJ/CPF ',
  duplicar: 'Duplicar',
  duplicataMercantil: 'Duplicata mercantil',
  edicao: 'Edição',
  edicaoParceiroLimites: 'Ao entrar para edição o (_) estava em (_), durante a alteração foi alterado para (_) por outro usuário. Deseja continuar alterando o (_) para (_)?',
  edicaoParceiroNcms: 'Ao entrar para editar o (_ _) estava com NCM (_), durante a alteração do mesmo outro usuário alterau para o NCM (_). Deseja continuar alterando o NCM (_) para (_)?',
  editar: 'Editar',
  efetueGeracaoDeLancamentoContabil: 'Efetua a geração de lançamentos contábeis',
  efetueLoginNovamenteParaUtilizarAlteracoes: 'Faça "LOGIN" novamente para utilizar as alterações efetuadas',
  emAberto: 'Em aberto',
  emAndamento: 'Em andamento',
  emBranco: 'Em branco',
  emProcessoRegistro: 'Em processo de registro',
  emTransferencia: 'Em transferência',
  email: 'E-mail',
  emailUsuario: 'Usuário deve ser um e-mail válido',
  emailMensagensVarios: 'Utilize a opção de _ para informar todos os e-mail',
  embalagemSicca: 'Embalagem Sicca',
  embalagemSiccaVazia: 'Embalagem Sicca deve ser preenchida',
  emissao: 'Emissão',
  emiteNfe: 'Emite NF-e',
  emitente: 'Emitente',
  emitenteObrigatorio: 'Emitente é obrigatório',
  emitir: 'Emitir',
  emitirBoleto: 'Emitir boleto',
  emitirBoletoHomologacao: 'Emitir boleto em homologação',
  emitirEmContingencia: 'Emitir em contingência (SVN)',
  empresa: 'Empresa',
  empresaComCreditoDisponivel: 'Empresa com _ de crédito disponível',
  empresaFilial: 'Empresa filial',
  empresaNaoControlaComissao: 'Empresa não controla comissão, para alterar a regra de comissão, acesse o cadastro de empresa',
  encargos: 'Encargos',
  encerrado: 'Encerrado',
  encontrado: 'Encontrado',
  encontradoAbreviacao: 'Enc.',
  endCobranca: 'End cobrança',
  endEntrega: 'End entrega',
  sat: 'SAT',
  endEntregaEmbalagens: 'Entrega embalagens',
  endEtregaEmbalagens: 'End. entrega das embalagens',
  endEtregaEmbalagensNaoInformado: 'Endereço de entrega das embalagens vazias não informado na loja',
  endResCom: 'End Res/Com',
  endereco: 'Endereço',
  enderecoEntrega: 'Endereço entrega',
  enderecoNumero: 'Número endereço é permitido somente número com excessão de S/N',
  enquadramento: 'Enquadramento',
  enter: '<enter>',
  enterDescricao: '<enter> Entrar/editar',
  enterParaConfirmar: '[enter] para confirmar',
  entrada: 'Entrada',
  entradaPermitida: 'Entrada permitida apenas em _ com modalidade à vista',
  entradaSaida: 'Entrada/Saída',
  entradaSaidaDevolucao: 'Entrada/Saída/Devolução',
  entreAsMovimentacoesExisteNegociacaoCartaoParcelaDiferente: 'Não é permitido unificar movimentações realizadas utilizando cartão de crédito com parcelamentos diferentes',
  entrega: 'Entrega',
  entregaParaCliente: 'Entrega para cliente',
  entregaParcial: 'Entrega parcial',
  entregaProdutoMovimentacaoProduto: 'Movimentação produto x Entrega produto',
  entregar: 'Entregar',
  entregarTodos: 'Entregar todos',
  enviar: 'Enviar',
  enviarBoletoDestinatario: 'Enviar boleto ao destinatário',
  enviarBoletoParaCliente: 'Enviar boleto para o e-mail _',
  enviarCancelamentoCTeSomenteEnviado: 'Não é permitido cancelar CT-e que não esteja no status de transmitido.',
  enviarCancelamentoMDFeSomenteEnviado: 'Não é permitido cancelar MDF-e que não esteja no status de transmitido.',
  enviarCancelamentoReceituarioSomenteEnviado: 'Não é permitido cancelar receituário que não esteja no status de transmitido.',
  enviarCfopSpedContribuicao: 'Enviar para Sped Contribuição',
  enviarCfopSpedContribuicaoAjuda: 'Enviar para Sped Contribuição do Tipo Regime de Competência (F550)',
  enviarEmail: 'Enviar e-mail',
  enviarEmailCTeSomenteEnviado: 'Não é permitido enviar e-mail de CT-e que não esteja transmitido.',
  enviarEmailCteParaDestinatario: 'Enviar CT-e para o e-mail _',
  enviarEmailMDFeSomenteEnviadoEncerrado: 'Não é permitido enviar e-mail de MDF-e que não esteja transmitido ou encerrado.',
  enviarEncerramentoMDFeSomenteEnviado: 'Não é permitido encerrar MDF-e que não esteja no status de transmitido.',
  enviarIbpt: 'Enviar IBPT',
  enviarIbptAjuda: 'Enviar valor aproximado dos tributos (Fonte IBPT)',
  enviarNotaFiscalParaCliente: 'Enviar nota fiscal eletrônica para o e-mail _',
  enviarPdfXmlParaCliente: 'Enviar PDF e XML para destinatário',
  enviarSped: 'Sped contribuições',
  enviarSolicitacaoCancelamento: 'Enviar solicitação de cancelamento',
  enviarSolicitacaoEncerramento: 'Enviar solicitação de encerramento',
  enviarVinculoReceituarioNfe: 'Enviar NF-e para vincular com o receituário',
  enviarTransporteNfe: 'Enviar placa transportadora',
  enviarValidacaoCTe: 'Não é permitido enviar CT-e que não esteja no status aguardando envio ou rejeição.',
  enviarValidacaoMDFe: 'Não é permitido enviar MDF-e que não esteja no status aguardando envio ou rejeição.',
  enviarValidacaoReceituarioEletronico: 'Não é permitido enviar receituário que não esteja no status aguardando envio ou rejeição.',
  enviarValidacaoCTeSomenteUm: 'Não é permitido enviar mais de um CT-e.',
  enviarValidacaoMDFeSomenteUm: 'Não é permitido enviar mais de um MDF-e.',
  enviarValidacaoReceituarioSomenteUm: 'Não é permitido enviar mais de um Receituário.',
  envio: 'Envio',
  envioInstantaneo: 'Envio instantâneo',
  epocaAplic: 'Época aplic.',
  epocaAplicacao: 'Época de aplicação',
  equipamento: 'Equipamento',
  equipamentoDeProtecaoIndividual: 'Equipamento de proteção individual obrigatório (EPI’s)',
  equipe: 'Equipe',
  equipeDeveConterAoMenoUmColaborador: 'Equipe deve conter pelo menos um colaborador',
  esc: '<esc>',
  escDescricao: '<esc> Sair',
  escolherImagem: 'Escolher imagem',
  escrituracao: 'Escrituração',
  escrituracaoAnterior: 'Escrituração anterior',
  escrituracaoRegimeCumulativo: 'Escrituração de operações com incidência exclusivamente no regime cumulativo',
  escrituracaoRegimeCumulativoNaoCumulativo: 'Escrituração de operações com incidência nos regimes não-cumulativo e cumulativo',
  escrituracaoRegimeNaoCumulativo: 'Escrituração de operações com incidência exclusivamente no regime não-cumulativo',
  especial: 'Especial',
  especie: 'Espécie',
  especieDoc: 'Espécie doc.',
  especifica: 'Específica',
  esperado: 'Esp',
  esquerda: 'esquerda',
  estDisponivelAte: 'Est. disponível (até)',
  estDisponivelDe: 'Est. disponível (de)',
  estFisico: 'Est. físico',
  estFisicoLocal: 'Est. físico',
  estFisicoLojas: 'Est. físico (lojas)',
  estMin: 'Est. min',
  estNfe: 'Est. NFe',
  estaComInscricaoReceitaIrregular: 'possui irregularidade na receita, solicite a regularização e tente emitir uma nova nota',
  estaConciliacaoEBaixasVinculadas: 'Esta conciliação e baixas vinculadas',
  estaFaltandoValorDe: 'Está faltando um valor de',
  estado: 'Estado',
  estadoCivil: 'Estado civil',
  estadoDefinidoNoEnderecoDoCliente: 'Estado _ definido no endereço principal do ',
  estadoDestino: 'Estado destino',
  estadoLojaAliquotaInterna: 'Alíquota interna incorreta para o(s) estado(s) _',
  estadoOrigem: 'Estado origem',
  naoInformado: 'Não informado',
  estadoParceiroDifereEstadoLoja: 'Cliente (_) com estado (_) diferente do estado da loja (_). Tem certeza que deseja continuar?',
  estadual: 'Estadual',
  estagioCultura: 'Estagio cultura',
  estamosPreparandoTudo: 'Estamos preparando tudo, isso pode levar alguns minutos. Se preferir, você pode sair desta tela que a solicitação continuará em processamento. Assim, você poderá voltar mais tarde e apenas filtrar para encontrar esta solicitação e fazer o dowload dos arquivos',
  esteRegistro: 'Este registro',
  estiloAlternativo: 'Estilo alternativo',
  estiloMovimentacao: 'Estilo movimentação',
  estiloPadrao: 'Estilo padrão',
  estoque: 'Estoque ',
  estoqueComprado: 'Estoque comprado',
  estoqueDisponivel: 'Estoque disponível',
  estoqueDisponivelDiferenteNFe: 'Estoque disponível diferente NF-e',
  estoqueDisponivelIgualNfe: 'Estoque disponível igual NF-e',
  estoqueDisponivelMaiorNfe: 'Estoque disponível maior NF-e',
  estoqueDisponivelMenorMinimo: 'Estoque disponível abaixo do mínimo',
  estoqueDisponivelMenorNfe: 'Estoque disponível menor NF-e',
  estoqueDisponivelMenorPendente: 'Estoque disponível menor pendente',
  estoqueDisponivelNegativo: 'Estoque disponível negativo',
  estoqueDisponivelPositivo: 'Estoque disponível positivo',
  estoqueDisponivelZerado: 'Estoque disponível zerado',
  estoqueFisico: 'Estoque Fisico',
  estoqueMaximoxQuantidadeDisponivel: 'Estoque máximo - Quantidade disponível',
  estoqueMenorMinimo: 'Estoque abaixo do mínimo',
  estoqueMinimo: 'Estoque mínimo',
  estoqueMinimoAbreviacao: 'Estoque mín',
  estoqueMinimoEmTodasLojas: 'Estoque mínimo em todas as lojas',
  estoqueMinimoMaximo: 'Estoque mínimo e máximo',
  estoqueNegativo: 'Estoque negativo',
  estoqueNfeNegativo: 'Estoque NF-e negativo',
  estoqueNfePositivo: 'Estoque NF-e positivo',
  estoquePendente: 'Estoque pendente',
  estoquePendenteMaiorFisico: 'Permitir pendente maior que disponível',
  estoquePendenteNao: 'estoquePendenteNao',
  estoquePendenteSim: 'estoquePendenteSim',
  estoqueReservado: 'Estoque Reservado',
  estoqueTransferido: 'Estoque Transferido',
  estornar: 'Estornar',
  estornarBaixa: 'Estornar baixa',
  estornoFinanceiro: 'Estorno financeiro',
  excedente: 'Excedente',
  excel: 'Excel',
  exceto: 'Exceto',
  excetoPermissaoProdutoValor: 'Não (Exceto com permissão na tela de produto valor)',
  excluaParcelasParaAlterar: 'Exclua as parcelas para alterar',
  excluaProdutosParaAlterar: 'Exclua os produtos para alterar a natureza da operação',
  excluir: 'Excluir',
  exigeAberturaCaixa: 'Exige abertura de caixa',
  exigeAliquotaPISCofins: 'O produto _ está utilizando o CST PIS/Cofins _ neste caso a alíquota é obrigatória',
  exigeAprovacao: 'Exige aprovação',
  exigeAprovacaoCrediario: 'Exceto para clientes com negociação livre ou modalidade seja á vista',
  exigeAprovacaoCreditario: 'Exige aprovação do crediário',
  exigeAprovacaoInterna: 'Exige aprovação internas',
  exigeAprovacaoInternaAjuda: 'Ao habilitar, irá exigir aprovação para todas as movimentações internas (Requisição de Entrada, Requisição de Saída, Balanço e Movimentação manual)',
  exigeSenha: 'Exige senha',
  exigirCpf: 'Exigir CPF',
  existeItensComCulturaPragaSemNumeroAplicacao: 'Existe(m) iten(s) com cultura e praga, mas sem o número de aplicação',
  existeItensComESemCultura: 'Existe(m) _ iten(s) com cultura e _ iten(s) sem cultura',
  existeItensComESemPraga: 'Existe(m) _ iten(s) com praga e _ iten(s) sem praga',
  existeItensComESemVinculo: 'Existem _ itens com vínculo e _ itens sem vínculo',
  existeNumeroFaltantesParcelas: 'Existem numerações de parcelas fora de sequência',
  existemItensDeOutraLoja: 'Existem itens selecionados de outra loja, deseja continuar?',
  existemItensFaltantes: 'Existem _ itens faltantes, que não estão presentes na movimentação',
  expandir: 'Expandir',
  expandirTodos: 'Expandir todos',
  extra: 'Extra',
  extramente: 'I - Extremamente tóxico',
  entreGrupo: 'Entre grupo',
  ajudaEntreGrupo: 'Roteiro irá realizar lançamento contábil entre o grupo, ou seja em mais de uma loja ou empresa',
  f2: '[F2]',
  f9: '[F9]',
  fabricante: 'Fabricante',
  falha: 'Falha',
  falhaAoTransmitir: 'Instabilidade ao transmitir',
  familia: 'Familia',
  fator: 'Fator',
  fatorMaximoSugerido: 'Fator Máximo',
  fatorNaoPodeSerZero: 'Fator não pode ser zero',
  fatura: 'Fatura',
  faturaAberta: 'Fatura aberta',
  faturamento: 'Faturamento',
  faturamentoFinalizado: 'Faturamento finalizado',
  faturamentoNaoFinalizado: 'Faturamento não finalizado',
  favoritar: 'Favoritar',
  favorito: 'Favorito',
  favoritos: 'Favoritos',
  fax: 'Fax',
  fazerNova: 'Fazer novo(a)',
  fechado: 'Fechado',
  fechamento: 'Fechamento',
  fechamentoHojeParcelaUnica: 'Número fixo de parcelas para forma de fechamento hoje aceita apenas 1 parcela',
  fechar: 'Fechar',
  fecharCaixa: 'Fechar caixa',
  federal: 'Federal',
  feminimo: 'Feminino',
  fevereiro: 'Fevereiro',
  fevereiroAbreviacao: 'Fev.',
  fichaEmergencia: 'Ficha emergência',
  filial: 'Filial',
  filtrar: 'Filtrar',
  filtro: 'Filtro',
  final: 'final',
  finalidade: 'Finalidade',
  finalizado: 'Finalizado',
  finalizadoComSucesso: 'Finalizado com sucesso!',
  finalizar: 'Finalizar',
  finalizarLiberarEntrega: 'Finalizar e liberar entrega',
  finalizarSemModeloDocumentoFiscal: 'Tem certeza que deseja finalizar, sem modelo no documento fiscal?',
  financeira: 'Financeira',
  financeiro: 'Financeiro',
  financeiroAberto: 'Financeiro aberto',
  financeiroFinalizado: 'Financeiro finalizado faça nova busca',
  financeiroGerado: 'Financeiro gerado (Ao vendedor)',
  financeiroNaoLiberado: 'Financeiro ainda não foi liberado',
  financeiroVencido: 'Financeiro vencido',
  fiscais: 'Fiscais',
  fiscal: 'Fiscal',
  fisica: 'Fisica',
  fisico: 'Físico',
  fonteIbpt: 'Fonte IBPT',
  fora: 'Fora',
  foramUtilizadorVariosCfops: 'Foram utilizados mais de um CFOP nesta operação. \nCFOP(s): _. Deseja continuar?',
  foramUtilizadosLotesVencidos: 'Foram utilizados lotes vencidos. \nProduto(s): _. Deseja continuar?',
  forma: 'Forma de',
  formaDeCobranca: 'Forma de cobrança',
  formaEntrega: 'Forma de entrega',
  formaFechamento: 'Forma de fechamento',
  formaPagamento: 'Forma de Pagamento',
  formaPagamentoAbreviado: 'Pgto',
  formacao: 'Formação',
  formatacao: 'Formatação',
  formatarXml: 'Formatar xml',
  formulacao: 'Formulação',
  fornecedor: 'Fornecedor',
  fornecedorNaoPossuiEndereco: 'Fornecedor não possui endereço principal',
  fornecedorTransportadora: 'FORNECEDOR/TRANSPORTADORA',
  foto: 'Foto',
  fotos: 'Fotos',
  fpg: 'FPG',
  frequencia: 'Frequência',
  frete: 'Frete',
  freteTipo: 'Tipo frete',
  generoProduto: 'Gênero do produto',
  geradoResgateFinanceiro: 'Gerado resgate financeiro',
  gerar: 'Gerar',
  gerarLancamentos: 'Gerar lançamentos',
  gerarParcelas: 'Gerar parcelas',
  gerarRecibo: 'Gerar recibo',
  gerarRelatorioDesejadoEntrega: 'Ao finalizar uma entrega será gerado automaticamente o relatório desejado',
  gerarRelatorioDesejadoMovimentacao: 'Ao finalizar uma orçamento/estudo de venda será gerado automaticamente o relatório desejado',
  gerouComissao: 'Gerou comissão',
  gerouFinanceiro: 'Gerou financeiro',
  gestor: 'Gestor',
  grupo: 'Grupo',
  grupoAlteraProduto: 'Ao alterar as informações de impostos abaixo, todos os produtos vinculados a este grupo sofrerão alterações',
  grupoColaborador: 'Grupo colaborador',
  grupoContabil: 'Grupo contabil',
  grupoDeProduto: 'Grupo de produto',
  grupoEmbalagem: 'Grupo de embalagem',
  grupoPai: 'Grupo pai',
  grupoPermissoes: 'Grupo permissões',
  guiaAnexoI: 'Guia de aplicação / agrotóxicos sob venda controlada / venda aplicada (anexo I)',
  hectares: 'Hectares',
  historico: 'Histórico',
  historicoCompra: 'Histórico compra',
  hoje: 'Hoje',
  hojeAVista: 'Hoje (à vista)',
  homologacao: 'Homologação',
  hora: 'Hora',
  horaInvalida: 'Hora informada inválida',
  horario: 'Horário',
  horarioDeAcesso: 'Horário de acesso',
  horarioFinal: 'Horário final',
  horarioInicial: 'Horário inicial',
  ibge: 'IBGE',
  ibgeSeteCarateres: 'IBGE deve conter 7 carácteres',
  ibptNuncaEnviar: 'Nunca calcular',
  ibptSempreEnviar: 'Calcular para todos',
  ibptSomenteConsumidorFinal: 'Calcular somente para consumir final',
  icms: 'ICMS',
  icmsBase: 'Base ICMS',
  icmsDesonerado: 'ICMS Desonerado',
  icmsDesoneradoNfe: 'ICMS desonerado:',
  icmsDiferimento: 'ICMS Diferido',
  icmsDispensadoNfe: 'ICMS dispensado:',
  icmsEntrada: 'ICMS (entrada)',
  icmsEspecifica: 'ICMS Específica',
  icmsSaida: 'ICMS (saída)',
  icmsSt: 'ICMS ST',
  icmsStBase: 'Base ICMS ST',
  icmsStValor: 'Valor ICMS ST',
  icmsValor: 'Valor ICMS',
  id: 'Id',
  identidade: 'Identidade',
  identificacao: 'Identificação',
  ignorarInadimplentes: 'Ignorar inadimplentes',
  ignorarMesAtual: 'Ignorar mês atual',
  igual: 'Igual',
  imagem: 'Imagem',
  importarPlanoContaPadrao: 'Importar plano de contas padrão',
  importarXmlNfe: 'Importar XML NF-e',
  impostos: 'Impostos',
  impressaoAtualizada: 'Atualiza impressão',
  impressaoAtualizadaRemessa: 'Atualiza dados',
  imprimir: 'Imprimir',
  imprimirComprovante: 'Imprimir comprovante',
  improvavel: 'III - Improvável causar dano agudo',
  inadimplencia: 'inadimplência',
  inadimplente: 'inadimplente',
  inalacao: 'Inalação',
  inativar: 'Inativar',
  inativo: 'Inativo',
  incorreto: 'incorreto',
  incrementouDomingo: 'Foi incremento um dia no vencimento por ser domingo',
  independente: 'Independente',
  indicacao: 'Indicação',
  indicacoes: 'Indicações',
  indicadorSituacao: 'Indicador Situação',
  indice: 'índice',
  industrialEquiparado: 'Industrial ou equiparado a industrial',
  infoComplementar: 'Inf. complementar',
  informacaoDaTela: 'na tela _',
  informacaoAgronomoObrigatorioReceituario: 'Agrônomo é obrigatório quando existir pelo menos um item que vai emitir receitário',
  informacaoComplementar: 'Informação complementar',
  informacaoEntregaEmbalagem: 'Após tríplice lavagem das embalagens vazias devolver na',
  informacaoManejoResistencia: 'Informações sobre o manejo integrado de pragas',
  informacaoMdfeObrigatoriedadePercurso: 'Obrigatório quando existir pelo menos uma UF entre a UF de carregamento e UF de descarregamento',
  informacoesBancarias: 'Informações bancárias',
  informacoesCarga: 'Informações carga',
  informacoesFiscais: 'Informações fiscais',
  informacoesResiduosEmbalagens: 'Para embalagens em plástico rígido, vidro ou metal, após o completo esvaziamento, devem ser enxaguadas 3 vezes, com agitação, e as caldas resultantes vertidas no tanque de pulverização. Inutilize as embalagens lavadas. Fica proibido o enterro, queima ou abandono de embalagens. Armazene-as na propriedade, longe de pessoas, animais, alimentos e residências. Adevolução e obrigatória ( Lei 9974/2000) no prazo de até um ano',
  informativoRecebimentos: 'Informativo de recebimentos',
  informativoPagamentos: 'Informativo de pagamentos',
  informeAbaixoDados: 'Prencha abaixo os dados do',
  informeCidadeEstado: 'Informe o nome da cidade e o estado',
  informeNomeDoContatoParaVinculo: 'Informe o nome do contato para o tipo de contato (_)',
  informeNomeDoVeiculo: 'Informe o nome do veículo',
  informeNumeroDoTelefone: 'Informe o número de telefone',
  informeNumeroDocumento: 'Informe um número de documento',
  informeNumeroMovimentacao: 'Informe o número da movimentação',
  informeQuantidadeUmPro: 'Informe a quantidade em pelo menos um produto',
  informeUmDia: 'Informe um dia: 1 a 31',
  informeUmItem: 'Informe pelo menos 1 item',
  informeValorEntrada: 'Informe o valor da entrada',
  ingestao: 'Ingestão',
  ingredienteAtivo: 'Ingrediente ativo',
  inicial: 'Inicial',
  inscricao: 'Inscrição',
  inscricaoCpfCnpj: '_, inscrito(a) no _ sob o nº _',
  inscricaoEstadual: 'Inscrição estadual',
  inscricaoEstadualNaoPodeConterLetras: 'Inscrição estadual obrigatória para contribuinte e registro não pode conter letras.',
  inscricaoEstadualNumeroDigitos: 'Inscrição estadual deve ter de 2 a 14 digitos.',
  inscricaoMunicipal: 'Inscrição municipal',
  inscricaoProdutor: 'Inscrição produtor',
  insercao: 'Inserção',
  inseridoPor: 'Inserido por ',
  inserir: 'Inserir',
  inssRetido: 'INSS Retido',
  instituicaoFinanceira: 'Instiuição financeira',
  instrucoesBanco: 'Instruções (banco)',
  integracao: 'Integração',
  integracoes: 'Integrações',
  interna: 'Interna',
  interno: 'Interno',
  intervaloSeguranca: 'Intervalo de segurança',
  inventarioSpedMensalmente: 'Enviar mensalmente (exceto fevereiro que será enviado do ano anterior)',
  inventarioSpedInclusiveFevereiro: 'Enviar mensalmente (fevereiro enviar inventário do ano anterior e do mês atual)',
  inventarioSpedTradicional: 'Enviar somente no mês de fevereiro (tradicional)',
  ipDaRede: 'Ip da rede',
  ipi: 'IPI',
  ipiBase: 'IPI base',
  ipiValor: 'IPI valor',
  irrfRetido: 'IRRF Retido',
  isentas: 'Isentas',
  isento: 'ISENTO',
  iss: 'ISS',
  item: 'Item',
  itemAbaixoValorUnitarioMenorTabela: 'O(s) iten(s) abaixo, estão com valor unitário menor que valor de tabela. \n_',
  itemComValorXmlDiferente: 'O(s) iten(s) abaixo estão com divergência de informações se comparado(s) com o XML importado. \n\n_\nDeseja continuar?',
  itemComValoresDeImpostosIncorretos: 'Item _: _ está com valores de _ incorretos verifique (base, alíquota e valor)',
  itemMovimentacaProdutoZerado: 'O(s) iten(s) abaixo, estão com valor unitário zerado. \n_',
  itemSemMovimentacaoQuantidade: 'Item _ - _ sem quantidade',
  itemVinculado: 'Item vinculado',
  itens: 'Itens',
  itensComNegociacoesDiferentes: 'O(s) iten(s) abaixo estão com negociação diferente de _',
  janeiro: 'Janeiro',
  janeiroAbreviacao: 'Jan.',
  julho: 'Julho',
  julhoAbreviacao: 'Jul.',
  junho: 'Junho',
  junhoAbreviacao: 'Jun.',
  juridica: 'Jurídica',
  juro: 'Juro',
  juroAutomatico: 'Juro automático',
  juroDiario: 'Juro diário',
  justificativa: 'Justificativa',
  lancamento: 'Lançamento',
  lancamentoContabeis: 'Lançamentos contábeis',
  lancamentoContabil: 'Lançamento contábil',
  lancamentoContabilComDiferencaDeValores: 'Não e permitindo diferença de valores.',
  lancamentoContabilNaoEfetuadoParceiroNaoPossuiContaContabil: 'Baixa não pode ser efetuada, devido (_) não possuir conta contábil para efetuar o lançamento contábil',
  lancamentoDireto: 'Lançamento direto',
  lancamentoRepetido: 'Não pode haver lançamentos com conta e conta contrapartida repetidos',
  lancamentos: 'Lançamentos',
  latitude: 'Latitude',
  layout: 'Layout',
  lcr: 'LCR',
  leiaInformacoes: 'Leia e siga rigorosamente as orientações da bula do produto',
  liberacao: 'Liberação',
  liberadas: 'Liberadas',
  ligacaoTelefone: 'Ligação telefônica',
  limitar: 'Limitar',
  limitarEspecie: 'Limitar espécie',
  limitarPor: 'Limitar por',
  limitarRegistros: 'Limitar registros por',
  limite: 'Limite',
  limiteAnterior: 'Limite anterior',
  limiteAtual: 'Limite atual',
  limiteCredito: 'Limite de crédito',
  limiteCreditoDisponivel: 'Limite crédito disponível',
  limiteDisponivel: 'Limite disponível',
  limiteMaximo: 'Limite máximo',
  limiteMaximoMaiorPorcentagemRenda: 'Cliente _ com limite máximo maior que 50% da renda mensal. Deseja continuar?',
  limiteMaximoMenorQueLimiteALiberar: 'Cliente _ está com _ maior que o limite máximo. Deseja continuar?',
  limiteNovo: 'Limite novo',
  listarInformacoesFiscais: 'Listar informações fiscais',
  listarNovamenteInformacoesFiscais: 'Tem certeza que deseja listar novamente as informações fiscais?',
  livre: 'Livre',
  lmcCre: 'LMC CRE',
  local: 'Local',
  localAplicacao: 'Local de aplicação',
  localDeAcesso: 'Local de acesso',
  localEntregaEmbalagens: 'Local para entrega das embalagens',
  localPagamento: 'Local de pagamento',
  logradouro: 'Logradouro',
  logradouroObrigatorio: 'Logradouro é obrigatório',
  loginTecnospeed: 'Suporte tecnospeed - Utilize o e-mail suporte@agrownegocios.com.br e senha 2024AGR@ [Ao clicar a senha será copiada, basta colar no navegador]',
  loginManagerSas: 'Consulta documentos fiscais - [A cada clique será copiado uma informação para realizar o login]',
  loja: 'Loja',
  lojaAbreviacao: 'Loj',
  lojaBoleto: 'Loja referência boleto',
  lojaBoletoMensagem: 'Ao emitir um boleto com está conta as informações do emitente pegará da loja referência',
  lojaCadastro: 'Loja onde foi realizado o cadastro',
  lojaDestino: 'Loja destino',
  lojaDestinoIgual: 'A loja de entrada dever ser diferente da loja de saída',
  lojaEntrada: 'Loja entrada',
  lojaEntradaNaoPossuiParceiro: 'Loja de entrada não possui parceiro vinculado.',
  lojaNaoPossuiEmailCadastrado: 'Loja não possui e-mail cadastrado',
  lojaObrigatoria: 'Campo loja é obrigatório',
  lojaOrigem: 'Loja cadastro',
  lojaPagamento: 'Loja pagamento',
  lojaPossuiDataInicioSistema: 'Loja _ não contém data inicio de utilização de sistema. Preencha para vincular transferência.',
  lojaSaida: 'Loja saída',
  lojaValor: 'Loja valor',
  lojaVinculada: 'Loja vinculada',
  lojaVinculadaMensagem: 'Ao vincular a loja à conta, ela poderá ser utilizada exclusivamente para os assuntos financeiros da loja',
  longitude: 'Longitude',
  lote: 'Lote',
  luc: 'Luc',
  lucro: 'Lucro',
  lucroAno: 'Lucro ano',
  lucroMedio: 'Lucro Médio',
  lucroMenorIgualZero: 'Lucro menor ou igual a zero',
  lucroMenorIgual: 'Lucro menor ou igual',
  lucroMenorOue: 'Lucro menor que',
  lucroPresumido: 'Lucro presumido',
  lucroReal: 'Lucro real',
  lucroTotalDia: 'Lucro total (Dia)',
  lucroTotalMes: 'Lucro total (Mês)',
  maio: 'Maio',
  maioAbreviacao: 'Mai.',
  maior: 'Maior',
  maiorAtraso: 'Maior atraso',
  maiorIgual: 'Maior igual',
  maiorQue: 'Maior que',
  mais: '(+)',
  maisIncremento: '(+) Incremento',
  maisInformacoes: 'Mais informações',
  maisMulta: '(+) Multa',
  manual: 'Manual',
  marcaFabricante: 'Marca/Fabricante',
  marcado: 'Marcado',
  marcarTodos: 'Marcar todos',
  marco: 'Março',
  marcoAbreviacao: 'Mar.',
  marg: 'Marg',
  margGerencialAte: 'Marg. gerencial (Até)',
  margGerencialDe: 'Marg. gerencial (De)',
  margem: 'Margem',
  margemDiferenciada: 'Cálculo especial (operação: custo / margem)',
  margemGerencial: '% Margem gerencial',
  margemGerencialAte: 'Margem gerencial (Até)',
  margemGerencialDe: 'Margem gerencial (De)',
  margemGerencialManterProduto: 'Margem para manter o produto, cobrir os gastos fixos do negócio',
  margemLucro: 'Margem de lucro',
  margemLucroAte: 'Margem lucro (Até)',
  margemLucroAtual: 'Percentual entre o preço de venda e',
  margemLucroCalculo: '(preço de venda - _) / _',
  margemLucroDe: 'Margem lucro (De)',
  margemLucroDifReal: 'Margem de lucro diferente da margem real',
  margemReal: 'Margem real',
  margemRealAte: 'Margem real (Até)',
  margemRealDe: 'Margem real (De)',
  margemTradicional: 'Cálculo tradicional (operação: custo * margem)',
  mascaraContaContabil: 'Máscara plano de conta',
  mascaraFinal: 'Máscara final',
  mascaraInicial: 'Máscara inicial',
  masculino: 'Masculino',
  matriz: 'Matriz',
  max3caracteres: 'Máx. 3 caracteres',
  maximaReceita: 'Máxima receita',
  maximo3Caracteres: 'Máximo 3 caracteres',
  maximo50Caracteres: 'Máximo 50 caracteres',
  maximo: 'Máximo',
  maximoUmDigito: 'Máx. 1 dígito',
  mdfe: 'MDF-e',
  mdfeDocumentoFiscalVazio: 'Nenhum documento fiscal informado',
  mdfeMensagemRetornoErro: 'Informações do MDF-e \n Situação: _',
  mdfeMensagemRetornoSucesso: 'Informações do MDF-e \n Situação: _ \n Número _ \n Chave: _',
  mediaAtraso: 'Média atraso',
  mediaMensalVendaAnoAanterior: 'Média mensal venda ano anterior',
  mediaMensalVendaAnoAanteriorAbreviacao: 'MV.ANO',
  mediaMensalVendaTrimestreAnterior: 'Média mensal trimestre anterior',
  mediaMensalVendaTrimestreAnteriorAbreviacao: 'MV.TRM-A',
  mediaMensalVendaTrimestrePosterior: 'Média mensal trimestre posterior',
  mediaMensalVendaTrimestrePosteriorAbreviacao: 'MV.TRM-P',
  mediaMensalVendasAnoAnterior: 'Média mensal de vendas no ano anterior',
  mediaMensalVendasTrimestreAnterior: ' Média mensal de vendas trimestre anterior (ano passado)',
  mediaMensalVendasTrimestrePosterior: 'Média mensal de vendas trimestre posterior (ano passado)',
  mediaPonderada: 'Média Ponderada',
  menor: 'Menor',
  menorIgual: 'Menor igual',
  menorQue: 'Menor que',
  menos: '(-)',
  mensagemPadraoParaSuporteCliente: '_, aqui é o _ da empresa _, estou usando o ERP aGrow web na versão _',
  mensagemAguardarEspera: 'Aparentemente consulta com muitos registros',
  mensagemAguardarEsperaDesistir: 'Deseja desistir e informar mais critérios?',
  mensagemDuplicataMercantil: 'Reconheço(emos) com exatidão desta duplicata de venda mercantil, na importância acima que pagarei(emos) a _, ou a sua ordem na praça e vencimentos indicados. Recebemos da _ os produtos, em perfeito estado, constantes na(s) fatura(s) _.',
  mensagemRelatorio1127: 'O percentual da comissão _% é referente ao valor da comissão _ sobre o valor da parcela negociada inicialmente de _',
  mensal: 'Mensal',
  menu: 'Menu',
  menuRapido: 'Menu rápido',
  mes: 'Mês',
  mesAno: 'Mês/Ano',
  mesFinalMenor: 'O mês final não pode ser menor',
  mesObrigatorio: 'O mês deve ser preenchido',
  metodoApropriacaoDireta: 'Método de apropriação direta',
  metodoRateioProporcional: 'Método de Rateio Proporcional (Receita Bruta)',
  minhaConta: 'Minha conta',
  minimo: 'Mínimo',
  minimoCaracteresSenhaOperacional: 'Mínimo 6 caracteres',
  minimoDeCaracteres: 'Ao menos 8 caracteres e no máximo 30 caracteres',
  minimoDoisMaximoCincoCaracteres: 'Mín. 2 e máx. 5 caracteres',
  modalidade: 'Modalidade',
  modalidadeAplicacao: 'Modalidade aplicação',
  modalidadeAplicacaoAbreviacao: 'Modalidade aplic.',
  modelo: 'Modelo',
  moderadamente: 'II - Moderadamente tóxico',
  modificacaoEmRelatorioNaoIdentificada: 'Não foi identificado nenhuma modificação em relatórios',
  modoClaro: 'Modo claro',
  modoEscuro: 'Modo escuro',
  modulo: 'Módulo',
  moduloPai: 'Módulo pai',
  mostraMargemLucro: 'Mostrar margem de lucro',
  mostraAlertaProduto: 'Apresenta a observação no momento da escolha deste item na venda',
  mostrarAlerta: 'Mostrar alerta',
  mostrarExpandir: 'Mostrar / Expandir',
  mostrarHierarquiaDaConta: 'Mostrar hierarquia da conta',
  mostrarInformacoesEstoque: 'Para mostrar informações de estoque',
  mostrarMovimentacaoDeOutroUsuario: 'Permitir visualizar movimentação de outro responsável',
  mostrarNoMapa: 'Mostrar no mapa',
  mostrarTodos: 'Mostrar todos',
  motivo: 'Motivo',
  motivoIsencao: 'Motivo isenção',
  motivoOcorrencia: 'Motivo ocorrência',
  motorista: 'Motorista',
  movimentacao: 'Movimentação',
  movimentacaoCancelada: 'Movimentação cancelada com sucesso',
  movimentacaoClienteEspecial: 'Movimentação cliente especial',
  movimentacaoFinalizada: 'Movimentação já finalizada',
  movimentacaoFinanceiroNecessitaParcela: 'Movimentação gera financeiro, informe pelo menos uma parcela',
  movimentacaoMovimentacaoPendente: 'Movimentação X Movimentação pendente',
  movimentacaoNumeroAbreviacao: 'Nº Mov',
  movimentacaoPor: 'Movimentação por',
  movimentacaoProdutoEstoqueHistorico: 'Movimentação produto X Estoque histórico',
  movimentacaoRecusada: 'Movimentação não aprovada',
  movimentacaoSemItens: 'Movimentação não contém itens.',
  movimentacaoValorNegativo: 'Não é permitido valor  total negativo',
  movimentacaoVinculadaADevolucao: 'Não existe nota fiscal de venda vinculada a devolução selecionada, informe a chave vinculada manualmente',
  movimentacoesDistorcidas: 'Movimentações distorcidas',
  movimentacoesGeraramFinanceiro: 'Movimentações que geraram financeiro',
  movimentacoesUltrapassaramLimiteMaximo: 'Movimentações que ultrapassaram o limite máximo',
  mudarPara: 'Mudar para',
  multa: 'Multa',
  multiplicador: 'Multiplicador',
  multiplicadorComercializacao: 'Fração na qual um produto pode ser comercializado',
  multiplos: 'Múltiplos',
  mvAno: 'Mv. ano',
  mvTrmA: 'Mv. trm. a',
  mvTrmP: 'Mv. trm. p',
  mva: 'MVA',
  nAplicacoes: 'Nº aplicações',
  nDocumento: 'N° Documento',
  nFinanceiro: 'N° financeiro',
  nMovimentacao: 'Nº movimentação',
  nReceituario: 'Nº receituário',
  nNotasSeremCanceladas: 'Nº de notas a serem canceladas na receita ou a desistir do cancelamento',
  nReduzido: 'Nº reduzido',
  nao: 'Não',
  naoAparece: 'Não aparece',
  naoAutorizada: 'Não autorizada',
  naoAutorizado: 'Não autorizado',
  naoClassificado: 'IV - Não classificado',
  naoCobrar: 'Não cobrar',
  naoComissionado: 'Não comissionado',
  naoContribuinte: 'NÃO CONTRIBUINTE',
  naoDeveConterUnidadeAlternativaFatorZero: 'Não pode conter unidade alternativa com fator igual a 0',
  naoEncontradoBaixa: 'Não foi encontrada nenhuma _.',
  naoEnviada: 'Não enviada',
  naoFinalizado: 'Não finalizado',
  naoFiscal: 'Não fiscal',
  naoFoiPossivelDesistirCancelamento: 'Não foi possível desistir do cancelamento, porque a receita atualizou a situação da NF-e como CANCELADA',
  naoFoiPossivelAtualizarMovimentacao: 'Não foi possível atualizar movimentação com as informações da receita. Tente novamente',
  naoFoiPossivelCancelar: 'Houve uma instabilidade no SEFAZ ao tentar realizar o cancelamento. Tente novamente',
  naoFoiPossivelConectarServidor: 'Verificando a comunicação com o servidor. Tente novamente',
  naoFoiPossivelEfetuarDownload: 'Houve uma instabilidade ao realizar o download. Tente novamente',
  naoFoiPossivelEnviarCartaCorrecao: 'Não foi possível enviar carta de correção. Tente novamente',
  naoFoiPossivelReenviarEmail: 'Houve uma instabilidade ao reenviar o e-mail. Tente novamente',
  naoGerouParcelas: 'Não foi gerado parcelas',
  naoLiberadas: 'Não Liberadas',
  naoObrigar: 'Não obrigar',
  naoPermiteDiferencaValores: 'Não permitir diferença de valores',
  naoPermiteInativar: 'Não é permitido inativar registro com filho',
  naoPermitidoBaixaParcial: 'Não é permitido efetuar baixa parcial',
  naoPermitidoBaixaReceitaDespesaComJurosOuDesconto: 'Não é permitido adicionar juro ou desconto em baixa de receita e despesa juntas',
  naoPermitidoBaixarFinanceiroDeParceirosDiferentes: 'Não é permitido baixar financeiros de clientes diferentes',
  naoPermitidoBaixarFinanceiroDeFornecedoresDiferentes: 'Não é permitido baixar financeiros de fornecedores diferentes',
  naoPermitidoDiferencaDebidoCredito: 'Não é permitido diferença entre débito (_) e crédito (_)',
  naoPermitidoUsarPlanoContasPai: 'Não e permitido utilizar plano de contas pai (_)',
  naoPermitidoVencimentoIgual: 'Não é permitido mais de uma parcela com mesmo vencimento e mesmo tipo de título',
  naoPossuiBaixa: 'Não possui nenhuma Baixa',
  naoPossuiObservacaoInterna: 'Não possui observação interna',
  naoPossuiPermissaoParaExcluir: 'Não possui permissão para excluir registro',
  naoRegistrarMulta: 'Não registra a multa',
  naoUsarContaEspecifica: 'Não usar conta específica',
  naoUsaBoleto: 'Não usa boleto',
  natureza: 'Natureza',
  naturezaOperacao: 'Natureza da operação',
  naturezaOperacaoBuscandoValoresParaOperacoes: '_: _ utilizada em operação de _ utilizara _ como valor unitário.\nDeseja continuar?',
  naturezaOperacaoPadraoMobilidade: 'Para utilizar a natureza da operação como padrão na mobilidade o campo usado como deve ser do tipo venda',
  naturezaOperacaoPadraoMobilidadePergunta: 'Há outra natureza da operação padrão para a mobilidade. Tem certeza de que deseja alterá-la?',
  naturezaPadraoMobilidade: 'Natureza padrão mobilidade',
  naturezaPadraoMobilidadeAjuda: 'Natureza da operação padrão para mobilidade ao utilizar Pedido e Nota Fiscal',
  naturezaPai: 'Natureza pai',
  naturezaReceita: 'Natureza receita',
  naturezaReceitaM105M505: 'Natureza receita (M105 e M505)',
  naturezaReceitaM410M810: 'Natureza receita (M410 e M810)',
  navegadorAjuda: '[navegador] Ajuda',
  navegadorAlternaAba: '[navegador] Alternar abas',
  navegadorAtualizar: '[navegador] Atualizar',
  navegadorConfiguracoes: '[navegador] Configurações',
  navegadorLocalizar: '[navegador] Localizar',
  navegadorTabulacao: '[navegador] Tabulação',
  navegadorTelaCheia: '[navegador] Tela cheia',
  ncm: 'NCM',
  ncmCestObrigatorioParaProduto: 'NCM e CEST são obrigatórios para o CST _ usado no item _',
  ncmDescricao: 'Nomenclatura comum do Mercosul. Pode ser preenchido pela tela de _ ou _',
  ncmObrigatorioProduto: 'NCM é obrigatório para o CST _ usado no item _',
  ncm_cest: 'NCM/CEST',
  neg: 'NEG',
  negociacao: 'Negociação',
  negociacaoAprazoCertezaVencimento: 'Negociação a prazo, tem certeza que deseja colocar o vencimento para hoje?',
  negociacaoBloqueada: 'Negociação bloqueada para o cliente atual',
  negociacaoNaoConfiguradaGerarBoleto: 'Negociação não configurada para gerar boleto',
  negociacaoNaoPermitidaParaParceiro: 'Negociação (_) não permitida para o cliente atual',
  negociacaoObrigatorio: 'Negociação é obrigatório',
  negociacaoPadrao: 'Negociação padrão',
  negociacaoPrazoDias: 'Negociação prazo (dias)',
  negociacaoPrazoDiasAbreviado: 'Neg. prz. (dias)',
  negociacaoSelecionadaLimiteParcelamento: '_: _ só permite parcelar em até _x',
  negociada: 'Negociada',
  negociado: 'Negociado',
  nenhum: 'Nenhum',
  nenhumBoletoFoiSelecionado: 'Nenhum boleto selecionado',
  nenhumEstoqueAlterado: 'Nenhum estoque foi alterada',
  nenhumFinanceiroAlterado: 'Nenhum financeiro foi alterado ou adicionado',
  nenhumMenuAlterado: 'Nenhum menu foi alterado',
  nenhumProdutoAlterada: 'Nenhuma produto foi alterado',
  nenhumRegistroAlterado: 'Nenhum registro alterado',
  nenhumRegistroEncontrado: 'Nenhum registro encontrado de acordo com o filtro',
  nenhuma: 'Nenhuma',
  nenhumaAbr: 'Nenhum(a)',
  nenhumaNomenclaturaAlterada: 'Nenhuma nomenclatura foi alterada',
  nfce: 'NFC-e',
  nfe: 'NF-e',
  nfeNaoTransmitida: 'Notas fiscais não transmitidas',
  nfeTotal: 'NF-e total',
  nfeTotalMes: 'NF-e total mês',
  nfeTransmitida: 'NF-e transmitida com sucesso',
  nivel: 'Nível',
  nome: 'Nome',
  nomeCientifico: 'Nome científico',
  nomeCompleto: 'Nome completo',
  nomeContatoObrigatorio: 'Nome do contato é obrigatório',
  nomeEmbarque: 'Nome embarque',
  nomeFantasia: 'Nome fantasia',
  nomeTecnico: 'Nome técnico',
  nomeUmSobrenome: 'Informe o nome e apenas um sobrenome | máximo: 15 caracteres',
  nomeUsuarioUmSobrenome: 'Nome do usuário pode conter somente um sobrenome',
  nomeVeiculoObrigatorio: 'Nome do veículo é obrigatório',
  nomenclatura: 'Nomenclatura',
  nominal: 'Nominal',
  normal: 'Normal',
  notaACancelar: 'Nota a cancelar ou desistir do cancelamento',
  notaDenegada: 'Nota denegada',
  notaFiscal: 'Nota fiscal',
  notaPromissoria: 'Nota promissória',
  notaReferenciada: 'Nota referenciada',
  notaTransmitidaBloquearCancelar: 'Nota já transmitida, utilize a tela de NF-e para cancelamento',
  notasACancelar: 'Notas a cancelar ou desistir do cancelamento',
  notasJaEnviadas: 'A(s) nota(s) do(s) parceiro(s): _ Já foram enviadas, deseja filtrar novamente?',
  novaBaixa: 'Nova baixa',
  novembro: 'Novembro',
  novembroAbreviacao: 'Nov.',
  numeracao: 'Numeração',
  numeracaoBoleto: 'Númeração boleto',
  numeracaoConta: 'Numeração conta',
  numeracaoNaoPodeTerminarEmPonto: 'Numeração não pode terminar em ponto',
  numeracaoPai: 'Numeração pai',
  numero: 'Número',
  numeroAbreviacao: 'Núm',
  numeroAbreviado: 'Nº',
  numeroAplicacao: 'Número aplicação',
  numeroAprovacaoAbreviacao: 'Nº Aprovação',
  numeroAutorizacao: 'Nº Autorização',
  numeroBanco: 'Número banco',
  numeroBoletoAbreviacao: 'Nª boleto',
  numeroCasasDecimais: 'Nº casas decimais',
  numeroCasasDecimaisEntrada: 'Nº casas entrada',
  numeroCest: 'Nº CEST',
  numeroCfopAbreviacao: 'Nº CFOP',
  numeroChequeAbreviacao: 'Nº Cheque',
  numeroClienteAbreviacao: 'Nº Cliente',
  numeroClienteFornecedor: 'Nº Cliente/Fornecedor',
  numeroColaboradorAbreviacao: 'Nº Colaborador',
  numeroConta: 'Número conta',
  numeroContaBancariaAbreviacao: 'Nº Conta bancária',
  numeroCst: 'Nº CST',
  numeroDocumento: 'Nº documento',
  numeroDocumentoAbreviacaoMarcacao: '<<Nº DOCUMENTO>>',
  numeroEntrega: 'Nº Entrega',
  numeroExatoParcela: 'Número exato de parcelas. Não permite alteração na movimentação',
  numeroFinanceiroAbreviacao: 'Nº Financeiro',
  numeroFiscal: 'Número fiscal',
  numeroFiscalAbreviacao: 'Núm. Fiscal',
  numeroFixoParcela: 'Número fixo',
  numeroFornecedorAbreviacao: 'Nº Fornecedor',
  numeroMaximo: 'Número máximo',
  numeroMaximoParcela: 'Número máximo de parcelas + entrada',
  numeroMaximoParcelaSomenteInteiro: 'Número máximo ou fixo da parcela deve ser um número inteiro',
  numeroMovimentacaoAbreviacao: 'Nº Movimentação',
  numeroNcm: 'Nº NCM',
  numeroNfe: 'Número NF-e',
  numeroObrigatorio: 'Número é obrigatório',
  numeroOnu: 'Número ONU',
  numeroParceiroAbreviacao: 'Nº Parceiro',
  numeroProdutoAbreviacao: 'Nº Produto',
  numeroReceitaDeMaiorReceitaAte: 'Número receita (de) maior que receita (até)',
  numeroReceitaForaFaixa: 'Número receita atual fora da faixa (de - até)',
  numeroRisco: 'Número risco',
  numeroTelefoneObrigatorio: 'Número telefone é obrigatório',
  numeroTransportadoraAbreviacao: 'Nº Transportadora',
  numeroUnico: 'Número único',
  oQueVoceDeseja: 'O que você deseja?',
  obrigatorioChaveReferenciadaParaDevolucaoPropria: 'Para devolução com emissão própria o campo chave referenciada é obrigatório',
  obrigar: 'Obrigar',
  obs: 'OBS',
  obsEntrega: 'Obs entrega',
  observacao: 'Observação',
  observacaoApresentaInterna: 'Será apresentada somente no cadastro',
  observacaoApresentaVenda: 'Está observação será apresentada no momento da venda',
  observacaoInterna: 'Obs. (interna)',
  observacaoProduto: 'Informação adicional, não é enviada para a NF-e',
  observacaoVenda: 'Obs. (venda)',
  ocorrenciaAjuda: 'Ao cadastrar uma ocorrência e mantar ela ativa, será permitido apenas venda a vista, no caso de venda a prazo, será exigido aprovação.',
  ocorrencia: 'Ocorrência',
  ocorrenciaMovimentacoes: 'Ocorrência das movimentações',
  ocultar: 'Ocultar',
  ocultarMaisInformacoes: 'Ocultar (Mais informações)',
  ocultarHistorico: 'Ocultar histórico',
  ocultarResumir: 'Ocultar / Resumir',
  ocultarResumo: 'Ocultar resumo',
  ok: 'Ok',
  olhosPele: 'Olhos/Pele',
  ondeVoceEsta: 'Onde você está',
  op: 'Op',
  opcoes: 'Opções',
  opcoesDePesquisa: 'Opções de pesquisa',
  operacao: 'Operação',
  operacaoMarcacao: '<<OPERAÇÃO>>',
  operacaoMovimentacao: 'Operação X Movimentação',
  operacoes: 'Operações',
  orcamentoValidoAte: 'Orçamento válido até',
  ordem: 'Ordem',
  ordemPagamento: 'Gerar ordem de pagamento',
  ordemPagamentoGerada: 'Ordem de pagamento gerada. Aguarde aprovação do gestor',
  ordenar: 'Ordenar',
  ordenarPorProduto: 'Para ordenar por produto',
  orientacao: 'Orientação',
  orientacaoDescricao: 'Leia atentamente o rótulo e a bula do produto. Equipamento de proteção individual obrigatório.',
  orientacaoInalacao: 'Remova a vítima para ambiente arejado. Procure um atendimento médico, levando a embalagem, rótulo, bula ou receituário agronômico do produto.',
  orientacaoInformacaoManejoResistencia: 'Sempre que houver disponibilidade de informações sobre MIP, provenientes dapesquisa pública ou privada, recomenda-se que estes programas sejam implementados.',
  orientacaoIngestao: 'Nunca dê nada via oral a uma pessoa inconsciente. É necessário considerar o volume a concentração da solução ingerida e o tempo é necessário transcorrido desde a ingestão. Atentar para nível de consciência e proteger vias aéreas do risco de aspiração. Verifique na bula do produto se énecessário provocar vômito ou não e procure logo um médico, levando a embalagem, rótulo, bula e receituário agronômico do produto.',
  orientacaoOlhosPele: 'Remova a vítima para ambiente arejado. Lave com água em abundância e procure logo um médico, levando a embalagem, rótulo, bula e receituário agronômico do produto.',
  orientacaoPrecaucaoGerais: 'Produto para uso exclusivamente agrícola. Caso o produto seja utilizado com outros defensivos agrícolas, o aplicador deve observar todos os itens deprecações no manuseio do defensivo agrícola. Não coma, não beba e não fume durante o manuseio e aplicação do produto. Não manuseie ou tratamento hidro aplique  o  produto  sem  os  EPI’s  recomendados.  Os  EPI’s  recomendados  devem  ser  vestidos  na  seguinte  ordem:  macacão  com  tratamento hidro-repelente, com mangas compridas passando por cima do punho das luvas e as pernas das calças por cima das botas, botas impermeáveis decano alto (PVC), avental impermeável, máscara com filtro mecânico classe P2, óculos de segurança com proteção lateral, touca árabe e luvas de nitrila. Não utilize EPI danificados. Não utilize equipamentos com vazamentos ou defeitos. Não desentupa bicos, orifícios e válvulas com a boca. Nãotransporte o produto juntamente com alimentos, medicamentos, rações, animais e pessoas. Mantenha o produto afastado de crianças e animais domésticos. Observe as disposições constantes na legislação estadual e municipal concernentes as atividades aero agrícolas e/ou terrestres.',
  orientacaoPrecaucaoGeraisAplicacao: 'Evite o máximo possível, o contato com a área tratada. Não fume, beba ou coma durante a aplicação do produto. Não aplique o produto na presença de ventos fortes e nas horas mais quentes do dia. Verifique a direção do vento e aplique de modo a não entrar na névoa do produto. Use máscara cobrindo o nariz e a boca. Aplique o produto somente nas doses recomendadas e observe o intervalo de segurança',
  orientacaoPrecaucaoGeraisAposAplicacao: 'Sinalizar a área tratada com os dizeres: ""PROIBIDA A ENTRADA, ÁREA TRATADA"" e manter os avisos até o final do período de reentrada. Mantenha crianças, animais domésticos e pessoas desprotegidas afastadas das áreas tratadas até o final do período de reentrada. Caso necessite entrar na área tratada com o produto antes do término do intervalo de reentrada, utilize os EPI’s recomendados para ouso durante a aplicação. Mantenha o restante do produto adequadamente fechado, em sua embalagem original em local trancado, longe do alcance de crianças e animais. Não reutilize as embalagens vazias. Tome banho imediatamente após a aplicação do produto. Troque e lave as suas roupas de proteção separado das demais roupas da família. Ao lavar as roupas utilizar luvas e avental impermeável. Faça a manutenção e lavagem dos equipamentos de proteção após cada aplicação do produto. Fique atento ao tempo de uso dos filtros, seguindo corretamente as especificações do fabricante. Não lave as embalagens ou equipamento aplicador em lagoas, fontes, rios e demais corpos d água. Não reutilizar a embalagem vazia. Evite a contaminação - Preserve a Natureza".',
  orientacaoPrecaucaoGeraisCalda: 'Caso ocorra contato acidental da pessoa com o produto, siga as orientações descritas em primeirossocorros, procure rapidamente um serviço médico de emergência, contate as autoridades locais competentes e a empresa fabricante do produto. Ao abrir a embalagem, faça-o de modo a evitar respingos. Manuseie o produto em local arejado.',
  orientacaoRecomendacaoManejoResistencia: 'Quando agrotóxicos com o mesmo modo de ação são utilizados repetidamente ao longo do tempo para controle fitossanitário, biótipos resistentes de pragas e/ou plantas daninhas, de ocorrência natural, podem sobreviver ao tratamento adequado, propagar e passar a dominar a área. Recomenda-se práticas culturais de manejo integrado distintas nas gerações subsequentes.',
  orientacaoResiduosEmbalagemProduto: 'O produtor deve recorrer sempre a assistência técnica de profissionais legalmente habilitados para a aplicação de agrotóxicos e afins. Os produtores,usuários, aplicadores, meeiros e arrendatários que não se utilizarem da assistência técnica, responsabilizam-se pelos danos a que derem causa,solidariamente.',
  orientacaoResiduosEmbalagens: 'Formulações  líquidas  ou  pó  solúveis  em  água  para  embalagens  plásticas  vidro  ou  metal,  após  o  completo esvaziamento as embalagens devem ser enxaguadas 3 (três) vezes, com agitação e as caldas resultantes vertidas no tanque do pulverizador. Formulações sem diluição em água devem ser totalmente esgotadas no tanque do pulverizador e depois inutilizadas. Não reutilize as embalagens vazias. Conforme a Lei 9974/2000, as embalagens de agrotóxicos devem ser obrigatoriamente entregues onde foram adquiridas ou à sua orientação,no prazo máximo de um ano.',
  origem: 'Origem',
  origemComissao: 'Origem comissão',
  ou: 'Ou',
  outras: 'Outras',
  outrasRetencoes: 'Outras retenções',
  outroMeiodePagamento: 'Outro meio de pagamento',
  outros: 'Outros',
  outubro: 'Outubro',
  outubroAbreviacao: 'Out.',
  padrao: 'Padrão',
  padraoContaCorrente: 'Padrão para conta corrente',
  pagamento: 'Pagamento',
  pagamentoNaLoja: 'Pagamento na loja',
  pagamentoNaLojaAbreviado: 'Pgt na loja',
  pagar: 'Pagar',
  pagina: 'Página',
  pago: 'Pago',
  pai: 'Pai',
  para: 'Para',
  paraAdicionar: 'Para adicionar',
  paraMaisOpcoesFiltro: 'Para mais opções de filtro',
  paraMenosOpcoesFiltro: 'Para menos opções de filtro',
  paraRealizarDownload: 'Para realizar o download',
  paraUsoInstituicao: 'Uso da inst.',
  paraVisualizarHistorico: 'Para visualizar o histórico',
  paraVisualizarResumo: 'Para visualizar o resumo das movimentações',
  parceiro: 'Parceiro',
  parceiroComCreditoDisponivel: 'Cliente com _ de crédito disponível',
  parceiroComCreditoDisponivelEFuturo: 'Cliente com _ de crédito disponível e _ de crédito total',
  parceiroDestinoNaoPossuiEnderecoCadastrado: 'Propriedade do cliente não possui endereço cadastrado ou incompleto',
  parceiroEstaComCepIncorreto: 'Destinatário: _ está com informação de CEP incorreto. Verifique e tente novamente.',
  parceiroInscricaoEstadualIncorreta: 'Destinatário: _ está com informação de inscrição estadual incorreto. Verifique e tente novamente.\n',
  parceiroMarcacao: '<<PARCEIRO>>',
  parceiroNaoPossuiCnpjCpf: 'Movimentação não pode ser salva, devido (_) não possuir _ cadastrado.',
  parceiroNaoPossuiContaContabil: 'Movimentação não pode ser salva, devido (_) não possuir conta contábil para efetuar o lançamento contábil',
  parceiroNaoPossuiCpfCnpjCadastrado: 'Cliente não possui CPF ou CNPJ cadastrado',
  parceiroNaoPossuiEmailCadastrado: 'Cliente não possui e-mail cadastrado',
  parceiroNaoPossuiEndereco: 'Cliente não possui endereço, destino nem endereço principal',
  parceiroNaoPossuiEnderecoCadastrado: 'Cliente não possui endereço cadastrado ou incompleto',
  parceiroNaoPossuiMovimentacao: 'Cliente não possui movimentação',
  parceiroSpedFiscal: 'Instiuição financeira que será informada no registro 1601 do Sped Fiscal',
  parceiroTelefoneObrigatorioParaEsteBanco: 'Para emitir boletos neste banco, é obrigatório informar o telefone PRINCIPAL no cadastro do cliente',
  parcela: 'Parcela',
  parcelaAte: 'Parcela (Até)',
  parcelaDataRepetida: 'Parcelas com datas repetidas(!)',
  parcelaDe: 'Parcela (De)',
  parcelaDiferenteMovimentacao: 'A soma dos valores das parcelas é diferente do valor da movimentação _ ',
  parcelaDiferenteMovimentacaoValorBloqueado: 'Por favor, recalcule as parcelas para atualizar os valores conforme as informações da movimentação',
  parcelaInicalIncorreta: 'Parcela inicial incorreta.',
  parcelaMarcacao: '<<PARCELA>>',
  parcelaValorMinimo: 'Valor mínimo de cada parcela deve ser maior que _',
  parcelamento: 'Parcelamento',
  parcelamentoNaoCondizComTabelaPreco: 'Parcelamento não condiz com negociação da tabela de preço. Até _ deve-se pagar _',
  parcelas: 'Parcela(s)',
  parcelasAjuda: 'O número de parcelas irá definir quantas conciliações serão geradas',
  patrimonialDRe: 'IMPORTA O PRESENTE _ SOMANDO NO _ _ (_) DE ACORDO COM A DOCUMENTAÇÃO QUE NOS FOI APRESENTADA.',
  patrimonialDepreciacaoInsert: 'Não é permitido inserir mais de uma deprecição ao mesmo tempo',
  patrimonialDepreciacaoObrigatorios: 'Verifique os campos obrigatórios da depreciação',
  patrimonioLiquido: 'Patrimônio líquido',
  pdf: 'PDF',
  pdfCarneDuplo: 'PDF carnê duplo (paisagem)',
  pdfCarneTriplo: 'PDF carnê triplo (retrato)',
  pdfDuplo: 'PDF  dupla (retrato)',
  pdfNormal: 'PDF normal',
  pedido: 'Pedido',
  pedidos: 'Pedidos',
  penalidade: 'Penalidade',
  penalidadeDiaPrazoLucro: 'Prazo referência',
  penalidadeDiaPrazoReferenciaLucro: 'Prazo referência para penalizar na margem de lucro quando não tem parcela lançada',
  penalizarPartirDe: 'Penalizar a partir de',
  pendente: 'Pendente',
  pendenteColcheteado: '[P]endente',
  pendenteMaiorFisico: 'Pendente maior que disponível',
  percentual: 'Percentual',
  percentualAteDataInformada: 'Percentual até a data informada',
  percentualComissao: 'Percentual comissão',
  percentualComissaoMaiorZero: 'Percentual comissão deve ser maior que zero',
  percentualDeLucro: 'Percentual de lucro',
  percentualDepreciacao: 'Percentual depreciação anual',
  percentualDesc: '% Desc',
  percentualDesconto: 'Percentual desconto',
  percentualDeveSerMenorQue100PorCento: 'Percentual deve ser menor que 100%',
  percentualDia: 'Percentual (Dia)',
  percentualDoLancamentoMaiorQueZero: 'Percentual do lançamento deve ser maior que zero',
  percentualDoRoteiroMaiorQueZero: 'Percentual do roteiro deve ser maior que zero',
  percentualLucroDia: 'Percentual lucro (Dia)',
  percentualLucroMes: 'Percentual lucro (Mês)',
  percentualMes: 'Percentual (Mês)',
  percentualMulta: 'Percentual de multa',
  percentualNaoEIgualASomaDosPercentuais: 'Percentual do roteiro contábil não é igual a soma dos percentuais dos roteiros',
  percentualSimbolo: '%',
  percentualSobreDiaCorrido: 'Percentual sobre o valor nominal dia corrido',
  percentualSobreDiaUtil: 'Percentual sobre o valor nominal dia útil',
  percurso: 'Percurso',
  perda: 'Perda',
  perfil: 'Perfil',
  perfilA: 'Perfil A',
  perfilB: 'Perfil B',
  perfilArquivo: 'Perfil arquivo',
  periodo: 'Período',
  periodoIntervaloAno: 'O intervalo entre as datas deve ser do mesmo ano',
  periodoIntervaloMes: 'O intervalo entre as datas deve ser do máximo 1 mês',
  periodoNaoRepetir: 'O(s) período(s) não podem repetir',
  permissao: 'Permissão',
  permissoes: 'Permissões',
  permiteAbrirMovimentacao: 'Permitir abrir direto na movimentação',
  permiteBuscarNumeroNome: 'Permite busca por número, nome ou referência',
  permiteBuscarNumeroNomeCpfCnpj: 'Permite busca por número, nome ou CPF/CNPJ',
  permiteDiferencaValores: 'Permitir diferença de valores',
  permiteVendaEstoqueNegativo: 'Permitir vender quantidade maior que o estoque físico (ex: entrega futura)',
  permiteVinculoOutroParceiro: 'Permite vínculo com outro cliente/fornecedor',
  permitir: 'Permitir',
  permitirAlterarDataBaixa: 'Permitir alterar data da baixa',
  permitirAlterarDataNegociacao: 'Permitir alterar data de negociação',
  permitirAlterarDiasEntre: 'Permitir alterar dia do vencimento',
  permitirBaixaEstornoRetroativo: 'Permitir baixar e estornar retroativo',
  permitirDevolucao: 'Permitir devolução',
  permitirDocumentoFiscaisJaUtilizados: 'Permitir documentos fiscais já utilizados',
  permitirEmitirNfe: 'Permitir emitir NF-e',
  permitirEntregaDireta: 'Permitir entrega direta',
  permitirFaturamentoComNcm: 'Para produto que emitir NF-e o campo NCM deve ser preenchido',
  permitirFaturamentoMatriz: 'Permitir faturamento para matriz?',
  permitirFaturamentoCompartilhado: 'Permitir faturamento compartilhado?',
  permitirVisualizar: 'Permitir Visualizar',
  pertence: 'Pertence (a)',
  peso: 'Peso',
  pesoBruto: 'Peso bruto',
  pesoLiquido: 'Peso líquido',
  pesoLiquidoMaiorPesoBruto: 'Verifique o peso líquido, pois ele está maior que o peso bruto',
  pesquisa: 'Pesquisa',
  pesquisaCliente: 'Pesquisa cliente',
  pesquisaClienteAtalho: 'Pesquisa cliente [CTRL + I]',
  pesquisaProduto: 'Pesquisa produto',
  pesquisaProdutoAtalho: 'Pesquisa produto [F8]',
  pesquisaRapida: 'Pesquisa rápida',
  pesquisar: 'Pesquisar',
  pesquisarPor: 'Pesquisar Por:',
  pessoa: 'Pessoa',
  pis: 'PIS',
  pisCofins: 'PIS E COFINS',
  pisBase: 'Base PIS',
  pisRetido: 'PIS Retido',
  pisValor: 'Valor PIS',
  pl: 'PL',
  placa: 'Placa',
  planejado: 'Planejado',
  planoConta: 'Plano de conta',
  planoContaPadrao: 'Plano de conta padrão',
  planoContaPaiNaoPodeEleMesmo: 'Plano conta pai não pode ser o próprio registro',
  polegadaAjuda: 'Define a ordem em que as polegadas serão apresentadas',
  porCliente: 'Por cliente',
  porSerVerdadeFirmamosOPresente: ' Por ser verdade firmamos o presente',
  porcentoPorAno: '% por ano',
  porcentoPorDia: '% por dia',
  portalNfeCopiarChaveFiscal: 'Ao utilizar o botão para consulta ao portal da NF-e o sistema já copia a chave informada',
  pouco: 'III - Pouco tóxico',
  praca: 'Praça',
  praga: 'Praga',
  prazo: 'Prazo',
  prazoAcimaDaMedia: 'Prazo acima da média',
  prazoMaximo: 'Prazo máximo',
  prazoFixo: 'Prazo fixo',
  prazoMaiorQue: 'Prazo maior que',
  preRequisitos: 'Pré-requisitos da senha de acesso',
  precaucoesGerais: 'Precauções gerais',
  precaucoesGeraisAplicacao: 'Precauções durante a aplicação',
  precaucoesGeraisAposAplicacao: 'Precauções após a aplicação',
  precaucoesGeraisCalda: 'Precauções na preparação da calda',
  precificacao: 'Precificação',
  preco: 'Preço',
  precoMenor: 'Valor menor que o valor de tabela',
  precoVenda: 'Preço venda',
  precoVendaMinimo: 'Preço venda mínimo',
  preenchaDescricao: 'Preencha a descrição',
  preenchaLojaEParceiroParaAbrirModalImposto: 'Preencha os campos de loja e _ para abrir a tela de imposto',
  preenchaNegociacao: 'Preencha a _',
  preenchaNoMinimo3caractresOuAlgumaOutraOpcaoDoFiltro: 'Preencha no mínimo 3 caracteres ou alguma outra opção de filtro.',
  preenchaNomeCliente: 'Preencha o nome do cliente',
  preenchaNomeConsumidorFinal: 'Preencha o nome do cliente com no mínimo 3 caracteres, exceto ( "+" e "%")',
  preenchaNomeItem: 'Preencha o nome do item',
  preenchaNumeroCheque: 'Preencha o número do cheque da(s) baixa(s) do tipo cheque',
  preenchaOCampoFornecedor: 'Preencha o campo fornecedor.',
  preenchaProduto: 'Preencha o produto para abrir a tela de imposto',
  preenchaProdutoFormuladoDose: 'Preencha as indicações para o produto _ na tela de _, para finalizar o cadastro de receituário eletrônico.',
  preenchaQuantidadeProdutoObrigatorio: 'Preencha os campos(s) obrigatório(s) do produto _',
  preenchaUmFiltro: 'Preencha pelo menos um filtro',
  preenchaUmFiltroAlemObrigatorio: 'Preencha pelo menos um filtro além do(s) obrigatório(s)',
  preenchaValorParcelas: 'Preencha valor para parcelas',
  preenchimentoAutomaticoClasseToxicologica: 'Clique nos botões abaixo para preenchimento automático da classe toxicológica',
  prestadorDeServicos: 'Prestador de serviços',
  prevencoesPrimeirosSocorros: 'Prevenção e primeiros socorros nos casos de acidente',
  previsaoPagamento: 'Previsão de pagamento',
  previsto: 'Previsto',
  previstoAbreviacao: 'Prev.',
  previsualizarDanfe: 'Pré visualizar DANFE',
  primeiraLoja: '1ª loja',
  prioridade: 'Prioridade',
  producao: 'Produção',
  producaoNenhumBoletoRecebido: 'Produção, mas nenhum boleto recebido',
  producaoBoletoRecebido: 'Produção, _ boleto(s) já recebido(s)',
  produto: 'Produto',
  produtoCfopCstPisCofins: 'O produto (_) está com CST PIS/Cofins diferente da operação do CFOP(Entrada/Saída)',
  produtoColaborador: 'Produto colaborador',
  produtoComDescontoNegativo: 'O seguinte produto está com desconto negativo: ',
  produtoComFoto: 'Produto com foto',
  produtoComObservacao: 'Produto com observação',
  produtoComPisCofinsEmOperacaoTransferencia: 'Item: _ com informações de _ incorretas.',
  produtoDescricao: 'Produto (descrição)',
  produtoDeveSerPagoEntre: 'Produto _ - _ deve ter seu valor total (_) pago entre _ e _',
  produtoFormulado: 'Produto formulado',
  produtoFormuladoVazio: 'Produto formulado deve ser preenchido',
  produtoImpostoValorNegativo: 'O produto (_) está com valor de imposto negativo',
  produtoNaoConfiguradaGerarReceituarioEletronico: 'Produto(s) não configurado para gerar receituário eletrônico ou receituário já emitido',
  produtoNaoPermiteFaturamento: 'Item: _ - _ não permite emitir NF-e.',
  produtoNaoVinculado: 'Você tem produtos que ainda não foram vinculados',
  produtoNomeAutomatico: 'Nome do prod. aut.',
  produtoNomeAutomaticoAjuda: 'Formação automática do nome do produto (Grupo + Descrição + Referência + Fabricante)',
  produtoNomeNFSe: 'Nome NFS-e',
  produtoNomeNFe: 'Nome NF-e',
  produtoNumero: 'Produto (número)',
  produtoPersonalizado: 'Produto personalizado',
  produtoPredominante: 'Produto predominante',
  produtoSemCfop: 'CFOP é obrigatório no produto _',
  produtoSemFoto: 'Produto sem foto',
  produtoSemImpostos: 'Impostos é obrigatório no produto _',
  produtoSemMovimentacoes: 'Produto sem movimentações',
  produtoSemObservacao: 'Produto sem observação',
  produtoSemVendas: 'Produto sem movimentações',
  produtoVendas3Meses: 'Vendas dos últimos 3 meses',
  produtoVinculadoForaXml: 'Produto _ vinculado mas não existente no XML',
  produtobase: 'Base de ICMS deve ser zerada para o CST _ usado no item _',
  produtos: 'Produtos',
  produtosLancancadosEmDuplicidade: 'O(s) produto(s) abaixo existe(m) lançamento(s) duplicado(s).\n_Deseja continuar?',
  promocao: 'Promoção',
  promocaoAbreviado: 'Pro.',
  pronto: 'Pronto',
  propria: 'Própria',
  propriedade: 'Propriedade',
  proprio: 'Próprio',
  protestarDiasCorridos: 'Protestar dias corridos',
  protestarDiasUteis: 'Protestar dias úteis',
  protestarFimFamilentarCorridos: 'Protestar fim falimentar - dias corridos',
  protestarFimFamilentarUteis: 'Protestar fim falimentar - dias úteis',
  protestarNao: 'Não protestar',
  protestarNegativacao: 'Negativação sem protesto',
  protestoCartorial: 'Protesto cartorial',
  protocolo: 'Protocolo',
  proxima: 'Próxima',
  pvm: 'PVM',
  qtd: 'Qtd',
  qtdDesfazimento: 'Qtd desfazimento',
  qtdEnt: 'Qtd ent',
  qtdMaxima: 'Qtd. máxima',
  qtdMinima: 'Qtd. mínima',
  qtdPend: 'Qtd pend',
  quantidade: 'Quantidade',
  quantidadeAComprar: 'Quantidade a comprar',
  quantidadeAnterior: 'Qtd. anterior',
  quantidadeComprada: 'Quantidade comprada',
  quantidadeDeRegistros: 'Quantidade de registros',
  quantidadeDesfeita: 'Quantidade desfeita',
  quantidadeDia: 'Quantidade dias',
  quantidadeDiaX: 'Quantidade dias (x)',
  quantidadeDisponivel: 'Quantidade disponível',
  quantidadeDisponivelAte: 'Qtd. disponível (Até)',
  quantidadeDisponivelDe: 'Qtd. disponível (De)',
  quantidadeDisponivelxNfe: 'Diferença NFe',
  quantidadeDisponivelxNfeDescricao: 'Quantidade NF-e - Quantidade disponível',
  quantidadeDisponivelxPendente: 'Diferença pendente',
  quantidadeDisponivelxPendenteDescricao: 'Quantidade pendente - Quantidade disponível',
  quantidadeDoItemDiferente: 'Quantidades descrita no item _ - _ difere da quantidade total',
  quantidadeEmBranco: 'Quantidade em branco',
  quantidadeEntrada: 'Qtd. entrada',
  quantidadeEntregue: 'Quantidade entregue',
  quantidadeEntregueAbreviacao: 'Qtd. entregue',
  quantidadeEstoqueEstoque: 'Quantidade - Estoque x Estoque',
  quantidadeEstorno: 'Quantidade estorno',
  quantidadeEtiquetas: 'Quantidade etiquetas',
  quantidadeFaturada: 'Quantidade faturada',
  quantidadeFisicaAte: 'Quantidade física (Até)',
  quantidadeFisicaDe: 'Quantidade física (De)',
  quantidadeFisica: 'Quantidade física',
  quantidadeInformada: 'Quantidade informada',
  quantidadeMaximaParcela: 'Quantidade máxima de parcelas',
  quantidadeNfe: 'Quantidade NF-e',
  quantidadeNfeAte: 'Qtd. NF-e (Até)',
  quantidadeNfeDe: 'Qtd. NF-e (De)',
  quantidadeParcelaDeMaiorQquantidadeParcelaAte: 'Quantidade Parcelas (De) maior que quantidade Parcelas (Até)',
  quantidadeParcelas: 'Qtd. parcelas',
  quantidadePendente: 'Quantidade pendente',
  quantidadePendenteAbreviada: 'Qtd. pendente',
  quantidadePendenteFaturada: 'Qtd. pendente a faturar',
  quantidadePeso: 'Quantidade/Peso',
  quantidadeProduto: 'Qtd. produto',
  quantidadeProdutoQuantidadeMovimentacao: 'Quantidade - Movimentação (produto) x Movimentação (quantidade)',
  quantidadeProdutoReceita: 'Qtd. produto receita',
  quantidadeProdutoReceitaLoja: 'Quantidade de produto por receita não informado na loja',
  quantidadeSaida: 'Qtd. saída',
  quantidadeTotal: 'Quantidade total',
  quantidadeTotalAbreviado: 'Qtd. Total',
  quartaFeira: 'Quarta-feira',
  que: 'Que',
  quintaFeira: 'Quinta-feira',
  quinzenal: 'Quinzenal',
  ramoAtividade: 'Ramo atividade',
  razaoSocial: 'Razão social',
  real: 'Real',
  realizadoComSucesso: 'Realizado(a) com sucesso!',
  realizarBaixa: 'Realizar baixa',
  realizarEntrega: 'Realizar entrega',
  recalcular: 'Recalcular',
  recebimento: 'Recebimento',
  recebimentoNaoSeraRealizadoImediato: 'Recebimento não imediato:',
  receita: 'Receita',
  receitaAConciliar: 'Receita a conciliar',
  receitaAmanha: 'Receita amanhã',
  receitaAte: 'Receita (Até)',
  receitaDe: 'Receita (De)',
  receitaEDespesa: 'Receita / Despesa',
  receitaGeral: 'Receita geral',
  receitaMaiorMesAnterior: 'Receita maior que mês anterior',
  receitaMenorMesAnterior: 'Receita menor que mês anterior',
  receitas: 'Receitas',
  receitasAno: 'Receitas ano',
  receitasBaixadasMesTipoTitulo: 'Receitas baixadas do mês por tipo de título',
  receitasDisponiveis: 'Receitas disponíveis',
  receituario: 'Receituário',
  receituarioAgronomico: 'Receituário agronômico',
  receituarioEletronico: 'Receituário eletrônico',
  receituarioPreenchido: 'Receituário preenchido',
  recibo: 'Recibo',
  replicarParaTodosVazios: 'Replicar para todos os vazios',
  recolherInformacoes: 'Recolher informações',
  recomendacao: 'Recomendação',
  recomendacaoManejoResistencia: 'Recomendações para o manejo de resistência',
  recusado: 'Recusado',
  recusar: 'Recusar',
  ref: 'Ref.',
  referenteAQuitacao: ', Referente a quitação ',
  referencia: 'Referência',
  referenciaEstoqueDisponivel: 'Referência: Estoque disponível',
  referenciaEstoqueFisico: 'Referência: Estoque físico',
  referenciaEstoqueNfe: 'Referência: Estoque NF-e',
  regiao: 'Região',
  regimeCaixa: 'Regime de caixa - Escrituração consolidada (Registro F500)',
  regimeCompetencia: 'Regime de competência - Escrituração consolidada (Registro F550)',
  regimeCompetenciaDetalhada: 'Regime de competência - Escrituração detalhada, com base nos registros dos blocos',
  registrar: 'Registrar',
  registro: 'Registro',
  registroInseridoEm: 'Registro inserido em ',
  registroNaoDisponivel: 'Registro não está mais disponível',
  registroPossuiVinculoNotaFiscal: 'Este registro possui vínculo a uma nota fiscal',
  regra: 'Regra',
  regraComissao: 'Regra comissão',
  regraDoCliente: 'Regra do cliente',
  regraRecebimento: 'Regra recebimento',
  regraUtilizadaApenasEmDevolucaoPedidoOrcamento: 'Regra utilizada apenas em devolução e pedido',
  regras: 'Regras',
  reiniciarAprovacao: 'Reiniciar aprovação',
  reiniciarFiltro: 'Reiniciar filtro',
  rejeicao: 'Rejeição',
  relacionamento: 'Relacionamento',
  relatorio: 'Relatório',
  relatorioAdicional: 'Relatório Adicional',
  relatorioExigeParametros: 'Este relatório exige parâmetros. Verifique os critérios informados',
  relatorios: 'Relatórios',
  remessa: 'Remessa',
  remetente: 'Remetente',
  remover: 'Remover',
  removerAcesso: 'Bloquear acesso',
  removerDashboardInicial: 'Remover dashboard inicial',
  removerEdicao: 'Bloquear edição',
  removerExclusao: 'Bloquear exclusão',
  removerFavorito: 'Bloquear favorito',
  removerInativar: 'Bloquear inativar',
  removerInsercao: 'Bloquear inserção',
  removerPaginacao: 'Tem certeza que deseja remover paginação?',
  removerPermissao: 'Remover permissão',
  renavan: 'Renavan',
  rendaMensal: 'Renda mensal',
  renegociar: 'Renegociar',
  renegociacao: 'Renegociação',
  repetir: 'Repetir',
  reprovada: 'Reprovada',
  res: 'Res',
  reservado: 'Reservado',
  resgatarCredito: 'Resgatar crédito',
  residencial: 'Residencial',
  residuosEmbalagens: 'Resíduos  e  embalagens',
  respeitaNegociacao: 'No vencimento da parcela',
  respeitandoCultura: 'Respeitando mesma cultura',
  responsalvelEntrega: 'Responsável (entrega)',
  responsavel: 'Responsável',
  restante: 'Restante',
  restauraPadrao: 'Restaurar padrão',
  restricao: 'Restrição',
  restricaoReceituario: 'Existe restrição para a cultura e/ou estado e/ou equipamento informado',
  restricoes: 'Restrições',
  restrita: 'Restrita',
  resultado: 'Resultado',
  resumo: 'Resumo',
  resumoFinanceiro: 'Resumo financeiro',
  resumoMovimentacoes: 'Resumo movimentações',
  retirada: 'Retirada',
  retirarDevolucao: 'Retirar devolução',
  retirarLoja: ' Retirar na loja',
  retornoDisponivelAutomatico: 'Retorno disponível automático',
  retornoDisponivelManual: 'Retorno disponível manual',
  reutilizarFinanceiroNovoBoleto: 'Reutilizar o financeiro para um novo boleto?',
  reutilizarFinanceiroNovoBoletoMensagem: 'Tem certeza que deseja reutilizar o financeiro para um novo boleto?',
  rntrc: 'RNTRC',
  rg: 'RG',
  romaneioEntrega: 'Romaneio entrega',
  roteiro: 'Roteiro',
  roteiroContabil: 'Roteiro Contábil',
  roteiroRepetido: 'Não pode haver roteiros com conta e conta contrapartida repetidos',
  sabado: 'Sábado',
  sacado: 'Sacado',
  safra: 'Safra',
  saida: 'Saída',
  saidas: 'Saídas',
  sair: 'Sair',
  saldo: 'Saldo',
  saldoAnterior: 'Saldo anterior',
  saldoAte: 'Saldo (Até)',
  saldoAtual: 'Saldo atual',
  saldoAtualReal: 'Saldo atual (real)',
  saldoDe: 'Saldo (De)',
  saldoEm: 'Saldo bancário em ',
  saldoFinal: 'Saldo final',
  saldoInicial: 'Saldo inicial',
  saldoPrevisto: 'Saldo previsto',
  saldoPrevistoConta: 'Saldo atual + receitas abertas + receitas baixadas e não conciliadas - despesas abertas - despesas baixadas e não conciliadas',
  salvar: 'Salvar',
  salvarAdicionarNovo: 'Salvar e adicionar novo',
  salvarFinalizar: 'Salvar e finalizar',
  salvarTransmitir: 'Salvar e transmitir',
  salveEfetueLoginNovamente: 'Foto modificada. Salve e efetue login novamente para visualização da nova foto nos relatório.',
  salveFotoEfetueLoginNovamente: 'Foto modificada. Salve e efetue login novamente para visualização da nova foto',
  seMaior: 'Se Maior',
  segundaFeira: 'Segunda-feira',
  segundaLoja: '2ª loja',
  seguro: 'Seguro',
  sejaBemVindo: 'Seja bem-vindo a',
  selecionados: 'Selecionados',
  selecionarLojasDistintas: 'Loja de (entrada e saída) devem ser diferentes para selecionar itens',
  selecionarTodos: 'Selecionar todos',
  selecionarVencimentoAte: 'Selecionar vencimento até',
  selecione: 'Selecione',
  selecioneALoja: 'Selecione a loja',
  selecioneAoMenosUmPeriodo: 'Selecione ao menos uma tabela de preço e um período',
  selecioneComissao: 'Selecione pelo menos uma comissão',
  selecioneComissaoAguardando: 'Selecionar todas as comissões com valores aguardando',
  selecioneEndereco: 'Selecione endereço',
  selecioneFinanceiro: 'Selecione pelo menos um financeiro',
  selecioneLoja: 'Selecione a loja',
  selecioneLojaPagamento: 'Selecione a loja de pagamento para visualizar os lançamentos contábeis',
  selecioneNaturezaOperacao: 'Selecione natureza da operação',
  selecioneNegociacao: 'Selecione a _',
  selecioneParceiro: 'Selecione o _',
  selecionePeloMenosUmRegistro: 'Selecione pelo menos um registro',
  selecioneRoteiro: 'Selecione um roteiro para gerar os lançamentos',
  selecioneUmItemLista: 'Selecione pelo menos um item da lista',
  semAcesso: 'Sem acesso',
  semComissao: 'Sem comissão',
  semConciliacaoSelecionada: 'Nenhuma conciliação selecionada',
  semConta: 'Ainda não tem conta?',
  semCreditoSuficiente: 'Cliente sem crédito suficiente',
  semCustoGerencial: 'Sem custo gerencial',
  semInstrucaoDesconto: 'Sem instrução de desconto',
  semMovimentacao: 'Sem movimentação',
  semMovimento: 'Sem movimento',
  semPermissaoAcesso: 'Sem permissão de acesso',
  semResultadoServidor: 'Sem resultado do servidor',
  semValorDisponivel: 'Sem valor disponível',
  semanaAno: 'Semana/Ano',
  semanal: 'Semanal',
  semdetalheLog: 'Nenhuma informação foi modificada, apenas foi clicado em Salvar',
  semestral: 'Semestral',
  sempre: 'Sempre',
  senha: 'Senha',
  senhaAcesso: 'Senha acesso',
  senhaAcessoDeveAtenderOsPreRequisitos: 'Senha de acesso deve atender todos os pré-requisitos',
  senhaFraca: 'Mínimo 8 caracteres',
  senhaIncorreta: 'Senha incorreta',
  incorreta: 'incorreta',
  senhaOperacional: 'Senha operacional',
  senhaOperacionalNaoVinculadaAoUsuario: 'Senha operacional não vinculada ao usuário _',
  separado: 'Separado',
  sequencia: 'Sequência',
  sequenciaRemessa: 'Sequência da remessa',
  serie: 'Série',
  servico: 'Serviço',
  servidorDaReceitaFederalEstaComLentidao: 'O servidor da receita federal vinculado a seu estado, está apresentando lentidão no processamento de emissão de NF-e.',
  servidorDaReceitaFederalEstaForaDeOperacao: 'O servidor da receita federal vinculado a seu estado, está fora de operação e foi habilitado o modo de contingência.',
  servidorEmManutencao: 'Desculpe, servidor em manutenção. Aguarde um momento e por favor tente novamente.',
  servidorReceitaIndisponivel: 'Servido da receita indisponível. Tente novamente.',
  seteCaracteres: '7 carácteres',
  setembro: 'Setembro',
  setembroAbreviacao: 'Set.',
  sexo: 'Sexo',
  sextaFeira: 'Sexta-feira',
  sim: 'Sim',
  simOriginadasMovimentacao: 'Sim - Somente originadas de movimentação (compras)',
  simTodasDespesas: 'Sim - Todas despesas',
  simboloMais: '+',
  simples: 'Simples',
  simplesNacional: 'Simples nacional',
  simplesNacionalExcesso: 'Simples nacional (excesso)',
  sinalizacao: 'Sinalização',
  sinteticaConta: 'Sintética (Grupo de contas)',
  sistemaDialogo: 'Sistema',
  site: 'Site',
  siteAgrow: 'www.agrownegocios.com.br',
  situacao: 'Situação',
  situacaoComissao: 'Situação (Comissão)',
  situacaoFinanceira: 'Situação (Financeiro)',
  situacaoMovimentacaoParcela: 'Situação – Movimentação x Movimentação (parcelamento)',
  situacaoMovimentacaoProduto: 'Situação – Movimentação x Movimentação (produto)',
  situacaoMovimentacaoQuantidade: 'Situação – Movimentação x Movimentação (quantidade)',
  situacaoReceita: 'Situação receita',
  sms: 'SMS',
  solicitacaoConcluida: 'Solicitação concluida',
  solicitacaoEmAndamento: 'Solicitação em andamento',
  solicitarPagamento: 'Solicitar pagamento',
  solteiro: 'Solteiro',
  somaDosHectares: 'A soma dos hectares não pode ser superior a área',
  somatarioDeImpostos: 'Somatória dos valores de impostos difere do valor total dos produtos',
  somarIcmsStIpiPisCofins: 'Somar ICMS ST e IPI na Base PIS e Cofins',
  somarIcmsStIpiPisCofinsAjuda: 'Somar ICMS ST e IPI na Base PIS e Cofins para o todas as notas',
  somenteAceitamosDevolucaoEm30Dias: 'Somente aceitamos devolução no prazo de 30 dias',
  somenteClientesDebito: 'Somente cliente com débito',
  somenteEm: 'Somente em',
  somenteFinanceiro: 'Somente para usuários do financeiro',
  somenteLetraNumero: 'Somente letra e número',
  somenteParaUsuarioQueUtilizam: 'Somente para usuários que utilizam',
  somenteReceitaMaiorDespesa: 'Somente cliente com débito maior que crédito',
  somenteSemLimiteDisponivel: 'Somente sem limite disponível',
  somenteVendaAVista: 'Somente a vista',
  spedContribuicaoMatrizAjuda: 'O Sped Contribuições consolida em um único arquivo as informações da matriz e de suas filiais',
  spedContribuicoes: 'Sped Contribuições',
  spedFiscal: 'Sped Fiscal',
  spedFiscalBlocoH: 'Bloco H',
  spedFiscalBlocoHAjuda: 'Inventário (informações estoque)',
  spedFiscalBlocoK: 'Bloco K',
  spedFiscalBlocoKAjuda: 'Enviar mensalmente o bloco K (informações estoque)',
  spedFiscalValorMercadoriaLiquidoAjuda: 'Valor da mercadoria no Bloco C enviar o valor líquido ((Valor unitário x Quantidade) - Desconto)',
  status: 'Status',
  suporte: 'Suporte',
  suporteTecnospeed: 'Suporte tecnospeed',
  sub: 'Sub',
  subGrupo: 'Sub-grupo',
  subTotal: 'Sub-total',
  substituicao: 'Substituição',
  substituicaoItem: 'Substituição de item',
  subtrairIcmsPisCofins: 'Subtrair valor ICMS da Base PIS e Cofins',
  subtrairIcmsPisCofinsAjuda: 'Subtrair valor ICMS da Base PIS e Cofins para o todas as notas do enquadramento Lucro Real e para Lucro Presumido somente das saídas',
  sucesso: 'Sucesso',
  sugerirLojaEmLogin: 'Sugerir loja em login',
  sugestao: 'Sugestão',
  sugestaoAnalisar: 'Regra sugestão',
  sugestaoAutomatica: 'Sugestão automática',
  sugestaoBaixa: 'Sugestão baixa',
  tabela: 'Tabela',
  tabelaPreco: 'Tabela preço',
  taxa: 'Taxa',
  taxaDiaria: 'Taxa diária',
  taxaFixa: 'Taxa fixa',
  taxaJurosMora: 'Taxa de Juros/Mora',
  taxaMensal: 'Taxa mensal',
  taxas: 'Taxas',
  teclaEnterAdicionar: '[enter] para adicionar',
  teclaEspacoQuantidadeRestante: 'Tecla [espaço] quantidade = quantidade restante',
  teclaEspacoSelecionar: 'Tecla [espaço] ou [duplo clique] para selecionar',
  teclasAtalho: 'Teclas de Atalho',
  tecnicoAgricola: 'Técnico agrícola',
  telaInicial: 'Tela inicial',
  telefone: 'Telefone',
  tenteNovamente: 'Tente novamente',
  tercaFeira: 'Terça-feira',
  terceiro: 'Terceiro',
  tercerizacao: 'Tercerização',
  terrestre: 'Terrestre',
  textoDuplicataMercantil: 'Reconhece(mos) a exatidão desta duplicata de venda mercantil na importância acima que pagarei(emos) a _ ou a sua ordem na praça e vencimentos indicados, e na falta mais juros e despesas cfe.lei.',
  tipo: 'Tipo',
  tipoConta: 'Tipo Conta',
  tipoDaConta: 'Tipo da conta',
  tipoDeAtividade: 'Tipo de atividade',
  tipoDeContato: 'Tipo de contato',
  tipoDeLancamento: ' Tipo de lançamento',
  tipoDeTelefone: 'Tipo de telefone',
  tipoDeTituloMarcacao: '<<TIPO DE TÍTULO>>',
  tipoDeTituloNaoPossuiTaxa: '_ (_) não possui configuração de taxa(s), se foi preenchido agora inicie o processo novamente',
  tipoDesconto: 'Tipo desconto',
  tipoEmissao: 'Tipo emissão',
  tipoEndereco: 'Tipo de endereço',
  tipoEntrega: 'Tipo de entrega',
  tipoEnvio: 'Tipo de envio',
  tipoImpressao: 'Tipo impressão',
  tipoItem: 'Tipo item',
  tipoJuros: 'Tipo de juros',
  tipoJurosMora: 'Tipo de Juros/Mora',
  tipoLancamento: 'Tipo lançamento',
  tipoMedida: 'Tipo medida',
  tipoMovimento: 'Tipo de movimento',
  tipoMulta: 'Tipo multa',
  tipoPagamento: 'Tipo de Pagamento',
  tipoPeriodoNaoPodeUsarTaxaComposta: 'Tipo período não pode usar taxa composta',
  tipoPessoa: 'Tipo pessoa',
  tipoProtesto: 'Tipo protesto',
  tipoTitulo: 'Tipo de título',
  tipoTituloPrevista: 'Tipo de título prevista',
  titularDaEmpresa: 'Titular da empresa',
  titulosBaixaDuplicados: 'Não é permitido lançar mais de um título com a mesma conta, o mesmo tipo de título e o mesmo número de documento',
  todas: 'Todas',
  todasLojas: 'Todas as lojas',
  todos: 'Todos',
  topCincoVendedorMargem: 'Top 5 vendedores margem',
  topCincoVendedorValores: 'Top 5 vendedores valor',
  topProdutoMargem: 'Top produto margem',
  tornarDashboardInicial: 'Tornar dashboard inicial',
  totais: 'Totais',
  totaisPorTipoDeTitulo: 'Totais por tipo de título',
  totaisReceberPagar: 'Totais a receber e a pagar',
  total: 'Total',
  totalAberto: 'Total aberto',
  totalAmanha: 'Total amanhã',
  totalAnoAnterior: 'Total (ano anterior)',
  totalCompras: 'Total compras',
  totalComprasUltimoAno: 'Total compras último ano',
  totalCustoNfe: 'Total custo NF-e',
  totalCustoReposicao: 'Total custo reposição',
  totalDescontosNfe: '(-) Desconto',
  totalDespesaAcessoriasNfe: '(+) Despesa acessória',
  totalFiscal: 'Totais fiscais',
  totalFreteNaoSomaNfe: 'Frete não soma',
  totalFreteNaoSomaNfeExplicacao: 'Frete que não compõem o total do lançamento, mas pode ser usado para compor o custo de reposição do produto',
  totalFreteNfe: '(+) Frete',
  totalGeral: 'Total geral',
  totalGeralNfe: '(=) Total geral',
  totalIcmsDesoneradoNfe: '(-) ICMS desonerado',
  totalIcmsStNfe: '(+) ICMS ST',
  totalIpiNfe: '(+) IPI',
  totalIss: 'Valor ISS não subtrai no valor total',
  totalOutros: '(+) Outros',
  totalPagar: 'Total pagar',
  totalPeriodoInformado: 'Total (período informado)',
  totalPrecoVenda: 'Total preço venda',
  totalProdutosNfe: 'Total produtos',
  totalReceber: 'Total receber',
  totalReceberPagar: 'Totais a Receber e a Pagar',
  totalSeguroNfe: '(+) Seguro',
  totalUtilizarQuantidade: 'Total utilizar quantidade',
  totalizador: 'Totalizador',
  totalizadores: 'Totalizadores',
  totalizarPor: 'Totalizar por',
  trabalhaCorrentista: 'Trabalha Correntista',
  tradicional: 'Tradicional',
  tranferenciaCreditoGerada: 'Transferência crédito. Aguarde aprovação do gestor',
  transferencia: 'Transferência',
  transferenciaPisCofins: 'Transferência x PIS / COFINS',
  transferir: 'Transferir',
  transmitidaComSucesso: 'Transmitida com sucesso',
  transmitido: 'Transmitido',
  transmitir: 'Transmitir',
  transportadora: 'Transportadora',
  transportadoraNaoPossuiEmailCadastrado: 'Transportadora não possui e-mail cadastrado',
  transporte: 'Transporte',
  transporteEspecie: 'Espécie',
  transporteMarca: 'Marca',
  transporteNumeracao: 'Numeração',
  transporteQuantidade: 'Quantidade',
  transporteTipoContaDestinatario: '1 - CONTA DESTINATARIO',
  transporteTipoContaEmitente: '0 - CONTA EMITENTE',
  transporteTipoContaTerceiros: '2 - CONTA TERCEIROS',
  transporteTipoProprioDestinatario: '4 - PROPRIO DESTINATARIO',
  transporteTipoProprioRemetente: '3 - PROPRIO REMETENTE',
  transporteTipoSemFrete: '9 - SEM FRETE',
  treinamento: 'Treinamento',
  transporteTipoFreteCteDestinatario: '3 - PROPRIO DESTINATARIO',
  transporteTipoFreteCteExpedidor: '0 - CONTA EXPEDIDOR',
  transporteTipoFreteCteRecebedor: '2 - CONTA RECEBEDOR',
  transporteTipoFreteCteRemetente: '3 - CONTA REMETENTE',
  transporteTipoFreteCteOutros: '4 - OUTROS',
  trimestral: 'Trimestral',
  troco: 'Troco',
  trt: 'TRT',
  uf: 'UF',
  ufDestino: 'UF destino',
  ufOrigem: 'UF origem',
  ultimaAtualizacaoAbreviacao: 'Últ. atualização',
  ultimaReceita: 'Última receita',
  ultimaReceitaNaoMaiorMaximaReceita: 'Última receita maior que máxima receita',
  ultimoAcesso: 'Último acesso',
  ultimoDiaMes: 'Último dia do mês',
  ultimoPagamento: 'Último pagamento ao vendedor',
  ultimoRecebimento: 'Último recebimento do cliente',
  umCaracterEspecial: 'Ao menos um dos seguintes caracteres especiais ( @ , ? , { , } , | , ] , [ , = , ^ , ~ );',
  umaLetraMaiuscula: 'Ao menos uma letra maiúscula;',
  undCaldaAereo: 'Und. calda aéreo',
  undCaldaTerrestre: 'Und. calda terrestre',
  unidade: 'Unidade',
  unidadeAbreviacao: 'Und',
  unidadeAlternativa: 'Unidade alternativa',
  unidadeBase: 'Unidade base',
  unidadeDose: 'Unidade dose',
  unidadeMedida: 'Unidade medida',
  unidadeVolume: 'Unidade volume',
  unificado: 'Unificado',
  unitario: 'Unitário',
  usaCusto: 'Usa custo',
  usaIcmsDesonerado: 'Usa ICMS desonerado',
  usadoComo: 'Usado como',
  usoConsumo: 'Uso consumo',
  usoConsumoMensagem: 'Ao marcar o grupo como "uso consumo", nenhum dos produtos associados a ele será incluído nos blocos H e k do SPED',
  usuario: 'Usuário',
  usuarioSenhaIncorretos: 'Usuário ou senha incorretos',
  utilizarLojaPreco: 'A loja _ utiliza preço de outra loja?',
  utilizarOrdemDePagamento: 'Ordem de pagamento',
  utilizeBotaoPesquisaParaListar: 'Utilize o botão "PESQUISAR [F2]" para listar _',
  utme: 'UTME',
  utmn: 'UTMN',
  variacaoCarteira: 'Variação de carteira',
  validaLimiteCredito: 'Valida limite de crédito',
  validaInadimplente: 'Valida Inadimplente',
  validacao: 'Validação',
  validacaoAtiva: 'Validação ativa',
  validacoes: 'Validações',
  validade: 'Validade',
  validarXml: 'Validar xml',
  validarAbrirCteJaEnviado: 'Não é permitido abrir CT-e já enviado',
  validarAbrirMdfeJaEnviado: 'Não é permitido abrir MDF-e já enviado',
  validarInadimplencia: 'Validar inadimplência',
  validarRegistroPai: 'Atenção não é permitido vincular registro pai com ele mesmo',
  valor: 'Valor',
  valorAPagar: 'Valor à pagar',
  valorAReceber: 'Valor à receber',
  valorAVista: 'Valor a vista',
  valorAReceberCartao: 'Valor pendente de recebimento da operadora do cartão',
  valorAguardando: 'Valor aguardando',
  valorAnterior: 'Valor anterior',
  valorAnulado: 'Valor anulado',
  valorAproximadoDosTributos: 'Valor aproximado dos tributos',
  valorAquisicao: 'Valor aquisição',
  valorAte: 'Valor (Até)',
  valorAtual: 'Valor atual',
  valorBloqueado: 'Valor bloqueado',
  valorCarga: 'Valor carga',
  valorCargaDeveSerMaiorQueZero: 'Valor da carga deve ser maior que zero (0)',
  valorCfop: 'Valor CFOP',
  valorComissao: 'Valor comissão',
  valorDe: 'Valor (De)',
  valorDebito: 'Valor débito',
  valorDesconto: 'Valor desconto',
  valorDescontoFinanceiroFinanceiroBaixa: 'Valor desconto - Financeiro x Baixa',
  valorDesfeito: 'Valor desfeito',
  valorDeveSerMaiorQueZero: 'Valor deve ser maior que zero (0)',
  valorDiferenteZero: 'Valor deve ser diferente de zero',
  valorDisponivel: 'Valor disponível',
  valorEmPercentual: 'Valor em percentual',
  valorEmReaisFixo: 'Valor em reais (Fixo)',
  valorEncontrado: 'Valor encontrado',
  valorEntrada: 'Valor entrada',
  valorEsperado: 'Valor esperado',
  valorEstorno: 'Valor estorno',
  valorFaltanteMenor0: 'O valor faltante é menor que 0',
  valorFaltantePagarVendedor: 'Com valor faltante a pagar ao VENDEDOR',
  valorFaturado: 'Valor faturado',
  valorFinal: 'Valor final',
  valorFinalFinanceiro: 'Valor final - Financeiro',
  valorFinalMaiorQueZero: 'Valor final deve ser maior que zero',
  valorFinanceiro: 'Valor financeiro',
  valorFinanceiroDeveSerMaiorZero: 'Valor do financeiro deve ser maior que zero',
  valorFinanceiroFinanceiroBaixa: 'Valor baixa - Financeiro x Baixa',
  valorFinanceiroMovimentacaoParcelamento: 'Valor financeiro – Movimentação x Movimentação (parcelamento)',
  valorFixoAteDataInformada: 'Valor fixo até a data informada',
  valorInformadoSuperiorEm: 'Valor informado, está superior em',
  valorJuroFinanceiro: 'Valor juro - Financeiro x Baixa',
  valorLiberado: 'Valor liberado',
  valorLiquido: 'Valor líquido',
  valorLiquidoMovimentacaoParFinanceiro: 'Valor – Movimentação (parcelamento) x Financeiro',
  valorLucro: 'Valor do lucro',
  valorMaximo100: 'Valor Máximo: 100',
  valorMaximo: 'Valor Máximo',
  valorMinimo: 'Valor mínimo',
  valorMinimoParcela: 'Valor mínimo parcela',
  valorMovimentacaoFinanceiro: 'Valor financeiro - Movimentação x Financeiro',
  valorMovimentacaoMovimentacaoParceiro: 'Valor - Movimentação x Movimentação (parcelamento)',
  valorMovimentacaoSemFinanceiro: 'Valor – Movimentação sem Financeiro x Financeiro',
  valorNaoLiberado: 'Valor NÃO liberado',
  valorNegociacaoZerado: 'O campo desconto máximo ficou zerado, desta forma ao usar esta negociação não poderá ser concedido desconto, tem certeza?',
  valorOriginal: 'Valor original',
  valorBaixa: 'Valor baixa',
  valorPagamento: 'Valor pagamento',
  valorPago: 'Valor pago',
  valorPagoMaiorValorRestante: 'Valor pago (_) é maior que o valor restante (_)',
  valorPagoMaiorValorRestanteDesconto: 'Valor pago (_) é maior que o valor restante (_). Sendo, valor restante (_) com desconto (_)',
  valorParcela: 'Valor parcela',
  valorParcelaMenosTaxa: 'Valor da parcela - taxa do cartão',
  valorPendente: 'Valor pendente',
  valorPorAntecipacaoDiaCorrido: 'Valor por antecipação dia corrido',
  valorPorAntecipacaoDiaUtil: 'Valor por antecipação dia útil',
  valorPorDia: 'Valor por dia',
  valorPorExtenso: 'Valor por extenso',
  valorRecebimento: 'Valor recebimento',
  valorReferenciaMaiorQueZero: 'Valor deve ser maior que zero',
  valorRestante: 'Valor restante',
  valorRestanteFicaraAberto: 'Valor restante que ficará aberto',
  valorRestanteFinanceiro: 'Valor restante – Financeiro',
  valorRestanteTotal: 'Valor restante total: _',
  valorResTotal: 'Valor restante total',
  valorTabela: 'Valor tabela',
  valorTotal: 'Valor total',
  valorTotalAPagar: 'Valor total a pagar',
  valorTotalAberto: 'Valor total aberto',
  valorTotalBoletoIncorreto: 'Valor total do boleto incorreto, filtre novamente para continuar',
  valorTotalDia: 'Valor total (Dia)',
  valorTotalItem: 'Valor total do item',
  valorTotalMenosCredito: 'Valor total - crédito',
  valorTotalMes: 'Valor total (Mês)',
  valorTotalRateioDiferenteValorReferencia: 'O valor total do rateio (_) corresponde a (_%) do valor de referência (_)',
  valorTransferirIncorreto: 'Valor a transferir está incorreto',
  valorUltCompra: 'Valor Últ. Compra',
  valorUltimaCompraAbreviado: 'Valor últ. compra',
  valorUnitario: 'Valor unitário',
  valorUnitarioAbreviacao: 'Valor un.',
  valorUnitarioComDesconto: 'Valor unitário com desconto',
  valorUnitarioFinal: 'Valor unitário final',
  valorUnitarioInformado: 'Valor unitário informado',
  valorUnitarioReal: 'Valor unitário real',
  valorVencido: 'Valor vencido',
  valorVenda: 'Valor da venda',
  valores: 'Valores',
  valoresIncorretos: 'Valores incorretos',
  valorAPagarMaiorQueValorRestante: 'Valor à pagar maior que o valor restante',
  variacao: 'Variação',
  variacaoDescontoAcrescimoValor: 'Variação de (desconto ou acréscimo) no valor total acima do permitido.',
  variacaoDescontoAcrescimoValorTotal: 'Variação desconto e acréscimo no valor total',
  variacaoMaxima: 'Variação máxima',
  variacaoValorTotal: 'Variação valor total',
  variosEmails: 'Para informar vários e-mails, utilize o caractere ";" como separador. O conjunto de todos os e-mails deve ter no máximo 50 caracteres',
  veiculo: 'Veículo',
  vencidoDeste: 'Vencido desde',
  vencidosAvencer: 'Vencidos / À vencer',
  vencimento: 'Vencimento',
  vencimentoMenorNegociacao: 'Data vencimento menor que data negociação',
  vencimentos: 'Vencimento(s)',
  vend: 'Vend',
  venda: 'Venda',
  vendaDevolucao: 'Venda/Devolução',
  vendaDireta: 'Venda direta',
  vendaMaisDias: 'Data venda + dias',
  vendaMesAnoPassado: 'Vendas mês (ano passado)',
  vendaMesAnterior: 'Venda mês anterior',
  vendaMesAnteriorAbreviacao: 'VM.ANT',
  vendaMesAtual: 'Venda mês atual',
  vendaMesAtualAbreviacao: 'VM.ATUAL',
  vendaUltimosMeses: 'Vendas nos últimos 3 meses',
  vendas: 'Vendas',
  vendasHoje: 'Vendas Hoje',
  vendasMesAtual: 'Vendas mês atual',
  vendasUltimosDias: 'Vendas últimos 7 dias',
  vendedor: 'Vendedor',
  verifiqueOsProdutos: 'Verifique o produto _, contém valores incorretos',
  verifiqueBaixa: 'as informações da baixa',
  verifiqueCamposReceituarioMaiorZero: 'Verifique os campos número de aplicação, dose e área eles devem ser maior que zero',
  verifiqueChaveComplementarDigitoVerificador: 'Verifique a chave a complementar, pois ela incorreta',
  verifiqueChaveComplementarModeloSerieNumero: 'Verifique a chave, uma vez que os campos do modelo, série e número não correspondem às informações fornecidas',
  verifiqueDataBaixa: 'Data baixa menor que data negociação',
  verifiqueDiasEntreDiaFixo: 'Verifique dias entre ou dia fixo',
  verifiqueEnderecoEntrega: 'Verifique o endereço de entrega',
  verifiqueEstoqueMinimoMaximo: 'Verifique os campos estoque mínimo e máximo',
  verifiqueEstoqueMinimoMaximoPercentual: 'Verifique o percentual padrão de estoque definido por loja',
  verifiqueFiltroProdutoDescricao: 'Verifique filtro produto (descrição) deve conter no mínimo 4 caracteres contendo letras ou números.',
  verifiqueInformacaoMotivoIsencaoAnvisa: 'Código ANVISA utilizado exige um motivo de isenção',
  verifiqueInformacoesAnvisa: 'NCM utilizado exige as informações da ANVISA (código e valor máximo)',
  verifiqueLoja: 'Preencha o campo loja',
  verifiqueLojaObrigatorio: 'Para configurar o valor, preencha o campo ao lado (Loja valor)',
  receituarioEmitido: 'Receituário já iniciado processo de emissão',
  verifiqueObrigatorio: 'Verifique os campos obrigatórios',
  verifiqueObrigatorioDocumentoFiscal: 'Verifique os campos obrigatórios documento fiscal',
  verifiqueObrigatorioFinanceiro: 'Verifique os campos obrigatórios nos financeiros alterados',
  verifiqueObrigatorioRemessa: 'Verifique todos os campos de desconto, multa, juros e protesto na aba de remessa',
  verifiqueRepetirRenegociar: 'Verifique os campos repetir e/ou renegociar',
  documentoFiscalUsuarioaGrow: 'Usuário aGrow não faz transmissão de documento eletrônico, verifique o console e veja o conteúdo do json em caso de NF-e ou veja o arquivo que foi realizado o download em caso de SAT',
  versao: 'Versão',
  versaoData: '22/01/2025 às 14:50',
  versaoSistema: 'v578',
  viaCliente: 'Via Cliente',
  viaEmpresa: 'Via Empresa',
  vinculaOutroParceiro: 'Vincula outro cliente/fornecedor',
  cartaoDebitoSomenteUmaTaxa: 'Cartão de débito permite uma única taxa',
  vinculado: 'Vinculado',
  vincular: 'Vincular',
  vincularCom: 'Vincular com',
  vincularParcelas: 'Vincular parcelas',
  vinculo: 'Vinculo',
  vinculoCompendio: 'Vinculo Compêndio',
  visita: 'Visita',
  visualizar: 'Visualizar',
  visualizarComissao: 'Visualizar comissão',
  visualizarDanfe: 'Visualizar DANFe',
  visualizarFinanceiro: 'Visualizar financeiro',
  visualizarHistorico: 'Visualizar histórico',
  visualizarHistoricoLimiteCredito: 'Visualizar histórico limite de crédito',
  visualizarLancamentos: 'Visualizar lançamentos',
  visualizarLimite: 'Visualizar limite',
  visualizarMovimentacao: 'Visualizar movimentação',
  visualizarMovimentacaoOrigem: 'Visualizar movimentação de origem',
  visualizarParceiro: 'Visualizar parceiro',
  visualizarPlanoContaPadrao: 'Visualizar plano de contas padrão',
  visualizarProduto: 'Visualizar produto',
  visualizarResumo: 'Visualizar resumo',
  visualizarTodosAberto: 'Visualizar todos em aberto',
  viuvo: 'Viúvo',
  visualizarJsonBoleto: 'Visualizar JSON boleto',
  vizualizarHistoricoCredito: 'Visualizar histórico de crédito',
  vizualizarProdutosTotalizados: 'Visualizar produtos totalizados',
  vl: 'VL',
  vlr: 'VLR',
  vmAnt: 'Vm. ant',
  vmAtual: 'Vm. atual',
  voceEstaAqui: 'Você está aqui',
  voltar: 'Voltar',
  voltarPagina: 'Voltar pagina',
  voltarParaPesquisa: 'Voltar para pesquisa',
  voltarRegistro: 'Voltar registro',
  volume: 'Volume',
  volumeCalda: 'Volume Calda',
  volumeCaldaAte: 'Volume calda (Até)',
  volumeCaldaDe: 'Volume calda (De)',
  whatsapp: 'Whatsapp',
  xcb: 'XCB',
  xml: 'XML',
  dicaLoteSemProduto: 'Facilita no momento da entrada da nota fiscal',
  certezaLoteSemProduto: 'Se você vincular este lote a um produto, irá facilitar no momento da entrada da nota fiscal. Tem certeza que deseja continuar sem vincular um produto?',
  dicaParcelamemtoDiaFixo: 'Joga as parcelas de cada mês sempre para o dia definido aqui (exceto domingo)',
  dicaParcelamemtoLivre: 'O vendedor escolhe as datas de vencimento para cada parcela',
  dicaParcelamemtoMensal: 'Joga as parcelas para o último dia do mês (exceto domingo)',
  dicaParcelamemtoPrazoFixo: 'Joga os dias aqui determinado como prazo entre cada parcela (exceto domingo)',
  dicaParcelamemtoQuinzenal: 'Joga as parcelas para o dia 15 ou dia 30 de cada mês (exceto domingo)',
  dicaParcelamemtoSemanal: 'Joga as parcelas para as sextas-feiras de cada semana',
  dicaParcelamemtoSomenteAVista: 'Só permite escolher negociação com mobilidade a vista',
  zeroIgualDataNegociacaoComoReferencia: '0 = Será utilizado a data da negociação como referência',
  zoomAumentar: '[navegador] Aumentar zoom',
  zoomDiminuir: '[navegador] Diminuir zoom',
  somenteUsuarioAgrow: 'somente usuário aGrow',
  dicaRenegociarPrimeiro: 'Altera o financeiro atual para os valores aqui apresentados, mantendo o valor pago e juros calculados',
  dicaRenegociarManterPrimeiro: 'Não altera o financeiro atual e atualiza somente o campo parcela',
  dicaRenegociarDemais: 'Cria um novo financeiro',
  ignoraSequenciaParcela: 'Ignora a sequência atual da parcela e gera nova sequência',
  impossivelChegarValorDesejaAutomaticamente: 'Impossível chegar exatamente no valor desejado (_) de forma automática',
  zerado: 'zerado',
  webServiceCepIndisponivel: 'O web service do busca CEP está temporariamente indisponível, prossiga com o cadastro do endereço manualmente ou tente novamente mais tarde',
  bloqueiaSemInscricaoAjuda: 'Bloqueia a emissão de NF-e para produtos deste grupo onde o cliente não tem inscrição de produtor',
  exigeInscricao: 'Exige inscrição',
  valorPagoMaiorValorLiberado: 'Valor pago maior que o valor liberado, mediante aprovação',
  baseNaoCalculada: 'Base de cálculo não calculada, pois a alíquota de ICMS para o estado _ não foi definida no cadastro da loja',
  financeiroBloqueado: 'Financeiro bloqueado',
  financeiroParcialmenteBloqueado: 'Financeiro parcialmente bloqueado',
  financeiroParcialmenteBloqueadoDetalhe: 'Financeiro parcialmente bloqueado, movimentação no valor de _ e valor do financeiro apenas _',
};

export default bibDialogo;
