import { plainToClass } from 'class-transformer';
import bibDialogo from 'src/app/biblioteca/bibDialogo';
import bibPropriedade from 'src/app/biblioteca/bibPropriedade';
import bibServico from 'src/app/biblioteca/bibServico';
import { Criterio } from 'src/app/modelo/criterio';
import { Filtro } from 'src/app/modelo/filtro';
import { FiltroCombo } from 'src/app/modelo/filtroCombo';

const filtroComboSituacaoNFe: FiltroCombo[] = plainToClass(FiltroCombo, [
  { nome: bibDialogo.nfeTransmitida, id: 'fin.numero_fiscal is not null' },
  { nome: bibDialogo.aguardandoNfe, id: 'fin.numero_fiscal is null' },
]);

const filtros: Filtro[] = plainToClass(Filtro, [
  { rotulo: bibDialogo.dataBaixaDe, nome: 'DATA_BAIXA_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataBaixaAte, nome: 'DATA_BAIXA_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataNegociacaoDe, nome: 'NEGOCIACAO_DE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.dataNegociacaoAte, nome: 'NEGOCIACAO_ATE', coluna: 6, tipo: bibPropriedade.filtro.data },
  { rotulo: bibDialogo.cliente, nome: 'ID_PARCEIRO', coluna: 12, servicoWeb: bibServico.cliente, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.vendedor, nome: 'ID_COLABORADOR', coluna: 12, servicoWeb: bibServico.colaborador, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.especie + ' (' + bibDialogo.tipoTitulo + ')', nome: 'IDS_ESPECIE', coluna: 6, servicoWeb: bibServico.especie, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.relacionamento, nome: 'ID_RELACIONAMENTO', coluna: 6, servicoWeb: bibServico.relacionamento, tipo: bibPropriedade.filtro.busca },
  { id: 'contaContabil', rotulo: bibDialogo.contaContabil, nome: 'ID_CONTA_CONTABIL', coluna: 6, servicoWeb: bibServico.contaContabil, tipo: bibPropriedade.filtro.busca, criterios: [new Criterio('SEM_ID_ZERO', 'S')] },
  { id: 'natureza', rotulo: bibDialogo.natureza, nome: 'IDS_NATUREZA', coluna: 6, servicoWeb: bibServico.natureza, tipo: bibPropriedade.filtro.checklista },
  { id: 'centroResultado', rotulo: bibDialogo.centroResultado, nome: 'IDS_CENTRO_RESULTADO', coluna: 6, servicoWeb: bibServico.centroResultado, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.familia, nome: 'ID_FAMILIA', coluna: 6, servicoWeb: bibServico.familia, tipo: bibPropriedade.filtro.busca },
  { rotulo: bibDialogo.contaBancaria, nome: 'IDS_CONTA_BANCARIA', coluna: 6, servicoWeb: bibServico.conta, tipo: bibPropriedade.filtro.checklista, criterios: [new Criterio('ID_USUARIO_CONTA', 'S'), new Criterio('ATIVO', 'S')] },
  { rotulo: bibDialogo.loja, nome: 'IDS_LOJA', coluna: 6, servicoWeb: bibServico.loja, tipo: bibPropriedade.filtro.checklista },
  { rotulo: bibDialogo.nfe, nome: 'NFE', coluna: 6, tipo: bibPropriedade.filtro.combo, lista: filtroComboSituacaoNFe, obrigatorio: true },
]);
export default filtros;
