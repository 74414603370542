import { plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import bibDialogo from '../biblioteca/bibDialogo';
import bibServico from '../biblioteca/bibServico';
import { CfopEmpresa } from '../modelo/cfopEmpresa';
import { ClassificacaoFiscal } from '../modelo/classificacaoFiscal';
import { Criterio } from '../modelo/criterio';
import { Cst } from '../modelo/cst';
import { GrupoIcms } from '../modelo/grupoIcms';
import { GrupoIcmsSt } from '../modelo/grupoIcmsSt';
import { ImpostoDevolucao } from '../modelo/impostoDevolucao';
import { LojaEstado } from '../modelo/lojaEstado';
import { Movimentacao } from '../modelo/movimentacao';
import { MovimentacaoParcela } from '../modelo/movimentacaoParcela';
import { MovimentacaoProduto } from '../modelo/movimentacaoProduto';
import { Negociacao } from '../modelo/negociacao';
import { ParceiroDestino } from '../modelo/parceiroDestino';
import { ParceiroEndereco } from '../modelo/parceiroEndereco';
import { Produto } from '../modelo/produto';
import { ProdutoIcms } from '../modelo/produtoIcms';
import { ProdutoIcmsEspecifico } from '../modelo/produtoIcmsEspecifico';
import { ProdutoIcmsSt } from '../modelo/produtoIcmsSt';
import { ProdutoPisCofins } from '../modelo/produtoPisCofins';
import { Transporte } from '../modelo/transporte';
import { MovimentacaoControlador } from '../pagina/movimentacao/frm/movimentacaoControlador';
import { ComunicacaoService } from '../servico/comunicacao.service';
import { Util } from './util';
import { UtilSessao } from './util.sessao';

export class UtilMovimentacaoImpostos {
  public bibDialogo = bibDialogo;
  public bibServico = bibServico;
  public bloqueiaAlteracao: boolean;
  public cfopsEmpresa: CfopEmpresa[];
  public classificacaoBloqueadoEstoque: string;
  public classificacaoBloqueadoEstoqueNfe: string;
  public classificacaoBloqueadoFinanceiro: string;
  public devolucao: boolean;
  public financeiro: string;
  public icmsCsts: any[] = [];
  public idLoja: number;
  public idOperacao: number;
  public idParceiro: number;
  public idParceiroDestino: number;
  public movimentacaoProdutoValorUnitarioFinal: number;
  public naturezaOperacaoFinalidade: number;
  public parceiroDestino: ParceiroDestino;
  public parceiroEndereco: ParceiroEndereco;
  public parceiroTipoPessoa: string;
  public saida: boolean;
  public util: Util = new Util();
  public utilSessao: UtilSessao = new UtilSessao();
  private parceiroInscricao: string;
  private movimentacao: Movimentacao;

  constructor(public comunicacaoService: ComunicacaoService, public casasDecimais) { }

  listarImpostos(movimentacaoProduto: MovimentacaoProduto, saida: boolean, parceiroDestino: ParceiroDestino, parceiroEndereco: ParceiroEndereco, naturezaOperacaoFinalidade: number, devolucao: boolean, bloqueiaAlteracao: boolean, financeiro: string, idLoja: number, classificacaoBloqueadoEstoque: string, classificacaoBloqueadoEstoqueNfe: string, classificacaoBloqueadoFinanceiro: string, idOperacao: number, idParceiro: number, idParceiroDestino: number, parceiroTipoPessoa: string, parceiroInscricao: string, atualizaBusca: boolean = false, movimentacaoNegociacao: Negociacao, mediaDiaPrazo: number, movimentacaoParcelas: MovimentacaoParcela[], valorTotal: number, movimentacaoControlador: MovimentacaoControlador, entradaServico: boolean, movimentacao: Movimentacao): MovimentacaoProduto {
    this.configurar(saida, parceiroDestino, parceiroEndereco, naturezaOperacaoFinalidade, devolucao, bloqueiaAlteracao, financeiro, idLoja, classificacaoBloqueadoEstoque, classificacaoBloqueadoEstoqueNfe, classificacaoBloqueadoFinanceiro, idOperacao, idParceiro, idParceiroDestino, parceiroTipoPessoa, parceiroInscricao, atualizaBusca, movimentacao);
    return this.listarInformacoesFiscais(movimentacaoProduto, atualizaBusca, movimentacaoNegociacao, mediaDiaPrazo, movimentacaoParcelas, valorTotal, saida, naturezaOperacaoFinalidade, movimentacaoControlador, entradaServico);
  }

  configurar(saida: boolean, parceiroDestino: ParceiroDestino, parceiroEndereco: ParceiroEndereco, naturezaOperacaoFinalidade: number, devolucao: boolean, bloqueiaAlteracao: boolean, financeiro: string, idLoja: number, classificacaoBloqueadoEstoque: string, classificacaoBloqueadoEstoqueNfe: string, classificacaoBloqueadoFinanceiro: string, idOperacao: number, idParceiro: number, idParceiroDestino: number, parceiroTipoPessoa: string, parceiroInscricao: string, atualizaBusca: boolean = false, movimentacao: Movimentacao): void {
    this.saida = saida;
    this.idLoja = idLoja;
    this.devolucao = devolucao;
    this.idParceiro = idParceiro;
    this.idOperacao = idOperacao;
    this.financeiro = financeiro;
    this.parceiroDestino = parceiroDestino;
    this.parceiroEndereco = parceiroEndereco;
    this.bloqueiaAlteracao = bloqueiaAlteracao;
    this.idParceiroDestino = idParceiroDestino;
    this.parceiroTipoPessoa = parceiroTipoPessoa;
    this.parceiroInscricao = parceiroInscricao;
    this.naturezaOperacaoFinalidade = naturezaOperacaoFinalidade;
    this.classificacaoBloqueadoEstoque = classificacaoBloqueadoEstoque;
    this.classificacaoBloqueadoEstoqueNfe = classificacaoBloqueadoEstoqueNfe;
    this.classificacaoBloqueadoFinanceiro = classificacaoBloqueadoFinanceiro;
    this.movimentacao = movimentacao;
  }

  listarInformacoesFiscais(movimentacaoProduto: MovimentacaoProduto, atualizaBusca: boolean, movimentacaoNegociacao: Negociacao, mediaDiaPrazo: number, movimentacaoParcelas: MovimentacaoParcela[], valorTotal: number, saida: boolean, naturezaOperacaoFinalidade: number, movimentacaoControlador: MovimentacaoControlador, entradaServico: boolean): MovimentacaoProduto {
    if (movimentacaoProduto) {
      this.comunicacaoService.listar(new Transporte([new Criterio('ID', movimentacaoProduto.idProduto), new Criterio('ATIVO', 'S')]), this.bibServico.produto).subscribe((res) => {
        this.setMovimentacaoProdutoValorUnitatioFinal(movimentacaoProduto);
        const produto: Produto = plainToClass(Produto, res[0]) as any;
        this.comunicacaoService.listar(new Transporte(this.criteriosCstIcms(movimentacaoProduto)), this.bibServico.cstIcms).subscribe((res) => {
          this.icmsCsts = plainToClass(Cst, res) as any;
          this.comunicacaoService.listar(new Transporte(this.criteriosCfopEmpresa(movimentacaoProduto)), this.bibServico.cfopEmpresa).subscribe((res) => {
            this.cfopsEmpresa = plainToClass(CfopEmpresa, res) as any;
            this.listarProdutoPisCofins(this.getCriterioPisCofins(movimentacaoProduto)).subscribe((res) => {
              const produtoPisCofins: ProdutoPisCofins = plainToClass(ProdutoPisCofins, res[0]) as any;
              this.listarProdutoIcms(this.getCriterioIcms(movimentacaoProduto)).subscribe((res) => {
                const produtoIcms: ProdutoIcms = plainToClass(ProdutoIcms, res[0]) as any;
                movimentacaoProduto.usaIcmsDesonerado = produtoIcms && produtoIcms.usaIcmsDesonerado ? produtoIcms.usaIcmsDesonerado : 'N';
                this.comunicacaoService.listar(new Transporte(this.getCriterioIcms(movimentacaoProduto)), this.bibServico.grupoIcms).subscribe((res) => {
                  const grupoIcms: GrupoIcms = plainToClass(GrupoIcms, res[0]) as any;
                  movimentacaoProduto.usaIcmsDesonerado = movimentacaoProduto.usaIcmsDesonerado == 'N' && !produtoIcms ? (grupoIcms && grupoIcms.usaIcmsDesonerado ? grupoIcms.usaIcmsDesonerado : 'N') : movimentacaoProduto.usaIcmsDesonerado;
                  this.listarLojaEstadoProdutoGrupo(movimentacaoProduto, produtoIcms, grupoIcms, naturezaOperacaoFinalidade).subscribe((res) => {
                    const lojaEstado: LojaEstado = plainToClass(LojaEstado, res[0]) as any;
                    this.listarProdutoIcmsSt(movimentacaoProduto).subscribe((res) => {
                      const produtoIcmsSt: ProdutoIcmsSt = plainToClass(ProdutoIcmsSt, res[0]) as any;
                      this.listarGrupoIcmsSt(movimentacaoProduto).subscribe((res) => {
                        const grupoIcmsSt: GrupoIcmsSt = plainToClass(GrupoIcmsSt, res[0]) as any;
                        this.comunicacaoService.listar(new Transporte(this.montarCriterioClassificaoFiscal(produto, produtoIcms, grupoIcms)), this.bibServico.classificacaoFiscal).subscribe((res) => {
                          const classificacaoFiscal = plainToClass(ClassificacaoFiscal, res)[0] as any;
                          this.listarImpostoDevolucao(movimentacaoProduto, naturezaOperacaoFinalidade).subscribe((res) => {
                            let impostoDevolucoes: ImpostoDevolucao[] = plainToClass(ImpostoDevolucao, res) as any;
                            this.listarProdutoAliquotaIcmsEspecifica(this.getCriterioIcms(movimentacaoProduto)).subscribe((res) => {
                              const produtoAliquotaIcmsEspecifica: ProdutoIcmsEspecifico = plainToClass(ProdutoIcmsEspecifico, res[0]) as any;
                              this.setBaseCalculoIcms(movimentacaoProduto, classificacaoFiscal, impostoDevolucoes, naturezaOperacaoFinalidade);
                              this.setLojaIcmsAliquota(movimentacaoProduto, lojaEstado, impostoDevolucoes, naturezaOperacaoFinalidade, produtoAliquotaIcmsEspecifica);
                              this.setIdCfop(movimentacaoProduto, produto, lojaEstado, atualizaBusca, impostoDevolucoes, naturezaOperacaoFinalidade);
                              this.setProdutoGrupoIcms(movimentacaoProduto, produtoIcms, grupoIcms, impostoDevolucoes, naturezaOperacaoFinalidade);
                              this.setCodigoBeneficioFiscal(movimentacaoProduto, produtoIcms, grupoIcms, classificacaoFiscal);
                              this.setIdCstCofins(movimentacaoProduto, produtoPisCofins, atualizaBusca);
                              this.setIdCstPis(movimentacaoProduto, produtoPisCofins, atualizaBusca);
                              this.setIdCstIpi(movimentacaoProduto, produto, atualizaBusca);
                              this.setProdutoGrupoIcmsSt(movimentacaoProduto, produtoIcmsSt, grupoIcmsSt);
                              this.setIcmsDesonerado(movimentacaoProduto, produto, impostoDevolucoes, naturezaOperacaoFinalidade);
                              const descontoValor: number = movimentacaoProduto.descontoValor;
                              const valorTotalAnterior: number = movimentacaoProduto.getQuantidade() * movimentacaoProduto.getValorUnitario();
                              this.atualizarValorUnitario(movimentacaoProduto, movimentacaoNegociacao, mediaDiaPrazo, movimentacaoParcelas, valorTotal, saida, naturezaOperacaoFinalidade);
                              movimentacaoProduto.setDescontoValor(descontoValor, this.movimentacao.negociacao, this.movimentacao.movimentacaoParcelas, this.movimentacao.valorTotal);
                              this.atualizarBaseMovimentacaoProduto(movimentacaoProduto, naturezaOperacaoFinalidade, movimentacaoControlador.saida);
                              this.corrigirIcmsDesonerado(movimentacaoProduto, valorTotalAnterior, movimentacaoControlador);
                              if (movimentacaoControlador) {
                                movimentacaoControlador.totalizar(false);
                              }
                              return this.ehPreenchidoImpostos(movimentacaoProduto, entradaServico);
                            });
                          });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
      return movimentacaoProduto;
    }
  }

  atualizarValorUnitario(movimentacaoProduto: MovimentacaoProduto, movimentacaoNegociacao: Negociacao, mediaDiaPrazo: number, movimentacaoParcelas: MovimentacaoParcela[], valorTotal: number, saida: boolean, naturezaOperacao: number = 0) {
    movimentacaoProduto.setValorUnitario(movimentacaoProduto.getValorUnitario(), movimentacaoNegociacao, mediaDiaPrazo, movimentacaoParcelas, valorTotal, saida, naturezaOperacao, this.casasDecimais);
  }

  montarCriterioClassificaoFiscal(produto: Produto, produtoIcms: ProdutoIcms, grupoIcms: GrupoIcms): Criterio[] {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    let cfopEmpresa: CfopEmpresa = this.cfopsEmpresa.find((cfopEmpresa) => cfopEmpresa.id == (this.saida ? produto.idCfopSaida : produto.idCfopEntrada));
    let idClassificacaoFiscal: number = cfopEmpresa && cfopEmpresa.idClassificacaoFiscal ? cfopEmpresa.idClassificacaoFiscal : produtoIcms ? produtoIcms.idClassificacaoFiscal : grupoIcms ? grupoIcms.idClassificacaoFiscal : 0;
    criterios.push(new Criterio('ID', idClassificacaoFiscal));
    return criterios;
  }

  montarCriterioMovimentacaoProdutoOrigem(movimentacaoProduto: MovimentacaoProduto): Criterio[] {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID', movimentacaoProduto.idMovimentacaoProOrigem));
    return criterios;
  }

  montarCriterioMovimentacaoProdutoId(movimentacaoProduto: MovimentacaoProduto): Criterio[] {
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ID_MOVIMENTACAO_PRO_ORIGENS', movimentacaoProduto.id));
    return criterios;
  }

  setMovimentacaoProdutoValorUnitatioFinal(movimentacaoProduto: MovimentacaoProduto): void {
    if (movimentacaoProduto.valorTotal > 0) {
      this.movimentacaoProdutoValorUnitarioFinal = movimentacaoProduto.valorTotal / movimentacaoProduto.getQuantidade();
    }
  }

  criteriosCstIcms(movimentacaoProduto: MovimentacaoProduto): Criterio[] {
    const simplesNacional: string = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento == 1 ? 'S' : 'N';
    let criterios: Criterio[] = [new Criterio('ATIVO', 'S')];
    if (this.saida) {
      criterios.push(new Criterio('SIMPLES_NACIONAL', simplesNacional));
    }
    if (movimentacaoProduto.idCstIcms) {
      criterios.push(new Criterio('ID_SELECIONADO', movimentacaoProduto.idCstIcms));
    }
    return criterios;
  }

  criteriosCfopEmpresa(movimentacaoProduto: MovimentacaoProduto): Criterio[] {
    const cfopOperacao: string = this.verificarOperacaoCfop();
    let criterios: Criterio[] = [];
    if (cfopOperacao || movimentacaoProduto.idCfopEmpresa) {
      criterios.push(new Criterio('ATIVO', 'S'));
      const DEVOLUCAO: number = 3;
      if (this.naturezaOperacaoFinalidade != DEVOLUCAO) {
        criterios.push(new Criterio('DEVOLUCAO', 'N'));
        if (this.naturezaOperacaoFinalidade != 2) {
          criterios.push(new Criterio('ENTRADA_SAIDA', this.saida ? 'S' : 'E'));
        } else {
          criterios.push(new Criterio('TRANSFERENCIA', 'S'));
        }
      }
      if (cfopOperacao) {
        criterios.push(new Criterio('OPERACAO', cfopOperacao));
      }
    }
    return criterios;
  }

  verificarOperacaoCfop(): string {
    const loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
    if (this.parceiroDestino) {
      return this.parceiroDestino.idPais != loja.idPais ? 'I' : this.parceiroDestino.idEstado == loja.idEstado ? 'E' : 'F';
    } else if (this.parceiroEndereco) {
      return this.parceiroEndereco.idPais != loja.idPais ? 'I' : this.parceiroEndereco.idEstado == loja.idEstado ? 'E' : 'F';
    }
  }

  listarProdutoIcms(criterios: Criterio[]): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoIcms);
  }

  listarProdutoPisCofins(criterios: Criterio[]): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoPisCofins);
  }

  listarProdutoAliquotaIcmsEspecifica(criterios: Criterio[]): Observable<any> {
    return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoIcmsEspecifico);
  }

  getCriterioIcms(movimentacaoProduto: MovimentacaoProduto): Criterio[] {
    const criterios: Criterio[] = [new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)];
    const idConsumidorFinal: number = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja) && this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).idConsumidorFinal ? this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).idConsumidorFinal : this.utilSessao.getEmpresa().idConsumidorFinal;
    const idCidadeLoja: number = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).idCidade;
    if (this.idParceiroDestino != null && this.idParceiroDestino != idConsumidorFinal) {
      criterios.push(new Criterio('ID_PARCEIRO_DESTINO', this.idParceiroDestino));
    } else if (this.idParceiro != null && this.idParceiro != idConsumidorFinal) {
      criterios.push(new Criterio('ID_PARCEIRO', this.idParceiro));
    } else if (this.idParceiro != null && this.idParceiro == idConsumidorFinal) {
      criterios.push(new Criterio('ID_CONSUMIDOR_FINAL', idCidadeLoja));
    }
    const enquadramento: number = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja) && this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento;
    criterios.push(new Criterio('ENQUADRAMENTO', enquadramento));
    criterios.push(new Criterio('ESTADO_LOJA', this.idLoja));
    return criterios;
  }

  getCriterioPisCofins(movimentacaoProduto: MovimentacaoProduto): Criterio[] {
    const criterios: Criterio[] = [new Criterio('ID_PRODUTO', movimentacaoProduto.idProduto)];
    const enquadramento: number = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento;
    criterios.push(new Criterio('ENQUADRAMENTO', enquadramento));
    return criterios;
  }


  setLojaIcmsAliquota(movimentacaoProduto: MovimentacaoProduto, lojaEstado: LojaEstado, impostoDevolucoes: any[], naturezaOperacaoFinalidade: number, produtoAliquotaIcmsEspecifica: ProdutoIcmsEspecifico): void {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO && impostoDevolucoes.length > 0) {
      movimentacaoProduto.setIcmsAliquota(impostoDevolucoes.length == 1 ? impostoDevolucoes[0].icmsAliquota : 0.0);
    } else {
      if (lojaEstado && movimentacaoProduto.importacaoXml != 'S') {
        if (produtoAliquotaIcmsEspecifica != null) {
          movimentacaoProduto.setIcmsAliquota(this.parceiroTipoPessoa == 'F' ? produtoAliquotaIcmsEspecifica.aliquotaFisica : produtoAliquotaIcmsEspecifica.aliquotaJuridica);
        } else {
          movimentacaoProduto.setIcmsAliquota(this.parceiroTipoPessoa == 'F' ? lojaEstado.aliquotaFisica : lojaEstado.aliquotaJuridica);
        }
      }
    }
  }

  setBaseCalculoIcms(movimentacaoProduto: MovimentacaoProduto, classificacaoFiscal: ClassificacaoFiscal, impostoDevolucoes: ImpostoDevolucao[], naturezaOperacaoFinalidade: number): void {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO && impostoDevolucoes.length > 0) {
      movimentacaoProduto.idCstIcms = impostoDevolucoes.length == 1 ? impostoDevolucoes[0].idCstIcms : null;
      movimentacaoProduto.icmsBasePercentual = impostoDevolucoes.length == 1 ? impostoDevolucoes[0].icmsBasePercentual : 0.0;
      if (movimentacaoProduto.icmsBasePercentual != 0) {
        movimentacaoProduto.subtrairDesoneradoBase = true;
      }
    } else {
      if (classificacaoFiscal) {
        movimentacaoProduto.idCstIcms = this.verificarIsento() && classificacaoFiscal.idCstIcmsSemInscricao ? classificacaoFiscal.idCstIcmsSemInscricao : classificacaoFiscal.idCstIcms;
        movimentacaoProduto.icmsBasePercentual = classificacaoFiscal.base;
      }
    }
  }

  verificarIsento(): boolean {
    return this.parceiroInscricao == null || this.parceiroInscricao.toLowerCase() == 'isento' || this.parceiroInscricao.toLowerCase() == '';
  }

  setCodigoBeneficioFiscal(movimentacaoProduto: MovimentacaoProduto, produtoIcms: ProdutoIcms, grupoIcms: GrupoIcms, classificacaoFiscalCfop: ClassificacaoFiscal): void {
    let classificacaoFiscal: ClassificacaoFiscal = null;
    let criterios: Criterio[] = [];
    criterios.push(new Criterio('ATIVO', 'S'));
    criterios.push(new Criterio('ID', produtoIcms && produtoIcms.idClassificacaoFiscal ? produtoIcms.idClassificacaoFiscal : grupoIcms && grupoIcms.idClassificacaoFiscal ? grupoIcms.idClassificacaoFiscal : classificacaoFiscalCfop && classificacaoFiscalCfop.id ? classificacaoFiscalCfop.id : 0));
    this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.classificacaoFiscal).subscribe((res) => {
      classificacaoFiscal = plainToClass(ClassificacaoFiscal, res)[0] as any;
      movimentacaoProduto.codigoBeneficioFiscal = classificacaoFiscal?.codigoBeneficioFiscal;
    });
  }

  setProdutoGrupoIcms(movimentacaoProduto: MovimentacaoProduto, produtoIcms: ProdutoIcms, grupoIcms: GrupoIcms, impostoDevolucoes: any[], naturezaOperacaoFinalidade: number): void {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO && impostoDevolucoes.length == 1) {
      this.setIcms(movimentacaoProduto, impostoDevolucoes[0].idCstIcms, impostoDevolucoes[0].icmsBasePercentual);
    } else {
      if (movimentacaoProduto.importacaoXml != 'S') {
        if (produtoIcms) {
          let produtoIcmsSemIncricao: boolean = this.verificarIsento() && produtoIcms.classificacaoFiscalIdCstIcmsSemInscricao != null;
          this.setIcms(movimentacaoProduto, produtoIcmsSemIncricao ? produtoIcms.classificacaoFiscalIdCstIcmsSemInscricao : produtoIcms.classificacaoFiscalIdCstIcms, produtoIcmsSemIncricao ? 100 : produtoIcms.classificacaoFiscalBase);
        } else if (grupoIcms) {
          let grupoIcmsSemIncricao: boolean = this.verificarIsento() && grupoIcms.classificacaoFiscalIdCstIcmsSemInscricao != null;
          this.setIcms(movimentacaoProduto, grupoIcmsSemIncricao ? grupoIcms.classificacaoFiscalIdCstIcmsSemInscricao : grupoIcms.classificacaoFiscalIdCstIcms, grupoIcmsSemIncricao ? 100 : grupoIcms.classificacaoFiscalBase);
        }
      }
    }
  }

  setIcms(movimentacaoProduto: MovimentacaoProduto, idCstIcms: number, baseCalculo: number) {
    if (idCstIcms != null) {
      movimentacaoProduto.idCstIcms = idCstIcms;
    }
    if (baseCalculo != null) {
      movimentacaoProduto.icmsBasePercentual = baseCalculo;
    }
    if (idCstIcms != null && baseCalculo != null) {
      movimentacaoProduto.setIcmsBase(movimentacaoProduto.getIcmsAliquota() > 0 ? this.util.arredondar((movimentacaoProduto.valorTotal / 100) * baseCalculo) : 0);
    }
  }

  setProdutoGrupoIcmsSt(movimentacaoProduto: MovimentacaoProduto, produtoIcmsSt: ProdutoIcmsSt, grupoIcmsSt: GrupoIcmsSt): void {
    if (produtoIcmsSt) {
      const icmsStBase: number = (this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() * produtoIcmsSt.mva) / 100 + this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade();
      movimentacaoProduto.setIcmsStBase(this.util.arredondar(icmsStBase));
      const icmsStValor: number = (icmsStBase * produtoIcmsSt.aliquota) / 100 - (this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() * movimentacaoProduto.getIcmsAliquota()) / 100;
      movimentacaoProduto.icmsStMva = produtoIcmsSt.mva;
      movimentacaoProduto.icmsStAliquota = produtoIcmsSt.aliquota;
      movimentacaoProduto.setIcmsStValor(this.util.arredondar(icmsStValor));
    } else if (grupoIcmsSt) {
      const icmsStBase: number = (this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() * grupoIcmsSt.mva) / 100 + this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade();
      movimentacaoProduto.setIcmsStBase(this.util.arredondar(icmsStBase));
      const icmsStValor: number = (icmsStBase * grupoIcmsSt.aliquota) / 100 - (this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() * movimentacaoProduto.getIcmsAliquota()) / 100;
      movimentacaoProduto.icmsStMva = grupoIcmsSt.mva;
      movimentacaoProduto.icmsStAliquota = grupoIcmsSt.aliquota;
      movimentacaoProduto.setIcmsStValor(this.util.arredondar(icmsStValor));
    }
  }

  setIdCstCofins(movimentacaoProduto: MovimentacaoProduto, produtoPisCofins: ProdutoPisCofins, atualizaBusca: boolean): void {
    if (!movimentacaoProduto.idCstCofins || atualizaBusca == true) {
      let idCstCofins: number;
      if (this.devolucao) {
        idCstCofins = this.saida ? produtoPisCofins?.idCstCofinsEntrada : produtoPisCofins?.idCstCofinsSaida;
        movimentacaoProduto.exigeAliquotaCofins = this.saida ? produtoPisCofins?.exigeAliquotaCofinsEntrada : produtoPisCofins?.exigeAliquotaCofinsSaida;
      } else {
        idCstCofins = this.saida ? produtoPisCofins?.idCstCofinsSaida : produtoPisCofins?.idCstCofinsEntrada;
        movimentacaoProduto.exigeAliquotaCofins = this.saida ? produtoPisCofins?.exigeAliquotaCofinsSaida : produtoPisCofins?.exigeAliquotaCofinsEntrada;
      }
      if (idCstCofins) {
        movimentacaoProduto.idCstCofins = idCstCofins;
        this.iniciarCofins(movimentacaoProduto, this.saida ? produtoPisCofins?.aliquotaCofinsSaida : produtoPisCofins?.aliquotaCofinsEntrada);
      }
    }
  }

  iniciarCofins(movimentacaoProduto: MovimentacaoProduto, aliquota: number) {
    if (this.naturezaOperacaoFinalidade != 2) {
      if (movimentacaoProduto.idCstCofins) {
        movimentacaoProduto.setCofinsBase(this.util.arredondar(movimentacaoProduto.valorFinal));
        movimentacaoProduto.setCofinsAliquota(aliquota);
      }
      if ((movimentacaoProduto.getCofinsBase() == 0 || movimentacaoProduto.getCofinsAliquota() == 0) && movimentacaoProduto.exigeAliquotaCofins == 'N') {
        movimentacaoProduto.setCofinsBase(0);
        movimentacaoProduto.setCofinsAliquota(0);
      }
    } else {
      movimentacaoProduto.setCofinsBase(0);
      movimentacaoProduto.setCofinsAliquota(0);
    }
  }

  setIdCstPis(movimentacaoProduto: MovimentacaoProduto, produtoPisCofins: ProdutoPisCofins, atualizaBusca: boolean): void {
    if (!movimentacaoProduto.idCstPis || atualizaBusca == true) {
      let idCstPis: number;
      if (this.devolucao) {
        idCstPis = this.saida ? produtoPisCofins?.idCstPisEntrada : produtoPisCofins?.idCstPisSaida;
        movimentacaoProduto.exigeAliquotaPis = this.saida ? produtoPisCofins?.exigeAliquotaCofinsEntrada : produtoPisCofins?.exigeAliquotaCofinsSaida;
      } else {
        idCstPis = this.saida ? produtoPisCofins?.idCstPisSaida : produtoPisCofins?.idCstPisEntrada;
        movimentacaoProduto.exigeAliquotaPis = this.saida ? produtoPisCofins?.exigeAliquotaCofinsSaida : produtoPisCofins?.exigeAliquotaCofinsEntrada;
      }
      if (idCstPis) {
        movimentacaoProduto.idCstPis = idCstPis;
        this.iniciarPis(movimentacaoProduto, this.saida ? produtoPisCofins?.aliquotaPisSaida : produtoPisCofins?.aliquotaPisEntrada);
      }
    }
  }

  iniciarPis(movimentacaoProduto: MovimentacaoProduto, aliquota: number) {
    if (this.naturezaOperacaoFinalidade != 2) {
      if (movimentacaoProduto.idCstPis) {
        movimentacaoProduto.setPisBase(this.util.arredondar(movimentacaoProduto.valorFinal));
        movimentacaoProduto.setPisAliquota(aliquota);
      }
      if ((movimentacaoProduto.getPisBase() == 0 || movimentacaoProduto.getPisAliquota() == 0) && movimentacaoProduto.exigeAliquotaPis == 'N') {
        movimentacaoProduto.setPisBase(0);
        movimentacaoProduto.setPisAliquota(0);
      }
    } else {
      movimentacaoProduto.setPisBase(0);
      movimentacaoProduto.setPisAliquota(0);
    }
  }

  setIdCstIpi(movimentacaoProduto: MovimentacaoProduto, produto: Produto, atualizaBusca: boolean): void {
    if (!movimentacaoProduto.idCstIpi || atualizaBusca == true) {
      let idCstIpi: string;
      if (this.devolucao) {
        idCstIpi = this.saida ? produto.idIpiEntrada : produto.idIpiSaida;
      } else {
        idCstIpi = this.saida ? produto.idIpiSaida : produto.idIpiEntrada;
      }
      if (idCstIpi) {
        movimentacaoProduto.idCstIpi = idCstIpi;
        this.iniciarIpi(movimentacaoProduto, this.saida ? produto.ipiAliquotaSaida : produto.ipiAliquotaEntrada);
      }
    }
  }

  iniciarIpi(movimentacaoProduto: MovimentacaoProduto, aliquota: number) {
    if (movimentacaoProduto.importacaoXml == 'N') {
      if (movimentacaoProduto.idCstIpi) {
        movimentacaoProduto.setIpiBase(this.util.arredondar(movimentacaoProduto.valorTotal));
        movimentacaoProduto.setIpiAliquota(aliquota);
      }
      if (movimentacaoProduto.getIpiBase() == 0 || movimentacaoProduto.getIpiAliquota() == 0) {
        movimentacaoProduto.setIpiBase(0);
        movimentacaoProduto.setIpiAliquota(0);
      }
    }
  }

  setIdCfop(movimentacaoProduto: MovimentacaoProduto, produto: Produto, lojaEstado: LojaEstado, atualizaBusca: boolean, impostoDevolucoes: any[], naturezaOperacaoFinalidade: number): void {
    let dentroUf: boolean = true;
    const loja = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja);
    if (this.parceiroDestino) {
      dentroUf = this.parceiroDestino.idEstado == loja.idEstado;
    } else {
      if (this.parceiroEndereco) {
        dentroUf = this.parceiroEndereco.idEstado == loja.idEstado;
      }
    }

    let idCfopEmpresa: number;
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO) {
      if (impostoDevolucoes[0] != null && impostoDevolucoes[0].idCfopEmpresa) {
        const cfopEmpresa = this.cfopsEmpresa.find((_cfopEmpresa) => _cfopEmpresa.id == impostoDevolucoes[0].idCfopEmpresa);
        idCfopEmpresa = cfopEmpresa.idCfop;
      } else {
        const cfopEmpresa: CfopEmpresa = this.cfopsEmpresa.find((cfopEmpresa) => cfopEmpresa.id == (dentroUf == true ? produto.idCfopSaida : produto.idCfopSaidaForaUf));
        if (cfopEmpresa) {
          idCfopEmpresa = cfopEmpresa.idCfop ? cfopEmpresa.idCfop : null;
        }
      }
    } else {
      idCfopEmpresa = this.naturezaOperacaoFinalidade == 1 ? (this.saida ? (dentroUf == true ? produto.idCfopSaida : produto.idCfopSaidaForaUf) : dentroUf == true ? produto.idCfopEntrada : produto.idCfopEntradaForaUf) : dentroUf == true ? produto.idCfopTransferencia : produto.idCfopTransferenciaForaUf;
    }
    let cfopEmpresa: CfopEmpresa;
    if ((!movimentacaoProduto.idCfopEmpresa && idCfopEmpresa && !movimentacaoProduto.produtoNfe) || atualizaBusca == true) {
      if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO) {
        cfopEmpresa = this.cfopsEmpresa.find((_cfopEmpresa) => _cfopEmpresa.idCfop == idCfopEmpresa);
        if (cfopEmpresa && cfopEmpresa.idCfopDevolucao) {
          cfopEmpresa = this.cfopsEmpresa.find((_cfopEmpresa) => _cfopEmpresa.idCfop == cfopEmpresa.idCfopDevolucao);
          this.setCfopEmpresa(movimentacaoProduto, cfopEmpresa, lojaEstado, impostoDevolucoes, naturezaOperacaoFinalidade);
        } else {
          this.setCfopEmpresa(movimentacaoProduto, cfopEmpresa, lojaEstado, impostoDevolucoes, naturezaOperacaoFinalidade);
        }
        this.cfopsEmpresa.filter((_cfopEmpresa) => _cfopEmpresa.cfopDevolucao == 'S');
      } else {
        cfopEmpresa = this.cfopsEmpresa.find((cfopEmpresa) => cfopEmpresa.id == idCfopEmpresa);
        this.setCfopEmpresa(movimentacaoProduto, cfopEmpresa, lojaEstado, impostoDevolucoes, naturezaOperacaoFinalidade);
      }
    }
  }

  listarLojaEstadoProdutoGrupo(movimentacaoProduto: MovimentacaoProduto, produtoIcms: ProdutoIcms, grupoIcms: GrupoIcms, naturezaOperacaoFinalidade: number): Observable<any> {
    let icmsCstProdutoGrupo: Cst;
    if (produtoIcms) {
      icmsCstProdutoGrupo = this.icmsCsts.find((icmsCst) => icmsCst.id == (this.verificarIsento() && produtoIcms.classificacaoFiscalIdCstIcmsSemInscricao ? produtoIcms.classificacaoFiscalIdCstIcmsSemInscricao : produtoIcms.classificacaoFiscalIdCstIcms));
    } else if (grupoIcms) {
      icmsCstProdutoGrupo = this.icmsCsts.find((icmsCst) => icmsCst.id == (this.verificarIsento() && grupoIcms.classificacaoFiscalIdCstIcmsSemInscricao ? grupoIcms.classificacaoFiscalIdCstIcmsSemInscricao : grupoIcms.classificacaoFiscalIdCstIcms));
    }
    return this.listaLojaEstado(movimentacaoProduto, icmsCstProdutoGrupo && icmsCstProdutoGrupo.cst ? icmsCstProdutoGrupo.cst : null, naturezaOperacaoFinalidade);
  }

  listaLojaEstado(movimentacaoProduto: MovimentacaoProduto, cst: string, naturezaOperacaoFinalidade: number): Observable<any> {
    movimentacaoProduto.destacarSemLojaEstado = false;
    if (!this.zerarAliquotaIcms(movimentacaoProduto, cst) || cst == null) {
      let criterios: Criterio[] = this.montarCriterioLojaEstado();
      movimentacaoProduto.destacarSemLojaEstado = true;
      return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.lojaEstado);
    }
    return of({});
  }

  zerarAliquotaIcms(movimentacaoProduto: MovimentacaoProduto, cst: string = null): boolean {
    const simplesNacional: string = this.utilSessao.getLojas().find((loja) => loja.id == this.idLoja).enquadramento == 1 ? 'S' : 'N';
    const cstFormatado: string = cst ? cst.substring(1, cst.length) : null;
    if (simplesNacional == 'S') {
      return this.ehEnquadramentoSimplesNacionalIsento(movimentacaoProduto, cstFormatado);
    }
    return this.ehEnquadramentoIsento(movimentacaoProduto, cstFormatado);
  }

  ehEnquadramentoSimplesNacionalIsento(movimentacaoProduto: MovimentacaoProduto, cst: string = null): boolean {
    if (cst && cst != '900') {
      movimentacaoProduto.setIcmsAliquota(0);
      return true;
    }
    return false;
  }

  ehEnquadramentoIsento(movimentacaoProduto: MovimentacaoProduto, cst: string = null): boolean {
    if (cst && ['30', '40', '41', '50', '60'].includes(cst) && movimentacaoProduto.usaIcmsDesonerado == 'N') {
      movimentacaoProduto.setIcmsAliquota(0);
      return true;
    }
    return false;
  }

  montarCriterioLojaEstado(): Criterio[] {
    const criterios: Criterio[] = [new Criterio('ID_LOJA', this.idLoja)];
    if (this.idParceiroDestino) {
      criterios.push(new Criterio('ID_PARCEIRO_DESTINO', this.idParceiroDestino));
    } else {
      criterios.push(new Criterio('ID_PARCEIRO', this.idParceiro));
    }
    return criterios;
  }

  listarProdutoIcmsSt(movimentacaoProduto: MovimentacaoProduto): Observable<any> {
    if (this.saida) {
      let criterios: Criterio[] = this.getCriterioIcms(movimentacaoProduto);
      return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.produtoIcmsSt);
    }
    return of({});
  }

  listarMovimentacaoProdutoOrigem(movimentacaoProduto: MovimentacaoProduto, naturezaOperacaoFinalidade: number): Observable<any> {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO) {
      let criterios: Criterio[] = this.montarCriterioMovimentacaoProdutoOrigem(movimentacaoProduto);
      return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoProduto);
    }
    return of({});
  }

  listarImpostoDevolucao(movimentacaoProduto: MovimentacaoProduto, naturezaOperacaoFinalidade: number): Observable<any> {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO && movimentacaoProduto.idMovimentacaoProOrigem != null) {
      return this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO_PRO', movimentacaoProduto.idMovimentacaoProOrigem.toString())), this.bibServico.impostoDevolucao);
    }
    return of({});
  }

  listarMovimentacaoProdutoId(movimentacaoProduto: MovimentacaoProduto, naturezaOperacaoFinalidade: number): Observable<any> {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO) {
      let criterios: Criterio[] = this.montarCriterioMovimentacaoProdutoId(movimentacaoProduto);
      return this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.movimentacaoProduto);
    }
    return of({});
  }

  listarGrupoIcmsSt(movimentacaoProduto: MovimentacaoProduto): Observable<any> {
    if (this.saida) {
      let criterios: Criterio[] = this.getCriterioIcms(movimentacaoProduto);
      this.comunicacaoService.listar(new Transporte(criterios), this.bibServico.grupoIcmsSt);
    }
    return of({});
  }

  setIcmsDesonerado(movimentacaoProduto: MovimentacaoProduto, produto: Produto, impostoDevolucoes: any[], naturezaOperacaoFinalidade: number): void {
    const FINALIDADE_DEVOLUCAO: number = 3;
    if (naturezaOperacaoFinalidade == FINALIDADE_DEVOLUCAO && this.saida == false) {
      movimentacaoProduto.setIcmsDesonerado(impostoDevolucoes.length == 1 ? impostoDevolucoes[0].icmsDesonerado : 0.0);
    } else {
      if (this.saida && naturezaOperacaoFinalidade != FINALIDADE_DEVOLUCAO) {
        if (produto && movimentacaoProduto.usaIcmsDesonerado == 'S') {
          const icmsDesonerado: number = ((this.movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() - movimentacaoProduto.getIcmsBase()) / 100) * movimentacaoProduto.getIcmsAliquota();
          movimentacaoProduto.setIcmsDesonerado(icmsDesonerado);
        } else {
          movimentacaoProduto.setIcmsDesonerado(0);
        }
      }
    }
  }

  corrigirIcmsDesonerado(movimentacaoProduto: MovimentacaoProduto, valorTotalAnterior: number, movimentacaoControlador: MovimentacaoControlador) {
    if (movimentacaoProduto.usaIcmsDesonerado == 'S') {
      const valorTotal: number = (movimentacaoProduto.getQuantidade() * movimentacaoProduto.getValorUnitario()) - movimentacaoProduto.getIcmsDesonerado();
      const diferenca: number = valorTotal - valorTotalAnterior;
      if (diferenca != 0) {
        const icmsDesonerado: number = movimentacaoProduto.getIcmsDesonerado() + diferenca;
        movimentacaoProduto.setIcmsDesonerado(icmsDesonerado);
      }
    }
  }

  ehPreenchidoImpostos(movimentacaoProduto: MovimentacaoProduto, entradaServico: boolean): MovimentacaoProduto {
    if (entradaServico) {
      if (!movimentacaoProduto.idCfopEmpresa || movimentacaoProduto.getInssRetidoValor() < 0 || movimentacaoProduto.getIrrfRetidoValor() < 0 || movimentacaoProduto.getCsllRetidoValor() < 0 || movimentacaoProduto.getPisRetidoValor() < 0 || movimentacaoProduto.getCofinsRetidoValor() < 0 || movimentacaoProduto.getDeducoesValor() < 0 || movimentacaoProduto.getOutrasRetencoesValor() < 0 || movimentacaoProduto.getIssBase() < 0 || movimentacaoProduto.getIssValor() < 0) {
        movimentacaoProduto.impostosPreenchidos = false;
      } else {
        movimentacaoProduto.impostosPreenchidos = true;
      }
    } else {
      if (!movimentacaoProduto.idCfopEmpresa || !movimentacaoProduto.idCstIcms || !movimentacaoProduto.idCstCofins || !movimentacaoProduto.idCstPis || !movimentacaoProduto.idCstIpi || movimentacaoProduto.getIcmsStValor() < 0 || movimentacaoProduto.getIcmsValor() < 0 || movimentacaoProduto.getCofinsValor() < 0 || movimentacaoProduto.getPisValor() < 0 || movimentacaoProduto.getIpiValor() < 0 || movimentacaoProduto.getInssRetidoValor() < 0 || movimentacaoProduto.getIrrfRetidoValor() < 0 || movimentacaoProduto.getCsllRetidoValor() < 0 || movimentacaoProduto.getPisRetidoValor() < 0 || movimentacaoProduto.getCofinsRetidoValor() < 0 || movimentacaoProduto.getDeducoesValor() < 0 || movimentacaoProduto.getOutrasRetencoesValor() < 0 || movimentacaoProduto.getIssBase() < 0 || movimentacaoProduto.getIssValor() < 0) {
        movimentacaoProduto.impostosPreenchidos = false;
      } else {
        movimentacaoProduto.impostosPreenchidos = true;
      }
    }
    return movimentacaoProduto;
  }

  setCfopEmpresa(movimentacaoProduto: MovimentacaoProduto, cfopEmpresa: CfopEmpresa, lojaEstado: LojaEstado, impostoDevolucoes: any[], naturezaOperacaoFinalidade: number): void {
    movimentacaoProduto.setCfopEmpresa(cfopEmpresa, this.bloqueiaAlteracao, this.classificacaoBloqueadoEstoque, this.classificacaoBloqueadoEstoqueNfe, this.classificacaoBloqueadoFinanceiro, this.financeiro, this.idOperacao);
    if (cfopEmpresa && cfopEmpresa.idClassificacaoFiscal != undefined) {
      this.configurarImpostoCFOP(movimentacaoProduto, cfopEmpresa, lojaEstado, impostoDevolucoes, naturezaOperacaoFinalidade);
    }
  }

  configurarImpostoCFOP(movimentacaoProduto: MovimentacaoProduto, cfopEmpresa: CfopEmpresa, lojaEstado: LojaEstado, impostoDevolucoes: any[], naturezaOperacaoFinalidade: number): void {
    if (cfopEmpresa != undefined) {
      this.listarProdutoAliquotaIcmsEspecifica(this.getCriterioIcms(movimentacaoProduto)).subscribe((res) => {
        const produtoAliquotaIcmsEspecifica: ProdutoIcmsEspecifico = plainToClass(ProdutoIcmsEspecifico, res[0]) as any;
        this.setLojaIcmsAliquota(movimentacaoProduto, lojaEstado, impostoDevolucoes, naturezaOperacaoFinalidade, produtoAliquotaIcmsEspecifica);
        this.setIcms(movimentacaoProduto, movimentacaoProduto.idCstIcms, movimentacaoProduto.icmsBasePercentual);
        if (cfopEmpresa.idCstCofins != undefined) {
          movimentacaoProduto.idCstCofins = cfopEmpresa.idCstCofins;
          this.iniciarCofins(movimentacaoProduto, cfopEmpresa.cofinsAliquota);
        }
        if (cfopEmpresa.idCstPis != undefined) {
          movimentacaoProduto.idCstPis = cfopEmpresa.idCstPis;
          this.iniciarPis(movimentacaoProduto, cfopEmpresa.pisAliquota);
        }
        if (cfopEmpresa.idCstIpi != undefined) {
          movimentacaoProduto.idCstIpi = cfopEmpresa.idCstIpi;
          this.iniciarIpi(movimentacaoProduto, cfopEmpresa.ipiAliquota);
        }
      });
    }
  }

  atualizarBaseMovimentacaoProduto(movimentacaoProduto: MovimentacaoProduto, naturezaOperacaoFinalidade: number, ehSaida: boolean, recalculaDesonerado: boolean = true): void {
    const FINALIDADE_COMPLEMENTO: number = 4;
    const FINALIDADE_AJUSTE: number = 5;
    if (movimentacaoProduto.importacaoXml == 'N' && naturezaOperacaoFinalidade != FINALIDADE_COMPLEMENTO && naturezaOperacaoFinalidade != FINALIDADE_AJUSTE) {
      if (movimentacaoProduto.icmsBasePercentual > 0 && movimentacaoProduto.getIcmsAliquota() > 0) {
        movimentacaoProduto.setIcmsBase(this.util.arredondar((this.util.arredondar(movimentacaoProduto.valorTotal - (movimentacaoProduto.subtrairDesoneradoBase ? movimentacaoProduto.icmsDesonerado : 0)) / 100) * movimentacaoProduto.icmsBasePercentual));
        movimentacaoProduto.subtrairDesoneradoBase = false;
      } else {
        movimentacaoProduto.setIcmsBase(0);
      }
      if (naturezaOperacaoFinalidade != 2) {
        movimentacaoProduto.setIssBase(this.util.arredondar(movimentacaoProduto.valorTotal));
        if (movimentacaoProduto.getIpiAliquota() > 0) {
          movimentacaoProduto.setIpiBase(this.util.arredondar(movimentacaoProduto.valorTotal));
        } else {
          movimentacaoProduto.setIpiBase(0);
        }
        if (movimentacaoProduto.getCofinsAliquota() > 0) {
          movimentacaoProduto.setCofinsBase(this.util.arredondar(movimentacaoProduto.valorFinal));
        } else {
          movimentacaoProduto.setCofinsBase(0);
        }
        if (movimentacaoProduto.getPisAliquota() > 0) {
          movimentacaoProduto.setPisBase(this.util.arredondar(movimentacaoProduto.valorFinal));
        } else {
          movimentacaoProduto.setPisBase(0);
        }
      } else {
        movimentacaoProduto.setCofinsBase(0);
        movimentacaoProduto.setPisBase(0);
      }
      const FINALIDADE_DEVOLUCAO: number = 3;
      if (naturezaOperacaoFinalidade != FINALIDADE_DEVOLUCAO && recalculaDesonerado) {
        if (ehSaida) {
          if (movimentacaoProduto.usaIcmsDesonerado == 'S') {
            let diferenca: number = movimentacaoProduto.getValorUnitario() * movimentacaoProduto.getQuantidade() - new Util().arredondar(movimentacaoProduto.getValorUnitario() * movimentacaoProduto.getQuantidade());
            diferenca = diferenca < 0 ? diferenca * -1 : 0;
            const icmsDesonerado = ((movimentacaoProduto.getValorUnitario() * movimentacaoProduto.getQuantidade() - movimentacaoProduto.getIcmsBase()) / 100) * movimentacaoProduto.getIcmsAliquota();
            movimentacaoProduto.setIcmsDesonerado(icmsDesonerado + diferenca);
          } else {
            movimentacaoProduto.setIcmsDesonerado(0);
          }
        }
      }
      if (ehSaida && movimentacaoProduto.icmsStMva > 0 && movimentacaoProduto.icmsStAliquota > 0 && movimentacaoProduto.getQuantidade() > 0) {
        let movimentacaoProdutoValorUnitarioFinal: number = this.util.arredondar(movimentacaoProduto.valorTotal) / movimentacaoProduto.getQuantidade();
        const icmsStBase: number = (movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() * movimentacaoProduto.icmsStMva) / 100 + movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade();
        movimentacaoProduto.setIcmsStBase(this.util.arredondar(icmsStBase));
        const icmsStValor: number = (icmsStBase * movimentacaoProduto.icmsStAliquota) / 100 - (movimentacaoProdutoValorUnitarioFinal * movimentacaoProduto.getQuantidade() * movimentacaoProduto.getIcmsAliquota()) / 100;
        movimentacaoProduto.setIcmsStValor(this.util.arredondar(icmsStValor));
      }
    }
    movimentacaoProduto.subtrairIcmsDaBasePisCofins(movimentacaoProduto.getIcmsValor());
  }
}
