<layout [titulo]="loja.nomeFantasia" [id]="loja.id" (voltarAvancarEmt)="ehAlteracao()">
  <header class="page-cover pt-3 mb-5">
    <div class="text-center upper">
      <div class="fileinput-button">
        <div class="fileinput-button-label">{{ bibDialogo.alterarLogomarca }}</div>
        <img id="output" [src]="loja.fotoLoja ? loja.fotoLoja : loja.foto == 'S' ? conexao.caminhoAwsS3 + '' + loja.idEmpresa + '/loja/' + loja.id + '.jpg' : imagemEmpresa" [alt]="loja.razaoSocial" class="img-thumbnail" width="200" height="200" />
        <input class="tamanho-foto-empresa" accept="image/*" type="file" id="fileupload-avatar" (change)="alterarFoto($event)" #arquivoUpload />
      </div>
      <div *ngIf="loja.foto == 'S'" class="pt-2"><i class="fa fa-trash fa-cinza-escuro clique" (click)="deletarFoto()"></i></div>
    </div>
  </header>
  <div class="row">
    <texto class="col-sm" [id]="'razaoSocial'" [rotulo]="bibDialogo.razaoSocial" [campo]="loja.razaoSocial" (alteracao)="loja.razaoSocial = $event" [obrigatorio]="true" [foco]="true"></texto>
    <interruptor [rotulo]="bibDialogo.ativo" [campo]="loja.ativo" (alteracao)="loja.ativo = $event" [desabilitado]="!temPermissaoInativar"></interruptor>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'nomeFantasia'" [rotulo]="bibDialogo.nomeFantasia" [campo]="loja.nomeFantasia" (alteracao)="loja.nomeFantasia = $event" [obrigatorio]="true"></texto>
  </div>
  <div class="row">
    <lista class="col-sm-4" [id]="'praca'" [rotulo]="bibDialogo.praca" [campo]="loja.idPraca" (alteracao)="loja.idPraca = $event.id" [lista]="pracas" [obrigatorio]="true" [idMenu]="permissaoPraca && permissaoPraca.inserir == 'S' ? menuPraca.idMenu : null" [icone]="bibIcone.atualizar" (btnAdicional)="listarPraca()"></lista>
    <texto class="col-sm-4" [id]="'abreviacao'" [rotulo]="bibDialogo.abreviacao" [campo]="loja.abreviacao" (alteracao)="loja.abreviacao = $event" [ajuda]="bibDialogo.max3caracteres" [obrigatorio]="true" [maximoCaracteres]="3"></texto>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'cnpj'" [rotulo]="bibDialogo.cnpj" [campo]="loja.cnpj" (alteracao)="loja.cnpj = $event" [tipo]="bibPropriedade.mascara.cnpj" [obrigatorio]="true" [desabilitado]="true"></texto>
    <texto class="col-sm" [id]="'inscricaoEstadual'" [rotulo]="bibDialogo.inscricaoEstadual" [campo]="loja.inscricaoEstadual" (alteracao)="loja.inscricaoEstadual = $event" [maximoCaracteres]="20" [obrigatorio]="true"></texto>
    <texto class="col-sm" [id]="'inscricaoMunicipal'" [rotulo]="bibDialogo.inscricaoMunicipal" [campo]="loja.inscricaoMunicipal" (alteracao)="loja.inscricaoMunicipal = $event" [maximoCaracteres]="20"></texto>
  </div>
  <div class="row">
    <texto class="col-sm" [id]="'cnae'" [rotulo]="bibDialogo.cnae" [campo]="loja.cnae" (alteracao)="loja.cnae = $event" [maximoCaracteres]="7"></texto>
    <lista class="col-sm" [id]="'enquadramento'" [rotulo]="bibDialogo.enquadramento" [campo]="loja.enquadramento" [lista]="listaEnquadramento" (alteracao)="loja.enquadramento = $event.id" [obrigatorio]="true"></lista>
    <data class="col-sm" [id]="'dataAbertura'" [rotulo]="bibDialogo.dataAbertura" [campo]="loja.dataAbertura" (alteracao)="loja.dataAbertura = $event"></data>
  </div>
  <titulo [titulo]="bibDialogo.contato + 's'" [imagem]="bibImagem.familia"></titulo>
  <texto [id]="'responsavel'" [rotulo]="bibDialogo.responsavel" [campo]="loja.responsavel" (alteracao)="loja.responsavel = $event"></texto>
  <div class="row">
    <texto class="col-sm" [id]="'email'" [rotulo]="bibDialogo.email" [campo]="loja.email" (alteracao)="loja.email = $event" [tipo]="bibPropriedade.texto.email" [normal]="true" [maximoCaracteres]="100"></texto>
    <texto class="col-sm" [id]="'site'" [rotulo]="bibDialogo.site" [campo]="loja.site" (alteracao)="loja.site = $event" [maximoCaracteres]="100"></texto>
  </div>
  <div class="row">
    <mascara class="col-sm-6" [id]="'telefone'" [rotulo]="bibDialogo.telefone" [campo]="loja.telefone" (alteracao)="loja.telefone = $event" [tipo]="bibPropriedade.mascara.telefone" [obrigatorio]="true"> </mascara>
  </div>
  <titulo [titulo]="bibDialogo.endereco" [imagem]="bibImagem.endereco"></titulo>
  <enderecoCep [objeto]="loja" [tipo]="bibDialogo.loja"></enderecoCep>
  <titulo [titulo]="bibDialogo.dadosContador" [imagem]="bibImagem.pessoa"></titulo>
  <div class="row">
    <texto class="col-sm-12" [id]="'contador'" [rotulo]="bibDialogo.contador" [campo]="loja.contador" (alteracao)="loja.contador = $event" [obrigatorio]="true"></texto>
  </div>
  <div class="row">
    <texto class="col-sm-5" [id]="'crc'" [rotulo]="bibDialogo.crc" [campo]="loja.contadorCrc" (alteracao)="loja.contadorCrc = $event" [maximoCaracteres]="20"></texto>  
    <lista class="col-sm-2" [rotulo]="bibDialogo.pessoa + ' ' + bibDialogo.juridica.toLowerCase()" [campo]="loja.contadorPessoaJuridica" (alteracao)="loja.contadorPessoaJuridica = $event.id"  [lista]="listaSimNao" [obrigatorio]="true"></lista>
    <mascara class="col-sm-5" [id]="'cpfContador'" [rotulo]="loja.contadorPessoaJuridica == 'S' ? bibDialogo.cnpj : bibDialogo.cpf" [campo]="loja.contadorCpf" (alteracao)="setContadorCnpjCpf(loja, $event)" [tipo]="loja.contadorPessoaJuridica == 'S' ? bibPropriedade.mascara.cnpj : bibPropriedade.mascara.cpf " [atencao]="!loja.ehValidoCnpjCpf && loja.contadorCpf ? bibDialogo.incorreto : null" [obrigatorio]="true" [maximoCaracteres]="14"></mascara>
  </div>
  <div class="row">
    <texto class="col-sm-6" [id]="'emailContador'" [rotulo]="bibDialogo.email" [campo]="loja.contadorEmail" (alteracao)="loja.contadorEmail = $event" [tipo]="bibPropriedade.texto.email" [normal]="true" [maximoCaracteres]="100" [obrigatorio]="true"></texto>
    <busca class="col-sm-6" [id]="'cidadeContador'" [rotulo]="bibDialogo.cidade" [campo]="loja.contadorCidade" (alteracao)="setContadorCidade($event)" [servico]="bibServico.cidade" [obrigatorio]="true"></busca>    
  </div>  
  <div *ngIf="empresa.receituario == 'S' && utilSessao.sistema == 'aGrow-w'">
    <titulo [titulo]="bibDialogo.receituario" [imagem]="bibImagem.receituario"></titulo>
    <div>
      <div class="row">
        <texto class="col-sm-12" [id]="'endEtregaEmbalagens'" [rotulo]="bibDialogo.endEtregaEmbalagens" [campo]="loja.receituarioEntregaEmbalagem" (alteracao)="loja.receituarioEntregaEmbalagem = $event" [maximoCaracteres]="500"></texto>
      </div>
      <div class="row align-items-center">
        <texto class="col-sm-4" [id]="'quantidadeProdutoReceita'" [rotulo]="bibDialogo.quantidadeProdutoReceita" [campo]="loja.receituarioQuantidadeProduto" (alteracao)="loja.receituarioQuantidadeProduto = $event" [tipo]="bibPropriedade.texto.inteiro"></texto>
        <atencao [ngClass]="compacto ? '' : 'mt-4'" *ngIf="loja.receituarioIgnorarCulturaPro == 'N'" [atencao]="bibDialogo.respeitandoCultura"></atencao>
      </div>
      <div class="row">
        <check class="pt-2 pl-2" [campo]="loja.receituarioIgnorarCulturaPro" (alteracao)="loja.receituarioIgnorarCulturaPro = $event"></check>
        <rotulo class="pt-2" [id]="'desconsiderarCultura'" [rotulo]="bibDialogo.desconsiderarCultura"></rotulo>
      </div>
    </div>
  </div>
  <titulo [titulo]="bibDialogo.nfe" [imagem]="bibImagem.nfe"></titulo>
  <div class="row">
    <!-- <texto class="col-sm-4" [rotulo]="bibDialogo.serie" [campo]="loja.serie" (alteracao)="loja.serie = $event" [maximoCaracteres]="4"></texto> -->
    <lista class="col-sm-6" [id]="'enviarEmail'" [rotulo]="bibDialogo.enviarEmail" [campo]="loja.nfeEmail" [lista]="listaSimNao" (alteracao)="loja.nfeEmail = $event.id" [obrigatorio]="true"></lista>
    <lista class="col-sm-6" [id]="'enviarIbpt'" [rotulo]="bibDialogo.enviarIbpt" [campo]="loja.enviarIbptNfe" [lista]="listaIbpt" (alteracao)="setEnviarIbptNfe(loja, $event.id)" [obrigatorio]="true" [ajuda]="bibDialogo.enviarIbptAjuda"></lista>
    <descricao class="col-sm-12" [id]="'infoComplementar'" [rotulo]="bibDialogo.infoComplementar" [campo]="loja.nfeMensagem" (alteracao)="setNfeMensagem($event)" [maximoCaracteres]="250"></descricao>
    <div class="row justify-content-end pt-2 pl-4">
      <botao [compacto]="compacto" [legenda]="bibDialogo.adicionarRotuloColaborador" [desabilitado]="bloqueiaResponsavel" (botaoEmt)="flagResponsavel()"></botao>
    </div>  
    <texto class="col-sm-12" [id]="'nfeEmailCopia'" [rotulo]="bibDialogo.email + ' ' + bibDialogo.copia" [campo]="loja.nfeEmailCopia" (alteracao)="loja.nfeEmailCopia = $event" [maximoCaracteres]="400" [ajudaDireita]="true" [tipo]="bibPropriedade.texto.email" [normal]="true" [ajuda]="bibDialogo.ajudaEmailCopiaNfe"></texto>
  </div>
  <titulo [titulo]="bibDialogo.mdfe" [imagem]="bibImagem.transporte"></titulo>
  <div class="row">
    <texto class="col-sm-4" [rotulo]="bibDialogo.serie" [campo]="loja.mdfeSerie" (alteracao)="loja.mdfeSerie = $event" [maximoCaracteres]="3"></texto>
    <lista class="col-sm-4" [id]="'mdfeAmbiente'" [rotulo]="bibDialogo.ambiente" [campo]="loja.mdfeAmbiente" [lista]="listaMdfeAmbiente" (alteracao)="setAmbiente(loja, $event.id)"></lista>
    <lista class="col-sm-4" [id]="'mdfeEnvio'" [rotulo]="bibDialogo.tipoEnvio" [campo]="loja.mdfeEnvio" [lista]="listaMdfeEnvio" (alteracao)="setTipoEnvio(loja, $event.id)"></lista>
  </div>
  <titulo *ngIf="maisDeUmaLoja" [titulo]="bibDialogo.cliente + ' ' + bibDialogo.transferencia" [imagem]="bibImagem.colaborador"></titulo>
  <div *ngIf="maisDeUmaLoja" class="row">
    <busca class="col-sm" [id]="'parceiro'" [rotulo]="bibDialogo.cliente" [campo]="loja.parceiro" (alteracao)="loja.idParceiroTransferencia = $event.id" [servico]="bibServico.cliente" [minimoCaracterBusca]="5" [ajudaDireita]="true" [ajuda]="bibDialogo.ajudaLojaClienteTransferencia"></busca>
  </div>

  <titulo *ngIf="maisDeUmaLoja" [titulo]="bibDialogo.clienteFornecedorFinal" [imagem]="bibImagem.cliente"></titulo>
  <div *ngIf="maisDeUmaLoja" class="row">
    <busca class="col-sm" [id]="'clienteFornecedorFinal'" [rotulo]="bibDialogo.clienteFornecedorFinal" [campo]="loja?.consumidor" (alteracao)="loja.idConsumidorFinal = $event.id" [minimoCaracterBusca]="5" [servico]="bibServico.cliente"></busca>
  </div>
  <titulo [titulo]="bibDialogo.juroAutomatico" [imagem]="bibImagem.juroautomatico"></titulo>
  <div class="row align-items-center">
    <decimal class="col-sm-4" [id]="'juroDiario'" [rotulo]="bibDialogo.juroDiario" [campo]="loja.juroPercentual" (alteracao)="lojaJuroPercentual($event)" [sufixo]="bibDialogo.porcentoPorDia" [valorMaximo]="100" [obrigatorio]="true" [numeroCasasDecimais]="4" [ajudaDireita]="true" [ajuda]="bibDialogo.ajudaLojaJuroDiario"></decimal>
    <texto class="col-sm-4" [id]="'carenciaDias'" [rotulo]="bibDialogo.carenciaDias" [campo]="loja.carenciaDias" (alteracao)="lojaCarenciaDias($event)" [sufixo]="loja.carenciaDias == 1 ? bibDialogo.dia.toLowerCase() : bibDialogo.dias.toLowerCase()" [tipo]="bibPropriedade.texto.inteiro"></texto>
  </div>
  <br>
  <div class="row align-items-center">
    <atencao *ngIf="apresentarAtencao && loja.id" [tipo]="bibPropriedade.atencao.alerta" [atencao]="bibDialogo.atualizacaoJuroAutomatico"></atencao>
  </div>
  <titulo [titulo]="bibDialogo.custoReposicao" [imagem]="bibImagem.custoReposicao"></titulo>
  <div class="row">
    <decimal class="col-md-6" [id]="'variacaoMaxima'" [rotulo]="bibDialogo.variacaoMaxima" [campo]="loja.regraVariacaoCusto" (alteracao)="loja.regraVariacaoCusto = $event" [obrigatorio]="true" [sufixo]="'%'" [ajudaDireita]="true" [ajuda]="bibDialogo.ajudaLojaVariacaoMaxima"></decimal>
  </div>
  <titulo [titulo]="bibDialogo.precoVenda" [imagem]="bibImagem.custoReposicao"></titulo>
  <div class="row">
    <decimal class="col-md-6" [id]="'precoVendaMinimo'" [rotulo]="bibDialogo.precoVendaMinimo" [campo]="loja.regraPrecoVenda" (alteracao)="loja.regraPrecoVenda = $event" [sufixo]="'%'" [ajudaDireita]="true" [ajuda]="bibDialogo.ajudaLojaPrecoMinimo"></decimal>
  </div>
  <titulo [titulo]="bibDialogo.margemLucro" [imagem]="bibImagem.juroautomatico"></titulo>
  <div class="row">
    <lista class="col-md-12" [id]="'margemLucroDiferenciada'" [rotulo]="bibDialogo.margemLucro" [campo]="loja.margemLucroDiferenciada" [lista]="listaMargemDiferenciada" (alteracao)="loja.margemLucroDiferenciada = $event.id" [obrigatorio]="true"></lista>
  </div>
  <div *ngIf="empresa.contabilidade == 'S'">
    <titulo [titulo]="bibDialogo.configuracaoFinanceiroContabil" [imagem]="bibImagem.contabilidade"></titulo>
    <div class="row">
      <lista class="col-sm-12" [idMenu]="permissaoConfiguracaoFinanceiroContabil && permissaoConfiguracaoFinanceiroContabil.inserir == 'S' ? menuConfiguracaoFinanceiroContabil.idMenu : null" [rotulo]="bibDialogo.receita" [campo]="loja.idConfFinanceiroContabil" (alteracao)="loja.idConfFinanceiroContabil = $event.id" [lista]="configuracaoFinanceiroContabeisReceitas" [icone]="bibIcone.atualizar" (btnAdicional)="listarConfiguracaoFinanceiroContabil()"></lista>
      <lista class="col-sm-12" [idMenu]="permissaoConfiguracaoFinanceiroContabil && permissaoConfiguracaoFinanceiroContabil.inserir == 'S' ? menuConfiguracaoFinanceiroContabil.idMenu : null" [rotulo]="bibDialogo.despesa" [campo]="loja.idFinanceiroContabilDespesa" (alteracao)="loja.idFinanceiroContabilDespesa = $event.id" [lista]="configuracaoFinanceiroContabeisDespesas" [icone]="bibIcone.atualizar" (btnAdicional)="listarConfiguracaoFinanceiroContabil()"></lista>
    </div>
    <titulo [titulo]="bibDialogo.configuracaoTransferenciaContabil" [imagem]="bibImagem.contabilidade"></titulo>
    <div class="row">
      <lista class="col-sm-12" [rotulo]="bibDialogo.transferencia" [campo]="loja.idConfTransferenciaContabil" (alteracao)="loja.idConfTransferenciaContabil = $event.id" [lista]="configuracaoTransferenciaContabeis" [icone]="bibIcone.atualizar" (btnAdicional)="listarConfiguracaoTransferenciaContabil()"></lista>
    </div>
  </div>
  <lojaEstado [lojaEstados]="loja.lojaEstados" [excluirLojaEstados]="excluirLojaEstados" [edicaoLojaEstados]="edicaoLojaEstados"></lojaEstado>
  <titulo [titulo]="bibDialogo.spedFiscal" [imagem]="bibImagem.contabilidade"></titulo>
  <div class="row">
    <lista class="col-sm-3" [id]="'spedFiscalPerfil'" [rotulo]="bibDialogo.perfil" [campo]="loja.spedFiscalPerfil" (alteracao)="loja.spedFiscalPerfil = $event.id" [lista]="listaPerfil" [ajuda]="bibDialogo.perfilArquivo" [obrigatorio]="true" [ajudaDireita]="true"></lista> 
    <texto class="col-sm-3" [rotulo]="bibDialogo.codigoReceitaE116" [campo]="loja.codigoReceitaE116Fiscal" (alteracao)="loja.codigoReceitaE116Fiscal = $event" [maximoCaracteres]="5"></texto>
    <lista class="col-sm-3" [id]="'spedValorMercadoriaLiquido'" [rotulo]="bibDialogo.valorLiquido" [campo]="loja.spedValorMercadoriaLiquido" (alteracao)="loja.spedValorMercadoriaLiquido = $event.id" [lista]="listaSimNao" [ajuda]="bibDialogo.spedFiscalValorMercadoriaLiquidoAjuda" [obrigatorio]="true" [ajudaDireita]="true"></lista> 
    <lista class="col-sm-3" [id]="'spedFiscalEnviarKMensal'" [rotulo]="bibDialogo.spedFiscalBlocoK" [campo]="loja.spedFiscalEnviarKMensal" (alteracao)="loja.spedFiscalEnviarKMensal = $event.id" [lista]="listaSimNao" [ajuda]="bibDialogo.spedFiscalBlocoKAjuda" [obrigatorio]="true"></lista> 
    <lista class="col-sm-12" [id]="'spedFiscalEnviarHMensal'" [rotulo]="bibDialogo.spedFiscalBlocoH" [campo]="loja.spedFiscalEnviarHMensal" (alteracao)="loja.spedFiscalEnviarHMensal = $event.id" [lista]="listaInventario" [ajuda]="bibDialogo.spedFiscalBlocoHAjuda" [obrigatorio]="true"  [ajudaDireita]="true"></lista> 
  </div>
  <titulo [titulo]="bibDialogo.spedContribuicoes" [imagem]="bibImagem.contabilidade"></titulo>
  <div class="row">
    <lista class="col-sm-6" [id]="'tipoDeAtividade'" [rotulo]="bibDialogo.tipoDeAtividade" [campo]="loja.spedContTipoAtividade" (alteracao)="loja.spedContTipoAtividade = $event.id" [lista]="listaTipoDeAtividade"></lista>
    <lista class="col-sm-6" [id]="'codApropriacaoCreditos'" [rotulo]="bibDialogo.codApropriacaoCreditos" [campo]="loja.spedContMetoApropriacao" (alteracao)="loja.spedContMetoApropriacao = $event.id" [lista]="listaCreditosComuns"></lista>
    <lista class="col-sm-6" [id]="'codContibuicaoApurada'" [rotulo]="bibDialogo.codContibuicaoApurada" [campo]="loja.spedContTipoContribuacao" (alteracao)="loja.spedContTipoContribuacao = $event.id" [lista]="listaContribuicaoApurada"></lista>
    <lista class="col-sm-6" [id]="'codCriterioEscrituracao'" [rotulo]="bibDialogo.codCriterioEscrituracao" [campo]="loja.spedContCritEscrituracao" (alteracao)="loja.spedContCritEscrituracao = $event.id" [lista]="listaApuracaoAdotado"></lista>
    <lista class="col-sm-12" [id]="'codIncidenciaTributaria'" [rotulo]="bibDialogo.codIncidenciaTributaria" [campo]="loja.spedContInciTributaria" (alteracao)="loja.spedContInciTributaria = $event.id" [lista]="listaIncidendiaTributaria"></lista>
    <lista *ngIf="apresentaLojaValorLojaMatriz" class="col-md-12" [id]="'lojaMatriz'" [rotulo]="bibDialogo.matriz"  [campo]="loja.idLojaMatriz" (alteracao)="loja.idLojaMatriz = $event.id" [lista]="lojaMatriz" [ajuda]="bibDialogo.spedContribuicaoMatrizAjuda"></lista>
  </div>
  <div *ngIf="apresentaLojaValorLojaMatriz == true">
    <titulo [titulo]="bibDialogo.lojaValor" [imagem]="bibImagem.loja"></titulo>
    <atencao [ngClass]="!compacto ? 'pt-4' : ''" [atencao]="this.util.substituir(bibDialogo.utilizarLojaPreco, [loja.nomeFantasia])"></atencao>
    <div class="row pt-3">
      <lista class="col-sm-3" [id]="'lojaValor'" [campo]="apresentaLoja" [lista]="listaSimNao" (alteracao)="setBuscaPrecoOutraLoja($event.id)" [obrigatorio]="true"></lista>
    </div>
    <lista *ngIf="apresentaLoja == 'S'" [rotulo]="bibDialogo.desejaBuscarPrecoLoja" class="col-md-12" [campo]="loja.idLojaValor" (alteracao)="loja.idLojaValor = $event.id" [lista]="lojaValores" [obrigatorio]="true" [id]="bibDialogo.lojaValor"></lista>
  </div>
</layout>
<botao [compacto]="compacto" [legenda]="bibDialogo.salvar" [tipo]="bibPropriedade.botao.sucesso" [fundo]="true" (botaoEmt)="persistirLoja()" [desabilitado]="bloquearPermissao(loja.id)"></botao>
<auditoria *ngIf="loja.id" [idUsuarioInclusao]="loja.idUsuarioInclusao" [dataHoraInclusao]="loja.dataHoraInclusao" [idUsuarioAlteracao]="loja.idUsuarioAlteracao" [dataHoraAlteracao]="loja.dataHoraAlteracao"></auditoria>
