import { CamposExtras } from './camposExtras';
import { Cobranca } from './cobranca';
import { Destinatario } from './destinatario';
import { Emitente } from './emitente';
import { Item } from './item';
import { NotaReferenciada } from './notaReferenciada';
import { Pagamento } from './pagamento';
import { ResponsavelAutorizado } from './responsavelAutorizado';
import { ResponsavelTecnico } from './responsavelTecnico';
import { Total } from './total';
import { TransporteTecnoSpeed } from './transporteTecnoSpeed';

export class Nfe {
  public chave: string;
  public codigo: string;
  public consumidorFinal: boolean;
  public copiasEmail: string[] = [];
  public dataAutorizacao: string;
  public dataEmissao: string;
  public dataSaidaEntrada: string;
  public destinatario: Destinatario;
  public emitente: Emitente;
  public enviarEmail: boolean = false;
  public finalidade: string;
  public idIntegracao: string;
  public informacoesComplementares: string;
  public informacoesComplementaresContribuinte: string;
  public intermediador: number = 0;
  public itens: Item[] = [];
  public modelo: string;
  public natureza: string;
  public notaReferenciada: NotaReferenciada;
  public numero: string;
  public pagamentos: Pagamento[] = [];
  public cobranca: Cobranca;
  public presencial: boolean;
  public responsavelTecnico: ResponsavelTecnico;
  public saida: boolean;
  public serie: string;
  public status: string;
  public tipoEmissao: string;
  public total: Total;
  public transporte: TransporteTecnoSpeed;
  public camposExtras: CamposExtras;
  public responsavelAutorizado: ResponsavelAutorizado;
}
