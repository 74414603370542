import { Component, Input } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { addDays, addMonths, setDate } from 'date-fns';
import { Observable, of } from 'rxjs';
import { PesquisaChequeComponent } from 'src/app/formulario/pesquisa/pesquisaCheque/pesquisaCheque.component';
import { Baixa } from 'src/app/modelo/baixa';
import { BaixaMovimentacaoProduto } from 'src/app/modelo/baixaMovimentacaoProduto';
import { Caixa } from 'src/app/modelo/caixa';
import { Cheque } from 'src/app/modelo/cheque';
import { Conciliacao } from 'src/app/modelo/conciliacao';
import { Conferencia } from 'src/app/modelo/conferencia';
import { ConferenciaFinanceiro } from 'src/app/modelo/conferenciaFinanceiro';
import { ConferenciaTitulo } from 'src/app/modelo/conferenciaTitulo';
import { Conta } from 'src/app/modelo/conta';
import { Criterio } from 'src/app/modelo/criterio';
import { Financeiro } from 'src/app/modelo/financeiro';
import { FinanceiroBaixa } from 'src/app/modelo/financeiroBaixa';
import { FinanceiroBaixaPro } from 'src/app/modelo/financeiroBaixaPro';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Loja } from 'src/app/modelo/loja';
import { Movimentacao } from 'src/app/modelo/movimentacao';
import { MovimentacaoParcela } from 'src/app/modelo/movimentacaoParcela';
import { Negociacao } from 'src/app/modelo/negociacao';
import { Parceiro } from 'src/app/modelo/parceiro';
import { Resultado } from 'src/app/modelo/resultado';
import { TipoTitulo } from 'src/app/modelo/tipoTitulo';
import { TipoTituloCaixa } from 'src/app/modelo/tipoTituloCaixa';
import { Transporte } from 'src/app/modelo/transporte';
import { Usuario } from 'src/app/modelo/usuario';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { CreditoComponent } from '../../credito/credito.component';

@Component({
  selector: 'baixaBaixa',
  templateUrl: './baixaBaixa.component.html',
})
export class BaixaBaixaComponent extends PaginaComponent {
  @Input() baixa: Baixa;
  private baixaEmt: any;
  private financeiroBaixaPros: FinanceiroBaixaPro[] = [];
  private financeiroBaixas: FinanceiroBaixa[] = [];
  public componente: any = null;
  public contas: Conta[] = [];
  public contasPermitidas: Conta[] = [];
  public contasSelecionadas: Conta[] = [];
  public financeiros: Financeiro[];
  public lojaPagamento: Loja;
  public numeroDocumento: string;

  public parametro: string;
  public tipoTituloCartao: boolean = false;
  public tipoTituloCheque: boolean = false;
  public tipoTituloDinheiro: boolean = false;
  public tipoTituloCheques: TipoTitulo[] = [];
  public tipoTituloFinanceiro: boolean;
  public tipoTituloPermitidos: TipoTitulo[] = [];
  public usaCartao: boolean = false;
  public valorRestante: number = 0;
  public taxasTiposTitulos: TipoTituloCaixa[] = [];
  public movimentacaoParcelas: MovimentacaoParcela[] = [];

  ngOnInit(): void {
    this.baixaEmt = this.baixa.baixaNotificacao.baixaEmt.subscribe((res) => {
      if (res == 4) {
        this.ir();
      }
    });
    this.financeiros = this.baixa.mostrarFinanceiroSelecionados();
    this.definirRegrasFinanceiroBaixa();

    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.cheque) {
        let cheques: Cheque[] = this.plainToClass(Cheque, identificacao.conteudo);
        this.adicionarExcluirBaixaCheque(cheques);
      }
    });
    this.activatedRoute.params.subscribe((params) => (this.parametro = params['parametro']));
    if (this.parametro != 'RAPIDA') {
      this.listarParceiro();
    }
  }

  replicarTitulosEContaParaTodosVazios(financeiroBaixa: FinanceiroBaixa): void {
    if (confirm(this.util.substituir(this.bibDialogo.desejaReplicarTituloEConta, [financeiroBaixa.tipoTitulo, financeiroBaixa.conta]))) {
      const financeirosBaixaDadosVazio: FinanceiroBaixa[] = this.baixa.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.idTipoTitulo == null && financeiroBaixa.idConta == null);
      financeirosBaixaDadosVazio.forEach((financeiroBaixaDadosVazio) => {
        financeiroBaixaDadosVazio.idTipoTitulo = financeiroBaixa.idTipoTitulo;
        financeiroBaixaDadosVazio.idConta = financeiroBaixa.idConta;
      })
    }
  }

  definirRegrasFinanceiroBaixa(): void {
    this.contasPermitidas = [];
    const idLojas: number[] = this.utilSessao.getLojas().map((loja) => loja.id);
    this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S')]), this.bibServico.conta).subscribe((res) => {
      this.contas = plainToClass(Conta, res) as any;
      this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('CONTA_LOJAS', idLojas.toString())]), this.bibServico.tipoTitulo).subscribe((res) => {
        this.baixa.tipoTitulos = plainToClass(TipoTitulo, res) as any;
        this.atribuirContasPermitidas();
        this.contasSelecionadas = this.contasPermitidas;
        this.tipoTituloPermitidos = this.baixa.tipoTitulos;
        this.tipoTituloCheques = this.baixa.tipoTitulos.filter((tipoTitulo) => tipoTitulo.idEspecie == 4);
        this.popularContaTipoTitulo();
        this.baixa.totalizarFinanceiroBaixa();
      });
    });
    this.ehTipoTituloCartao();
  }

  atribuirContasPermitidas(): void {
    this.utilSessao.getUsuarioContas().forEach((usuarioConta) => {
      this.contasPermitidas.push(this.contas.find((conta) => conta.id == usuarioConta.idConta));
    });
  }

  popularContaTipoTitulo(): void {
    if ((this.financeiros.length == 1 && this.financeiros[0].idTipoTitulo != undefined) || (this.financeiros.filter((financeiro) => financeiro.idTipoTitulo == this.financeiros[0].idTipoTitulo).length == this.financeiros.length && this.financeiros[0].idTipoTitulo != null)) {
      this.tipoTituloFinanceiro = this.financeiros.filter((financeiro) => financeiro.idTipoTitulo == this.financeiros[0].idTipoTitulo).length == this.baixa.mostrarFinanceiroSelecionados().length;
      if (this.utilSessao.getEmpresa().regraEspecieTipoTitulo == 'S') {
        this.tipoTituloPermitidos = this.baixa.tipoTitulos.filter((tipoTitulo) => tipoTitulo.especieNivel <= this.financeiros[0].especieNivel);
      } else {
        this.tipoTituloPermitidos = this.baixa.tipoTitulos;
      }
      if (this.tipoTituloFinanceiro && !this.baixa.utilizaConferencia) {
        const tipoTitulo: TipoTitulo = this.baixa.tipoTitulos.find((tipoTituloBusca) => tipoTituloBusca.id == this.financeiros[0].idTipoTitulo);
        this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
          if (!financeiroBaixa.idTipoTitulo) {
            this.setTipoTitulo(financeiroBaixa, tipoTitulo);
          } else {
            this.limparTipoTituloNivelMaior(financeiroBaixa, this.financeiros);
          }
        });
      }
    } else {
      if (this.utilSessao.getEmpresa().regraEspecieTipoTitulo == 'S') {
        let menorNivel: number = Math.min(...this.financeiros.filter((finaceiro) => finaceiro.especieNivel >= 0).map((financeiro) => financeiro.especieNivel));
        this.tipoTituloPermitidos = this.baixa.tipoTitulos.filter((tipoTitulo) => tipoTitulo.especieNivel <= menorNivel);
      } else {
        this.tipoTituloPermitidos = this.baixa.tipoTitulos;
      }
      this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
        this.limparTipoTituloNivelMaior(financeiroBaixa, this.tipoTituloPermitidos);
      });
    }
    this.verificarTipoDeTitulo();
    if (this.baixa.tipo != 'D') {
      this.listarParcelasETaxasCartao();
    }
  }

  listarParcelasETaxasCartao(): void {
    if (this.financeiros[0].idMovimentacao) {
      this.comunicacaoService.listar(new Transporte([new Criterio('ID_MOVIMENTACAO', this.financeiros[0].idMovimentacao)]), this.bibServico.movimentacaoParcela).subscribe((res) => {
        this.movimentacaoParcelas = plainToClass(MovimentacaoParcela, res) as any;
        const idsTiposTituloPermitidosCartao = this.tipoTituloPermitidos.filter((tipoTituloPermitido) => tipoTituloPermitido.idEspecie == 2 || tipoTituloPermitido.idEspecie == 10).map((tipoTitulo) => tipoTitulo.id);
        if (idsTiposTituloPermitidosCartao.length > 0) {
          this.comunicacaoService.listar(new Transporte(new Criterio('IDS_TIPO_TITULO', idsTiposTituloPermitidosCartao.toString())), this.bibServico.tipoTituloCaixa).subscribe((res) => {
            this.taxasTiposTitulos = plainToClass(TipoTituloCaixa, res) as any;
            this.retirarTiposDeTituloSemTaxasCadastradas();
          });
        }
      });
    }
  }

  retirarTiposDeTituloSemTaxasCadastradas(): void {
    const quantiadeParcelasMovimentacao: number = this.movimentacaoParcelas.filter((movimentacaoParcela) => movimentacaoParcela.entrada == 'N').length;
    const idsTipoTituloComTaxa: number[] = this.taxasTiposTitulos.filter((taxaTipoTitulo) => this.ehEspecieCartaoDebito(taxaTipoTitulo.idTipoTitulo) || (quantiadeParcelasMovimentacao >= taxaTipoTitulo.parcelaDe && quantiadeParcelasMovimentacao <= taxaTipoTitulo.parcelaAte)).map((taxa) => taxa.idTipoTitulo);
    const tipoTituloCartaoPermitidos: TipoTitulo[] = this.tipoTituloPermitidos.filter((tipoTituloPermitido) => idsTipoTituloComTaxa.includes(tipoTituloPermitido.id));
    const tipoTituloDiferenteCartao: TipoTitulo[] = this.tipoTituloPermitidos.filter((tipoTituloPermitido) => tipoTituloPermitido.idEspecie != 10 && tipoTituloPermitido.idEspecie != 2);
    this.tipoTituloPermitidos = tipoTituloCartaoPermitidos.concat(tipoTituloDiferenteCartao);
  }

  ehEspecieCartaoDebito(id: number): boolean {
    const DEBITO: number = 2;
    return this.tipoTituloPermitidos.find((tipoTituloPermitido) => tipoTituloPermitido.id == id && tipoTituloPermitido.idEspecie == DEBITO) != null;
  }

  limparTipoTituloNivelMaior(financeiroBaixa: FinanceiroBaixa, listas: any[]): void {
    const maiorNivel: number = Math.max(...listas.filter((lista) => lista.especieNivel >= 0).map((lista) => lista.especieNivel));
    if (maiorNivel < financeiroBaixa.tipoTituloNivel) {
      this.setTipoTitulo(financeiroBaixa, null);
    } else {
      this.contasSelecionadas = this.contasPermitidas;
    }
  }

  setTipoTitulo(financeiroBaixa: FinanceiroBaixa, tipoTitulo: TipoTitulo): void {
    if (tipoTitulo) {
      financeiroBaixa.idTipoTitulo = tipoTitulo.id;
      financeiroBaixa.tipoTitulo = tipoTitulo.nome;
      financeiroBaixa.tipoTituloNumeroTaxas = tipoTitulo.numeroTaxas;
      financeiroBaixa.obrigaNumeroDocumento = this.parametro != 'CONFERENCIA' && (tipoTitulo.idEspecie == 2 || tipoTitulo.idEspecie == 4 || tipoTitulo.idEspecie == 10) && this.baixa.valorDespesa < this.baixa.valorReceita;
      financeiroBaixa.tipoTituloIdEspecie = tipoTitulo.idEspecie;
      financeiroBaixa.tipoTituloNivel = tipoTitulo.especieNivel;
      financeiroBaixa.tipoTituloRegraRecebimento = tipoTitulo.regraRecebimento;
      financeiroBaixa.tipoTituloDia = tipoTitulo.dia;
      financeiroBaixa.tipoTituloIdConta = tipoTitulo.idConta;
      financeiroBaixa.dataPrevista = null;
      this.verificarTipoTituloContaPadrao(financeiroBaixa, tipoTitulo);
    } else {
      this.limparTipoTituloConta(financeiroBaixa);
    }
    this.verificarTipoDeTitulo();
  }

  verificarTipoTituloContaPadrao(financeiroBaixa: FinanceiroBaixa, tipoTitulo: TipoTitulo): void {
    let temPermissaoContaVinculadaTipoTitulo: boolean = false;
    if (tipoTitulo.idConta && tipoTitulo.sugestaoBaixa == 'S') {
      temPermissaoContaVinculadaTipoTitulo = this.contasPermitidas.find((conta) => conta.id == tipoTitulo.idConta) != null;
    }
    if (temPermissaoContaVinculadaTipoTitulo) {
      this.listarContaTipoTitulo(tipoTitulo.idConta).subscribe((res) => {
        const conta: Conta = this.plainToClass(Conta, res[0]) as any;
        this.setFinanceiroBaixaConta(financeiroBaixa, conta);
        this.contasSelecionadas = this.contasPermitidas.filter((conta) => conta.tipo == 'C');
        if (this.contasSelecionadas.find((contaPermitida) => contaPermitida.id == tipoTitulo.idConta) == null) {
          this.contasSelecionadas.push(conta);
        }
      });
    } else {
      this.listarCaixa().subscribe((res) => {
        const caixa: Caixa = this.plainToClass(Caixa, res[0]) as any;
        if (caixa != null) {
          financeiroBaixa.idConta = caixa.idConta;
          this.contasSelecionadas = this.contasSelecionadas.filter((conta) => conta.idCaixaVinculado == caixa.id);
        } else {
          financeiroBaixa.idConta = null;
        }
      });
    }
  }

  listarContaTipoTitulo(idConta: number): Observable<any> {
    return this.comunicacaoService.listar(new Transporte([new Criterio('ATIVO', 'S'), new Criterio('ID', idConta)]), this.bibServico.conta);
  }

  listarCaixa(): Observable<any> {
    return this.comunicacaoService.listar(new Transporte([new Criterio('CAIXA_ABERTO', 'S'), new Criterio('ID_USUARIO', this.utilSessao.getUsuario().id)]), this.bibServico.caixa);
  }

  setFinanceiroBaixaConta(financeiroBaixa: FinanceiroBaixa, conta: Conta): void {
    financeiroBaixa.idConta = conta.id;
    financeiroBaixa.conta = conta.nome;
    financeiroBaixa.idContaContaContabil = conta.idContaContabil;
    financeiroBaixa.contaContaContabil = conta.contaContabil;
  }

  limparTipoTituloConta(financeiroBaixa: FinanceiroBaixa): void {
    financeiroBaixa.idTipoTitulo = null;
    financeiroBaixa.idConta = null;
    financeiroBaixa.bloqueiaConta = false;
    financeiroBaixa.tipoTituloNivel = null;
    this.contasSelecionadas = this.contasPermitidas;
  }

  adicionarExcluirBaixaCheque(cheques: Cheque[]): void {
    this.excluirBaixaCheque(cheques);
    this.adicionarBaixaCheque(cheques);
  }

  adicionarBaixaCheque(cheques: Cheque[]): void {
    cheques.forEach((cheque) => {
      const financeiroBaixa: FinanceiroBaixa = this.baixa.financeiroBaixas.find((transferenciaCheque) => transferenciaCheque.idCheque == cheque.id);
      if (financeiroBaixa == undefined) {
        const valor: number = cheque.valor;
        const usuario: Usuario = this.utilSessao.getUsuario();
        const financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa(valor, null, cheque.idContaAtual, null, usuario.idEmpresa, usuario.id, usuario.id, 'F');
        financeiroBaixa.tipoTituloIdEspecie = 4;
        financeiroBaixa.numeroDocumento = cheque.numero;
        financeiroBaixa.idCheque = cheque.id;
        financeiroBaixa.idTipoTitulo = this.tipoTituloCheques.length == 1 ? this.tipoTituloCheques[0].id : null;
        this.validarDataConciliacaoPadrao(financeiroBaixa);
        this.baixa.adicionarFinanceiroBaixa(financeiroBaixa);
      }
    });
  }

  validarDataConciliacaoPadrao(financeiroBaixa: FinanceiroBaixa): void {
    const tipoTitulo = this.baixa.tipoTitulos.find((tipoTitulo) => tipoTitulo.id == financeiroBaixa.idTipoTitulo);
    if (tipoTitulo && tipoTitulo.diaConciliacaoPadrao != null && tipoTitulo.diaConciliacaoPadrao != 0) {
      financeiroBaixa.dataPrevista = new Date(financeiroBaixa.dataPrevista.setDate(tipoTitulo.diaConciliacaoPadrao));
    } else {
      financeiroBaixa.dataPrevista = new Date(this.baixa.dataBaixa);
    }
  }

  excluirBaixaCheque(cheques: Cheque[]): void {
    this.baixa.financeiroBaixas.forEach((financeiroBaixa, i) => {
      if (cheques.find((cheque) => cheque.id == financeiroBaixa.idCheque) == undefined) {
        this.baixa.financeiroBaixas.splice(i, 1);
      }
    });
    this.baixa.totalizarFinanceiroBaixa();
  }

  adicionar(): void {
    const financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa(this.baixa.getValorFaltante());
    this.baixa.adicionarFinanceiroBaixa(financeiroBaixa);
    if (this.lojaPagamento) {
      this.listarConfiguracao();
    }
  }

  setValor(financeiroBaixa: FinanceiroBaixa, valor: number): void {
    financeiroBaixa.valor = this.baixa.util.arredondar(valor);
    this.baixa.totalizarFinanceiroBaixa();
    if (this.lojaPagamento) {
      this.listarConfiguracao();
    }
  }

  recalcularValorTitulo(valorDescontoAnterior: number, valorJuroAnterior: number): void {
    if (this.baixa.financeiroBaixas.length == 1) {
      let valorFinalAnterior: number = this.util.arredondar(this.baixa.valorRestanteLiquido - valorDescontoAnterior + valorJuroAnterior);
      if (Number(this.baixa.financeiroBaixas[0].valorCheio.toFixed(2)) == Number(valorFinalAnterior.toFixed(2))) {
        let valorFinalNovo: number = this.util.arredondar(this.baixa.valorRestanteLiquido - this.baixa.valorDesconto + this.baixa.valorJuro);
        this.setValorCheio(this.baixa.financeiroBaixas[0], valorFinalNovo);
      }
    }
  }

  setDescontoValor(financeiroBaixa: FinanceiroBaixa, descontoValor: number): void {
    financeiroBaixa.descontoValor = descontoValor;
    this.calcularValor(financeiroBaixa);
    this.baixa.totalizarFinanceiroBaixa();
    if (this.lojaPagamento) {
      this.listarConfiguracao();
    }
  }

  setValorCheio(financeiroBaixa: FinanceiroBaixa, valorCheio: number): void {
    financeiroBaixa.valorCheio = this.util.arredondar(valorCheio ? valorCheio : 0);
    this.calcularValor(financeiroBaixa);
    this.baixa.totalizarFinanceiroBaixa();
  }

  setValorTroco(financeiroBaixa: FinanceiroBaixa, valorTroco: number): void {
    financeiroBaixa.valorTroco = valorTroco;
    this.calcularValor(financeiroBaixa);
    this.baixa.totalizarFinanceiroBaixa();
  }

  calcularValor(financeiroBaixa: FinanceiroBaixa): void {
    financeiroBaixa.valor = financeiroBaixa.valorCheio - financeiroBaixa.valorTroco;
  }

  ir(): void {
    if (this.ehValidoBaixaParcial() && this.ehValidoBaixaReceitaDespesaComJurosOuDescontoJuntas()) {
      if (this.baixa.getValorFaltante() > 0) {
        if (confirm(this.tratarMensagemValorFaltante())) {
          this.ehPersistirConciliacao();
        }
      } else {
        this.ehPersistirConciliacao();
      }
    }
  }

  ehPersistirConciliacao(): void {
    if (this.ehValido() && this.ehValidoLojaContaBancaria() && this.ehValidoTipoTitulo() && this.ehValidoCredito() && this.ehValidoCartaoCredito()) {
      this.listarMovimentacao().subscribe((res) => {
        const movimentacao: Movimentacao = this.plainToClass(Movimentacao, res[0]) as any;
        if (this.ehValidoParcelamento(movimentacao)) {
          this.listarNegociacao(movimentacao).subscribe((res) => {
            const negociacao: Negociacao = this.plainToClass(Negociacao, res[0]) as any;
            const transporte: Transporte = this.criarConciliacao(movimentacao && movimentacao.parcelamento ? this.baixa.parcelamento && this.baixa.parcelamento > 0 ? this.baixa.parcelamento : movimentacao.parcelamento : negociacao && negociacao.parcelaFixo ? negociacao.parcelaFixo : this.baixa.parcelamento > 0 ? this.baixa.parcelamento : 1);
            super.persistir(transporte, this.baixa.bibServico.conciliacao, null).subscribe(() => {
              if (!this.utilSessao.falha) {
                this.financeiroBaixas.forEach((financeiroBaixa) => (financeiroBaixa.id = null));
                this.definirId(this.financeiroBaixas, this.bibClasse.financeiroBaixa, true);
                this.baixa.idFinanceiroBaixaPagos = this.financeiroBaixas.map((financeiroBaixa) => financeiroBaixa.id);
                this.baixa.setEtapa(4);
              }
            });
          });
        }
      });
    }
  }

  ehValido(): boolean {
    const quantidadeInvalido: number = this.baixa.financeiroBaixas.filter((financeiroBaixa) => !financeiroBaixa.idConta || !financeiroBaixa.idTipoTitulo || financeiroBaixa.valor < 0 || (financeiroBaixa.obrigaNumeroDocumento && !financeiroBaixa.numeroDocumento)).length;
    if (this.baixa.dataBaixa != null && this.baixa.financeiroBaixas.length > 0 && quantidadeInvalido == 0 && this.baixa.getValorFaltante() >= 0 && this.lojaPagamento.id) {
      return true;
    }
    if (this.baixa.financeiroBaixas.length == 0) {
      this.utilSessao.setResultado(new Resultado(false, this.baixa.bibDialogo.naoPossuiBaixa));
    }
    if (quantidadeInvalido > 0) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio + ' e/ou ' + this.baixa.bibDialogo.verifiqueBaixa));
    }
    if (this.baixa.getValorFaltante() < 0) {
      if (this.baixa.valorTotalDesconto > 0) {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.baixa.bibDialogo.valorPagoMaiorValorRestanteDesconto, [this.util.moeda(this.baixa.valorTotalBaixa), this.util.moeda(this.baixa.valorRestanteLiquido), this.util.moeda(this.baixa.valorRestanteLiquido), this.util.moeda(this.baixa.valorTotalDesconto)])));
      } else {
        this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.baixa.bibDialogo.valorPagoMaiorValorRestante, [this.util.moeda(this.baixa.valorTotalBaixa), this.util.moeda(this.baixa.valorRestanteLiquido)])));
      }
    }
    if (!this.lojaPagamento) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    }
    return false;
  }

  ehValidoTipoTitulo(): boolean {
    if (this.baixa.tipo != 'D') {
      const financeiros: FinanceiroBaixa[] = this.baixa.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.tipoTituloIdEspecie == 2 || financeiroBaixa.tipoTituloIdEspecie == 10);
      if (financeiros.length > 0) {
        let contagemFinanceiroBaixaInvalido: number = 0;
        financeiros.forEach((financeiroBaixa) => {
          if (financeiroBaixa.tipoTituloNumeroTaxas == 0) {
            this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.baixa.bibDialogo.tipoDeTituloNaoPossuiTaxa, [this.menuTipoDeTitulo.apelido, financeiroBaixa.tipoTitulo])));
            contagemFinanceiroBaixaInvalido++;
          }
        });
        if (contagemFinanceiroBaixaInvalido > 0) {
          return false;
        }
      }
    }
    return true;
  }

  ehValidoLojaContaBancaria(): boolean {
    if (this.baixa.financeiroBaixas.length > 0) {
      let contagemLojaContaInvalida: number = 0;
      this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
        const conta: Conta = this.contas.find((conta) => conta.id == financeiroBaixa.idConta);
        if (conta.idLojaVinculada != null) {
          if (conta.idLojaVinculada != this.lojaPagamento.id) {
            this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.baixa.bibDialogo.contaBancariaLoja, [conta.nome, this.lojaPagamento.nomeFantasia])));
            contagemLojaContaInvalida++;
          }
        }
      });
      if (contagemLojaContaInvalida > 0) {
        return false;
      }
    }
    return true;
  }

  ehValidoBaixaParcial(): boolean {
    if (this.baixa.ehBaixaCaixa && this.baixa.getValorFaltante() > 0 && this.baixa.totalBaixaConferencia != this.baixa.valorTotalBaixa) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoPermitidoBaixaParcial));
      return false;
    }
    return true;
  }

  ehValidoBaixaReceitaDespesaComJurosOuDescontoJuntas(): boolean {
    if (this.baixa.valorDesconto > 0 || this.baixa.valorJuro > 0) {
      this.financeiros;
      const financeirosReceitaDespesa: Financeiro[] = this.financeiros.filter((financeiro) => financeiro.tipo != this.baixa.tipo);
      if (financeirosReceitaDespesa && financeirosReceitaDespesa.length > 0) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.naoPermitidoBaixaReceitaDespesaComJurosOuDesconto));
        return false;
      }
      return true;
    }
    return true;
  }

  ehValidoConferencia(): boolean {
    if (this.baixa.financeiroBaixas.length > 0 && this.baixa.getValorFaltante() >= 0 && this.lojaPagamento) {
      return true;
    }
    if (this.baixa.getValorFaltante() < 0) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.baixa.bibDialogo.valorPagoMaiorValorRestante, [this.baixa.valorTotalBaixa, this.baixa.valorRestanteLiquido])));
    }
    if (!this.lojaPagamento) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    }
    return false;
  }

  ehValidoCredito(): boolean {
    const financeirosCredito: FinanceiroBaixa[] = this.baixa.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.tipoTituloIdEspecie == 7);
    const soma = financeirosCredito.map((financeiroBaixa) => financeiroBaixa.valor).reduce((a, b) => a + b, 0);
    if (soma > this.baixa.parceiroCredito) {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.semCreditoSuficiente));
      return false;
    }
    return true;
  }

  ehValidoCartaoCredito(): boolean {
    if (this.parametro != 'RAPIDA') {
      let existemTitulosDuplicados: boolean = false;
      this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
        let qtdTitulosIguais: number = this.baixa.financeiroBaixas.filter((baixa) => baixa.idConta == financeiroBaixa.idConta && baixa.idTipoTitulo == financeiroBaixa.idTipoTitulo && baixa.numeroDocumento == financeiroBaixa.numeroDocumento && financeiroBaixa.numeroDocumento != "").length;
        if (qtdTitulosIguais > 1) {
          existemTitulosDuplicados = true;
        }
      });
      if (existemTitulosDuplicados) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.titulosBaixaDuplicados));
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  ehValidoParcelamento(movimentacao: Movimentacao): boolean {
    if (movimentacao && movimentacao.parcelamento && this.baixa.parcelamento && this.baixa.parcelamento > movimentacao.parcelamento) {
      this.utilSessao.setResultado(new Resultado(false, this.util.substituir(this.bibDialogo.cartaoParcelamentoMaximo, [movimentacao.parcelamento])));
      return false;
    }
    return true;
  }

  criarConciliacao(quantidadeParcelas: number): Transporte {
    let conciliacoes: Conciliacao[] = [];
    this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
      const especiesCartaoCreditoCartaoDebitoPix: number[] = [2, 10, 11];
      let chaveCartaoTransferencia: string;
      if (financeiroBaixa.tipoTituloIdConta != null && financeiroBaixa.idConta != financeiroBaixa.tipoTituloIdConta && this.baixa.tipo == 'R' && especiesCartaoCreditoCartaoDebitoPix.includes(financeiroBaixa.tipoTituloIdEspecie)) {
        chaveCartaoTransferencia = this.util.criarId();
      }
      conciliacoes = this.criarConciliacoes(financeiroBaixa, quantidadeParcelas, conciliacoes, this.baixa.tipo, financeiroBaixa.idConta, false, true, chaveCartaoTransferencia);
      if (chaveCartaoTransferencia != null) {
        conciliacoes = this.criarConciliacaoReceitaContaCartao(conciliacoes, financeiroBaixa, quantidadeParcelas, chaveCartaoTransferencia);
        conciliacoes = this.criarConciliacaoDespesaContaBaixa(conciliacoes, financeiroBaixa, quantidadeParcelas, chaveCartaoTransferencia);
      }
      conciliacoes = this.atualizarValorLiquido(conciliacoes, quantidadeParcelas);
    });
    const transporte: Transporte = new Transporte(conciliacoes);
    return transporte;
  }

  criarConciliacaoReceitaContaCartao(conciliacoes: Conciliacao[], financeiroBaixa: FinanceiroBaixa, quantidadeParcelas: number, chaveCartaoTransferencia: string): Conciliacao[] {
    return this.criarConciliacoes(financeiroBaixa, quantidadeParcelas, conciliacoes, this.baixa.tipo, financeiroBaixa.tipoTituloIdConta, false, false, chaveCartaoTransferencia);
  }

  criarConciliacaoDespesaContaBaixa(conciliacoes: Conciliacao[], financeiroBaixa: FinanceiroBaixa, quantidadeParcelas: number, chaveCartaoTransferencia: string): Conciliacao[] {
    return this.criarConciliacoes(financeiroBaixa, quantidadeParcelas, conciliacoes, 'D', financeiroBaixa.idConta, true, false, chaveCartaoTransferencia);
  }

  atualizarValorLiquido(conciliacoes: Conciliacao[], quantidadeParcelas: number): Conciliacao[] {
    conciliacoes.forEach((conciliacao) => {
      let taxa: number = this.taxasTiposTitulos.find((taxaTipoTitulo) => taxaTipoTitulo.idTipoTitulo == conciliacao.idTipoTitulo && taxaTipoTitulo.parcelaDe <= quantidadeParcelas && taxaTipoTitulo.parcelaAte >= quantidadeParcelas)?.taxa;
      conciliacao.valorLiquido = taxa ? conciliacao.valor - (conciliacao.valor * taxa) / 100 : conciliacao.valor;
    });
    return conciliacoes;
  }

  /* Em funcionamento - Aguardando refatoração */
  listarParceiro(): void {
    const idParceiro: number = this.baixa.mostrarFinanceiroSelecionados()[0].idParceiro;
    if (this.baixa.parceiro == null || this.baixa.parceiro.id != idParceiro) {
      this.comunicacaoService.listar(new Transporte([new Criterio('ID', idParceiro)]), this.baixa.bibServico.parceiro).subscribe((res) => {
        this.baixa.setParceiro(plainToClass(Parceiro, res[0]));
      });
    }
  }

  /* Em funcionamento - Aguardando refatoração */
  listarMovimentacao(): Observable<any> {
    const idMovimentacao: number = this.baixa.mostrarFinanceiroSelecionados()[0].idMovimentacao;
    const cartaoCredito: number = 10;
    const temTituloComCartao: boolean = this.baixa.financeiroBaixas.filter((titulo) => titulo.tipoTituloIdEspecie == cartaoCredito).length > 0;
    if (idMovimentacao && temTituloComCartao) {
      return this.comunicacaoService.listar(new Transporte(new Criterio('ID', idMovimentacao)), this.bibServico.movimentacao);
    }
    return of({});
  }

  listarNegociacao(movimentacao: Movimentacao): Observable<any> {
    if (movimentacao && movimentacao.idNegociacao) {
      return this.comunicacaoService.listar(new Transporte(new Criterio('ID', movimentacao.idNegociacao)), this.bibServico.negociacao);
    }
    return of({});
  }

  /* Em funcionamento - Aguardando refatoração */
  criarConciliacoes(financeiroBaixa: FinanceiroBaixa, quantidadeParcelas: number, conciliacoes: Conciliacao[], tipo: string, idConta: number, conciliacaoCartaoEstornoAutomatico: boolean, vinculaBaixa: boolean, chaveCartaoTransferencia: string): Conciliacao[] {
    const cartaoCredito: number = 10;
    if (financeiroBaixa.tipoTituloIdEspecie == cartaoCredito && quantidadeParcelas > 0) {
      conciliacoes = this.adicionarConciliacaoPorParcelaCartao(financeiroBaixa, quantidadeParcelas, conciliacoes, tipo, idConta, conciliacaoCartaoEstornoAutomatico, vinculaBaixa, chaveCartaoTransferencia);
    } else {
      let conciliacao: Conciliacao = this.adicionarConciliacao(financeiroBaixa, null, this.baixa.dataBaixa, null, tipo, idConta, conciliacaoCartaoEstornoAutomatico, vinculaBaixa, chaveCartaoTransferencia, 0)
      conciliacoes.push(conciliacao);
      const dinheiro: number = 1;
      if (financeiroBaixa.tipoTituloIdEspecie != dinheiro && financeiroBaixa.valorTroco > 0) {
        conciliacao = this.adicionarConciliacao(financeiroBaixa, null, this.baixa.dataBaixa, null, tipo == 'D' ? 'R' : 'D', idConta, conciliacaoCartaoEstornoAutomatico, vinculaBaixa, chaveCartaoTransferencia, financeiroBaixa.valorTroco)
        conciliacoes.push(conciliacao);
      }
    }
    this.adicionarChaveConciliacoes(conciliacoes);
    return conciliacoes;
  }

  /* Em funcionamento - Aguardando refatoração */
  adicionarConciliacao(financeiroBaixa: FinanceiroBaixa, valor: number = null, dataVencimento: Date = null, parcela: string = null, tipo: string, idConta: number, conciliacaoCartaoEstornoAutomatico: boolean, vinculaBaixa: boolean, chaveCartaoTransferencia: string, valorTroco: number = 0): Conciliacao {
    let conciliacao: Conciliacao = new Conciliacao();
    conciliacao.idEmpresa = this.lojaPagamento.idEmpresa;
    conciliacao.idConta = idConta;
    conciliacao.valor = valorTroco > 0 ? valorTroco : valor ? valor : financeiroBaixa.valorCheio;
    conciliacao.valorTotal = financeiroBaixa.valor;
    if (financeiroBaixa.troco == false) {
      this.ehCalcularDescontoJuro(conciliacao, financeiroBaixa.valor);
    }
    conciliacao.tipo = tipo;
    conciliacao.idLoja = this.lojaPagamento.id;
    const dataPrevista: Date = financeiroBaixa.dataPrevista ? financeiroBaixa.dataPrevista : (this.baixa.dataBaixa ? this.baixa.dataBaixa : dataVencimento);
    conciliacao.dataPrevista = this.ajustarDataRapidaAnalisandoRegraTipoTitulo(dataPrevista, financeiroBaixa.tipoTituloRegraRecebimento, financeiroBaixa.tipoTituloDia);

    conciliacao.observacao = financeiroBaixa.observacao;
    if (this.baixa.mostrarFinanceiroSelecionados().filter((financeiro) => financeiro.idParceiro == this.baixa.mostrarFinanceiroSelecionados()[0].idParceiro).length == this.baixa.mostrarFinanceiroSelecionados().length) {
      conciliacao.idParceiro = this.baixa.mostrarFinanceiroSelecionados()[0].idParceiro;
      conciliacao.nomeParceiro = this.baixa.mostrarFinanceiroSelecionados()[0].parceiro;
    }
    conciliacao.numeroDocumento = valorTroco > 0 ? null : financeiroBaixa.numeroDocumento;
    conciliacao.idCheque = financeiroBaixa.idCheque ? financeiroBaixa.idCheque : null;
    const conta: Conta = this.contas.find((conta) => conta.id == idConta);
    conciliacao.idCaixa = conta.idCaixaVinculado ? conta.idCaixaVinculado : null;
    conciliacao.origem = 0;
    conciliacao.parcela = parcela;
    conciliacao.dataBaixa = this.baixa.dataBaixa;
    if (valorTroco > 0) {
      const dinheiro: number = 1;
      const tipoTituloTroco: TipoTitulo = this.tipoTituloPermitidos.find((tipoTitulo) => tipoTitulo.idEspecie == dinheiro);
      conciliacao.idTipoTitulo = tipoTituloTroco.id;
    } else {
      conciliacao.idTipoTitulo = financeiroBaixa.idTipoTitulo;
    }
    const tipoTitulo: TipoTitulo = this.tipoTituloPermitidos.find((tipoTitulo) => tipoTitulo.id == conciliacao.idTipoTitulo);
    conciliacao.conciliado = conciliacaoCartaoEstornoAutomatico ? 'C' : conta.conciliacaoAutomatica == 'S' || tipoTitulo.conciliacaoAutomatica == 'S' ? 'S' : 'N';
    conciliacao.dataConciliacao = conciliacaoCartaoEstornoAutomatico ? null : conta.conciliacaoAutomatica == 'S' || tipoTitulo.conciliacaoAutomatica == 'S' ? this.baixa.dataBaixa : null;
    if (valorTroco > 0) {
      const dinheiro: number = 1;
      const tipoTituloTroco: TipoTitulo = this.tipoTituloPermitidos.find((tipoTitulo) => tipoTitulo.idEspecie == dinheiro);
      conciliacao.idTipoTitulo = tipoTituloTroco.id;
    } else {
      conciliacao.idTipoTitulo = financeiroBaixa.idTipoTitulo;
    }
    if (vinculaBaixa) {
      conciliacao.financeiroBaixas = this.criarFinanceiroBaixa(financeiroBaixa, conciliacao.valor, conciliacao.dataPrevista, conciliacao.conciliado, valorTroco);
    }
    if (this.baixa.baixaRapida) {
      const idFinanceiro: number = conciliacao.financeiroBaixas[0].idFinanceiro;
      const financeiro: Financeiro = this.baixa.mostrarFinanceiroSelecionados().find((financeiro) => financeiro.id == idFinanceiro);
      conciliacao.idParceiro = financeiro.idParceiro;
      conciliacao.nomeParceiro = financeiro.parceiro;
    }
    conciliacao.chaveCartaoTransferencia = chaveCartaoTransferencia;
    conciliacao.valorTroco = this.baixa.valorTotalTroco;
    return conciliacao;
  }

  ehCalcularDescontoJuro(conciliacao: Conciliacao, valor: number): void {
    let descontoConciliacao: number = 0;
    let jutoConciliacao: number = 0;
    const valorTotalBaixa: number = (this.baixa.valorTotalBaixaCheio - this.baixa.valorTotalTroco);
    if (valorTotalBaixa > 0) {
      descontoConciliacao = (valor * this.baixa.valorDesconto) / valorTotalBaixa;
      jutoConciliacao = (valor * this.baixa.valorJuro) / valorTotalBaixa;
    }
    conciliacao.juro = jutoConciliacao;
    conciliacao.desconto = descontoConciliacao;
  }

  /* Em funcionamento - Aguardando refatoração */
  ajustarDataRapidaAnalisandoRegraTipoTitulo(dataPrevista: Date, tipoTituloRegraRecebimento: number = 1, tipoTituloDia: number = 1): Date {
    const respeitaNegociacao: number = 1;
    const diasAntecipacao: number = 2;
    const diaFixo: number = 3;

    switch (Number(tipoTituloRegraRecebimento)) {
      case respeitaNegociacao:
        return dataPrevista;

      case diasAntecipacao:
        const dataHoje: Date = new Date();
        return addDays(dataHoje, tipoTituloDia);

      case diaFixo:
        const diaPrevisto: number = dataPrevista.getDate();
        let dataPrevistaNova: Date = setDate(dataPrevista, tipoTituloDia);
        if (diaPrevisto >= tipoTituloDia) {
          dataPrevistaNova = addMonths(dataPrevistaNova, 1);
        }
        return dataPrevistaNova;
    }
  }

  criarFinanceiroBaixa(titulo: FinanceiroBaixa, valorConciliacao: number, dataPrevista: Date, conciliado: string, valorTroco: number): FinanceiroBaixa[] {
    const financeiroBaixas: FinanceiroBaixa[] = [];
    valorConciliacao = valorConciliacao - titulo.valorTroco;
    let valorTotalBaixa = this.baixa.valorTotalBaixa;
    if (valorTroco > 0) {
      valorTotalBaixa = valorTotalBaixa + this.baixa.valorDesconto;
    }
    let financeiros: Financeiro[] = [];
    if (this.baixa.baixaRapida) {
      financeiros = this.baixa.mostrarFinanceiroSelecionados().filter((financeiro) => financeiro.getValorAPagarTotal() > 0 && financeiro.id == titulo.idFinanceiro);
    } else {
      financeiros = this.baixa.mostrarFinanceiroSelecionados().filter((financeiro) => financeiro.getValorAPagarTotal() > 0);
    }
    const totalAPagarFinanceirosSelecionados: number = financeiros.reduce((valor, financeiro) => valor + financeiro.valorRestante, 0);
    financeiros.forEach((financeiro) => {
      let percentualValorTitulo: number = 0;
      if (this.baixa.baixaRapida) {
        percentualValorTitulo = this.baixa.getProporcao(valorConciliacao, titulo.valorCheio);
      } else {
        percentualValorTitulo = this.baixa.getProporcao(valorConciliacao, valorTotalBaixa);
      }
      const valorAPagarTotal: number = financeiro.getValorAPagarTotal();
      const valorProporcional: number = valorAPagarTotal * percentualValorTitulo;
      let juroAutomaticoProporcionalAoTitulo: number = 0;
      let juroManualProporcionalAoTitulo: number = 0;
      if (valorTroco == 0) {
        juroAutomaticoProporcionalAoTitulo = (valorProporcional * financeiro.juroAutomatico) / financeiro.valorRestante;
        juroManualProporcionalAoTitulo = (valorProporcional * this.baixa.valorJuro) / totalAPagarFinanceirosSelecionados;
      }

      if (valorTotalBaixa < financeiro.juroAutomatico) {
        percentualValorTitulo = (valorAPagarTotal / valorProporcional);
      }
      const financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa();
      financeiroBaixa.idFinanceiro = financeiro.id;
      financeiroBaixa.idConta = titulo.idConta;
      financeiroBaixa.conta = titulo.conta;
      const dinheiro: number = 1;
      let tipoTituloTroco: TipoTitulo = this.tipoTituloPermitidos.find((tipoTitulo) => tipoTitulo.idEspecie == dinheiro);
      financeiroBaixa.idTipoTitulo = valorTroco > 0 ? tipoTituloTroco.id : titulo.idTipoTitulo;
      financeiroBaixa.idEmpresa = financeiro.idEmpresa;
      financeiroBaixa.tipoTitulo = valorTroco > 0 ? tipoTituloTroco.nome : titulo.tipoTitulo;
      financeiroBaixa.idContaContaContabil = titulo.idContaContaContabil;
      financeiroBaixa.contaContaContabil = titulo.contaContaContabil;
      financeiroBaixa.parcela = financeiro.parcela;
      financeiroBaixa.idUsuarioAlteracao = this.utilSessao.getUsuario().id;
      financeiroBaixa.idUsuarioInclusao = this.utilSessao.getUsuario().id;
      financeiroBaixa.numeroDocumento = valorTroco > 0 ? null : titulo.numeroDocumento;
      financeiroBaixa.idLoja = this.lojaPagamento.id;
      financeiroBaixa.valor = valorProporcional - juroAutomaticoProporcionalAoTitulo;
      financeiroBaixa.tipo = valorTroco > 0 ? financeiro.tipo == 'D' ? 'R' : 'D' : financeiro.tipo;
      financeiroBaixa.troco = valorTroco > 0;
      financeiroBaixa.chaveGeral = this.baixa.chaveGeral;
      let valorReceitaOuDespesa: number = 0;
      if (this.baixa.tipo == 'R') {
        valorReceitaOuDespesa = this.baixa.valorReceita;
      } else {
        valorReceitaOuDespesa = this.baixa.valorDespesa;
      }
      let descontoProporcionalAoTitulo: number = 0;
      if (this.baixa.valorTotalBaixaCheio > 0 && valorTroco == 0) {
        descontoProporcionalAoTitulo = (valorConciliacao * this.baixa.valorDesconto) / this.baixa.valorTotalBaixa;
        descontoProporcionalAoTitulo = (financeiro.valorRestante * descontoProporcionalAoTitulo) / totalAPagarFinanceirosSelecionados;
      }
      financeiroBaixa.valorCheio = valorProporcional - descontoProporcionalAoTitulo + (this.baixa.valorJuro > 0 ? juroManualProporcionalAoTitulo : 0);
      financeiroBaixa.descontoValor = descontoProporcionalAoTitulo;
      financeiroBaixa.juroValor = juroAutomaticoProporcionalAoTitulo;
      financeiroBaixa.juroManual = this.baixa.valorJuro > 0 ? juroManualProporcionalAoTitulo : 0;
      financeiroBaixa.valorTroco = 0;
      financeiroBaixa.idMovimentacao = financeiro.idMovimentacao;
      financeiroBaixa.idCheque = titulo.idCheque ? titulo.idCheque : null;
      financeiroBaixa.observacao = titulo.observacao;
      financeiroBaixa.dataPrevista = dataPrevista;
      financeiroBaixa.dataBaixa = this.baixa.dataBaixa;
      financeiroBaixa.conciliado = conciliado;
      financeiroBaixas.push(financeiroBaixa);
    });
    return financeiroBaixas;
  }

  /* Em funcionamento - Aguardando refatoração */
  adicionarDiferenca(valor: number, valorRateado: number): number {
    return valor - valorRateado;
  }

  /* Em funcionamento - Aguardando refatoração */
  adicionarConciliacaoPorParcelaCartao(financeiroBaixa: FinanceiroBaixa, quantidadeParcelas: number, conciliacoes: Conciliacao[], tipo: string, idConta: number, conciliacaoCartaoEstornoAutomatico: boolean, vinculaBaixa: boolean, chaveCartaoTransferencia: string): Conciliacao[] {
    let valorRateado: number = 0;
    let i: number = 1;
    while (quantidadeParcelas >= i) {
      const parcela: string = i + '/' + quantidadeParcelas;
      let valorDividido: number = this.util.arredondar(financeiroBaixa.valor / quantidadeParcelas);
      const ultimaParcela: boolean = i == quantidadeParcelas;
      if (ultimaParcela) {
        valorDividido = this.util.arredondar(valorDividido + (this.adicionarDiferenca(financeiroBaixa.valor, valorRateado) - valorDividido));
      }
      valorRateado += valorDividido;
      const dataVencimento: Date = addMonths(financeiroBaixa.dataPrevista, i);
      conciliacoes.push(this.adicionarConciliacao(financeiroBaixa, valorDividido, dataVencimento, parcela, tipo, idConta, conciliacaoCartaoEstornoAutomatico, vinculaBaixa, chaveCartaoTransferencia, 0));
      i++;
    }
    return conciliacoes;
  }

  /* Em funcionamento - Aguardando refatoração */
  setValorDesconto(valorDesconto: number) {
    const valorDescontoAnterior: number = this.util.arredondar(this.baixa.valorDesconto ? this.baixa.valorDesconto : 0);
    this.baixa.valorDesconto = this.util.arredondar(valorDesconto ? valorDesconto : 0);
    this.recalcularValorTitulo(valorDescontoAnterior, this.baixa.valorJuro);
    this.baixa.totalizarFinanceiroBaixa();
  }

  /* Em funcionamento - Aguardando refatoração */
  setValorAcrescimo(valorAcrescimo: number) {
    const valorJuroAnterior: number = this.util.arredondar(this.baixa.valorJuro ? this.baixa.valorJuro : 0);
    this.baixa.valorJuro = this.util.arredondar(valorAcrescimo ? valorAcrescimo : 0);
    this.recalcularValorTitulo(this.baixa.valorDesconto, valorJuroAnterior);
    this.baixa.totalizarFinanceiroBaixa();
  }

  /* Em funcionamento - Aguardando refatoração */
  ehPersistirConferencia(): void {
    if (this.ehValidoConferencia()) {
      if (this.baixa.getValorFaltante() > 0) {
        if (confirm(this.tratarMensagemValorFaltante())) {
          this.persistirConferencia();
        }
      } else {
        this.persistirConferencia();
      }
    }
  }

  tratarMensagemValorFaltante(): string {
    const valorSelecionado: number = this.baixa.valorRestanteLiquido - this.baixa.valorDesconto + this.baixa.valorJuro;
    return this.util.substituir(this.bibDialogo.baixarValorMenorTituloSelecionado, [this.util.moeda(this.baixa.valorTotalBaixa), this.util.moeda(valorSelecionado), this.util.moeda(this.baixa.getValorFaltante())]);
  }

  persistirConferencia(): void {
    const conferencia: Conferencia = this.criarConferencia();
    this.persistir(new Transporte(conferencia), this.baixa.bibServico.conferencia, null).subscribe((res) => {
      this.gerarRelatorioNegociacaoPagamentoDuplicataMercantil(this.plainToClass(Resultado, res) as any);
    });
  }

  /* Em funcionamento - Aguardando refatoração */
  criarConferencia(): Conferencia {
    const conferencia: Conferencia = new Conferencia();
    conferencia.prioridade = this.baixa.prioridadeBaixa;
    conferencia.idLoja = this.lojaPagamento.id;
    conferencia.idParceiro = this.baixa.parceiro.id;
    conferencia.valorDesconto = this.baixa.valorDesconto;
    conferencia.valorAcrescimo = this.baixa.valorJuro;
    conferencia.valor = this.baixa.valorTotalBaixa;
    conferencia.tipo = this.baixa.tipo;
    conferencia.origem = this.parametro == 'DUPLICATA' ? 'D' : 'C';
    this.criarConferenciaTitulo(conferencia);
    this.criarConferenciaFinanceiro(conferencia);
    return conferencia;
  }

  /* Em funcionamento - Aguardando refatoração */
  criarConferenciaTitulo(conferencia: Conferencia): void {
    this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
      let conferenciaTitulo: ConferenciaTitulo = new ConferenciaTitulo();
      conferenciaTitulo.dataBaixa = this.baixa.dataBaixa;
      conferenciaTitulo.idTipoTitulo = financeiroBaixa.idTipoTitulo;
      conferenciaTitulo.idConta = financeiroBaixa.idConta;
      conferenciaTitulo.valor = financeiroBaixa.valor;
      conferenciaTitulo.numeroDocumento = financeiroBaixa.numeroDocumento;
      conferenciaTitulo.descontoValor = financeiroBaixa.descontoValor;
      conferenciaTitulo.valorTroco = financeiroBaixa.valorTroco;
      conferenciaTitulo.valorCheio = financeiroBaixa.valorCheio;
      conferenciaTitulo.observacao = financeiroBaixa.observacao;
      conferencia.conferenciaTitulos.push(conferenciaTitulo);
    });
  }

  /* Em funcionamento - Aguardando refatoração */
  criarConferenciaFinanceiro(conferencia: Conferencia): void {
    this.baixa.mostrarFinanceiroSelecionados().forEach((financeiro) => {
      let conferenciaFinanceiro: ConferenciaFinanceiro = new ConferenciaFinanceiro();
      conferenciaFinanceiro.idFinanceiro = financeiro.id;
      conferencia.conferenciaFinanceiros.push(conferenciaFinanceiro);
    });
  }

  formatarHistorico(historico: string, tipo: string, financeiroBaixa: FinanceiroBaixa): string {
    if (historico.length > 0) {
      historico = historico.replace('<<PARCEIRO>>', this.baixa.financeiros[0].parceiro);
      historico = historico.replace('<<TIPO DE TÍTULO>>', financeiroBaixa.tipoTitulo);
      const parcelas: string[] = this.baixa
        .mostrarFinanceiroSelecionados()
        .filter((financeiro) => financeiro.tipo == tipo)
        .map((financeiro) => financeiro.parcela);
      historico = historico.replace('<<PARCELA>>', parcelas && parcelas.length == 1 ? (parcelas[0] == '01/01' ? '' : parcelas[0]) : 'Nº parcelas');
      historico = historico.replace(
        '<<Nº DOCUMENTO>>',
        this.baixa
          .mostrarFinanceiroSelecionados()
          .filter((financeiro) => financeiro.tipo == tipo)
          .map((financeiro) => financeiro.numero)
          .toString()
      );
      const operacoes = [
        ...new Set(
          this.baixa
            .mostrarFinanceiroSelecionados()
            .filter((financeiro) => financeiro.tipo == tipo)
            .map((financeiro) => financeiro.origem)
        ),
      ];

      historico = historico.replace('<<OPERAÇÃO>>', operacoes.toString());

      if (historico.length > 150) {
        return this.util.truncarStringAdicionarReticencias(historico, 150);
      }
    }
    return historico;
  }

  setObservacao(financeiroBaixa: FinanceiroBaixa, observacao: string): void {
    financeiroBaixa.observacao = this.formatarHistorico(observacao, this.baixa.tipo, financeiroBaixa);
  }

  gerarRelatorioNegociacaoPagamentoDuplicataMercantil(resultados: Resultado[]): void {
    if (!this.utilSessao.falha) {
      this.utilSessao.setIdentificacao(new Identificacao('controlaPermissao', false));
      if (this.parametro == 'DUPLICATA') {
        this.baixa.util.abrirRelatorio(resultados[0].id, 2028, true, true);
      } else {
        this.baixa.util.abrirRelatorio(resultados[0].id, 2011, true, true);
      }
      this.atualizarConferenciaNumero(resultados[0].id);
    }
  }

  atualizarConferenciaNumero(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.conferencia).subscribe((res) => {
      this.baixa.conferenciaNumero = (this.plainToClass(Conferencia, res[0]) as any).numero;
      this.baixa.setEtapa(4);
    });
  }

  multiplicarBaixaFinanceiro(): void {
    this.financeiroBaixas = [];
    this.financeiroBaixaPros = [];
    this.baixa.financeiroBaixas.forEach((financeiroBaixa, posicao) => {
      financeiroBaixa.posicao = posicao;
      let financeiroComValorPagars: Financeiro[] = this.baixa.mostrarFinanceiroSelecionados().filter((financeiro) => financeiro.getValorAPagarTotal() > 0);
      let indice: number = 0;
      for (const financeiro of financeiroComValorPagars) {
        indice++;
        this.criarFinanceiroBaixaAntigo(financeiro, financeiroBaixa, indice == financeiroComValorPagars.length);
      }
    });
    this.verificarArredondamentoFinanceiro();
  }

  criarFinanceiroBaixaAntigo(financeiro: Financeiro, financeiroBaixa: FinanceiroBaixa, ultimo: boolean): void {
    const valorAPagarProporcional: number = this.baixa.getProporcao(financeiroBaixa.valor, this.baixa.valorTotalBaixa) * financeiro.getValorAPagarTotal();
    financeiro.setValorAPagarProporcional(valorAPagarProporcional);
    const usuario: Usuario = this.utilSessao.getUsuario();
    const valorTroco: number = this.calcularValorTrocoProporcional(financeiroBaixa, ultimo);
    const financeiroBaixaJuro: number = this.calcularFinanceiroBaixaJuroProporcional(financeiro, financeiroBaixa);
    const financeiroBaixaNova = new FinanceiroBaixa(financeiro.getValorAPagarProporcional() + financeiroBaixaJuro, financeiro.id, financeiroBaixa.idConta, financeiroBaixa.idTipoTitulo, usuario.idEmpresa, usuario.id, usuario.id, 'F', financeiroBaixa.numeroDocumento, financeiro.idLoja, financeiroBaixa.descontoValor, financeiroBaixaJuro, valorTroco);
    financeiroBaixaNova.posicao = financeiroBaixa.posicao;
    financeiroBaixaNova.idMovimentacao = financeiro.idMovimentacao;
    financeiroBaixaNova.idCheque = financeiroBaixa.idCheque ? financeiroBaixa.idCheque : null;
    financeiroBaixaNova.observacao = financeiroBaixa.observacao;
    financeiroBaixaNova.dataPrevista = financeiroBaixa.dataPrevista;
    this.financeiroBaixas.push(financeiroBaixaNova);
    this.multiplicarBaixaFinanceiroProduto(this.baixa.listarMovimentacaoProduto(financeiro.baixaMovimentacaoProdutos), financeiroBaixa.valor, this.baixa.valorTotalBaixa, financeiroBaixa.posicao, financeiro);
  }

  calcularValorTrocoProporcional(financeiroBaixa: FinanceiroBaixa, ultimo: boolean): number {
    let trocoValor: number = 0;
    if (ultimo) {
      let trocoValorCalculado: number = 0;
      this.financeiroBaixas.filter((financeiroBaixaBusca) => financeiroBaixaBusca.posicao == financeiroBaixa.posicao).forEach((financeiroBaixaBusca) => (trocoValorCalculado += financeiroBaixaBusca.valorTroco));
      trocoValor = financeiroBaixa.valorTroco - trocoValorCalculado;
    } else {
      let financeiroContagem: number = this.baixa.mostrarFinanceiroSelecionados().filter((financeiro) => financeiro.getValorAPagarTotal() > 0).length;
      trocoValor = this.util.arredondar(financeiroBaixa.valorTroco / financeiroContagem);
    }
    return trocoValor;
  }

  calcularFinanceiroBaixaJuroProporcional(financeiro: Financeiro, financeiroBaixa: FinanceiroBaixa): number {
    let juroValor: number = 0;
    if (financeiroBaixa.posicao + 1 == this.baixa.financeiroBaixas.length) {
      let juroValorCalculado: number = 0;
      this.financeiroBaixas.filter((financeiroBaixaBusca) => financeiroBaixaBusca.idFinanceiro == financeiro.id).forEach((financeiroBaixaBusca) => (juroValorCalculado += financeiroBaixaBusca.juroValor));
      juroValor = financeiro.juroAPagar - juroValorCalculado;
    } else {
      juroValor = this.baixa.getProporcao(financeiroBaixa.valor, this.baixa.valorTotalBaixa) * financeiro.juroAPagar;
    }
    return juroValor;
  }

  multiplicarBaixaFinanceiroProduto(movimentacaoProdutos: BaixaMovimentacaoProduto[], valorFinanceiroBaixa: number, valorTotalFinanceiroBaixa: number, posicao: number, financeiro: Financeiro): void {
    movimentacaoProdutos.forEach((movimentacaoProduto) => {
      let financeiroBaixaPro = new FinanceiroBaixaPro();
      financeiroBaixaPro.posicao = posicao;
      financeiroBaixaPro.idFinanceiro = financeiro.id;
      financeiroBaixaPro.idMovimentacaoPro = movimentacaoProduto.id;
      financeiroBaixaPro.setValor(this.baixa.util.arredondar((movimentacaoProduto.valorRestante * valorFinanceiroBaixa) / this.baixa.valorRestanteLiquido));
      this.financeiroBaixaPros.push(financeiroBaixaPro);
    });
  }

  verificarArredondamentoFinanceiro(): void {
    for (const financeiroBaixa of this.baixa.financeiroBaixas) {
      const _financeiroBaixas: FinanceiroBaixa[] = this.financeiroBaixas.filter((_financeiroBaixa) => _financeiroBaixa.posicao == financeiroBaixa.posicao);
      let valorTotalBaixa: number = 0;
      for (let _financeiroBaixa of _financeiroBaixas) {
        valorTotalBaixa += _financeiroBaixa.getValor();
      }
      const diferenca = valorTotalBaixa - financeiroBaixa.getValor();
      _financeiroBaixas[0].setValor(_financeiroBaixas[0].getValor() - diferenca);
    }
  }

  listarParcelas(conciliacoes: Conciliacao[]): Observable<any> {
    const pagamentoCartao: boolean = this.ehPagamentoCartao(conciliacoes);
    if (pagamentoCartao) {
      const idMovimentacao: number = this.baixa.mostrarFinanceiroSelecionados()[0].idMovimentacao;
      return this.comunicacaoService.listar(new Transporte([new Criterio('ID_MOVIMENTACAO', idMovimentacao), new Criterio('IDS_ESPECIE', '2, 10')]), this.bibServico.movimentacaoParcela);
    }
    return of(null);
  }

  ehPagamentoCartao(conciliacoes: Conciliacao[]): boolean {
    return conciliacoes.filter((conciliacao) => conciliacao.tipoTituloIdEspecie == 2 || conciliacao.tipoTituloIdEspecie == 10).length > 0;
  }

  adicionarChaveConciliacoes(conciliacoes: Conciliacao[]): void {
    let conciliacaoChave: string = 'F' + new Date().getTime().toString() + '_' + this.utilSessao.getUsuario().id;
    conciliacoes.forEach((conciliacao) => {
      conciliacao.chave = conciliacaoChave + '_' + conciliacao.idTipoTitulo.toString() + (conciliacao.numeroDocumento != null ? '_' + conciliacao.numeroDocumento : '');
    });
  }

  dividirValorPagoMovimentacao(movimentacaoParcelas: MovimentacaoParcela[], conciliacoes: Conciliacao[]): Conciliacao[] {
    if (this.baixa.utilizaEspecieCartao) {
      let conciliacaoParcelas: Conciliacao[] = [];
      conciliacoes.forEach((conciliacao) => {
        if (conciliacao.tipoTituloIdEspecie == 2 || conciliacao.tipoTituloIdEspecie == 10) {
          this.gerarConciliacaoParceladaCartao(movimentacaoParcelas, conciliacao).forEach((conciliacaoParcelasNova) => {
            conciliacaoParcelas.push(conciliacaoParcelasNova);
          });
        } else {
          conciliacaoParcelas.push(this.adicionarParcela(conciliacao));
        }
      });
      return conciliacaoParcelas;
    } else {
      conciliacoes.forEach((conciliacao) => {
        this.adicionarParcela(conciliacao);
      });
    }
    return conciliacoes;
  }

  adicionarParcela(conciliacao: Conciliacao): Conciliacao {
    conciliacao.parcela = '1/1';
    return conciliacao;
  }

  gerarConciliacaoParceladaCartao(movimentacaoParcelas: MovimentacaoParcela[], conciliacao: Conciliacao): Conciliacao[] {
    let conciliacaoParcelas: Conciliacao[] = [];
    const chave: string = 'F' + new Date().getTime().toString() + '_' + this.utilSessao.getUsuario().id + '_' + conciliacao.idTipoTitulo.toString() + conciliacao.numeroDocumento ? conciliacao.numeroDocumento.toString() : '';
    let valorTotalConciliacao: number = 0;
    for (let i = 0; i < movimentacaoParcelas.length; i++) {
      let conciliacaoNova: Conciliacao = JSON.parse(JSON.stringify(conciliacao));
      valorTotalConciliacao += this.baixa.util.arredondar(conciliacao.valor / movimentacaoParcelas.length);
      conciliacaoNova.valor = this.baixa.util.arredondar(conciliacao.valor / movimentacaoParcelas.length);
      conciliacaoNova.chave = chave + '_' + i;
      conciliacaoNova.dataPrevista = this.setDataRapidaAnalisandoRegraTipoTitulo(movimentacaoParcelas[i].dataVencimento, conciliacao);
      conciliacaoNova.financeiroBaixas = this.gerarNovosFinanceirosBaixas(movimentacaoParcelas.length, conciliacao.financeiroBaixas);
      conciliacaoNova.parcela = i + 1 + '/' + movimentacaoParcelas.length;
      conciliacaoParcelas.push(conciliacaoNova);
    }
    this.arredondarValor(conciliacaoParcelas, valorTotalConciliacao, conciliacao.valor);
    return conciliacaoParcelas;
  }

  arredondarValor(conciliacaoParcelas: Conciliacao[], valorTotalConciliacao: number, valorConciliacao: number): void {
    if (valorTotalConciliacao != valorConciliacao) {
      let diferenca: number = this.util.arredondar(valorTotalConciliacao - valorConciliacao);
      if (diferenca < 0) {
        diferenca = diferenca * -1;
      }
      conciliacaoParcelas[0].valor = this.util.arredondar((conciliacaoParcelas[0].valor += diferenca));
      conciliacaoParcelas[0].financeiroBaixas[0].valor = this.util.arredondar((conciliacaoParcelas[0].financeiroBaixas[0].valor += diferenca));
      conciliacaoParcelas[0].financeiroBaixas[0].valorCheio = this.util.arredondar((conciliacaoParcelas[0].financeiroBaixas[0].valorCheio += diferenca));
    }
  }

  setDataRapidaAnalisandoRegraTipoTitulo(dataPrevista: Date, conciliacao: Conciliacao): Date {
    if (conciliacao.tipoTituloRegraRecebimento != 1) {
      if (conciliacao.tipoTituloRegraRecebimento == 2) {
        dataPrevista.setDate(dataPrevista.getDate() + conciliacao.tipoTituloDia);
      } else {
        dataPrevista = setDate(new Date(dataPrevista), conciliacao.tipoTituloDia);
        if (dataPrevista.getDate() > conciliacao.tipoTituloDia) {
          dataPrevista = addMonths(dataPrevista, 1);
        }
      }
    }
    return dataPrevista;
  }

  gerarNovosFinanceirosBaixas(quantidadeParcela: number, financeiroBaixas: FinanceiroBaixa[]): FinanceiroBaixa[] {
    let financeiroBaixaParcelados: FinanceiroBaixa[] = [];
    financeiroBaixas.forEach((financeiroBaixa) => {
      let financeiroBaixaNovo: FinanceiroBaixa = JSON.parse(JSON.stringify(financeiroBaixa));
      financeiroBaixaNovo.valor = this.baixa.util.arredondar(financeiroBaixa.valor / quantidadeParcela);
      financeiroBaixaNovo.valorCheio = this.baixa.util.arredondar(financeiroBaixa.valorCheio / quantidadeParcela);
      financeiroBaixaNovo.valorTroco = this.baixa.util.arredondar(financeiroBaixa.valorTroco / quantidadeParcela);
      financeiroBaixaParcelados.push(financeiroBaixaNovo);
    });
    return financeiroBaixaParcelados;
  }

  setPrioridadeBaixa(prioridadeBaixa: number): void {
    this.baixa.setPrioridadeBaixa(prioridadeBaixa);
  }

  abrirModalCheque(): void {
    const idsConta: number[] = this.utilSessao.getUsuarioContas().map((usuarioConta) => usuarioConta.idConta);
    this.utilSessao.setIdentificacao(new Identificacao('idsConta', idsConta));
    this.utilSessao.setIdentificacao(new Identificacao('conta', this.bibDialogo.contasComAcesso));
    this.utilSessao.setIdentificacao(new Identificacao('chequesSelecionados', this.montarCheques()));
    this.utilSessao.setIdentificacao(new Identificacao('chequeOrigemBaixa', true));
    this.componente = PesquisaChequeComponent;
  }

  montarCheques(): Cheque[] {
    const cheques: Cheque[] = [];
    this.baixa.financeiroBaixas.forEach((financeiroBaixa) => {
      if (financeiroBaixa.tipoTituloIdEspecie == 4 && !financeiroBaixa.id && financeiroBaixa.idCheque) {
        const cheque: Cheque = new Cheque();
        cheque.numero = financeiroBaixa.numeroDocumento;
        cheque.data = this.baixa.dataBaixa;
        cheque.dataPreDatada = financeiroBaixa.dataPrevista;
        cheque.parceiro = financeiroBaixa.chequeParceiro;
        cheque.valor = financeiroBaixa.valor;
        cheque.idLoja = financeiroBaixa.chequeIdLoja;
        cheque.abreviacao = financeiroBaixa.chequeAbreviacao;
        cheque.selecionado = 'S';
        cheque.id = financeiroBaixa.idCheque;
        cheques.push(cheque);
      }
    });
    return cheques;
  }

  setNumeroDocumentoParcelas(numeroDocumento: string): void {
    this.numeroDocumento = numeroDocumento;
  }

  vincularParcelasMovimentacao(): void {
    if (!this.numeroDocumento || this.numeroDocumento == '') {
      this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.informeNumeroDocumento));
    } else {
      this.baixa.financeiroBaixas = [];
      const financeiro: Financeiro = this.baixa.mostrarFinanceiroSelecionados()[0];
      this.comunicacaoService.listar(new Transporte(new Criterio('ID_MOVIMENTACAO', financeiro.idMovimentacao)), this.bibServico.movimentacaoParcela).subscribe((res) => {
        let movimentacaoParcelas: MovimentacaoParcela[] = this.plainToClass(MovimentacaoParcela, res) as any;
        movimentacaoParcelas.forEach((movimentacaoParcela) => {
          const usuario: Usuario = this.utilSessao.getUsuario();
          const financeiroBaixa: FinanceiroBaixa = new FinanceiroBaixa(movimentacaoParcela.getValorParcela(), null, financeiro.idConta, null, usuario.idEmpresa, usuario.id, usuario.id, 'F');
          financeiroBaixa.numeroDocumento = this.numeroDocumento;
          financeiroBaixa.dataPrevista = new Date(movimentacaoParcela.dataVencimento);
          this.baixa.financeiroBaixas.push(financeiroBaixa);
        });
        this.baixa.totalizarFinanceiroBaixa();
      });
    }
  }

  abrirModalHistoricoParceiro(): void {
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', this.baixa.parceiro.id));
    this.utilSessao.setIdentificacao(new Identificacao('pertence', 'P'));
    this.componente = CreditoComponent;
  }

  abrirModalHistoricoEmpresa(): void {
    this.utilSessao.setIdentificacao(new Identificacao('idParceiro', this.baixa.parceiro.id));
    this.utilSessao.setIdentificacao(new Identificacao('pertence', 'E'));
    this.componente = CreditoComponent;
  }

  ngOnDestroy(): void {
    this.baixaEmt.unsubscribe();
  }

  setLojaPagamento(loja: Loja) {
    this.lojaPagamento = loja;
  }

  listarConfiguracao(): void {
    this.multiplicarBaixaFinanceiro();
  }

  ehSetEtapa(): void {
    if (this.baixa.utilizaConferencia || this.baixa.utilizaIdFinanceiro) {
      this.baixa.setEtapa(1);
    } else {
      this.baixa.setEtapa(2);
    }
  }

  verificarTipoDeTitulo(): void {
    this.ehTipoTituloCheque();
    this.ehTipoTituloCartao();
    this.ehTipoTituloDinheiro();
  }

  ehTipoTituloCheque(): void {
    if (this.baixa.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.tipoTituloIdEspecie == 4).length > 0) {
      this.tipoTituloCheque = true;
    } else {
      this.tipoTituloCheque = false;
    }
  }

  ehTipoTituloCartao(): void {
    if (this.baixa.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.tipoTituloIdEspecie == 2 || financeiroBaixa.tipoTituloIdEspecie == 10).length > 0) {
      this.tipoTituloCartao = true;
      this.baixa.movimentacaoComCartaoCredito = true;
    } else {
      this.tipoTituloCartao = false;
      this.baixa.movimentacaoComCartaoCredito = false;
    }
  }

  ehTipoTituloDinheiro(): void {
    if (this.baixa.financeiroBaixas.filter((financeiroBaixa) => financeiroBaixa.tipoTituloIdEspecie == 1).length > 0) {
      this.tipoTituloDinheiro = true;
    } else {
      this.tipoTituloDinheiro = false;
    }
  }
}
