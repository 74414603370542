import { Component } from '@angular/core';
import { CategoriaRoteiroContabil } from 'src/app/modelo/categoriaRoteiroContabil';
import { Cidade } from 'src/app/modelo/cidade';
import { Criterio } from 'src/app/modelo/criterio';
import { Identificacao } from 'src/app/modelo/identificacao';
import { Resultado } from 'src/app/modelo/resultado';
import { RoteiroContabil } from 'src/app/modelo/roteiroContabil';
import { RoteiroContabilRateio } from 'src/app/modelo/roteiroContabilRateio';
import { Transporte } from 'src/app/modelo/transporte';
import { PaginaComponent } from 'src/app/utilitario/pagina.component';
import { RoteiroContabilRateioFrmComponent } from './roteiroContabilRateioFrm/roteiroContabilRateioFrm.component';

@Component({
  templateUrl: './roteiroContabilFrm.component.html',
})
export class RoteiroContabilFrmComponent extends PaginaComponent {
  public categoriaRoteiroContabeis: CategoriaRoteiroContabil[] = [];
  public componente: any;
  public idsRoteiroContabilRateioExclusao: number[] = [];
  public modificaNome: boolean = false;
  public nomeCategoriaRoteiroContabil: string;
  public roteiroContabil: RoteiroContabil = new RoteiroContabil();
  public listaLojaDiferente: any[] = [
    { id: 'S', nome: this.bibDialogo.sim.toUpperCase() },
    { id: 'N', nome: this.bibDialogo.nao.toUpperCase() },
  ];

  ngOnInit(): void {
    this.ehAlteracao();
    this.listarCategoriaRoteiroContabeis();
    this.modalNotificacao.modalEmt.subscribe((identificacao) => {
      if (identificacao.nome == this.bibClasse.roteiroContabilRateio) {
        this.adicionarTemporario(this.plainToClass(RoteiroContabilRateio, identificacao.conteudo), this.roteiroContabil.roteiroContabilRateios);
        this.verificarNome(this.roteiroContabil.roteiroContabilRateios[0]);
      }
    });
  }

  verificarNome(roteiroContabilRateio: RoteiroContabilRateio): void {
    if (this.modificaNome) {
      this.roteiroContabil.nome = roteiroContabilRateio.historico;
    }
  }

  ehAlteracao(): void {
    this.roteiroContabil = this.plainToClass(Cidade, this.ehAtualizacao(this.roteiroContabil));
    if (this.roteiroContabil.id) {
      this.listarRoteiroContabil(this.roteiroContabil.id);
    }
  }

  listarCategoriaRoteiroContabeis(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ATIVO', 'S')), this.bibServico.categoriaRoteiroContabil).subscribe((res) => {
      this.categoriaRoteiroContabeis = this.plainToClass(CategoriaRoteiroContabil, res) as any;
    });
  }

  listarRoteiroContabil(id: number): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.roteiroContabil).subscribe((res) => {
      this.roteiroContabil = this.plainToClass(RoteiroContabil, res[0]) as any;
      this.listarRoteiroContabilRateio();
    });
  }

  listarRoteiroContabilRateio(): void {
    this.comunicacaoService.listar(new Transporte(new Criterio('ID_ROTEIRO_CONTABIL', this.roteiroContabil.id)), this.bibServico.roteiroContabilRateio).subscribe((res) => {
      this.roteiroContabil.roteiroContabilRateios = this.plainToClass(RoteiroContabilRateio, res) as any;
      this.ehAbrirModal();
    });
  }

  ehAbrirModal(): void {
    if (this.categoriaRoteiroContabeis.length == 1 && this.roteiroContabil.roteiroContabilRateios.length == 0) {
      this.abrirModal(null);
    }
  }

  setCategoriaRoteiroContabil(id: number): void {
    this.setNomeCategoriaRoteiroContabil(id);
    this.roteiroContabil.idCategoriaRoteiroContabil = id;
    if (this.roteiroContabil.roteiroContabilRateios.length == 0 && !this.roteiroContabil.id) {
      this.abrirModal(null);
    }
  }

  setNomeCategoriaRoteiroContabil(id: number): void {
    this.categoriaRoteiroContabeis.find((categoriaRoteiroContabil) => {
      if (categoriaRoteiroContabil.id == id) {
        this.nomeCategoriaRoteiroContabil = categoriaRoteiroContabil.nome;
      }
    });
  }

  abrirModal(roteiroContabilRateio: RoteiroContabilRateio): void {
    this.atualizaModificaNome(roteiroContabilRateio);
    this.utilSessao.setIdentificacao(new Identificacao('objeto', roteiroContabilRateio));
    this.utilSessao.setIdentificacao(new Identificacao('nomeRoteiroContabil', this.roteiroContabil.nome && this.nomeCategoriaRoteiroContabil != undefined ? this.roteiroContabil.nome + ' - ' + this.nomeCategoriaRoteiroContabil : this.nomeCategoriaRoteiroContabil != undefined ? this.nomeCategoriaRoteiroContabil : this.roteiroContabil.nome ? this.roteiroContabil.nome : this.menuRoteiroContabil.apelido));
    this.utilSessao.setIdentificacao(new Identificacao('lojaDiferente', this.roteiroContabil.lojaDiferente));
    this.componente = RoteiroContabilRateioFrmComponent;
  }

  atualizaModificaNome(roteiroContabilRateio: RoteiroContabilRateio): void {
    this.modificaNome = this.roteiroContabil.nome == null ? true : roteiroContabilRateio && roteiroContabilRateio.historico == this.roteiroContabil.nome ? true : false;
  }

  persistirRoteiroContabil(novo: boolean = false): void {
    if (this.ehValido()) {
      let transporte: Transporte = new Transporte(this.roteiroContabil);
      transporte.adicionar(this.idsRoteiroContabilRateioExclusao);
      super.persistir(transporte, this.bibServico.roteiroContabil, novo ? new RoteiroContabil() : null).subscribe(() => {
        if (!this.utilSessao.falha) {
          this.idsRoteiroContabilRateioExclusao = [];
          this.definirId([this.roteiroContabil], this.bibClasse.roteiroContabil, true);
          this.definirId(this.roteiroContabil.roteiroContabilRateios, this.bibClasse.roteiroContabilRateio);
          this.atualizarDados(this.roteiroContabil.id);
          if (novo) {
            this.roteiroContabil.roteiroContabilRateios = [];
          }
        }
      });
    }
  }

  atualizarDados(id: number): void {
    if (id != null && id != 0) {
      this.comunicacaoService.listar(new Transporte(new Criterio('ID', id)), this.bibServico.roteiroContabil).subscribe((res) => {
        const roteiroContabil = this.plainToClass(RoteiroContabil, res[0]) as any;
        this.roteiroContabil.numero = roteiroContabil.numero;
      });
    }
  }

  ehValido(): boolean {
    const ehValidoObrigatorio: boolean = this.setEhValidoObrigatorio();
    const ehValidoUmRateio: boolean = this.setEhValidoUmRateio();
    const ehValidoRateioContaIgual: boolean = this.setEhValidoRateioContaIgual();
    return ehValidoObrigatorio && ehValidoUmRateio && ehValidoRateioContaIgual;
  }

  setEhValidoObrigatorio(): boolean {
    if (this.roteiroContabil.nome && this.roteiroContabil.idCategoriaRoteiroContabil) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.verifiqueObrigatorio));
    return false;
  }

  setEhValidoUmRateio(): boolean {
    if (this.roteiroContabil.roteiroContabilRateios.length > 0) {
      return true;
    }
    this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.deveConterPeloMenosUmRoteiro));
    return false;
  }

  setEhValidoRateioContaIgual(): boolean {
    let ehValidoRateioIgual: boolean = true;
    this.roteiroContabil.roteiroContabilRateios.forEach((roteiroContabilRateio) => {
      let contemRateioIgual: number = 0;
      contemRateioIgual += this.roteiroContabil.roteiroContabilRateios.filter((roteiroContabilRateioBusca) => roteiroContabilRateioBusca.idContaContabil == roteiroContabilRateio.idContaContabil && roteiroContabilRateioBusca.idContaContabilContrapartida == roteiroContabilRateio.idContaContabilContrapartida).length;
      if (contemRateioIgual > 1) {
        this.utilSessao.setResultado(new Resultado(false, this.bibDialogo.roteiroRepetido));
        ehValidoRateioIgual = false;
      }
    });
    return ehValidoRateioIgual;
  }
}
