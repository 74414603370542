<relatorioLayout [relatorio]="relatorio" [relatorioResultados]="this.relatorioResultados" [excel]="excel" [empresa]="empresa" [filtros]="filtros" [relatorioAdicionais]="relatorioAdicionais" (filtroEmt)="listarRelatorio($event)">
  <table>
    <thead>
      <th class="r-0-5">{{ bibDialogo.loja }}</th>
      <th class="largura-data">{{ bibDialogo.data }}</th>      
      <th class="r-1-5">{{ bibDialogo.contaContabil }}</th> 
      <th class="r">{{ bibDialogo.historico }}</th>
      <th class="r-1 text-right">{{ bibDialogo.debito }}</th>
      <th class="r-1 text-right">{{ bibDialogo.credito }}</th>
      <th class="r-1 text-right">{{ bibDialogo.saldo }}</th>
    </thead>
    <!-- <tbody>
      <ng-container *ngIf="lancamentoContabeis.length > 0 && ehRelatorio4 || ehRelatorio2008">
        <tr>
          <td [colSpan]="mostrarLoja ? 4 : 3"></td>
          <td [colSpan]="ehRelatorio2008 ? 2 : 1" class="text-right">{{ bibDialogo.saldoAnterior }}</td>
          <td colspan="2"></td>
        </tr>
      </ng-container>
    </tbody> -->
    <tbody>
      <ng-container *ngFor="let contaContabil of contaContabeis; let i = index">
        <ng-container *ngIf="i > 0"><tr class="sem-borda"><td></td></tr><tr class="sem-borda"><td></td></tr><tr class="sem-borda"><td></td></tr>  </ng-container>      
        <tr>
          <td colspan="5" class="negrito">{{ contaContabil.numeracao + ' - ' +contaContabil.nome }}<atencao [atencao]="contaContabil.id"></atencao></td>
          <td colspan="2" class="fonte-9px text-right">{{ contaContabil.saldoAnterior | monetario }}</td>
        </tr>
        <ng-container *ngFor="let lancamentoContabil of contaContabil.lancamentoContabeis; let j = index">
          <tr class="conteudo">
            <td>{{ lancamentoContabil.abreviacao }}</td>
            <td>{{ (lancamentoContabil.data | data) }}</td>          
            <td class="fonte-10px"><ajuda [ajuda]="lancamentoContabil.contaContabilContraPartida" [ajudaDireita]="true"></ajuda>{{ lancamentoContabil.numeracaoContrapartida }}</td> 
            <td class="fonte-10px">{{ lancamentoContabil.historico }}</td>
            <td class="text-right">{{ lancamentoContabil.debito | monetario }}</td>
            <td class="text-right">{{ lancamentoContabil.credito | monetario }}</td>          
            <td class="fonte-9px text-right" [ngClass]="contaContabil.lancamentoContabeis.length == j + 1 ? 'negrito' : ''">{{ lancamentoContabil.saldo | monetario }}</td>
          </tr>
          <!-- <tr rodapeTotal [relatorioGrupo]="relatorioGrupo" [i]="i" class="sem-borda"></tr> -->
        </ng-container>
      </ng-container>
    </tbody>
    <!-- <tfoot *ngIf="lancamentoContabeis.length > 0">
      <tr>
        <td *ngIf="ehRelatorio4 || ehRelatorio2008 || ehRelatorio2023" [colSpan]="mostrarLoja ? 5 : 4"></td>
        <td *ngIf="ehRelatorio2022 || ehRelatorio2023" [colSpan]="mostrarLoja  || ehRelatorio2023 ? 4 : 3"></td>        
        <td class="text-right font-weight-bold borda-cima" [colSpan]="ehRelatorio2008 ? 2 : 1">{{ lancamentoContabeis[0].debitoTotal | monetario }}</td>
        <td class="text-right font-weight-bold borda-cima">{{ lancamentoContabeis[0].creditoTotal | monetario }}</td>
        <td class="text-right font-weight-bold borda-cima">{{ saldoAtual | monetario }}</td>
      </tr>
    </tfoot> -->
  </table>
</relatorioLayout>
