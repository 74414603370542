<div class="pt-3 pl-2 pr-2">
  <header class="card-header modal-etapas">
    <ul class="nav nav-tabs card-header-tabs">
      <li class="nav-item">
        <a class="nav-link active show" data-toggle="tab" id="pesquisa" (click)="setAbaSelecionada(false)" href="#pesquisa">{{ bibDialogo.pesquisa }}</a>
      </li>
      <li *ngIf="retornaConsulta" class="nav-item">
        <a class="nav-link" data-toggle="tab" id="abaSelecionado" (click)="setAbaSelecionada(true)" href="#selecionados"
          >{{ bibDialogo.selecionados }}<span *ngIf="selecionados.length > 0" class="badge badge-primary">{{ selecionados.length }}</span></a
        >
      </li>
    </ul>
  </header>
  <div id="myTabContent" class="tab-content mt-1">
    <div class="tab-pane fade active show" id="pesquisa">
      <div class="row form-group" [ngClass]="compacto ? 'tamanho-minimo-compacto' : 'm-0'">
        <select *ngIf="usaAbaSelecionada == false" [ngClass]="[compacto ? 'compacto' : 'compacto', !ehModoClaro ? 'modo-escuro' : '']" [(ngModel)]="filtro.nome" class="col-sm-2 custom-select">
          <option *ngFor="let nome of ['NOME', 'NUMERO', 'CODIGO_BARRA', 'REFERENCIA']" [value]="nome">{{ nome == 'NOME' ? 'PRODUTO' : nome == 'NUMERO' ? 'Nº PRODUTO' : nome == 'CODIGO_BARRA' ? 'CÓDIGO BARRA' : 'REFERÊNCIA' }}</option>
        </select>
        <busca *ngIf="usaAbaSelecionada == false && !apresentaFiltros" class="col-sm" [focoId]="focoId" [minimoCaracterBusca]="this.filtro.nome == 'NUMERO' ? 1 : 3" [campo]="filtro.valor" (alteracao)="alterarValorFiltro($event)" [listaExterna]="true" [compacto]="compacto" [foco]="true" [id]="'produto'" [focoId]="focoBusca" (btnAdicional)="apresentaFiltros == false ? listarProduto() : null" (navegacao)="$event == true ? (posicaoProduto = -1) : (pesquisando = false)" [compacto]="true"></busca>
        <texto *ngIf="usaAbaSelecionada == false && apresentaFiltros" class="col-sm" [focoId]="focoId" [campo]="filtro.valor" (alteracao)="alterarValorFiltro($event)" [compacto]="compacto" [foco]="true" [id]="'produto'" [compacto]="true" [focoId]="focoBusca"></texto>
        <p *ngIf="usaAbaSelecionada == false" class="col-sm-4" [ngClass]="compacto ? '' : ''">
          {{ apresentaFiltros ? bibDialogo.paraMenosOpcoesFiltro : bibDialogo.paraMaisOpcoesFiltro }}<strong class="clique" (click)="setApresentaFiltro()">{{ ' ' + bibDialogo.cliqueAqui.toLowerCase() }}</strong>
        </p>
        <titulo *ngIf="usaAbaSelecionada == true" [titulo]="bibDialogo.selecionados.toLowerCase()" [compacto]="true"></titulo>
      </div>
      <div *ngIf="apresentaFiltros && !usaAbaSelecionada">
        <div class="row">
          <busca class="col-sm" [rotulo]="menuFabricante.apelido" [compacto]="true" [campo]="nomeFabricante" (alteracao)="setFiltroFabricante($event)" [servico]="bibServico.fabricante"></busca>
          <busca class="col-sm" [rotulo]="menuGrupo.apelido" [compacto]="true" [campo]="nomeGrupo" (alteracao)="setFiltroGrupo($event)" [servico]="bibServico.grupo"></busca>
        </div>
        <div class="row">
          <decimal class="col-sm" [rotulo]="bibDialogo.estDisponivelDe" [compacto]="true" [campo]="filtroSaldoDe.valor" (alteracao)="filtroSaldoDe.valor = $event"></decimal>
          <decimal class="col-sm" [rotulo]="bibDialogo.estDisponivelAte" [compacto]="true" [campo]="filtroSaldoAte.valor" (alteracao)="setFiltroSaldoAte($event)"></decimal>
        </div>
        <div class="row">
          <botao [compacto]="compacto" class="col-sm" [legenda]="bibDialogo.pesquisar" [compacto]="true" [fundo]="true" (botaoEmt)="listarProduto()"></botao>
        </div>
      </div>
      <table class="table table-responsive-sm tabela-fixa">
        <thead [ngClass]="loja ? 'fonte-pequena' : 'fonte-media'">
          <tr>
            <th *ngIf="retornaConsulta" class="largura-check"></th>
            <th class="largura-numero text-right clique" (click)="util.ordenarLista(usaAbaSelecionada ? selecionados : produtos, 'numero')">{{ bibDialogo.numero }}</th>
            <th [ngClass]="interna || usaLimitarEstoquePorLoja ? 'largura-produto-g' : 'largura-produto'" class="clique" (click)="util.ordenarLista(usaAbaSelecionada ? selecionados : produtos, 'nome')">{{ bibDialogo.produto }}</th>
            <th class="largura-unidade clique" (click)="util.ordenarLista(usaAbaSelecionada ? selecionados : produtos, 'abreviacao')">{{ bibDialogo.unidadeAbreviacao }}</th>
            <th class="largura-valor text-right clique" *ngIf="!interna && (loja || utilizaTabelaPreco)" (click)="util.ordenarLista(usaAbaSelecionada ? selecionados : produtos, entrada || naturezaOperacaoUsaCustoReposicao == '2' ? 'custoReposicao' : naturezaOperacaoUsaCustoReposicao == '1' ? 'precoVenda' : 'custoNfe')">{{ entrada || naturezaOperacaoUsaCustoReposicao == '3' || naturezaOperacaoUsaCustoReposicao == '2' ? bibDialogo.custo : bibDialogo.precoVenda }}</th>
            <th *ngIf="loja" [ngClass]="!usaAbaSelecionada ? 'largura-valor' : 'aba-selecionada-largura-valor'" class="text-right clique" (click)="util.ordenarLista(usaAbaSelecionada ? selecionados : produtos, 'quantidadeDisponivel')">{{ !usaAbaSelecionada ? bibDialogo.estoque + ' ' + loja.abreviacao : bibDialogo.quantidade }}</th>
            <th *ngIf="loja && !usaAbaSelecionada && !usaLimitarEstoquePorLoja" class="largura-valor text-right clique" (click)="util.ordenarLista(usaAbaSelecionada ? selecionados : produtos, 'quantidadeTotalDisponivel')">{{ bibDialogo.disponivelTotal }}</th>
          </tr>
        </thead>
        <tbody #corpoTabelaProduto [ngClass]="usaAbaSelecionada ? 'tamanho-produto' : abaMaisInformacoes && apresentaFiltros ? 'tamanho-produto-reduzido-filtro-informacoes' : !abaMaisInformacoes && apresentaFiltros ? 'tamanho-produto-reduzido-filtro' : !abaMaisInformacoes ? 'tamanho-produto' : 'tamanho-produto-reduzido'" tabindex="-1" (keydown)="$event.keyCode == bibAtalho.espaco ? $event.preventDefault() : null">
          <ng-container *ngFor="let produto of usaAbaSelecionada ? selecionados : produtos; let i = index">
            <tr (click)="usaAbaSelecionada ? '' : listarEstoque(produto.id, i)" [ngClass]="i == posicaoProduto ? 'selecionado' : ''" class="clique" (dblclick)="retornaConsulta ? ehSetSelecionado(i) : ''">
              <td *ngIf="retornaConsulta" class="largura-check">
                <check [campo]="produto.selecionado" [id]="'selecionaItem' + i" (alteracao)="ehSetSelecionado(i)"></check>
              </td>
              <td class="largura-numero text-right">{{ produto.numero }}</td>
              <td [ngClass]="interna || usaLimitarEstoquePorLoja ? 'largura-produto-g' : 'largura-produto'" class="limitar">{{ produto.nome }}</td>
              <td class="largura-unidade">{{ produto.abreviacao }}</td>
              <td class="largura-valor text-right" *ngIf="!interna && (loja || utilizaTabelaPreco)">{{ (entrada && naturezaOperacaoUsaCustoReposicao == '2' ? produto.custoReposicao : naturezaOperacaoUsaCustoReposicao == '3' ? produto.custoNfe : produto.precoVenda) | monetario: this.empresaRegraCasasDecimais }}</td>
              <td *ngIf="loja" [ngClass]="!usaAbaSelecionada ? 'largura-valor' : 'aba-selecionada-largura-valor'" class="text-right"><decimal *ngIf="usaAbaSelecionada" [campo]="produto.quantidade ? produto.quantidade : 1" [compacto]="true" (alteracao)="produto.quantidade = $event" [id]="'quantidadeSelecionada' + i" [numeroCasasDecimais]="this.empresaRegraCasasDecimais" [focoId]="focoQuantidadeProduto"></decimal>{{ !usaAbaSelecionada ? (produto.quantidadeDisponivel ? (produto.quantidadeDisponivel | monetario: empresaRegraCasasDecimais) : '0,00') : '' }}</td>
              <td *ngIf="loja && !usaAbaSelecionada && !usaLimitarEstoquePorLoja" class="largura-valor text-right">{{ !usaAbaSelecionada ? (produto.quantidadeTotalDisponivel ? (produto.quantidadeTotalDisponivel | monetario: empresaRegraCasasDecimais) : '0,00') : '' }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <div *ngIf="!usaAbaSelecionada" class="pt-0 card-header mt-1 row justify-content-between align-items-center">
        <ul class="nav nav-tabs card-header-tabs pt-1">
          <li *ngIf="produtos[posicaoProduto]" class="pt-2 offset clique" (click)="alterarTamanhoInformacoes(produtos[posicaoProduto].id, posicaoProduto)">
            <strong class="mr-4">{{ abaMaisInformacoes ? bibDialogo.recolherInformacoes : bibDialogo.maisInformacoes }}</strong>
          </li>
          <li *ngIf="produtos[posicaoProduto] ? produtos[posicaoProduto].observacao : false">
            <btnAdicional [icone]="bibIcone.carta" [ajuda]="bibDialogo.produtoComObservacao" (btnAdicional)="mostrarObservacao(produtos[posicaoProduto].id, posicaoProduto)"></btnAdicional>
          </li>
          <li *ngIf="produtos[posicaoProduto] ? produtos[posicaoProduto].foto == 'S' : false">
            <btnAdicional [icone]="bibIcone.cameraFoto" [ajuda]="bibDialogo.produtoComFoto" (btnAdicional)="mostrarFoto(produtos[posicaoProduto].id, posicaoProduto)"></btnAdicional>
          </li>
        </ul>
        <div class="row align-content-end" *ngIf="produtos.length > 0 && posicaoProduto > -1">
          <div class="ml-3 pb-0" *ngIf="produtos[posicaoProduto].categoria"><atencao [atencao]="bibDialogo.deposito.toUpperCase() + ': ' + produtos[posicaoProduto].categoria"></atencao></div>
          <div class="ml-3 pb-0" *ngIf="utilizaTabelaPreco" (click)="abrirModalTabelaPreco(produtos[posicaoProduto])"><ajuda class="naoImprimir" [ajuda]="'Clique aqui para vizualizar a tabela de preços'"></ajuda><atencao [atencao]="bibDialogo.tabelaPreco.toUpperCase()" [tipo]="bibPropriedade.atencao.roxo"></atencao></div>
          <div class="ml-3 pb-0" *ngIf="produtos[posicaoProduto].custoGerencial > 0  && (permissaoProdutoValor !== undefined || entrada || utilSessao.sistema != 'aGrow-w')"><ajuda class="naoImprimir" [ajuda]="custoGerencial"></ajuda> <atencao [atencao]="nomenclaturaCustoGerencial.toUpperCase() + ': ' + (produtos[posicaoProduto].custoGerencial | monetario)"></atencao></div>
          <div class="ml-3 pb-0" *ngIf="produtos[posicaoProduto].precoVendaMinimo > 0 && saida || ehF8"><ajuda class="naoImprimir" [ajuda]="bibDialogo.precoVendaMinimo"></ajuda><atencao [atencao]="bibDialogo.pvm + ': ' + (produtos[posicaoProduto].precoVendaMinimo | monetario)"></atencao></div>
          <div class="pb-0" *ngIf="!produtos[posicaoProduto].custoGerencial && !saida"><atencao [atencao]="bibDialogo.semCustoGerencial" [tipo]="bibPropriedade.atencao.discreto"></atencao></div>
        </div>
      </div>
      <div *ngIf="abaMaisInformacoes" class="row">
        <header *ngIf="produtos[posicaoProduto]" class="pt-0 card-header modal-estoque-descricao row justify-content-between align-items-center abas-pesquisa-produto">
          <ul class="nav nav-tabs card-header-tabs pt-1">
            <li class="nav-item pt-1" (click)="ativarAbaEstoque()">
              <a [ngClass]="mostraEstoque ? 'active show' : ''" [class]="'nav-link pb-0 pt-1'" data-toggle="tab" href="#estoque">{{ bibDialogo.estoque }}</a>
            </li>
            <li *ngIf="produtos[posicaoProduto] && produtos[posicaoProduto] && produtos[posicaoProduto].observacao" class="nav-item pl-2 pt-1" (click)="ativarAbaObservacao()">
              <a [ngClass]="mostraObservacao ? 'active show' : ''" [class]="'nav-link pb-0 pt-1'" data-toggle="tab" href="#descricao">{{ bibDialogo.observacao }}</a>
            </li>
            <li *ngIf="produtos[posicaoProduto].foto == 'S'" (click)="ativarAbaFotos(produtos[posicaoProduto].id)" class="nav-item pl-0 pt-1">
              <a [ngClass]="mostraFoto ? 'active show' : ''" [class]="'nav-link pb-0 pt-1'" data-toggle="tab" href="#fotos">{{ bibDialogo.fotos }}</a>
            </li>
          </ul>
        </header>
        <div *ngIf="produtos[posicaoProduto]" id="myTabContent" class="tab-content">
          <div [ngClass]="mostraEstoque ? 'active show' : ''" class="tab-pane fade" id="estoque">
            <table class="table table-responsive-sm tabela-fixa tamanho-informacoes">
              <thead class="fonte-media">
                <tr class="altura-minima">
                  <th class="border-0" [ngClass]="usaEntregaOpcional ? 'l-20' : 'l-10'">{{ bibDialogo.loja }}</th>
                  <th *ngIf="usaEntregaOpcional" class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.fisica }}</th>
                  <th class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.disponivel }}</th>
                  <th class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.pendente }}</th>
                  <th class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.comprado }}</th>
                  <th class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.nfe }}</th>
                  <th class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.qtdMinima }}</th>
                  <th class="border-0 text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ bibDialogo.qtdMaxima }}</th>
                </tr>
              </thead>
              <tbody *ngIf="usaAbaSelecionada ? (this.selecionados.length > 0 ? true : false) : true">
                <ng-container *ngFor="let estoqueLoja of estoqueProdutoMinimoMaximos; let i = index">
                  <tr [ngClass]="loja && estoqueLoja.idLoja == loja.id ? 'bg-amarelo-claro altura-maxima' : 'altura-maxima'">
                    <td [ngClass]="usaEntregaOpcional ? 'l-20' : 'l-10'">{{ estoqueLoja.abreviacao }}</td>
                    <td *ngIf="usaEntregaOpcional" class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ estoqueLoja.quantidadeFisica | monetario: empresaRegraCasasDecimais }}</td>
                    <td class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ estoqueLoja.quantidadeDisponivel | monetario: empresaRegraCasasDecimais }}</td>
                    <td class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ (estoqueLoja.quantidadeReservada + estoqueLoja.quantidadeCarteira) | monetario: empresaRegraCasasDecimais }}</td>
                    <td class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ estoqueLoja.quantidadeComprada | monetario: empresaRegraCasasDecimais }}</td>
                    <td class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ estoqueLoja.quantidadeNfe | monetario: empresaRegraCasasDecimais }}</td>
                    <td class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ estoqueLoja.quantidadeMinima | monetario: empresaRegraCasasDecimais }}</td>
                    <td class="text-right" [ngClass]="usaEntregaOpcional ? 'l-10' : 'l-15'">{{ estoqueLoja.quantidadeMaxima | monetario: empresaRegraCasasDecimais }}</td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <div [ngClass]="mostraObservacao ? 'active show' : ''" class="tab-pane fade" id="descricao">
            <table class="table table-responsive-sm tabela-fixa tamanho-informacoes">
              <tbody>
                <tr>
                  <td class="l-100">
                    <span *ngIf="produtos[posicaoProduto] ? produtos[posicaoProduto].observacao : false">{{ posicaoProduto > -1 ? (usaAbaSelecionada == true ? (selecionados.length > 0 ? selecionados[posicaoProduto].observacao : '') : produtos.length > 0 ? produtos[posicaoProduto].observacao : '') : '' }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div [ngClass]="mostraFoto ? 'active show' : ''" class="tab-pane fade" id="fotos">
            <table class="table table-responsive-sm tabela-fixa tamanho-informacoes">
              <tbody>
                <tr>
                  <td class="l-95 tamanho-informacoes"><carrossel *ngIf="possuiFoto" [pasta]="'produto'" [idPai]="produtos[posicaoProduto].id" [fotos]="produtoFotos" [somenteVisualizacao]="true" (imagem)="abrirModalImagem($event)"></carrossel></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div [ngClass]="mostraTabelaPreco ? 'active show' : ''" class="tab-pane fade" id="tabelaPreco">

          </div>          
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="retornaConsulta" class="row align-items-center">
    <botao [compacto]="true" class="col" [legenda]="bibDialogo.adicionar" [fundo]="true" (botaoEmt)="adicionarProduto()" (saindo)="focoId = $event" [desabilitado]="this.selecionados.length == 0"></botao>
    <check *ngIf="this.utilSessao.getUsuario()?.agrow == 'S'" [id]="'marcarTodos'" (alteracao)="selecionarTodos()" [rotulo]="bibDialogo.selecionarTodos + ' [' + bibDialogo.somenteUsuarioAgrow + ']'"></check>
    <atencao [atencao]="bibDialogo.teclaEspacoSelecionar"></atencao>
    <atencao [atencao]="bibDialogo.teclaEnterAdicionar"></atencao>
    <atencao [atencao]="bibDialogo.buscaParcial"></atencao>
  </div>
  <div *ngIf="blocoQuantidadeAberto" class="card card-quantidade bg-cinza-claro">
    <div class="modal-content">
      <div class="modal-header">
        <div class="card-header">{{ produtos[posicaoProduto] ? produtos[posicaoProduto].nome : '' }}</div>
        <span (click)="fecharCard()" aria-hidden="true"><i class="fa fa-times"></i></span>
      </div>
      <div class="card-body row m-0 p-0 justify-content-center">
        <decimal [id]="'quantidade'" [campo]="produtos[posicaoProduto]?.multiplicador ? produtos[posicaoProduto]?.multiplicador : 1" [rotulo]="bibDialogo.quantidade" [foco]="true" [focoId]="focoQuantidadeProduto" (alteracao)="produtos[posicaoProduto].quantidade = $event" (navegacao)="$event == true && produtos[posicaoProduto]?.multiplicador ? (produtos[posicaoProduto].quantidade = produtos[posicaoProduto]?.multiplicador) : null" [numeroCasasDecimais]="this.empresaRegraCasasDecimais"> </decimal>
      </div>
      <div class="row p-0 m-0 justify-content-end">
        <atencao [atencao]="bibDialogo.enterParaConfirmar"></atencao>
      </div>
    </div>
  </div>
</div>
<modal *ngIf="componente" [componente]="componente" (modalEmt)="componente = null"></modal>
